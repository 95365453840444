import narratives from '../narratives';

const bankingCodes = {
  '01': 'Uncollected Overdraft or Charges',
  '02': 'Unacceptable Cheque Activity (e.g. NSF)',
  '03': 'Unacceptable ABM Activity',
  '04': 'Unacceptable Deposit Account',
  '05': 'Supplied false information when opening accounts',
};

const dischargeCodes = {
  '01': 'Hearing set',
  '02': 'Absolute discharge',
  '03': 'Suspended discharge',
  '04': 'Conditional discharge',
  '05': 'Hearing adjourned',
  '06': 'Hearing refused',
  '07': 'Discharge annulled',
  '08': 'Discharge deceased',
  '09': 'Automatic discharge',
  10: 'Certificate of performance',
  11: 'Business proposal failed',
  12: 'Report filed with conditions',
  13: 'Absolute discharge trustee conditions met',
  14: 'Conditional discharge trustee recommendations',
  15: 'Opposition/request for discharge',
  16: 'Mediation',
  17: 'Conditional discharge conditions set by Mediation',
  18: 'Absolute discharge mediation conditions met',
  19: 'Absolute discharge',
};

const lookup = (table, code) => {
  if (!(code in table)) return '';
  return table[code];
};

export default {
  narrative: (code) => (narratives(code)),
  banking: (code) => (lookup(bankingCodes, code)),
  discharge: (code) => (lookup(dischargeCodes, code)),
};
