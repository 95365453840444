const getUserLocationIdFromAttributesIfDefined = (userAttributes) => {
  if (userAttributes.preferredLocationId) {
    return userAttributes.preferredLocationId;
  }
  if (userAttributes.homeLocationId) {
    return userAttributes.homeLocationId;
  }
  return null;
};

export default getUserLocationIdFromAttributesIfDefined;
