import React, { useContext, useState } from 'react';
import getCollapseConfig from '../../../utils/get-collapse-config';

const IsCollapsedContext = React.createContext();

export function IsCollapsedProvider({
  children, clientConfig,
}) {
  const collapseConfig = getCollapseConfig(clientConfig);

  const [state, setState] = useState(collapseConfig);

  const contextData = Object.fromEntries(
    Object.entries(state).map(([key, value]) => [
      key,
      {
        isCollapsed: value,
        toggleIsCollapsed: () => setState((prev) => ({
          ...prev,
          [key]: !prev[key],
        })),
      },
    ]),
  );

  return (
    <IsCollapsedContext.Provider value={contextData}>
      {children}
    </IsCollapsedContext.Provider>
  );
}

const useIsCollapsed = () => useContext(IsCollapsedContext);

export default useIsCollapsed;
