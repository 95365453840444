export default (idType, now = new Date()) => {
  const monthNumber = now.getMonth() + 1;
  const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  const date = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const seconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

  const prefix = idType === 'customer' ? 'ConsoleCustId' : 'ConsoleLoanId';

  return `${prefix}-${now.getFullYear()}${month}${date}-${hours}${minutes}${seconds}`;
};
