import BaseVehicleInputGroupView from './BaseVehicleInputGroup.view';

export default BaseVehicleInputGroupView;

export const fieldNames = [
  'VIN',
  'odometer',
  'odometerUnits',
  'make',
  'model',
  'bodyStyle',
  'trim',
  'year',
];
