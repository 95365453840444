import React from 'react';
import { getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth';
import Loader from '../components/Loader';
import SignOut from '../components/SignOut';
import SITE_PATHS from '../config/sitePaths';

const isUserLoggedIn = async () => {
  try {
    await getCurrentUser();
    return true;
  } catch {
    return false;
  }
};

export default function withAuthentication(Inner) {
  return class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAuthenticated: false,
        isLoading: true,
      };
    }

    async componentDidMount() {
      const isAuthenticated = await isUserLoggedIn();
      this.setState({ isAuthenticated, isLoading: false });
    }

    render() {
      if (this.state.isLoading) return (<Loader />);
      if (new RegExp(`${SITE_PATHS.SIGNOUT}$`).test(window.location.href)) {
        return (<SignOut isAuthenticated={this.state.isAuthenticated} signout={signOut} />);
      }
      if (this.state.isAuthenticated) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return (<Inner {...this.props} />);
      }
      signInWithRedirect();
      return null;
    }
  };
}
