import React from 'react';
import styles from '../TransUnionView.module.scss';
import TransUnionBranding from '../TransUnionBranding';
import TextReportView from './TextReportView';

const renderFallback = (report) => (
  <>
    <div className={styles.reportHeader}>
      <TransUnionBranding />
      <div className={styles.order}>
        <div className={styles.orderHeader}>
          <div>
            This Order
          </div>
        </div>
      </div>
    </div>
    <div className={styles.reportBody}>
      <div className={styles.textReport}>
        <TextReportView report={report} />
      </div>
    </div>
  </>
);

export default class ReportBulkhead extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.warn(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return renderFallback(this.props.report);
    }

    return this.props.children;
  }
}
