import React from 'react';

import { Alert, UncontrolledTooltip } from 'reactstrap';
import updateImpersonation from '../../utils/impersonation';

import styles from './style.module.scss';

const onStop = () => {
  updateImpersonation('', () => window.location.reload(false));
};

function ImpersonationBanner(props) {
  return (
    <div className={styles.impersonationBanner}>
      <Alert
        className={styles.impersonationContent}
        color="warning"
      >
        <div className={styles.impersonationMessage}>
          { `Viewing ${props.clientName}` }
        </div>
        <button
          type="button"
          id="exitImpersonation"
          className={`${styles.impersonationButton} close`}
          onClick={onStop}
        >
          Stop viewing
          <span className={styles.impersonationX}>&times;</span>
        </button>
        <UncontrolledTooltip
          className={styles.impersonationPopper}
          placement="bottom"
          target="exitImpersonation"
        >
          Stop viewing
        </UncontrolledTooltip>
      </Alert>
    </div>
  );
}

export default ImpersonationBanner;
