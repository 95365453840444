import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
} from 'reactstrap';
import api from '../../../services/api';
import ExportCSVModalView from './ExportCSVModal.view';

export default function (props) {
  const {
    sortBy,
    filters,
    search,
    isUat,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [exportResult, setExportResult] = useState();

  const handleModalClose = () => {
    setIsExporting(false);
    setExportResult(null);
    setIsModalOpen(false);
  };

  const handleExportCsv = async () => {
    setIsExporting(true);
    try {
      const response = await api('RequestHistoryExport', {
        sortBy,
        filters,
        search,
        isUat,
      });
      const { url, error, errorMessage } = response;
      if (error || errorMessage) {
        setExportResult({ error: error || errorMessage });
        return;
      }
      setExportResult({ url, success: true });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting CSV:', error);
      setExportResult({ error: 'An error occurred while exporting the CSV file.' });
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <Button
        color="secondary"
        className="ml-2 d-flex"
        onClick={() => setIsModalOpen(true)}
        disabled={isExporting}
        outline
      >
        <span className="mr-2">Export CSV</span>
        <FontAwesomeIcon icon="download" />
      </Button>
      { isModalOpen && (
        <ExportCSVModalView
          search={search}
          isExporting={isExporting}
          exportResult={exportResult}
          onClose={handleModalClose}
          onSubmit={handleExportCsv}
        />
      )}
    </>
  );
}
