import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from '../TransUnionView.module.scss';
import ReportTitle from '../ReportTitle';
import TransUnionBranding from '../TransUnionBranding';

const hitIndicators = {
  regularHit: 'Regular hit on file',
  califHit: 'Hit on file, but one or more files not returned because of failure to pass California subject selection criteria — more identification information is needed.',
  regularNoHit: 'No hit on file',
  califNoHit: 'No hit on file but files exist that do not pass California subject selection criteria — more identification information is needed.',
  subjectHit: 'Subject hit on file',
  clear: 'Clear for all fraud searches performed.',
  hit: 'Hit on file',
  noHit: 'No hit on file',
  fraudHit: 'Hit on fraud searches',
  fraudNoHit: 'No hit on fraud searches',
  privateNoHit: 'Private no hit',
  residentialNoHit: 'Residential no hit',
  businessNoHit: 'Business no hit',
  noNameNoHit: 'No name hit',
  nonMailableNoHit: 'Non-mailable no hit',
  error: 'An error occurred',
  stateSpecificNoHit: 'No hit on file – due to state-specific criteria — more identification information is needed',
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const renderPages = (currentPage, numPages, setPageNumber) => {
  const results = [];
  for (let i = 1; i <= numPages; i++) {
    results.push(
      (
        <PaginationItem
          key={`page_${i}`}
          active={i === currentPage}
        >
          <PaginationLink onClick={() => setPageNumber(i)}>
            { i }
          </PaginationLink>
        </PaginationItem>
      ),
    );
  }
  return results;
};

export default function (props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { report } = props;
  const { creditBureau: { product: { embeddedData = {}, error } = {} } = {} } = report;

  if (!embeddedData['#text']) {
    const {
      creditBureau: {
        product: {
          subject: {
            subjectRecord: {
              fileSummary: {
                fileHitIndicator,
              } = {},
            } = {},
          } = {},
        } = {},
      } = {},
    } = report;
    const reportStatus = hitIndicators[fileHitIndicator] ?? 'Invalid report format';
    return (
      <div className={styles.reportHeader}>
        <TransUnionBranding />
        <div className={styles.order}>
          <div className={styles.orderHeader}>
            <div>
              <div>
                Missing PDF report from bureau
              </div>
              <div className={styles.subtext}>
                { reportStatus }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    const messages = [].concat(error).map((e) => `Error ${e.code}: ${e.description}`);
    return (<ReportTitle message={messages} />);
  }

  const onDocumentLoadSuccess = (data) => {
    setNumPages(data.numPages);
  };

  return (
    <div className={styles.pdfContainer}>
      <Document
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        file={`data:application/pdf;base64,${embeddedData['#text']}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<div>Loading</div>}
        externalLinkTarget="_blank"
      >
        <Page pageNumber={pageNumber} width={1000} />
      </Document>
      { (numPages && numPages > 1) && (
        <Pagination aria-label="Page navigation">
          <PaginationItem>
            <PaginationLink
              previous
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber <= 1}
            />
          </PaginationItem>
          { renderPages(pageNumber, numPages, setPageNumber) }
          <PaginationItem>
            <PaginationLink
              next
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber >= numPages}
            />
          </PaginationItem>
        </Pagination>
      )}
    </div>
  );
}
