import React from 'react';
import { Container, Label } from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';

import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

import styles from './PersonalInformationBankingOnly.module.scss';

const formGroupFieldNames = [
  'firstName', 'lastName', 'cellPhoneNumber', 'email1',
];

class LoanInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
    super.skipValidationOnGroupIfEmpty = false;

    this.validator = new FormValidator(validationConfig);
  }

  toggle = (field, context, dataTreeKey) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));

    const event = {
      target: { value: !this.state[field], name: field },
    };

    this.generateFieldOnChangeEvent(context, dataTreeKey)(event);
  };

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="deal-information-input-group">
              <h4>Personal Information</h4>
              <div className="form-group-wrapper">
                <Container>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="firstName"
                    id="ApplicantFirstName"
                    label="First Name"
                    required
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.firstNameFieldInvalid}
                    validationMessage={this.state.firstNameFieldValidationMessage}
                    value={previousState.firstName || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="lastName"
                    id="ApplicantLastName"
                    label="Last Name"
                    required
                    labelsMarkedRequired
                    valid={!this.state.lastNameFieldInvalid}
                    validationMessage={this.state.lastNameFieldValidationMessage}
                    value={previousState.lastName || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="cellPhoneNumber"
                    id="ApplicantMobilePhoneNumber"
                    label="Cell Phone"
                    required
                    labelsMarkedRequired
                    valid={!this.state.cellPhoneNumberFieldInvalid}
                    validationMessage={this.state.cellPhoneNumberFieldValidationMessage}
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    maxLength="12"
                    helpText="###-###-####"
                    value={previousState.cellPhoneNumber || ''}
                    onChange={this.handlePhoneNumber(context, dataTreeKey)}
                    onKeyPress={this.handleKeyPress}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="email1"
                    id="ApplicantEmail1"
                    label="Email"
                    labelsMarkedRequired
                    required
                    valid={!this.state.email1FieldInvalid}
                    validationMessage={this.state.email1FieldValidationMessage}
                    type="text"
                    value={previousState.email1 || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    smallBottomMargin={this.props.allowMobileInvites}
                  />
                  {this.props.allowMobileInvites && (
                    <div className={styles.inviteBlock}>
                      <Toggle
                        id="sendTrustStartInviteToggle"
                        active={previousState.sendMobileInvite === true}
                        className={styles.inviteToggle}
                        on="Yes"
                        off="No"
                        size="xs"
                        onstyle="success"
                        handlestyle="default"
                        offstyle="custom"
                        onClick={() => this.toggle('sendMobileInvite', context, dataTreeKey)}
                      />
                      <Label htmlFor="sendTrustStartInviteToggle">Send Smart Consent™ Invite?</Label>
                    </div>
                  )}
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default LoanInputGroup;
