const anyBase = {
  dataType: 'any',
  allowedFileTypes: '.json, .xml',
};

const jsonBase = {
  dataType: 'JSON',
  allowedFileTypes: '.json',
};

const xmlBase = {
  dataType: 'XML',
  allowedFileTypes: '.xml',
};

export default [
  {
    displayName: 'Equifax',
    source: 'equifax',
    featureBlock: 'bureau',
    ...xmlBase,
  },
  {
    displayName: 'Experian',
    source: 'experian',
    featureBlock: 'bureau',
    ...xmlBase,
  },
  {
    displayName: 'Flinks',
    source: 'flinks',
    featureBlock: 'banking',
    ...jsonBase,
  },
  {
    displayName: 'Inverite',
    source: 'inverite',
    featureBlock: 'banking',
    ...jsonBase,
  },
  {
    displayName: 'Jumio',
    source: 'jumio',
    featureBlock: 'attachment',
    ...anyBase,
  },
  {
    displayName: 'Plaid',
    source: 'plaid',
    featureBlock: 'banking',
    ...jsonBase,
  },
  {
    displayName: 'Onfido',
    source: 'onfido',
    featureBlock: 'attachment',
    ...anyBase,
  },
  {
    displayName: 'Quovo',
    source: 'quovo',
    featureBlock: 'banking',
    ...jsonBase,
  },
  {
    displayName: 'Socure',
    source: 'socure',
    featureBlock: 'attachment',
    ...anyBase,
  },
  {
    displayName: 'Trans Union',
    source: 'transUnion',
    featureBlock: 'bureau',
    ...xmlBase,
  },
];
