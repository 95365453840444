import React from 'react';
import { CardBody } from 'reactstrap';
import { formatLongDateTime } from '../../../utils/format-date';

import styles from './ScoringReportSection.module.scss';

const renderToggleBtton = (showToggleButton, toggleCollapse, isCollapsed) => {
  if (!showToggleButton) return null;

  return (
    <div className={styles.showPreviousScoresButtonWrapper}>
      <button
        type="button"
        className={styles.expandButton}
        onClick={() => toggleCollapse(!isCollapsed)}
      >
        {isCollapsed ? 'Hide' : 'Show'}
        &nbsp;Previous Scores
      </button>
    </div>
  );
};

export default function (props) {
  const {
    displayButton,
    isCollapsed,
    isLatest,
    reportIndex,
    score = '-',
    scoreComponents = [],
    scoreQualifiers = [],
    timestamp,
    toggleCollapse,
  } = props;

  return (
    <CardBody className={[styles.cardBodyWrapper, (isLatest ? '' : styles.previousScoreNumber)].join(' ')}>
      <div className={styles.scoreNumberWrapper}>
        <div className={styles.scoreTimeStamp}>
          { formatLongDateTime(timestamp) }
        </div>
        <span className={[styles.scoreNumber, '_lr-hide'].join(' ')}>
          {score}
        </span>
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.attributesListing}>
          <span>Components:</span>
          <ul>
            {
            scoreComponents.map(
              (component) => <li key={`${reportIndex}${component}`}>{component}</li>,
            )
          }
          </ul>
        </div>
        <div className={styles.attributesListing}>
          <span>Qualifiers:</span>
          <ul>
            {
            scoreQualifiers.map((item) => (
              <li key={`${reportIndex}${item.qualifierCode}`}>
                {item.qualifierDescription}
                &nbsp;
                {`(${item.qualifierCode})`}
              </li>
            ))
          }
          </ul>
        </div>
      </div>
      {
      displayButton
        ? renderToggleBtton(isLatest, toggleCollapse, isCollapsed)
        : null
    }
    </CardBody>
  );
}
