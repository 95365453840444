import React from 'react';

export default function ManageDeploymentsErrorView(props) {
  return (
    <div className="m-auto text-center">
      <div>
        An error occurred while loading the deployments from FlowBuilder.
      </div>
      <div>
        { props.error }
      </div>
    </div>
  );
}
