import React from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  FormFeedback,
  FormText,
} from 'reactstrap';

import './ScoringRequestForm.validatedTextInput.scss';
import SanitizedTextInput from '../../components/SanitizedTextInput';

const highlightRequiredFields = ({ required, highlightEssentialInputs }) => {
  if (required && highlightEssentialInputs) return 'required-field';
  return '';
};

const renderHelpText = (text) => (text ? (<Col className="label-help"><FormText>{ text }</FormText></Col>) : null);

const renderCurrencySymbol = ({ isCurrency }) => {
  if (!isCurrency) return null;
  return <span className="currency-symbol">$</span>;
};

const buildInputClasses = (props) => {
  const result = [];
  if (props.inputClass) result.push(props.inputClass);
  if (props.isCurrency) result.push('currency-input');
  if (props.valid) {
    result.push(highlightRequiredFields(props));
  } else {
    result.push('is-invalid');
  }
  return result.join(' ');
};

const getType = ({ type, isCurrency }) => {
  if (type) return type;
  if (isCurrency) return 'number';
  return 'text';
};

export default function (props) {
  return (
    <FormGroup className={props.smallBottomMargin ? 'small-bottom-margin' : ''}>
      <Row>
        <Col sm="auto" className="label-main">
          <Label htmlFor={props.id}>
            {props.label}
            {highlightRequiredFields(props) || props.labelsMarkedRequired
              ? '*'
              : null}
          </Label>
        </Col>
        {renderHelpText(props.helpText)}
      </Row>
      {renderCurrencySymbol(props)}
      <SanitizedTextInput
        className={buildInputClasses(props)}
        name={props.name}
        id={props.id}
        type={getType(props)}
        pattern={props.pattern}
        maxLength={props.maxLength}
        value={props.value}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        disabled={props.disabled}
      />
      <FormFeedback>{props.validationMessage}</FormFeedback>
    </FormGroup>
  );
}
