import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericUp,
  faSortNumericDown,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import styles from './TableHeader.module.scss';

library.add(faSortAlphaUp, faSortAlphaDown, faSortNumericUp, faSortNumericDown);

const renderIconByTypeAndSort = (ascending, column, activeColumn) => {
  let icon = ascending ? faSortAlphaUp : faSortAlphaDown;
  const classNames = [styles.sortIcon];

  if (column === 'score' || column === 'create_at') {
    icon = ascending ? faSortNumericUp : faSortNumericDown;
  }

  if (column === activeColumn) {
    classNames.push(styles.sortActive);
  }

  return (
    <FontAwesomeIcon icon={icon} className={classNames.join(' ')} />
  );
};

export default function (props) {
  const renderSortIcon = (item) => {
    if (item.id === '') {
      return null;
    }

    return renderIconByTypeAndSort(props.sortBy.asc, item.id, props.sortBy.column);
  };

  return (
    <thead>
      <tr>
        {props.items && props.items.map((item) => {
          const classNames = [styles.tableHeaderSortable];

          if (item.id === props.sortBy.column) {
            classNames.push(styles.sortActive);
          }

          if (item.name === 'Link') {
            classNames.push(styles.textCenter);
          }

          return (
            <th
              key={item.id || item.name}
              className={classNames.join(' ')}
              onClick={props.sortClick}
              data-id={item.id}
            >
              {renderSortIcon(item)}
              {item.name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
