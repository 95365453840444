import React from 'react';

import styles from './AppendixTable.module.scss';

export default function (props) {
  return (
    <div id={props.id} className={styles.AppendixTable}>
      {props.children}
    </div>
  );
}
