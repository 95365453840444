import React, { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import api from '../../services/api';

export default function ({ modalOpen, toggle, userAccount }) {
  const [status, setStatus] = useState('');
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const statusMessages = {
    sending: { message: 'Sending email, please wait.' },
    success: { message: 'Success! Please check your email for the verification link.', className: 'text-primary' },
    error: { message: 'An error occurred. Unable to send verification link.', className: 'text-danger' },
  };

  const sendConfirmation = async () => {
    setIsSendBtnDisabled(true);
    setStatus(statusMessages.sending);

    const result = await api('SendEmailVerification', {
      accessToken: userAccount.accessToken,
      attributeName: 'email',
      origin: window.location.origin,
    });

    if (result?.$metadata?.httpStatusCode === 200) setStatus(statusMessages.success);
    else setStatus(statusMessages.error);
  };

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Email Verification</ModalHeader>
      <ModalBody>
        Click below to send a verification link to your email:
        <div className="my-3">
          <Input type="text" className="form-control bg-white" placeholder={userAccount.email} disabled />
        </div>
        <p className={status.className}>{status.message}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={sendConfirmation} disabled={isSendBtnDisabled}>
          {(status.message === statusMessages.sending.message) ? (<Spinner className="d-flex" size="sm" />) : 'Send'}
        </Button>
        <Button onClick={toggle}>{status ? 'Close' : 'Cancel'}</Button>
      </ModalFooter>
    </Modal>
  );
}
