import {
  dev,
  test,
  prod,
} from './environments';

export default {
  [dev.id]: test,
  [test.id]: prod,
};
