import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import ExpandCollapseIcon from '../../../../components/ExpandCollapseIcon';
import styles from '../ProductsReport.module.scss';

export default function MPT({ product }) {
  const [collapsed, setCollapse] = useState(true);
  const probabilityTable = product.score.probabilityTable || null;
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      <div>
        Recommended Amount:&nbsp;
        {product.score.recommendedAmount}
      </div>
      { probabilityTable
        && (
        <>
          <div className={styles.subHeadingDropdown}>
            Probability Table&nbsp;&nbsp;
            <ExpandCollapseIcon
              isCollapsed={collapsed}
              toggleIsCollapsed={() => setCollapse(!collapsed)}
            />
          </div>
          <Collapse isOpen={!collapsed}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Monthly Payment Amount</th>
                  <th>Payment Confidence</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {Object.keys(probabilityTable).map((key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{probabilityTable[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Collapse>
        </>
        )}
      <br />
      { !!product.scoreReasons?.length
        && (
          <table className={styles.table}>
            <caption>Score Reasons</caption>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {product.scoreReasons.map((reason) => (
                <tr key={reason.code}>
                  <td>{reason.code}</td>
                  <td>{reason.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}
