import React, { useState } from 'react';
import {
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import styles from './TransactionsFilter.module.scss';

const dropDownText = (selectedValue = 90) => (`${selectedValue} days`);
const isActive = (target, actual) => target === actual;

export default function (props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className={styles.container}>
      <Label className={styles.label} for="transactionsFilter">
        Transaction Filter
      </Label>
      <Dropdown
        name="transactionsFilter"
        className={styles.select}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret color="primary">
          { dropDownText(props.selectedValue) }
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            active={isActive('90', props.selectedValue)}
            onClick={() => props.onChange('90')}
          >
            90 Days
          </DropdownItem>
          <DropdownItem
            active={isActive('365', props.selectedValue)}
            onClick={() => props.onChange('365')}
          >
            365 Days
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
