import React, { useState } from 'react';

import styles from './block.module.scss';

export default function (field, getValidation, previousState, onChange, fieldFactory) {
  const { name, block, defaultValue } = field;
  const fieldName = `custom_${name}`;
  const isActive = !!(previousState[fieldName] || defaultValue);
  const [isPanelOpen, setPanelOpen] = useState(isActive);

  const onToggle = async (event) => {
    const { target: { value } } = event;
    await setPanelOpen(value);
    onChange(event);
  };

  return (
    <div>
      <div>
        {
          fieldFactory('boolean')(
            field,
            getValidation,
            previousState,
            onToggle,
          )
        }
      </div>
      {
        isPanelOpen
        && (
          <div className={[styles.panel, 'panel'].join(' ')}>
            {
              [].concat(block).map((f) => fieldFactory(f.type)(f, getValidation, previousState, onChange, fieldFactory))
            }
          </div>
        )
      }
    </div>
  );
}
