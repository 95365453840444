import React from 'react';

import Select from 'react-select';
import { CURRENT_ENVIRONMENT_NAME, UAT_ENVIRONMENT_NAME, ENVIRONMENT } from '../../config/console-configuration';

const environmentOptions = [
  {
    value: 'prod', label: `Live (${CURRENT_ENVIRONMENT_NAME})`, color: ENVIRONMENT === 'uat' ? '#5243AA' : '#19ab6d', isFixed: true,
  },
  {
    value: 'uat', label: `Test (${UAT_ENVIRONMENT_NAME})`, color: '#5243AA', isFixed: true,
  },
];

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
    marginTop: '-4px',
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isFocused,
  }) => ({
    ...styles,
    ...dot(data.color),
    backgroundColor: isFocused
      ? '#ddd' : 'white',
    color: 'black',
  }),
  input: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
    paddingTop: '3px',
  }),
};

export default function ({ environment, setEnvironment, isDisabled }) {
  const selectedOption = environmentOptions.filter((option) => option.value === environment)[0];

  return (
    <div className="float-right" style={{ maxWidth: '240px' }}>
      <Select
        value={selectedOption}
        options={environmentOptions}
        styles={colourStyles}
        isDisabled={isDisabled}
        onChange={({ value }) => setEnvironment(value)}
      />
    </div>
  );
}
