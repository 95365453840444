import { fetchAuthSession } from 'aws-amplify/auth';
import { FLOWBUILDER_API_URL } from '../config/console-configuration';

const trustConsoleIdHeader = 'x-trustconsole-id';

const runRequest = async (method, path, body, queryStringParams) => {
  const { tokens: { accessToken, idToken } } = await fetchAuthSession();
  const urlParts = [new URL(path, FLOWBUILDER_API_URL).href];
  if (queryStringParams) urlParts.push(new URLSearchParams(queryStringParams).toString());
  const requestConfig = {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      [trustConsoleIdHeader]: idToken.toString(),
    },
  };
  if (!/get/i.test(method) && body) {
    requestConfig.body = JSON.stringify(body);
  }
  try {
    const response = await fetch(urlParts.join('?'), requestConfig);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error in FlowBuilder API', error);
    return { error: 'Error in FlowBuilder API' };
  }
};

export const getUserRoles = () => runRequest('get', '/user-role');
export const setUserRole = (username, role) => runRequest('post', '/user-role', {
  username,
  role,
});
export const deleteUser = (username) => runRequest('delete', '/users', { username });
export const getDeployments = () => runRequest('get', '/deployments');
export const createDeployment = (environmentId, projectId, commitId, name) => runRequest(
  'post',
  '/deployments',
  {
    environmentId,
    projectId,
    commitId,
    name,
  },
);
export const getFlowsByDeployment = (projectId, commitId) => runRequest(
  'get',
  '/flows',
  null,
  { projectId, commitId },
);
