import React from 'react';
import { Label } from 'reactstrap';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

import styles from './IntegrationInputForms.module.scss';

export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let userId;
  let memberCode;
  let password;
  let productId;
  if (credentials) {
    ({
      ca: {
        userId,
        memberCode,
        password,
        productId,
      },
    } = credentials);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="TransUnion Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="transunion-input-userId">User ID:</Label>
          <SanitizedTextInput
            id="transunion-input-userId"
            value={userId}
            onChange={onInputChange}
            nestedKey="ca"
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="transunion-input-memberCode">Member Code:</Label>
          <SanitizedTextInput
            id="transunion-input-memberCode"
            value={memberCode}
            onChange={onInputChange}
            nestedKey="ca"
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="transunion-input-password">Password:</Label>
          <SanitizedTextInput
            id="transunion-input-password"
            value={password}
            onChange={onInputChange}
            nestedKey="ca"
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="transunion-input-productId">Product ID:</Label>
          <SanitizedTextInput
            id="transunion-input-productId"
            value={productId}
            onChange={onInputChange}
            nestedKey="ca"
          />
        </div>
      </div>
    </div>
  );
}
