const decimalFormat = new Intl.NumberFormat(
  'en-US',
  {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
);

const integerFormat = new Intl.NumberFormat(
  'en-US',
  {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
);

const shouldFormatDecimals = (showDecimals, value) => {
  if (!Number.isInteger(value)) return true;
  return showDecimals;
};

export default (valueAmount, showDecimals) => {
  const formatter = shouldFormatDecimals(showDecimals, valueAmount) ? decimalFormat : integerFormat;
  return formatter.format(valueAmount);
};
