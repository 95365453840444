import React from 'react';

import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

const buildInputClasses = (validation) => {
  if (validation.isValid) return 'required-field';
  return 'is-invalid';
};

export default function (
  {
    name, label, required, defaultValue, options,
  },
  getValidation,
  previousState,
  onChange,
) {
  const fieldName = `custom_${name}`;
  const validation = getValidation(name);

  return (
    <FormGroup key={fieldName}>
      <Row>
        <Col sm="auto" className="label-main">
          <Label htmlFor={fieldName}>
            {label}
            {required
              ? '*'
              : null}
          </Label>
        </Col>
      </Row>
      <Input
        className={buildInputClasses(validation)}
        type="select"
        name={fieldName}
        id={fieldName}
        required={required}
        onChange={onChange}
        defaultValue={previousState[fieldName] ?? defaultValue} // eslint-disable-line dot-notation
      >
        <option value="">Please Select...</option>
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </Input>
      <FormFeedback>{validation.validationMessage}</FormFeedback>
    </FormGroup>
  );
}
