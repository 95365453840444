import React from 'react';
import {
  Card, CardTitle, Collapse,
} from 'reactstrap';
import styles from './CollapsibleCard.module.scss';
import ExpandCollapseIcon from '../../../components/ExpandCollapseIcon';
import useIsCollapsed from '../hooks/useIsCollapsed';

function CollapsibleCard({
  collapseKey, children, title, className, print = true,
}) {
  const { [collapseKey]: { isCollapsed, toggleIsCollapsed } } = useIsCollapsed();

  const printClass = print ? styles.printable : styles.noPrint;
  const wrapperClass = print ? styles.cardWrapper : `${styles.cardWrapper} ${styles.noPrint}`;

  return (
    <Card className={`${wrapperClass} ${className}`}>
      <CardTitle tag="h3" className={styles.cardTitle}>
        <ExpandCollapseIcon
          isCollapsed={isCollapsed}
          toggleIsCollapsed={toggleIsCollapsed}
        />
        {title}
      </CardTitle>
      <Collapse isOpen={!isCollapsed} className={printClass}>
        {children}
      </Collapse>
    </Card>
  );
}

export default CollapsibleCard;
