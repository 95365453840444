import React, { useState } from 'react';
import { Button } from 'reactstrap';
import DeploymentDetails from './DeploymentDetails';

import styles from './ManageDeployments.module.scss';

const isAbsentOrEnabled = (config, key) => (
  !Object.hasOwn(config, key)
  || config[key]
);

const getProductConfigurations = (config, flow) => (
  Object.entries(config)
    .map(([key, value]) => ({ key, ...value }))
    .filter((c) => isAbsentOrEnabled(c, 'includeOnReport'))
    .filter((c) => c.flow?.split('/').slice(0, -1).join('/') === flow)
);

export default function DeploymentView(props) {
  const {
    environmentId,
    environments,
    prodFlowConfig = {},
    uatFlowConfig = {},
    uatOtherProducts = {},
    prodOtherProducts = {},
    deployment,
    deployment: {
      name,
      projectName,
      status,
      flow,
    },
    getDeploymentsByCommit,
    onCreateDeployment,
    onDeploymentUpdated,
    isAnyEditModeActive,
    onEditModeChange,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const prodConfigurations = getProductConfigurations(prodFlowConfig, flow);
  const uatConfigurations = getProductConfigurations(uatFlowConfig, flow);

  return (
    <div className="mt-2">
      <div className="d-flex justify-content-between">
        <div className={styles.projectField}>{projectName}</div>
        <div className={styles.nameField}>{name}</div>
        <div className={styles.statusField}>{status}</div>
        <div className={styles.configuredField}>
          {
            !!prodConfigurations.length && (
              <span className={styles.configuredEnv}>
                PROD
              </span>
            )
          }
          {
            !!uatConfigurations.length && (
              <span className={styles.configuredEnv}>
                UAT
              </span>
            )
          }
        </div>
        <div className={styles.actionsField}>
          <Button
            className="pb-1"
            color="link"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'View Less' : 'View More'}
          </Button>
        </div>
      </div>
      <DeploymentDetails
        isExpanded={isExpanded}
        environmentId={environmentId}
        environments={environments}
        deployment={deployment}
        prodFlowConfig={prodFlowConfig}
        uatFlowConfig={uatFlowConfig}
        uatOtherProducts={uatOtherProducts}
        prodOtherProducts={prodOtherProducts}
        prodConfigurations={prodConfigurations}
        uatConfigurations={uatConfigurations}
        getDeploymentsByCommit={getDeploymentsByCommit}
        onCreateDeployment={onCreateDeployment}
        onDeploymentUpdated={onDeploymentUpdated}
        isAnyEditModeActive={isAnyEditModeActive}
        onEditModeChange={onEditModeChange}
      />
    </div>
  );
}
