import React from 'react';

import Modal from './Modal';

const selectBody = (props) => {
  if (props.success === undefined) {
    return (
      `Are you sure you want to delete ${props.userName}?`
    );
  }
  if (!props.success) {
    return (
      <div>
        There was an error while deleting the user.
        <br />
        Please try again.
      </div>
    );
  }
  return (
    <div>
      Successfully deleted.
    </div>
  );
};

export default function (props) {
  const buttons = [
    {
      id: 'cancel',
      color: 'secondary',
      onClick: props.onClose,
      text: 'Close',
    },
  ];

  if (props.success === undefined) {
    buttons.unshift({
      id: 'delete',
      color: 'danger',
      onClick: props.onDelete,
      text: 'Delete',
    });
  }

  return (
    <Modal
      isOpen
      title="Deleting user"
      buttons={buttons}
    >
      { selectBody(props) }
    </Modal>
  );
}
