import React, { forwardRef } from 'react';

const Card = forwardRef(
  ({ children, className, ...props }, ref) => (
    <div
      ref={ref}
      className={`border rounded-xl ${className}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  ),
);

export default Card;
