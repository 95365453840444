export default [
  {
    field: 'cellPhoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    skipIfEmpty: true,
    validWhen: true,
    message: 'Phone number must be in the format ###-###-####.',
  },
  {
    field: 'workPhoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    skipIfEmpty: true,
    validWhen: true,
    message: 'Phone number must be in the format ###-###-####.',
  },
  {
    field: 'email2',
    method: 'isEmail',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Email must be a valid email address.',
  },
  {
    field: 'dateOfBirth',
    method: 'isEmpty',
    validWhen: false,
    message: 'Date of Birth is required.',
  },
  {
    field: 'dateOfBirth',
    method: 'isISO8601',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Date of birth must be in format YYYY-MM-DD.',
    options: [{ strict: true }],
  },
  {
    field: 'dateOfBirth',
    method: 'isLength',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Date of birth must be in format YYYY-MM-DD.',
    options: [{ min: 10, max: 10 }],
  },
  {
    field: 'socialInsuranceNumber',
    method: 'isEmpty',
    validWhen: false,
    message: 'SSN/SIN is required.',
  },
  {
    field: 'socialInsuranceNumber',
    method: 'isLength',
    validWhen: true,
    skipIfEmpty: true,
    message: 'SSN/SIN must be a number 9 digits long (#########).',
    options: [{ min: 9, max: 9 }],
  },
  {
    field: 'socialInsuranceNumber',
    method: 'isNumeric',
    validWhen: true,
    skipIfEmpty: true,
    message: 'SSN/SIN must be numbers only (#########).',
    options: [{ no_symbols: true }],
  },
];
