import React from 'react';
import ClientLogo, { clientLogoWidths } from '../../../components/ClientLogo';
import styles from './ClientLogoList.module.scss';

const renderLogos = (props) => (
  Object.values(clientLogoWidths).map((width) => (
    <div className="col" key={width}>
      <div className="card">
        <div className="card-header">
          { `Width: ${width}px` }
        </div>
        <div className={['card-body', 'd-flex', 'align-items-center', 'justify-content-center', styles.frame].join(' ')}>
          <ClientLogo
            companyName={props.displayName}
            companyLogo={props.logo}
            clientId={props.clientId}
            width={width}
          />
        </div>
      </div>
    </div>
  ))
);

const renderNoLogosDefined = () => (
  <div className="col value">No logos defined. Use the Upload button above to add one.</div>
);

export default (props) => (props.logo ? renderLogos(props) : renderNoLogosDefined());
