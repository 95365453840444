import React from 'react';
import getClientConfig from '../utils/get-client-config';

function withFeatureFlag(WrappedComponent, featureFlagName) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { enabled: false };
    }

    async componentDidMount() {
      try {
        const getClientConfigCallResult = await getClientConfig();
        const { result } = getClientConfigCallResult;
        const { clientConfig } = result;
        const { features } = clientConfig;

        if (!features) return;
        if (!(featureFlagName in features)) return;

        this.setState({ enabled: features[featureFlagName] });
      } catch (error) {
        console.error('WithFeatureFlag HOC error:', error);
      }
    }

    render() {
      if (!this.state.enabled) return null;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<WrappedComponent {...this.props} />);
    }
  };
}

export default withFeatureFlag;
