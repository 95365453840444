import React from 'react';
import styles from '../TransUnionView.module.scss';
import ReportTitle from '../ReportTitle';
import TransUnionBranding from '../TransUnionBranding';
import ReportBulkhead from './ReportBulkhead';
import ReportView from './ReportView';
import TextReportView from './TextReportView';

const isValidReport = (report) => (
  report
  && report.REL4Report
  && report.REL4Report.TU_FFR_Report
);

const reportHasErrors = (report) => (
  report
  && report.REL4Report
  && report.REL4Report.Errors
);

const isHit = (report) => (
  isValidReport(report)
  && /y/i.test(report.REL4Report.TU_FFR_Report.Hit)
);

const extractErrors = (report) => (
  [].concat(report.REL4Report.Errors).filter((e) => e.Error).map((e) => e.Error.Code).join()
);

function NoHit({ report }) {
  if (!isValidReport(report)
    || !report.REL4Report.TU_FFR_Report.TU_TTY_Report) {
    const message = 'No report for subject. Invalid format.';
    console.error(message, report);
    return (<ReportTitle message={message} />);
  }

  return (
    <>
      <div className={styles.reportHeader}>
        <TransUnionBranding />
        <div className={styles.order}>
          <div className={styles.orderHeader}>
            <div>
              No Hit for Subject
            </div>
          </div>
        </div>
      </div>
      <div className={styles.reportBody}>
        <TextReportView report={report} />
      </div>
    </>
  );
}

export default function (props) {
  const { report } = props;

  if (reportHasErrors(report)) {
    const message = `System Error: ${extractErrors(report)}`;
    console.error(message, report);
    return (<ReportTitle message={message} />);
  }

  if (!isHit(report)) return (<NoHit report={report} />);

  return (
    <ReportBulkhead report={report}>
      <ReportView report={report} />
    </ReportBulkhead>
  );
}
