import React, { useEffect, useState } from 'react';
import SmartConsentCardView from './SmartConsentCard.view';
import CreateMobileInviteModal from './CreateMobileInviteModal.view';
import { getTrustStartInviteData } from '../../../services/scoring-request-services';

export default function SmartConsentCardContainer(props) {
  const {
    features,
    requestId,
    person,
    isUat,
  } = props;
  const { viewInviteEmailEvents } = features;
  const [invites, setInvites] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (invites) return;
    if (!viewInviteEmailEvents) return;
    if (!requestId) return;
    async function wrapper() {
      let results;
      try {
        results = await getTrustStartInviteData(requestId.toLowerCase(), isUat);
      } catch (error) {
        console.error('Error while getting MetaData', error);
        return;
      }
      if (!results || results.error) {
        console.error('Unable to retrieve Smart Consent™ Invite data.');
        return;
      }
      setInvites(results.invites);
    }
    wrapper();
  }, [isUat, requestId, invites, setInvites, viewInviteEmailEvents]);

  if (!props.tsBankingURL && !viewInviteEmailEvents) return null;

  return (
    <>
      <CreateMobileInviteModal
        requestId={requestId}
        person={person}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
      <SmartConsentCardView
        features={props.features}
        showEmbeddedUrl={props.isDeveloper}
        bankingStatus={props.bankingStatus}
        hideEvents={props.hideEvents}
        tsBankingURL={props.tsBankingURL}
        invites={invites}
        onCreateInvite={() => setShowModal(true)}
      />
    </>
  );
}
