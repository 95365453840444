/* eslint-disable react/jsx-no-constructed-context-values */

import React, { createContext, useState } from 'react';
import { useModal } from '../../../../hooks/useModal';

export const ChatContext = createContext(undefined);

export function ChatProvider({
  children, chatId, clientId,
}) {
  const [chatStatus, setChatStatus] = useState('normal');
  const { showModal } = useModal();

  const publish = ({ variant, text }) => {
    if (variant === 'danger') {
      if (chatStatus !== 'done') {
        setChatStatus('error');
      }
      // eslint-disable-next-line no-console
      console.error(text);
      showModal({
        header: 'Error',
        body: text,
      });
    }
  };

  const [history, setHistory] = useState([]);

  const addToHistory = (chats) => {
    const isDone = chats.some((chat) => chat.done);

    if (isDone) {
      setChatStatus('done');
    }
    setHistory((prevHistory) => [...prevHistory, ...chats]);
  };

  return (
    <ChatContext.Provider
      value={{
        history,
        setHistory,
        addToHistory,
        chatId,
        clientId,
        chatStatus,
        setChatStatus,
        publish,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
