import React from 'react';

import styles from './TableRowItem.module.scss';

const renderLabel = (label) => {
  if (!label) {
    return null;
  }

  return (
    <span className={styles.rowLabel}>
      {label}
    </span>
  );
};

const renderSubLabel = (subLabel) => {
  if (!subLabel) {
    return null;
  }

  return (
    <span className={styles.rowSubLabel}>
      {subLabel}
    </span>
  );
};

const renderRequiredLabel = (requiredLabel, optional = false) => {
  if (!requiredLabel) {
    return null;
  }

  return (
    <span className={optional ? styles.optionalLabel : styles.requiredLabel}>
      {requiredLabel}
    </span>
  );
};

const renderExample = (label, example) => {
  if (!label && !example) {
    return null;
  }

  return (
    <>
      <span className={styles.exampleLabel}>
        {label}
      </span>
      <code className={styles.example}>
        {example}
      </code>
    </>
  );
};

export default function (props) {
  return (
    <div className={styles.tableRowItem}>
      <div className={`${props.extraWide ? styles.tableRowLeftExtraWide : styles.tableRowLeft}`}>
        { renderLabel(props.label) }
        { renderSubLabel(props.subLabel) }
        { renderRequiredLabel(props.requiredLabel, props.optional) }
        { renderExample(props.exampleLabel, props.example) }
      </div>
      <div className={styles.tableRowRight}>
        {props.children}
      </div>
    </div>
  );
}
