import React from 'react';
import {
  Container,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import StatesAndProvincesOptions from '../StatesAndProvincesOptions';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

const formGroupFieldNames = [
  'address', 'city', 'state', 'postalCode', 'residenceStatus', 'monthlyResidenceCost',
  'isCurrentResidence', 'durationAtResidence',
];

class ResidenceInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);
    super.formGroupFieldNames = formGroupFieldNames;

    if (props.required) {
      super.skipValidationOnGroupIfEmpty = false;
    }

    this.validator = new FormValidator(validationConfig);
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          const isCurrentResidence = previousState.isCurrentResidence || '';

          return (
            <Container>
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="address"
                id={`${this.props.fieldIdPrefix}ApplicantAddress`}
                label="Address"
                labelsMarkedRequired={this.state.labelsMarkedRequired}
                valid={!this.state.addressFieldInvalid}
                validationMessage={this.state.addressFieldValidationMessage}
                value={previousState.address || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="city"
                id={`${this.props.fieldIdPrefix}ApplicantCity`}
                label="City"
                labelsMarkedRequired={this.state.labelsMarkedRequired}
                valid={!this.state.cityFieldInvalid}
                validationMessage={this.state.cityFieldValidationMessage}
                value={previousState.city || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <FormGroup>
                <Label htmlFor={`${this.props.fieldIdPrefix}ApplicantProvince`}>
                  State / Province
                  {(this.state.labelsMarkedRequired) ? '*' : null}
                </Label>
                <Input
                  className={this.state.stateFieldInvalid ? 'is-invalid' : ''}
                  type="select"
                  name="state"
                  id={`${this.props.fieldIdPrefix}ApplicantProvince`}
                  value={previousState.state || ''}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey, true)}
                >
                  <StatesAndProvincesOptions />
                </Input>
                <FormFeedback>{ this.state.stateFieldValidationMessage }</FormFeedback>
              </FormGroup>
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="postalCode"
                id={`${this.props.fieldIdPrefix}ApplicantPostalCode`}
                label="Zip / Postal Code"
                labelsMarkedRequired={this.state.labelsMarkedRequired}
                valid={!this.state.postalCodeFieldInvalid}
                validationMessage={this.state.postalCodeFieldValidationMessage}
                value={previousState.postalCode || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <FormGroup>
                <Label htmlFor={`${this.props.fieldIdPrefix}ApplicantResidenceStatus`}>
                  Residence Status
                  {(this.state.labelsMarkedRequired) ? '*' : null}
                </Label>
                <Input
                  className={this.state.residenceStatusFieldInvalid ? 'is-invalid' : ''}
                  type="select"
                  name="residenceStatus"
                  id={`${this.props.fieldIdPrefix}ApplicantResidenceStatus`}
                  value={previousState.residenceStatus || ''}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                >
                  <option value="">Please Select...</option>
                  <option value="Own">Own</option>
                  <option value="Rent">Rent</option>
                  <option value="Family">Family</option>
                  <option value="Other">Other</option>
                </Input>
                <FormFeedback>{ this.state.residenceStatusFieldValidationMessage }</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`${this.props.fieldIdPrefix}ApplicantMonthlyResidenceCost`}>Monthly Residence Cost</Label>
                <Input
                  className={this.state.monthlyResidenceCostFieldInvalid ? 'is-invalid' : ''}
                  name="monthlyResidenceCost"
                  id={`${this.props.fieldIdPrefix}ApplicantMonthlyResidenceCost`}
                  type="number"
                  value={previousState.monthlyResidenceCost || ''}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`${this.props.fieldIdPrefix}ApplicantCurrentResidence`}>Current Residence</Label>
                <Input
                  className={this.state.isCurrentResidenceFieldInvalid ? 'is-invalid' : ''}
                  type="select"
                  name="isCurrentResidence"
                  id={`${this.props.fieldIdPrefix}ApplicantCurrentResidence`}
                  value={isCurrentResidence}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                >
                  <option value="">Please Select...</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`${this.props.fieldIdPrefix}ApplicantDurationAtResidence`}>Duration at Residence</Label>
                <Input
                  className={`short-width ${this.state.durationAtResidenceFieldInvalid ? 'is-invalid' : ''}`}
                  name="durationAtResidence"
                  id={`${this.props.fieldIdPrefix}ApplicantDurationAtResidence`}
                  type="number"
                  value={previousState.durationAtResidence || ''}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                />
                <span className="input-units">Months</span>
              </FormGroup>
            </Container>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default ResidenceInputGroup;
