import React from 'react';
import styles from '../ProductsReport.module.scss';

export default function KYC({ product }) {
  return (
    <div className={`${styles.content} ${styles.m}`}>
      <h5>{`${product.productCode}`}</h5>
      <div>
        Status:&nbsp;
        {product.kycStatus}
      </div>
      {product.kycWarning
        ? (
          <div>
            Warning:&nbsp;
            {product.kycWarning}
          </div>
        )
        : ''}
      { !!product.kycAlerts?.length
        && (
          <table className={styles.table}>
            <caption>Alerts</caption>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              { product.kycAlerts.map((reason) => (
                <tr key={reason.code}>
                  <td>{reason.code}</td>
                  <td>{reason.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}
