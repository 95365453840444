import React from 'react';
import TransUnion from './TransUnion';
import CollapsibleCard from '../CollapsibleCard';

const getTuProps = ({ transunion, transunionus }) => {
  if (!transunion && !transunionus) return null;
  const region = transunion ? 'ca' : 'us';
  const report = transunion || transunionus;
  return {
    report: report.data,
    region,
  };
};

export default function ({ bureauReport }) {
  if (!bureauReport) return null;

  const tuProps = getTuProps(bureauReport);
  if (!tuProps || !tuProps.report) {
    console.log('No TU reports');
    return null;
  }

  return (
    <CollapsibleCard title="Bureau Report" collapseKey="bureauReport">
      <TransUnion
          /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...tuProps}
      />
    </CollapsibleCard>
  );
}
