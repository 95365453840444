import React from 'react';

import './TrustScienceLogo.scss';

export default function () {
  return (
    <img
      alt="Trust Science Logo"
      className="ts-logo"
      src="/images/trust-science-logo.png"
      srcSet="/images/trust-science-logo.png 1x,
    /images/trust-science-logo@2x.png 2x,
    /images/trust-science-logo@3x.png 3x"
    />
  );
}
