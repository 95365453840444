const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const zeroPad = (value, digits = 2) => `00000${value.toString()}`.slice(-1 * digits);
const getPeriod = (hours) => (hours < 12 ? 'AM' : 'PM');
const formatHour = (hours) => (hours % 12 || 12);

const getParts = (date) => ({
  day: date.getDate(),
  month: date.getMonth(),
  year: date.getFullYear(),
  hours: date.getHours(),
  minutes: date.getMinutes(),
  seconds: date.getSeconds(),
  milliseconds: date.getMilliseconds(),
});

export const formatLongDateTime = (timestamp) => {
  const date = new Date(timestamp);
  if (/invalid/ig.test(date.toString())) return null;
  const {
    day,
    month,
    year,
    hours,
    minutes,
  } = getParts(date);
  return `${months[month]} ${day}, ${year} at ${formatHour(hours)}:${zeroPad(minutes)} ${getPeriod(hours)}`;
};

export const formatFullTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  if (/invalid/ig.test(date.toString())) return null;
  const {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
  } = getParts(date);
  return `${year}-${zeroPad(month + 1)}-${zeroPad(day)} ${zeroPad(formatHour(hours))}:${zeroPad(minutes)}:${zeroPad(seconds)}.${zeroPad(milliseconds.toString().slice(-3), 3)} ${getPeriod(hours)}`;
};
