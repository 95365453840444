import React from 'react';
import ClientLogo, { clientLogoWidths } from '../ClientLogo';
import styles from './ClientLetterhead.module.scss';

export default function (props) {
  return (
    <div className={styles.letterhead}>
      <div>
        <ClientLogo
          width={clientLogoWidths.medium}
          clientId={props.clientId}
          companyLogo={props.logo}
          companyName={props.displayName}
        />
      </div>
      <div>
        Prepared by:
        <br />
        {props.staffFirstName}
      &nbsp;
        {props.staffLastName}
        <br />
        {props.staffEmail}
      </div>
    </div>
  );
}
