import api from '../services/api';

export default async (filename, docType, featureFlag) => {
  const body = {
    docType,
    featureFlag,
    filename,
  };
  try {
    return await api('GetDocumentation', body);
  } catch (error) {
    console.error('ClientSettings API.get Error:', error);
    throw error;
  }
};
