import saveConfigPatchToServer from './saveConfigPatchToServer';

export const addCallback = (
  newCallback,
  setChangesMade,
  setCallback,
  toggleSaving,
  {
    setConfig,
    isUat,
  },
  onCatch,
) => {
  const [tag, callback] = newCallback;
  const payload = [
    {
      op: 'add',
      path: `/callbacks/${tag}`,
      value: callback,
    },
  ];

  saveConfigPatchToServer(payload, isUat)
    .then((res) => {
      setConfig(res);
      setCallback([]);
    })
    .catch(onCatch)
    .finally(() => {
      setChangesMade(false);
      toggleSaving(false);
    });
};

export const updateCallback = (
  newCallback,
  originalTag,
  setChangesMade,
  toggleSaving,
  {
    setConfig,
    isUat,
  },
  onCatch,
) => {
  const [tag, callback] = newCallback;
  const payload = [
    {
      op: 'remove',
      path: `/callbacks/${originalTag}`,
    },
    {
      op: 'add',
      path: `/callbacks/${tag}`,
      value: callback,
    },
  ];

  saveConfigPatchToServer(payload, isUat)
    .then((res) => {
      setConfig(res);
    })
    .catch(onCatch)
    .finally(() => {
      setChangesMade(false);
      toggleSaving(false);
    });
};

export const removeCallback = (
  setChangesMade,
  originalTag,
  toggleSaving,
  {
    setConfig,
    isUat,
  },
  onCatch,
) => {
  const payload = [
    {
      op: 'remove',
      path: `/callbacks/${originalTag}`,
    },
  ];

  saveConfigPatchToServer(payload, isUat)
    .then((res) => {
      setConfig(res);
    })
    .catch(onCatch)
    .finally(() => {
      setChangesMade(false);
      toggleSaving(false);
    });
};
