import React from 'react';
import {
  Button,
} from 'reactstrap';
import { runActionMenuItem } from '../../../services/workflow-services';
import { useModal } from '../../../hooks/useModal';

const mapParams = (result, current) => ({
  ...result,
  [current.name]: current.value,
});

const renderModalBody = (response) => {
  if (response.loading) return (<p data-testid="loading-message">Please wait...</p>);
  if (response.error) return (<p data-testid="error-message">An error occurred while attempting to run the action. Please try again.</p>);
  return (
    <p data-testid="friendly-message">
      The&nbsp;
      {response.label}
      &nbsp;action has been successfully accepted.
      It can take several minutes for the action to complete and for the results to appear in the scoring request.
      You can refresh this view periodically to see the changes.
    </p>
  );
};

const runAction = (requestId, item, showModal, isUat) => async () => {
  const header = item.label;
  showModal({
    header,
    body: renderModalBody({ ...item, loading: true }),
  });
  let result;
  try {
    result = await runActionMenuItem(requestId, item.action, item.params.reduce(mapParams, {}), isUat);
  } catch (error) {
    console.error('Unhandled error while running action menu item', { item, error });
    showModal({
      header,
      body: renderModalBody({ ...item, error, loading: false }),
    });
    return;
  }
  if (result?.errorType) {
    console.error('Workflow service error while running action menu item', { item, result });
    showModal({
      header,
      body: renderModalBody({ ...item, error: result.errorMessage, loading: false }),
    });
  } else {
    showModal({
      header,
      body: renderModalBody({ ...item, loading: false }),
    });
  }
};

export default function ({ requestId, menu, isUat }) {
  const { showModal } = useModal();

  if (!menu) return null;
  if (!Array.isArray(menu)) {
    console.error('Failed to get action menu from workflow service:', menu.errorMessage);
    return null;
  }
  const renderMenuItem = (item) => (
    <Button key={item.key} color="primary" onClick={runAction(requestId, item, showModal, isUat)}>
      {item.label}
    </Button>
  );

  return menu.map(renderMenuItem);
}
