import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
// eslint-disable-next-line no-unused-vars
import styles from './style.scss';

const renderWorkingHours = () => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const supportHoursStart = new Date();
  supportHoursStart.setUTCHours(15, 0, 0, 0);

  const supportHoursEnd = new Date();
  supportHoursEnd.setUTCHours(0, 0, 0, 0);

  const options = {
    timeZone: userTimezone,
    timeZoneName: 'short',
    hour: 'numeric',
    minute: '2-digit',
  };

  const hoursStartUserTZ = supportHoursStart.toLocaleString('en-US', options);
  const hoursEndUserTZ = supportHoursEnd.toLocaleString('en-US', options);

  return (`Trust Science Support can be reached Monday through Friday from ${hoursStartUserTZ} to ${hoursEndUserTZ} via our Support Portal or email.`);
};

export default function ({ onClose }) {
  return (
    <Modal isOpen>
      <ModalHeader>Help & Support</ModalHeader>
      <ModalBody>
        <p>
          {renderWorkingHours()}
        </p>
        <div className="list-group">
          <a href="https://trustscience.atlassian.net/servicedesk/customer/portals" target="_blank" className="list-group-item list-group-item-action" rel="noreferrer">
            <div className="support-link">Support Portal</div>
            <small className="card-subtitle mb-2 text-body-secondary">Submit support tickets for IT requests and IT incidents.</small>
          </a>
          <a href="mailto:support@trustscience.com" className="list-group-item list-group-item-action">
            <div className="support-link">Email</div>
            <small className="card-subtitle mb-2 text-body-secondary">Submit support ticket by emailing support@trustscience.com.</small>
          </a>
        </div>

      </ModalBody>
      <ModalFooter>
        <Button
          id="cancel"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
