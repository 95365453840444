export default [
  {
    field: 'oldPassword',
    method: 'isEmpty',
    validWhen: false,
    message: 'Old password is required.',
  },
  {
    field: 'newPassword',
    method: 'isEmpty',
    validWhen: false,
    message: 'New password is required.',
  },
  {
    field: 'newPassword',
    method: 'isLength',
    validWhen: true,
    message: 'Must be at least 12 characters.',
    options: [{ min: 12 }],
  },
  {
    field: 'newPassword',
    method: 'matches',
    validWhen: true,
    message: 'Requires a number, uppercase letter, lowercase letter and special character.',
    options: [/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/],
  },
  {
    field: 'confirmPassword',
    method: 'isEmpty',
    validWhen: false,
    message: 'Confirm password is required.',
  },
];
