import React, { useState, useEffect } from 'react';
import {
  Card,
  CardTitle,
  CardFooter,
  CardBody,
  Collapse,
} from 'reactstrap';

import {
  personInfo,
  employerInfo,
  incomeInfo,
  dealershipInfo,
  vehicleInfo,
  loanDetailInfo,
  assetInfo,
} from '../../config/inputFieldLabelMapping';

import ExpandCollapseIcon from '../ExpandCollapseIcon';
import UploadDataSource from '../UploadDataSource';
import FormContext from '../../containers/ScoringRequestForm/FormContext';
import InputDisplayGroup from './InputDisplayGroup';
import getClientLocations from '../../utils/get-client-locations';

import styles from './ScoringRequestInputSummary.module.scss';
import useIsCollapsed from '../../containers/ScoringRequestDetails/hooks/useIsCollapsed';

export default function ScoringRequestInputSummary(props) {
  const { applicationSummary: { isCollapsed, toggleIsCollapsed } } = useIsCollapsed();
  const [clientLocations, setClientLocations] = useState();

  useEffect(() => {
    if (!clientLocations) {
      getClientLocations('locations')
        .then(({ locations }) => setClientLocations(locations))
        .catch((error) => console.error('Error retrieving client locations:', error));
    }
  }, [clientLocations]);

  const requestSummaryData = props.requestSummaryData ? props.requestSummaryData : {};

  const renderExpandIcon = (beforeScoringScreen) => {
    if (beforeScoringScreen) {
      return null;
    }

    return (
      <ExpandCollapseIcon
        isCollapsed={isCollapsed}
        toggleIsCollapsed={toggleIsCollapsed}
      />
    );
  };

  const renderFooter = (beforeScoringScreen) => {
    if (beforeScoringScreen || props.requestSummaryData) {
      return null;
    }

    return (
      <CardFooter className={styles.cardFooter}>
        {
          isCollapsed
            ? 'Expand the card to see the information sent for this scoring request.'
            : 'Collapse the card to hide the information.'
        }
      </CardFooter>
    );
  };

  const renderBankingUploadSection = (beforeScoringScreen) => {
    if (beforeScoringScreen || props.requestSummaryData) {
      return null;
    }

    return (
      <UploadDataSource
        isUploadOpen={props.isUploadOpen}
        uploadResult={props.uploadResult}
        onCancel={props.onCancel}
        onSubmit={props.onSubmit}
        onDisplayUploadModal={props.onDisplayUploadModal}
      />
    );
  };

  const addMissingLocationFields = (dealership, storeNumber) => {
    if (!storeNumber || !clientLocations) {
      return dealership;
    }
    const location = clientLocations.find((cl) => cl.storeNumber === storeNumber);
    return location ? { ...dealership, ...location } : dealership;
  };

  const addStoreNumberAndState = (loanInfo, storeNumber, storeState) => (
    (loanInfo && storeNumber && storeState) ? {
      ...loanInfo, storeNumber, storeState, state: storeState,
    } : loanInfo);

  return (
    <FormContext.Consumer>
      {(context) => {
        const applicant = requestSummaryData.person || context.applicant;
        const employer = requestSummaryData.currentEmployment || context.employer;
        const income = requestSummaryData.currentEmployment || (context.employer && context.income);
        const coapplicant = requestSummaryData.cosignPerson || context.coapplicant;
        const storeNumber = requestSummaryData.loanApplication?.storeNumber || requestSummaryData.dealership?.storeNumber
          || context.loanInfo?.storeNumber || context.dealership?.storeNumber;
        const dealership = addMissingLocationFields(requestSummaryData.dealership || context.dealership, storeNumber);
        const purchasingVehicle = requestSummaryData.autoLoan || context.vehicle;
        const storeState = requestSummaryData.loanApplication?.state || requestSummaryData.loanApplication?.storeState
          || requestSummaryData.dealership?.state || context.loanInfo?.state
          || context.loanInfo?.storeState || context.dealership?.state;
        const loanDetails = addStoreNumberAndState(requestSummaryData.loanApplication || context.loanInfo, storeNumber, storeState);
        const tradeInVehicle = requestSummaryData.tradeInVehicle || context.tradeIn;
        const asset = requestSummaryData.asset || context.asset;
        const customFieldBlocks = requestSummaryData?.customFields;

        return (
          <Card className={styles.cardWrapper}>
            <CardTitle tag="h3" className={[styles.cardTitle, styles.rightArrow].join(' ')}>
              { renderExpandIcon(props.beforeScoringScreen) }
              Application Summary
              { props.beforeScoringScreen && renderBankingUploadSection(props.beforeScoringScreen) }
            </CardTitle>
            <Collapse isOpen={!isCollapsed || props.beforeScoringScreen} className={styles.printable}>
              <CardBody className={styles.cardBody}>
                <InputDisplayGroup title="Applicant Information" labels={personInfo} data={applicant} beforeScoringScreen={props.beforeScoringScreen} />
                <InputDisplayGroup title="Employment Information" labels={employerInfo} data={employer} />
                <InputDisplayGroup title="Income Information" labels={incomeInfo} data={income} />
                <InputDisplayGroup title="Co-Applicant Information" labels={personInfo} data={coapplicant} />
                <InputDisplayGroup title="Location Information" labels={dealershipInfo} data={dealership} />
                <InputDisplayGroup title="Purchasing Vehicle Information" labels={vehicleInfo} data={purchasingVehicle} />
                <InputDisplayGroup
                  title="Loan Details"
                  labels={loanDetailInfo}
                  data={loanDetails}
                  customFields={props.clientConfig?.customApplicationFormFields?.loanInfo}
                />
                <InputDisplayGroup title="Trade-In Information" labels={vehicleInfo} data={tradeInVehicle} />
                <InputDisplayGroup title="Asset Information" labels={assetInfo} data={asset} />
                <InputDisplayGroup title="Custom Fields" blocks={customFieldBlocks} />
              </CardBody>
            </Collapse>
            { renderFooter(props.beforeScoringScreen) }
          </Card>
        );
      }}
    </FormContext.Consumer>
  );
}
