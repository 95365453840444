import React from 'react';
import {
  Collapse,
} from 'reactstrap';

import styles from '../IncomeSourcesCard.module.scss';
import ExpandCollapseIcon from '../../../../components/ExpandCollapseIcon';
import IncomeTransactionsTable from '../IncomeTransactionsTable';
import getMonthlyIncome from '../get-monthly-income';
import renderMonthlyAmount from '../renderMonthlyAmount';

export default function OtherCreditSource({
  sourceData,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const monthlyNetIncome = getMonthlyIncome(sourceData, false);

  return (
    <div className="card my-2">
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-grow-1" aria-hidden="true">
            <div className="row">
              <div className="col-6 col-xl-7">
                <div className="row m-1">
                  <h6>
                    {sourceData.description.toUpperCase()}
                  </h6>
                </div>
              </div>
              <div className="col-6 col-xl-5">
                <div>
                  <div className="row mb-0">
                    <div className="col-12 text-left text-lg-center">
                      <h6>
                        {monthlyNetIncome <= 0 ? <span className="font-weight-bold">Unknown</span> : (
                          renderMonthlyAmount(monthlyNetIncome)
                        )}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 text-left">
                      {'First Occurrence: '}
                    </div>
                    <div className="col-12 col-lg-6 text-left font-weight-bold">
                      {sourceData.earliestDate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 text-left">
                      {'Last Occurrence: '}
                    </div>
                    <div className="col-12 col-lg-6 text-left font-weight-bold">
                      {sourceData.latestDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex align-items-center ${styles.incomeSourceCollapse}`}>
            <ExpandCollapseIcon
              isCollapsed={isCollapsed}
              toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        </div>
        <Collapse isOpen={!isCollapsed} className={styles.printable}>
          <IncomeTransactionsTable transactions={sourceData.transactions ?? []} />
        </Collapse>
      </div>
    </div>
  );
}
