import { useChatApi } from '../api/useChatApi';
import { useChatContext } from '../context/ChatProvider/hooks/useChatContext';

export const useQuestion = () => {
  const { addToHistory } = useChatContext();
  const { addQuestion } = useChatApi();

  const addQuestionHandler = async (
    chatId,
    chatQuestion,
  ) => {
    const response = await addQuestion({
      chatId,
      chatQuestion,
    });

    const userMessage = {
      message_id: response.message_id,
      message_content: chatQuestion.question,
      message_role: 'user',
    };

    addToHistory([userMessage, response]);
  };

  return {
    addQuestion: addQuestionHandler,
  };
};

export default useQuestion;
