import buildAddressDataBlock from '../FormHelpers/buildAddressDataBlock';
import { blockIsEmpty } from '../object';

const generateVehicleWithCommonProperties = (vehicleInfo) => ({
  VIN: vehicleInfo.VIN || undefined,
  odometer: {
    value: vehicleInfo.odometer ? Number(vehicleInfo.odometer) : undefined,
    units: vehicleInfo.odometerUnits || undefined,
  },
  year: vehicleInfo.year || undefined,
  make: vehicleInfo.make || undefined,
  model: vehicleInfo.model || undefined,
  trim: vehicleInfo.trim || undefined,
  bodyStyle: vehicleInfo.bodyStyle || undefined,
});

export default ({ dealership = {}, vehicle = {}, tradeIn = {} }) => {
  const tradeInAllowance = tradeIn.tradeInAllowance
    ? Number(tradeIn.tradeInAllowance)
    : undefined;

  const address = buildAddressDataBlock(dealership);

  let vehiclePayload = {
    ...generateVehicleWithCommonProperties(vehicle),
    appraisedValue: vehicle.appraisedValue || undefined,
    condition: vehicle.condition || undefined,
    tradeInAllowance: tradeIn.tradeInAllowance || undefined,
  };

  if (vehicle.value) {
    vehiclePayload.value = Number(vehicle.value);
  }

  if (vehicle.appraisedValue) {
    vehiclePayload.appraisedValue = Number(vehicle.appraisedValue);
  }

  if (blockIsEmpty(vehicle)) {
    vehiclePayload = undefined;
  }

  let tradeInVehiclePayload;

  // Check if tradeIn block contains any other information besides `tradeInAllowance`
  if (!blockIsEmpty({ ...tradeIn, tradeInAllowance: undefined })) {
    tradeInVehiclePayload = generateVehicleWithCommonProperties(tradeIn);
  }

  const autoLoanDataBlock = {
    tradeInAllowance,
    dealer: {
      name: dealership.name,
      type: dealership.type,
      address,
    },
    vehicle: vehiclePayload,
    tradeInVehicle: { ...tradeInVehiclePayload },
  };

  if (blockIsEmpty(autoLoanDataBlock.dealer)) {
    autoLoanDataBlock.dealer = undefined;
  }

  if (!autoLoanDataBlock.tradeInAllowance
    && !autoLoanDataBlock.dealer
    && !autoLoanDataBlock.vehicle
  ) {
    return {};
  }

  return autoLoanDataBlock;
};
