import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import {
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import SCORING_USE_CASES from '../../../config/scoringRequestUseCases';

import './BasicInformationFormGroup.scss';

const formGroupFieldNames = ['employment', 'coApplicant'];

class BasicInformationFormGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
  }

  validateFormBeforeProceeding() { // eslint-disable-line class-methods-use-this
    return false;
  }

  state = {
    employment: false,
    coApplicant: false,
    tradeIn: false,
  };

  toggle = (field, context, dataTreeKey) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));

    const event = {
      target: { value: !this.state[field], name: field },
    };

    this.generateFieldOnChangeEvent(context, dataTreeKey)(event);
  };

  render() {
    const isAutoLoanWorkflow = this.props.requestUseCase === SCORING_USE_CASES.AUTO
      || this.props.requestUseCase === SCORING_USE_CASES.AUTO_PREAPPROVAL;

    const showPreapprovalToggle = (this.props.requestUseCase.match(/-preapproval/ig)
      || this.props.clientConfig.features.allowPreapprovalFormInConsole)
      && this.props.requestUseCase !== SCORING_USE_CASES.INSTALLMENT;

    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;

          if (this.props.requestUseCase === SCORING_USE_CASES.BANKING_ONLY) {
            return (
              <FormGroup>
                <Label className="spaced" htmlFor="applicantIsEmployed">
                  <p>
                    <b>This form will start a Banking Only workflow.</b>
                  </p>
                  <p>
                    In order to generate a a Six°Score™ your customer must submit their banking
                    information to complete the Instant Banking Verification (IBV) process.
                  </p>
                  <p>
                    You can enable the &quot;Send Smart Consent™ Invite&quot; switch on the next screen
                    to have an automated email or SMS sent inviting them to submit their banking
                    through the Smart Consent™ application. Otherwise, you can use the Smart Consent™ link
                    provided after submitting the form to manually send an invite to your customer.
                  </p>
                </Label>
              </FormGroup>
            );
          }

          return (
            <div className="form-group-wrapper">
              <Container className="auto-scoring-request-form-starter">
                {
                  this.props.requestUseCase !== SCORING_USE_CASES.SHORTTERM
                    ? (
                      <Row>
                        <FormGroup>
                          <Label className="spaced" htmlFor="applicantIsEmployed">Employed?</Label>
                          <Toggle
                            id="applicantIsEmployed"
                            active={previousState.employment === true}
                            on="Yes"
                            off="No"
                            size="xs"
                            onstyle="success"
                            handlestyle="default"
                            offstyle="custom"
                            onClick={() => this.toggle('employment', context, dataTreeKey)}
                          />
                        </FormGroup>
                      </Row>
                    )
                    : null
                }
                <div className="row">
                  <FormGroup>
                    <Label className="spaced" htmlFor="coapplicantAvailable">Co-Applicant?</Label>
                    <Toggle
                      id="coapplicantAvailable"
                      active={previousState.coApplicant === true}
                      on="Yes"
                      off="No"
                      size="xs"
                      onstyle="success"
                      handlestyle="default"
                      offstyle="custom"
                      onClick={() => this.toggle('coApplicant', context, dataTreeKey)}
                    />
                  </FormGroup>
                </div>
                {
                  isAutoLoanWorkflow
                    ? (
                      <div className="row">
                        <FormGroup>
                          <Label className="spaced" htmlFor="tradeIn">Trade-In?</Label>
                          <Toggle
                            id="tradeIn"
                            active={previousState.tradeIn === true}
                            on="Yes"
                            off="No"
                            size="xs"
                            onstyle="success"
                            handlestyle="default"
                            offstyle="custom"
                            onClick={() => this.toggle('tradeIn', context, dataTreeKey)}
                          />
                        </FormGroup>
                      </div>
                    )
                    : null
                }
                {
                  showPreapprovalToggle
                    ? (
                      <div className="row">
                        <FormGroup>
                          <Label className="spaced" htmlFor="preapproval">Preapproval?</Label>
                          <Toggle
                            id="preapproval"
                            active={previousState.preapproval === true}
                            on="Yes"
                            off="No"
                            size="xs"
                            onstyle="success"
                            handlestyle="default"
                            offstyle="custom"
                            onClick={() => this.toggle('preapproval', context, dataTreeKey)}
                          />
                        </FormGroup>
                      </div>
                    )
                    : null
                }
              </Container>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default BasicInformationFormGroup;
