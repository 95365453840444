import React from 'react';

import styles from './InfoBlock.module.scss';

const generateClassName = (type, shortWidth) => {
  let className = styles.InfoBlock;

  if (styles[type]) {
    className += ` ${styles[type]}`;
  }

  if (shortWidth) {
    className += ` ${styles.shortWidth}`;
  }

  return className;
};

export default function (props) {
  return (
    <aside className={generateClassName(props.type, props.shortWidth)}>
      {props.children}
    </aside>
  );
}
