import React, { useState } from 'react';
import { Button } from 'reactstrap';
import VerificationModal from '../SendVerificationModal';

function VerificationBanner(props) {
  const [visibility, setVisibility] = useState('show');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div className={`alert alert-warning alert-dismissible fade ${visibility} d-flex justify-content-between mb-0 py-2`}>
      <div className="d-flex align-items-center">
        <p className="mb-0">Please verify your email to keep your account secure</p>
      </div>
      <div>
        <Button color="primary" onClick={toggle}>Verify Email</Button>
        <Button close onClick={() => setVisibility('hide')}>
          <span>&times;</span>
        </Button>
      </div>
      <VerificationModal
        modalOpen={modal}
        toggle={toggle}
        userAccount={props.userAccount}
      />
    </div>
  );
}

export default VerificationBanner;
