import api from './api';

export const getActionMenu = (requestId, isUat) => api('GetActionMenu', {
  requestId,
  isUat,
});

export const runActionMenuItem = (requestId, action, params, isUat) => api('RunActionMenuItem', {
  requestId,
  action,
  params,
  isUat,
});
