import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import EnvironmentView from './Environment.view';

const byEnvironmentIdDesc = (left, right) => (
  right.environmentId - left.environmentId
);

export default function ManageDeploymentsView(props) {
  const {
    prodFlowConfig,
    uatFlowConfig,
    uatOtherProducts,
    prodOtherProducts,
    deployments = [],
    getDeploymentsByCommit,
    onCreateDeployment,
    onDeploymentUpdated,
    isAnyEditModeActive,
    onEditModeChange,
  } = props;

  const [showAllDeployments, setShowAllDeployments] = React.useState(false);

  const environments = deployments.map((d) => ({
    id: d.environmentId,
    name: d.name,
    availableSlots: d.availableSlots,
  }));

  return (
    <div className="m-auto">
      <div className="mb-3 mx-auto text-right" style={{ width: '85%' }}>
        <button
          className="border-0 bg-transparent"
          type="button"
          onClick={() => setShowAllDeployments(!showAllDeployments)}
        >
          {'Show all deployments '}
          <Toggle
            id="inlineCheckbox2"
            active={showAllDeployments}
            on="Yes"
            off="No"
            size="xs"
            onstyle="success"
            handlestyle="default"
            offstyle="custom"
          />
        </button>
      </div>
      <div>
        {
          deployments
            .sort(byEnvironmentIdDesc)
            .map((d) => (
              <EnvironmentView
                key={d.environmentId}
                deployments={d}
                environments={environments}
                totalSlots={d.totalSlots}
                usedSlots={d.usedSlots}
                availableSlots={d.availableSlots}
                prodFlowConfig={prodFlowConfig}
                uatFlowConfig={uatFlowConfig}
                uatOtherProducts={uatOtherProducts}
                prodOtherProducts={prodOtherProducts}
                getDeploymentsByCommit={getDeploymentsByCommit}
                onCreateDeployment={onCreateDeployment}
                onDeploymentUpdated={onDeploymentUpdated}
                isAnyEditModeActive={isAnyEditModeActive}
                onEditModeChange={onEditModeChange}
                showAllDeployments={showAllDeployments}
              />
            ))
        }
      </div>
    </div>
  );
}
