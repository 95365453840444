import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormText,
} from 'reactstrap';
import styles from './style.scss';
import getClientLocations from '../../utils/get-client-locations';
import LocationSelector from '../LocationSelector';
import FormValidator from '../../utils/FormValidator';
import validationConfig from './validationConfig';
import SanitizedTextInput from '../SanitizedTextInput';
import * as userService from '../../services/user-services';
import useUserAttributes from '../../hooks/useUserAttributes';

const renderErrorMessage = (isShown, onClose) => {
  if (!isShown) return null;
  return (
    <>
      <ModalBody>
        <div id="errorMessage">
          There was an error saving your account. Please try again later or contact support for further assistance.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

const onSave = async (
  setSaveWasSuccessful,
  setSaveFailed,
  setValidations,
  firstName,
  lastName,
  syncUserAttributesUpdates,
  setLoading,
) => {
  setLoading(true);
  const validator = new FormValidator(validationConfig);
  const results = validator.validate({ firstName, lastName });

  if (results.isInvalid) {
    setValidations(results);
    setLoading(false);
  } else {
    try {
      await Promise.all([
        syncUserAttributesUpdates(),
        userService.updateCognitoAttributes({
          family_name: lastName,
          given_name: firstName,
        }),
      ]);
      setSaveWasSuccessful(true);
    } catch (error) {
      console.error('Error updating user attributes', error);
      setSaveFailed(true);
    } finally {
      setLoading(false);
    }
  }
};

const mapPhoneNumberToDisplay = (number) => {
  if (!number) return '';
  const areaCode = number.slice(2, 5);
  const middle = number.slice(5, 8);
  const last = number.slice(8);
  return [areaCode, middle, last].join('-');
};

const renderValidationError = (validation) => ((validation && validation.isInvalid) ? (<small className="validationError text-danger">{validation.message}</small>) : null);

const renderSuccessMessage = (isShown, onClose) => {
  if (!isShown) return null;
  return (
    <>
      <ModalBody>
        <div id="successMessage">
          Your user profile was successfully updated.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

export default function (props) {
  const [isLoading, setLoading] = useState(true);
  const [validations, setValidations] = useState({});
  const [saveWasSuccessful, setSaveWasSuccessful] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [locations, setLocation] = useState({});
  const [user, setUser] = useState(props.user);
  const { userAttributes, updateUserAttributes, syncUserAttributesUpdates } = useUserAttributes();
  const onCognitoAttributeChanged = (e) => {
    const { target: { id: key, value } } = e;
    setUser((previous) => ({
      ...previous,
      [key]: value,
    }));
  };
  const onUserAttributeChanged = (e) => {
    const { target: { id: key, value } } = e;
    updateUserAttributes({
      [key]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { locations: clientLocations } = await getClientLocations('locations');
        setLocation(clientLocations);
      } catch (error) {
        console.error('Error retrieving client locations:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Modal isOpen>
        <ModalHeader>User Profile</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Spinner />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal isOpen>
      <ModalHeader>User Profile</ModalHeader>
      { renderSuccessMessage(saveWasSuccessful, props.onClose) }
      { renderErrorMessage(saveFailed, props.onClose)}
      { (!saveWasSuccessful && !saveFailed) && (
        <>
          <ModalBody>
            <FormGroup className="changeName" key="given_name">
              <Label for="firstName">First Name</Label>
              <SanitizedTextInput
                id="given_name"
                name="firstName"
                type="text"
                value={user.given_name}
                onChange={onCognitoAttributeChanged}
              />
              {renderValidationError(validations.firstName)}
            </FormGroup>
            <FormGroup key="family_name">
              <Label for="family_name">Last Name</Label>
              <SanitizedTextInput
                id="family_name"
                name="lastName"
                type="text"
                value={user.family_name}
                onChange={onCognitoAttributeChanged}
              />
              {renderValidationError(validations.lastName)}
            </FormGroup>
            <FormGroup>
              <Label for="setEmail" className="float-left">Email </Label>
              <FormText className="float-left ml-2">joe@email.com</FormText>
              <SanitizedTextInput
                id="setEmail"
                name="email"
                help="joe@email.com"
                readOnly
                type="email"
                value={user.email}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phoneNum" className="float-left">Phone Number</Label>
              <FormText className="float-left ml-2">###-###-####</FormText>
              <Input
                id="phoneNum"
                name="phoneNum"
                type="text"
                value={mapPhoneNumberToDisplay(user.phone_number)}
                readOnly
              />
            </FormGroup>
            <FormGroup key="custom:homeLocationId" disabled>
              <LocationSelector
                disabled
                labelName="Home Location"
                id="homeLocationId"
                addresses={locations}
                attributeName="homeLocationId"
                onChange={onUserAttributeChanged}
                readOnly
                selectedLocationId={userAttributes.homeLocationId}
              />
            </FormGroup>
            { props.enabledFeatures.manageLocations ? (
              <FormGroup key="custom:preferredLocationId">
                <LocationSelector
                  labelName="Preferred Location"
                  id="preferredLocationId"
                  addresses={locations}
                  attributeName="preferredLocationId"
                  onChange={onUserAttributeChanged}
                  selectedLocationId={userAttributes.preferredLocationId || ''}
                />
              </FormGroup>
            ) : null}
            <FormGroup>
              <Label for="roles">User Roles</Label>
              <div className="d-flex justify-content-around flex-wrap p-2">
                {Object.entries(user['cognito:groups']).map(([id, name]) => (
                  <span key={id} className={styles.groups} data-tooltip={name}>
                    {name}
                  </span>
                ))}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              id="save"
              color="primary"
              onClick={() => onSave(
                setSaveWasSuccessful,
                setSaveFailed,
                setValidations,
                user.given_name,
                user.family_name,
                syncUserAttributesUpdates,
                setLoading,
              )}
              disabled={isLoading}
              outline={isLoading}
            >
              {isLoading ? (<Spinner className="d-flex" size="sm" color="primary" />) : 'Save'}
            </Button>
              &nbsp;
            <Button
              id="cancel"
              color="secondary"
              onClick={() => props.onClose()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
