import React from 'react';
import Modal from '../Modal';

const selectBody = (success) => (
  success
    ? (
      <div>
        Successfully updated.
      </div>
    )
    : (
      <div>
        There was an error while updating user attributes.
        <br />
        Please try again.
      </div>
    )
);

export default function ResultOfSavingModal(props) {
  const { success, buttons } = props;
  return (
    <Modal
      isOpen
      title="User Properties"
      buttons={buttons}
    >
      { selectBody(success) }
    </Modal>
  );
}
