import React from 'react';
import { useModal } from '../../hooks/useModal';
import api from '../../services/api';
import Loader from '../../components/Loader';
import RuleCard from './RuleCard';
import { ChatProvider } from '../RuleBuilder/context';
import CreateChatButton from './CreateChatButton';

export default function ManageRulesContainer({ history }) {
  const [rules, setRules] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { showModal } = useModal();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const rulesResult = await api('ManageGeneratedRules', {
        action: 'list',
      });
      if (rulesResult.success) {
        setRules(rulesResult.rules);
      } else {
        showModal({
          header: 'Failed to fetch rules',
          body: 'Failed to fetch rules. Please try again later.',
        });
        console.error('Failed to fetch rules', rulesResult);
      }
      setLoading(false);
      setRules(rulesResult.rules);
    };
    fetchData();
  }, [showModal]);

  const updateRuleState = (rule) => {
    setRules((prevRules) => {
      const newRules = prevRules.map((r) => {
        if (r.id === rule.id) {
          return rule;
        }
        if (r.name === rule.name && rule.enabled) {
          return {
            ...r,
            enabled: false,
          };
        }
        return r;
      });
      return newRules;
    });
  };

  return (
    <div className="flex-1">
      <main className="d-flex flex-column w-100 chatmain row align-items-center">
        <section className="d-flex flex-column flex-1 align-items-center w-100 px-5">
          <div className="d-flex flex-column align-items-center justify-center px-5">
            <h1 className="h2 font-weight-bold text-center">
              RuleBuilder
            </h1>
          </div>
          <div>
            <ChatProvider chatId={null}>
              <CreateChatButton history={history} setLoading={setLoading} />
            </ChatProvider>
          </div>
          {loading && <div className="my-4"><Loader className="position-static" /></div>}
          {rules?.map((rule) => (
            <RuleCard key={rule.id} rule={rule} updateRuleState={updateRuleState} />
          ))}
        </section>
      </main>
    </div>
  );
}
