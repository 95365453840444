import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faWindowClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Form,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import StatesAndProvincesOptions from '../../../containers/ScoringRequestForm/StatesAndProvincesOptions';
import styles from './Filters.module.scss';

library.add(faFilter, faWindowClose, faInfoCircle);

export default function (props) {
  const [popoverOpened, togglePopover] = useState(false);
  const [filters, setFilters] = useState(props.filters || {});
  const [searchTerms, setSearchTerms] = useState(props.searchTerms || {});

  const toggleFilterWindow = () => {
    togglePopover(!popoverOpened);
  };

  const applySearchFilters = (event) => {
    const { target } = event;

    if (target.dataset.isAFilter === 'true') {
      const { value, dataset: { filter, field } } = target;
      setFilters({
        ...filters,
        [filter || field]: value.trim(),
      });
      return;
    }

    if (['text', 'date'].includes(target.type)) {
      const { value, dataset } = target;
      if (!value || (value === '')) {
        setSearchTerms({
          ...searchTerms,
          [dataset.field]: undefined,
        });
      } else {
        setSearchTerms({
          ...searchTerms,
          [dataset.field]: value.trim(),
        });
      }
    }
  };

  const clearSearchFilters = () => {
    setFilters({});
    setSearchTerms({});
    props.performSearch({}, {});
    togglePopover(false);
  };

  const performSearch = () => {
    props.performSearch(filters, searchTerms);
    togglePopover(false);
  };

  const searchOnEnter = (event) => {
    if (event.charCode === 13) {
      performSearch();
    }
  };

  return (
    <div colSpan={props.colSpan} className={styles.filtersWrapper}>
      <Button color="success" className={styles.filterIcon} id="filterPopover">
        Filter
        &nbsp;
        <FontAwesomeIcon icon="filter" />
      </Button>
      <Popover className={styles.popover} placement="right" isOpen={popoverOpened} target="filterPopover" toggle={toggleFilterWindow}>
        <PopoverHeader className={styles.popoverTitle}>
          Request History Filters
          <FontAwesomeIcon icon="window-close" onClick={toggleFilterWindow} />
        </PopoverHeader>
        <PopoverBody>
          <div className={styles.popoverBody}>
            <Form inline>
              <h5>Filters:</h5>
              <div className={styles.filteringWrapper}>
                <div>
                  <span>
                    Has Banking
                  </span>
                  <Input
                    type="select"
                    name="hasBanking"
                    onChange={applySearchFilters}
                    data-filter="banking_flag"
                    defaultValue={filters.banking_flag}
                    data-is-a-filter
                  >
                    <option>Any</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Input>
                  <FontAwesomeIcon icon="info-circle" id="hasBankingTooltip" className={styles.filterDescription} />
                  <UncontrolledTooltip placement="right" target="hasBankingTooltip">
                    Requests containing banking information submitted by customer.
                  </UncontrolledTooltip>
                </div>
                <div>
                  <span>
                    Has Bureau
                  </span>
                  <Input
                    type="select"
                    name="hasBureau"
                    onChange={applySearchFilters}
                    data-filter="bureau_flag"
                    defaultValue={filters.bureau_flag}
                    data-is-a-filter
                  >
                    <option>Any</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Input>
                  <FontAwesomeIcon icon="info-circle" id="hasBureauTooltip" className={styles.filterDescription} />
                  <UncontrolledTooltip placement="right" target="hasBureauTooltip">
                    Requests where bureau data has been received, including no hit records.
                  </UncontrolledTooltip>
                </div>
                <div>
                  <span>
                    Has Attachments
                  </span>
                  <Input
                    type="select"
                    name="hasAttachments"
                    onChange={applySearchFilters}
                    data-filter="attachments_flag"
                    defaultValue={filters.attachments_flag}
                    data-is-a-filter
                  >
                    <option>Any</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Input>
                  <FontAwesomeIcon icon="info-circle" id="hasAttachmentsTooltip" className={styles.filterDescription} />
                  <UncontrolledTooltip placement="right" target="hasAttachmentsTooltip">
                    Requests containing attached documents.
                  </UncontrolledTooltip>
                </div>
                <div>
                  <span>
                    Invite Sent
                  </span>
                  <Input
                    type="select"
                    name="hasInvite"
                    onChange={applySearchFilters}
                    data-filter="invite_flag"
                    defaultValue={filters.invite_flag}
                    data-is-a-filter
                  >
                    <option>Any</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Input>
                  <FontAwesomeIcon icon="info-circle" id="inviteSentTooltip" className={styles.filterDescription} />
                  <UncontrolledTooltip placement="right" target="inviteSentTooltip">
                    Requests with an outgoing Smart Consent invitation.
                  </UncontrolledTooltip>
                </div>
              </div>
            </Form>
            <hr />
            <Form inline>
              <h5>Search By:</h5>
              <div className={styles.filteringWrapper}>
                {
                  props.items.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.search
                        ? (
                          <div className={styles.searchTextInputGroup}>
                            <span>{item.name}</span>
                            <Input
                              type={item.type || 'text'}
                              bsSize="sm"
                              data-field={item.id}
                              data-is-a-filter={item.isAFilter}
                              onChange={applySearchFilters}
                              onKeyPress={searchOnEnter}
                              defaultValue={item.isAFilter ? filters[item.id] : searchTerms[item.id]}
                              placeholder={item.helpText ? item.helpText : null}
                            />
                          </div>
                        )
                        : null}
                    </React.Fragment>
                  ))
                }
                <div className={styles.searchTextInputGroup}>
                  <span>Store State</span>
                  <Input
                    type="select"
                    onChange={applySearchFilters}
                    defaultValue={filters.store_state}
                    data-filter="store_state"
                    data-is-a-filter
                  >
                    <StatesAndProvincesOptions region={props.region} />
                  </Input>
                </div>
              </div>
            </Form>
            <hr />
            <div className={styles.buttonsGroupWrapper}>
              <Button color="secondary" onClick={toggleFilterWindow}>
                Cancel
              </Button>
              <Button color="success" onClick={performSearch}>
                Apply
              </Button>
              <Button color="success" onClick={clearSearchFilters}>
                Clear Filters
              </Button>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}
