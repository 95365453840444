import React, { useState } from 'react';
import { useSessionStorageValue } from '@react-hookz/web';
import Button from '../Button';

import { useChat } from '../../hooks/useChat';
import { useModal } from '../../../../hooks/useModal';

export function ChatInput({ history }) {
  const { value: saveable, set: setSaveable } = useSessionStorageValue('saveable', {
    defaultValue: false,
  });
  const [message, setMessage] = useState('');
  const {
    addQuestion, isLoading, chatStatus, saveRule,
  } = useChat();
  const { showModal } = useModal();

  const submitQuestion = () => {
    if (message.length === 0) return;
    if (!isLoading) {
      setSaveable(true);
      addQuestion(message, () => setMessage(''));
    }
  };

  const saveRuleHandler = async (enabled) => {
    if (message.length === 0) {
      showModal({
        title: 'Error',
        body: 'Please give the rule a name before saving.',
      });
      return;
    }
    if (!isLoading) {
      const result = await saveRule(message, enabled);
      if (!result.success) {
        showModal({
          title: 'Error',
          body: result.error,
        });
      } else {
        history.push('/rule-builder');
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (chatStatus !== 'done') {
          submitQuestion();
        } else {
          saveRuleHandler(true);
        }
      }}
      className="mt-3 p-4 rounded-xl border w-100 d-flex align-items-center justify-center"
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {chatStatus !== 'done' ? (
        <>
          <textarea
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={message}
            required
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents the newline from being entered in the textarea
                submitQuestion();
              }
            }}
            className="w-100 p-2 border outline-none rounded"
            placeholder="Begin conversation here..."
          />
          <Button
            className="px-3  btn-secondary"
            type="submit"
            isLoading={isLoading}
          >
            {isLoading
              ? 'Thinking...'
              : 'Chat'}
          </Button>
        </>
      ) : (
        saveable ? (
          <div className="row w-100 m-0">
            <div className="col-7">
              <h6>What would you like to name this rule?</h6>
              <input
                type="text"
              // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    saveRuleHandler(true);
                  }
                }}
                className="w-100 p-2 border outline-none rounded"
                placeholder="Name your new rule..."
              />
            </div>
            <div className="col-5 d-flex align-items-end">
              <div className="row">
                <div className="col-12 col-lg-5 px-0 pr-2">
                  <Button
                    className="w-100 ml-2 px-3 text-nowrap btn-secondary"
                    type="button"
                    isLoading={isLoading}
                    onClick={() => saveRuleHandler(false)}
                  >
                    Save Only
                  </Button>
                </div>
                <div className="col-12 col-lg-7 px-0 pr-2">
                  <Button
                    className="w-100 ml-2 px-3 text-nowrap btn-primary"
                    type="button"
                    isLoading={isLoading}
                    onClick={() => saveRuleHandler(true)}
                  >
                    Save & Enable
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h5 className="text-center w-100">
            Rule is not editable.
            Please create a new rule
            {' '}
            <a className="p-0" href="/rule-builder">here</a>
            {' '}
            instead.
          </h5>
        )
      )}
    </form>
  );
}

export default ChatInput;
