import React from 'react';
import { withRouter } from 'react-router-dom';

/*
  The sole purpose of this HOC is to avoid a false-positive warning from React during runtime,
  which says "Function components do not support contextType".

  But we need to use context API from React and `withRouter()` HOC together for application forms,
  so we will have to rewrap withRouter with another workaround component class.
*/

/* eslint-disable */
export default function withRouterWorkaround(Inner) {
  class Wrapped extends React.Component {
      render() {
          return <Inner {...this.props}/>;
      }
  }
  Wrapped.displayName = `WithRouterWorkaround(${Inner.displayName || Inner.name || '?'})`;
  return withRouter(Wrapped);
}
