/* eslint-disable react/no-danger */
import React from 'react';

import styles from './TextReportView.module.scss';

const toHtml = (text) => (
  text
    .trim()
    .replace(/\n/g, '<br />')
);

export default function TextReportView(props) {
  const {
    report: {
      REL4Report: {
        TU_FFR_Report: {
          TU_TTY_Report: report,
        } = {},
      } = {},
    } = {},
  } = props;
  if (!report) {
    return (
      <div className={styles.noReport}>No text report for subject</div>
    );
  }
  return (
    <div className={styles.textReport}>
      <pre dangerouslySetInnerHTML={{ __html: toHtml(report) }} />
    </div>
  );
}
