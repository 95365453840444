import React from 'react';

import { Link } from 'react-router-dom';
import SITE_PATHS from '../../config/sitePaths';
import FormContext from '../../containers/ScoringRequestForm/FormContext';
import SCORING_USE_CASES from '../../config/scoringRequestUseCases';

import styles from './ScoringRequestFormHeader.module.scss';

const isBeginning = (currentPath) => (
  currentPath === SITE_PATHS.AMS_FORM_START
    || currentPath === SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START
    || currentPath === SITE_PATHS.INSTALLMENT_FORM_START
    || currentPath === SITE_PATHS.BANKING_ONLY_FORM_START
    || currentPath === SITE_PATHS.SHORT_TERM_FORM_START
);

const highlightSection = (currentPath, matchingPath) => {
  let pathMatched;

  if (matchingPath === 'start') {
    pathMatched = isBeginning(currentPath);

    if (pathMatched) {
      return styles.highlighted;
    }
  }

  pathMatched = currentPath.includes(matchingPath);
  if (pathMatched) {
    return styles.highlighted;
  }

  return '';
};

const getDetailsPageUrl = (requestId, searchParams) => {
  const searchParamsString = searchParams ? `?${searchParams}` : '';
  return `${SITE_PATHS.SCORING_REQUEST_DETAILS.replace(':requestId', requestId)}${searchParamsString}`;
};

const renderProceedButton = (
  currentPath,
  runValidation,
  isRescoring,
  runUpdateOnly,
) => {
  const atPreviewScreen = currentPath.includes(SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW);

  if (isRescoring && atPreviewScreen) {
    return (
      <>
        <button
          type="button"
          className={`submit btn ${styles.btnPrimary} mb-2`}
          onClick={runValidation}
        >
          Update &amp; Rescore
        </button>
        { runUpdateOnly && (
          <button
            type="button"
            className={`submit btn ${styles.btnPrimary} mb-2`}
            onClick={runUpdateOnly}
          >
            Update Info
          </button>
        )}
      </>
    );
  }

  return (
    <button
      type="button"
      className={`submit btn ${styles.btnPrimary} mb-2`}
      onClick={() => {
        runValidation();
      }}
    >
      Next
    </button>
  );
};

export default function (props) {
  const endOfWorkflow = props.highlightedSection.includes(SITE_PATHS.SCORING_REQUEST.SCORING_REPORT);

  const renderCoApplicantSection = (enabled) => {
    if (!enabled) {
      return null;
    }

    return (
      <div
        className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, SITE_PATHS.SCORING_REQUEST.COAPPLICANT_INFO)}`}
      >
        Co-Applicant
      </div>
    );
  };

  const isStartOfForm = isBeginning(props.highlightedSection);

  const searchParams = props.isUat ? new URLSearchParams({
    env: 'uat',
  }) : null;

  return (
    <FormContext.Consumer>
      {(context) => (
        <div className={
          [
            styles.topRow,
            props.disableHeaderButtons ? styles.disabledButtons : '',
          ]
            .join(' ')
          }
        >
          <div className={styles.indicatorGroup}>
            <div className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, 'start')}`}>
              Basics
            </div>
            <div className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, SITE_PATHS.SCORING_REQUEST.APPLICANT_INFO)}`}>
              Applicant
            </div>
            { renderCoApplicantSection(context.basicInfo.coApplicant) }
            {props.requestUseCase === SCORING_USE_CASES.BANKING_ONLY
              ? null
              : (
                <div className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS)}`}>
                  Scoring Details
                </div>
              )}
            <div
              className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW)}`}
            >
              Review
            </div>
            <div
              className={`${styles.formStepIndicator} ${highlightSection(props.highlightedSection, SITE_PATHS.SCORING_REQUEST.SCORING_REPORT)}`}
            >
              Six°Score™
            </div>
          </div>
          <div className={styles.buttonGroup}>
            {!endOfWorkflow && (
              <button
                type="button"
                color="secondary"
                className={`submit btn ${styles.btnDanger} mb-2 `}
                onClick={props.isRescoringWorkflow ? props.returnToScoringDetails : props.backToHomeScreen}
              >
                {
                  props.isRescoringWorkflow
                    ? 'Exit'
                    : 'Cancel'
                }
              </button>
            )}

            {((!endOfWorkflow && !context.requestId) || (context.showPreviousButton && !context.requestId)) && !isStartOfForm && (
              <button
                type="button"
                color="secondary"
                className={`submit btn ${styles.btnSecondary} mb-2`}
                onClick={props.backward}
              >
                Previous
              </button>
            )}

            {(!endOfWorkflow && renderProceedButton(
              props.highlightedSection,
              props.validateSectionBeforeProceeding,
              props.isRescoringWorkflow,
              props.enableUpdateInfoButton && props.performUpdateRequestWithoutRescore,
            ))}

            {(endOfWorkflow && context.requestId) && (
            <>
              <Link className="order-2 p-0" to={getDetailsPageUrl(context.requestId, searchParams)}>
                <button
                  type="button"
                  color="secondary"
                  className={`submit btn ${styles.btnSecondary} mb-2`}
                >
                  See Details
                </button>
              </Link>
              <button
                type="button"
                className={`submit btn ${styles.btnPrimary} mb-2`}
                onClick={() => {
                  props.restart();
                }}
              >
                New Request
              </button>
            </>
            )}
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
}
