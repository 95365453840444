import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import styles from './BaseVehicleInputGroup.module.scss';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

export default function (props) {
  return (
    <>
      <FormGroup>
        <Label htmlFor="vehicleVIN">
          VIN
        </Label>
        <SanitizedTextInput
          className={props.state.VINFieldInvalid ? 'is-invalid' : ''}
          name="VIN"
          id="vehicleVIN"
          maxLength="17"
          defaultValue={props.previousState.VIN || ''}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="vehicleOdometer">
          Odometer
        </Label>
        <div className={styles.odometerUnitsWrapper}>
          <Input
            className={
            [
              (props.state.odometerFieldInvalid ? 'is-invalid' : ''),
              styles.odometerInput,
            ].join(' ')
          }
            name="odometer"
            id="vehicleOdometer"
            defaultValue={props.previousState.odometer || ''}
            onChange={props.onChange}
            type="number"
          />
          <Input
            className={
            [
              (props.state.odometerUnitsFieldInvalid ? 'is-invalid' : ''),
              styles.odometerUnitsSelect,
            ].join(' ')
          }
            type="select"
            name="odometerUnits"
            id="vehicleOdometerUnits"
            defaultValue={props.previousState.odometerUnits || ''}
            onChange={props.onChange}
          >
            <option value="">Units...</option>
            <option value="km">km</option>
            <option value="miles">miles</option>
          </Input>
        </div>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="year">
          Year
        </Label>
        <SanitizedTextInput
          className={props.state.yearFieldInvalid ? 'is-invalid' : ''}
          name="year"
          id="year"
          defaultValue={props.previousState.year || ''}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="make">
          Make
        </Label>
        <SanitizedTextInput
          className={props.state.makFieldeInvalid ? 'is-invalid' : ''}
          name="make"
          id="make"
          defaultValue={props.previousState.make || ''}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="model">
          Model
        </Label>
        <SanitizedTextInput
          className={props.state.modelFieldInvalid ? 'is-invalid' : ''}
          name="model"
          id="model"
          defaultValue={props.previousState.model || ''}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="trim">
          Trim
        </Label>
        <SanitizedTextInput
          className={props.state.trimFieldInvalid ? 'is-invalid' : ''}
          name="trim"
          id="trim"
          defaultValue={props.previousState.trim || ''}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="bodyStyle">
          Body Style
        </Label>
        <SanitizedTextInput
          className={props.state.bodyStyleFieldInvalid ? 'is-invalid' : ''}
          name="bodyStyle"
          id="bodyStyle"
          defaultValue={props.previousState.bodyStyle || ''}
          onChange={props.onChange}
        />
      </FormGroup>
    </>
  );
}
