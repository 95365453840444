import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useKeyboardEvent } from '@react-hookz/web';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import categoriesConfig from '../../../../config/banking-categories.json';

import styles from './AnnotationModal.module.scss';
import { useBankingAnnotations } from '../../hooks/useBankingAnnotations';
import saveAnnotation from './save-annotation';
import AnnotationSearch from './AnnotationSearch';
import { annotationDepthArray, minCategorySelectLength, specialCategories } from './annotation-config';

const categoriesConfigTopLevel = Object.fromEntries(
  Object.entries(categoriesConfig).filter(([categoryKey]) => !specialCategories.includes(categoryKey)),
);

const getRecursive = (obj, path) => path.reduce((recurse, key) => recurse?.[key], obj);

const filterCategories = ([categoryKey, categoryInfo]) => !categoryInfo.hide && categoryKey.startsWith('/');

const updateArraySuffix = (arr, oldValue, newValue) => {
  const index = arr.indexOf(oldValue);
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1).map(() => null)];
};

const getSelectableCategories = (selectedCategoryKeys) => {
  const filteredCategories = annotationDepthArray.map((index) => Object.entries(
    getRecursive(categoriesConfigTopLevel, selectedCategoryKeys.slice(0, index)) ?? {},
  ).filter(filterCategories).sort((a, b) => a[1].label.localeCompare(b[1].label)));

  const selectableCategories = filteredCategories.map((categoryArray) => [
    ['/unsure', categoriesConfig['/unsure']],
    ...categoryArray,
    ['/other', categoriesConfig['/other']],
  ]);
  return selectableCategories;
};

function AnnotationRow({
  categoryKey, categoryInfo, index, isActive, onClick,
}) {
  const selectedCategoryRef = useRef(null);
  useEffect(() => {
    if (isActive) {
      selectedCategoryRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [isActive]);

  if (!categoryKey) return null;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" tabIndex="-1" className={`list-group-item list-group-item-action py-1 px-2 ${isActive ? 'active' : ''}`} onClick={onClick} ref={selectedCategoryRef}>
      <div className={`badge badge-${isActive ? 'light' : 'primary'} mr-2 float-left ${styles.badge}`}>{index + 1}</div>
      {categoryInfo.label}
    </a>
  );
}

function CategorySelection({ selectableCategories, activeKey, setSelectedCategory }) {
  if (selectableCategories.length <= minCategorySelectLength) return null;

  return (
    <div className="px-2 col-3 overflow-auto h-100">
      <ul className="list-group">
        {selectableCategories.map(([categoryKey, categoryInfo], index) => (
          <AnnotationRow
            key={categoryKey}
            categoryKey={categoryKey}
            categoryInfo={categoryInfo}
            index={categoryKey === '/other' ? 99 - 1 : index}
            isActive={categoryKey === activeKey}
            onClick={() => setSelectedCategory(categoryKey)}
          />
        ))}
      </ul>
    </div>
  );
}

function CategoryDescription({
  categoryKey, categoryInfo, show, selected,
}) {
  if (!show) return null;
  return (
    <div className="px-2 col-3">
      <div
        className={`card mb-2 overflow-auto ${selected ? styles.selectedCard : styles.unselectedCard}`}
        style={{ height: '220px' }}
      >
        {categoryKey && (
        <div className="card-body">
          <h5 className="card-title">
            <div className={`badge badge-primary mb-2 mt-0 ${styles.badge}`}>
              {categoryKey.split('/').pop()}
            </div>
            <br />
            {categoryInfo.label}
          </h5>
          <p className="card-text">
            {categoryInfo.description}
          </p>
        </div>
        )}
      </div>
    </div>
  );
}

function AnnotationModal({
  modalData, accountInfo, closeModal,
}) {
  const { annotation } = modalData;

  const {
    addAnnotation, requestInfo, isUat, user,
  } = useBankingAnnotations();

  const existingAnnotationKeys = annotation?.annotation?.suggestedCategory?.split('/').map(
    (v) => `/${v}`,
  );
  const selectedCategoryKeysInit = ['/unsure', null, null, null].map((v, i) => existingAnnotationKeys?.[i] ?? v);

  const [categorySelectState, updateCategorySelectState] = useState({
    selectedCategoryKeys: selectedCategoryKeysInit,
    keyboardSelectIndex: 0,
    keyboardQueue: [],
  });

  const [searchBoxSelected, setSearchBoxSelected] = useState(false);

  const { selectedCategoryKeys } = categorySelectState;

  const selectableCategories = getSelectableCategories(selectedCategoryKeys);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const transaction = modalData.rawTransaction;
    const annotationData = {
      transaction,
      source: {
        ...accountInfo,
        ...requestInfo,
        transactionId: transaction.id,
        transactionIndex: transaction.transactionIndex,
      },
      annotation: {
        suggestedCategory: selectedCategoryKeys.filter((v) => v).join('').slice(1),
        suggestedBy: user.email,
      },
    };
    addAnnotation(annotationData);
    saveAnnotation({
      annotationData,
      isUat,
    });
    closeModal();
  };

  // Select category with number keys
  useKeyboardEvent(
    ({ key }) => !!key.match(/^[0-9]$/), // Filter to number keys
    (event) => {
      if (searchBoxSelected) return;

      const { key } = event;
      updateCategorySelectState((state) => {
        const { keyboardSelectIndex, keyboardQueue, selectedCategoryKeys: oldSelectedCategoryKeys } = state;

        let newKeyboardQueue;
        if (keyboardQueue.length === 1) {
          newKeyboardQueue = [keyboardQueue[0], key];
        } else {
          newKeyboardQueue = [key];
        }
        const indexString = newKeyboardQueue.join('');

        const oldSelectableCategories = getSelectableCategories(oldSelectedCategoryKeys);

        const index = Number(indexString) - 1;
        if (index + 1 === 99) {
          return {
            ...state,
            keyboardQueue: newKeyboardQueue,
            selectedCategoryKeys: updateArraySuffix(
              oldSelectedCategoryKeys,
              oldSelectedCategoryKeys[keyboardSelectIndex],
              '/other',
            ),
          };
        }
        if (index < 0 || index >= oldSelectableCategories[keyboardSelectIndex].length) {
          return {
            ...state,
            keyboardQueue: newKeyboardQueue,
          };
        }

        return {
          ...state,
          keyboardQueue: newKeyboardQueue,
          selectedCategoryKeys: updateArraySuffix(
            oldSelectedCategoryKeys,
            oldSelectedCategoryKeys[keyboardSelectIndex],
            oldSelectableCategories[keyboardSelectIndex][index][0],
          ),
        };
      });
    },
    [updateCategorySelectState],
  );

  // Switch between sub category levels with Tab
  useKeyboardEvent(
    ({ key }) => key === 'Tab',
    (event) => {
      event.preventDefault();
      const columnCount = selectableCategories.filter((categoryArray) => categoryArray.length > minCategorySelectLength).length;
      if (event.shiftKey) {
        updateCategorySelectState(
          ({ keyboardSelectIndex, ...obj }) => ({
            ...obj,
            keyboardSelectIndex: (keyboardSelectIndex - 1 + columnCount) % columnCount,
            keyboardQueue: [],
          }),
        );
      } else {
        updateCategorySelectState(
          ({ keyboardSelectIndex, ...obj }) => ({
            ...obj,
            keyboardSelectIndex: (keyboardSelectIndex + 1) % columnCount,
            keyboardQueue: [],
          }),
        );
      }
      setSearchBoxSelected(false);
      document.activeElement.blur();
    },
    [],
  );

  // Submit on Ctrl + Enter
  useKeyboardEvent(
    ({ key, ctrlKey }) => key === 'Enter' && ctrlKey,
    handleSubmit,
    [],
  );

  const setSearchAnnotationResult = (keys) => updateCategorySelectState((state) => ({
    ...state,
    selectedCategoryKeys: keys,
    keyboardSelectIndex: keys.filter((k) => k).length - 1,
  }));

  return (
    <Modal
      size="xl"
      isOpen={modalData.isOpen}
      toggle={closeModal}
      modalClassName="h-100"
      tabIndex="-1"
    >
      <ModalHeader toggle={closeModal}>
        Suggest an improved category
      </ModalHeader>
      <ModalBody>
        <table className="table">
          <tbody>
            <tr className="font-weight-bold text-capitalize">
              <td type="date">date</td>
              <td type="description">description</td>
              <td type="debit">debit</td>
              <td type="credit">credit</td>
              <td type="balance">balance</td>
              <td type="category">Current Category</td>
            </tr>
            <tr>
              <td type="date">{modalData.date}</td>
              <td type="description">{modalData.description}</td>
              <td type="debit">{modalData.debit}</td>
              <td type="credit">{modalData.credit}</td>
              <td type="balance">{modalData.balance}</td>
              <td type="category">
                <span>
                  {modalData.category.map((category, index) => (
                    <span key={category}>
                      <span>{index > 0 && ' / '}</span>
                      <span
                        className={`badge badge-primary mt-n4 ${styles.badge}`}
                        style={{
                          position: 'relative',
                          top: '-3px',
                        }}
                      >
                        {category}
                      </span>
                    </span>
                  ))}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="row my-0">
          <AnnotationSearch
            setSearchAnnotationResult={setSearchAnnotationResult}
            searchBoxSelected={searchBoxSelected}
            setSearchBoxSelected={setSearchBoxSelected}
          />

        </div>
        <div
          className="row"
        >
          {annotationDepthArray.map((index) => (
            <CategoryDescription
              key={index}
              show={selectableCategories[index].length > minCategorySelectLength}
              categoryKey={selectedCategoryKeys[index]}
              categoryInfo={selectableCategories[index] && Object.fromEntries(selectableCategories[index])[selectedCategoryKeys[index]]}
              selected={categorySelectState.keyboardSelectIndex === index && !searchBoxSelected}
            />
          ))}
        </div>
        <div className="row mt-2" style={{ height: '32vh' }}>
          {annotationDepthArray.map((index) => (
            <CategorySelection
              key={index}
              selectableCategories={selectableCategories[index]}
              activeKey={selectedCategoryKeys[index]}
              setSelectedCategory={(categoryKey) => {
                updateCategorySelectState((state) => ({
                  ...state,
                  keyboardSelectIndex: index,
                  selectedCategoryKeys: updateArraySuffix(state.selectedCategoryKeys, selectedCategoryKeys[index], categoryKey),
                }));
              }}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="text-muted mr-auto">
          Ctrl + Enter to submit
        </div>
        <Button color="secondary" tabIndex="-1" onClick={closeModal}>Cancel</Button>
        <Button color="primary" tabIndex="-1" onClick={handleSubmit} onFocus={(e) => e.preventDefault()}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
}

export default AnnotationModal;
