import React, { forwardRef } from 'react';
import Markdown from '../../../../../../components/Markdown';

export const ChatMessage = forwardRef(
  (
    {
      speaker,
      text,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={`py-3 px-3 w-100 d-flex flex-column overflow-hidden ${speaker === 'user' ? '' : 'bg-light-gray'}`}
      style={speaker === 'user' ? { whiteSpace: 'pre-line' } : {}}
    >
      <span
        className={`text-capitalize font-weight-normal badge badge-pill w-fit ${speaker === 'user' ? 'bg-user' : 'bg-assistant'}`}
      >
        {speaker}
      </span>
      <Markdown>
        {text}
      </Markdown>
    </div>
  ),
);

export default ChatMessage;
