import React, { useState } from 'react';
import { updatePassword } from 'aws-amplify/auth';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import FormValidator from '../../utils/FormValidator';
import validationConfig from './validationConfig';

import './style.scss';

const onSave = async (
  setSaveWasSuccessful,
  setValidations,
  oldPassword,
  newPassword,
  confirmPassword,
) => {
  const validatePasswordsMatch = {
    field: 'confirmPassword',
    method: 'equals',
    validWhen: true,
    message: 'The passwords do not match',
    options: [newPassword],
  };
  const validator = new FormValidator([...validationConfig, validatePasswordsMatch]);
  const results = validator.validate({ oldPassword, newPassword, confirmPassword });

  if (results.isInvalid) {
    setValidations(results);
  } else {
    try {
      await updatePassword({ oldPassword, newPassword });
      setSaveWasSuccessful(true);
    } catch (error) {
      console.error('Unable to change password:', error);
      setValidations({ oldPassword: { isInvalid: true, message: error.message } });
    }
  }
};

const renderValidationError = (validation) => ((validation && validation.isInvalid) ? (<small className="validationError text-danger">{validation.message}</small>) : null);

const renderSuccessMessage = (onClose) => (
  <>
    <ModalBody>
      <div id="successMessage">
        Password was successfully changed.
      </div>
    </ModalBody>
    <ModalFooter>
      <Button
        color="primary"
        onClick={() => onClose()}
      >
        Close
      </Button>
    </ModalFooter>
  </>
);

export default function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations, setValidations] = useState({});
  const [saveWasSuccessful, setSaveWasSuccessful] = useState(false);

  return (
    <Modal isOpen>
      <ModalHeader>Change Password</ModalHeader>
      { saveWasSuccessful
        ? renderSuccessMessage(props.onClose)
        : (
          <>
            <ModalBody>
              <FormGroup className="changePassword">
                <Label for="oldPassword">Old Password</Label>
                <Input
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  className={validations.oldPassword ? 'is-invalid' : null}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {renderValidationError(validations.oldPassword)}
              </FormGroup>
              <FormGroup>
                <Label for="newPassword">New Password</Label>
                <Input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  className={validations.newPassword ? 'is-invalid' : null}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {renderValidationError(validations.newPassword)}
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className={validations.confirmPassword ? 'is-invalid' : null}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {renderValidationError(validations.confirmPassword)}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                id="save"
                color="primary"
                onClick={async () => {
                  await onSave(
                    setSaveWasSuccessful,
                    setValidations,
                    oldPassword,
                    newPassword,
                    confirmPassword,
                  );
                }}
              >
                Save
              </Button>
              &nbsp;
              <Button
                id="cancel"
                color="secondary"
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
    </Modal>
  );
}
