import React, { useState, useEffect } from 'react';
import {
  getFlowsByDeployment,
} from '../../../services/flowbuilder-services';
import DeploymentDetailsCardView from './DeploymentDetailsCard.view';

export default function DeploymentDetails(props) {
  const { deployment, isExpanded } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [flows, setFlows] = useState([]);

  useEffect(() => {
    if (!isExpanded) return;
    const fetchFlows = async () => {
      setIsLoading(true);
      try {
        const results = await getFlowsByDeployment(deployment.projectId, deployment.commitId);
        if (!results || results.error || !results.length) return;
        setFlows(results);
      } catch (error) {
        console.error('Unable to get flows for deployment', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFlows();
  }, [deployment, isExpanded]);

  if (!isExpanded) return null;

  return (
    <DeploymentDetailsCardView
      environmentId={props.environmentId}
      environments={props.environments}
      deployment={deployment}
      prodFlowConfig={props.prodFlowConfig}
      uatFlowConfig={props.uatFlowConfig}
      uatOtherProducts={props.uatOtherProducts}
      prodOtherProducts={props.prodOtherProducts}
      prodConfigurations={props.prodConfigurations}
      uatConfigurations={props.uatConfigurations}
      getDeploymentsByCommit={props.getDeploymentsByCommit}
      onCreateDeployment={props.onCreateDeployment}
      onDeploymentUpdated={props.onDeploymentUpdated}
      isAnyEditModeActive={props.isAnyEditModeActive}
      onEditModeChange={props.onEditModeChange}
      isLoadingFlows={isLoading}
      flows={flows}
    />
  );
}
