export default [
  {
    field: 'firstName',
    method: 'isEmpty',
    validWhen: false,
    message: 'First name is required.',
  },
  {
    field: 'lastName',
    method: 'isEmpty',
    validWhen: false,
    message: 'Last name is required.',
  },
  {
    field: 'phone_number',
    method: 'isMobilePhone',
    options: ['en-US'],
    validWhen: true,
    skipIfEmpty: true,
    message: 'Phone number must be in format ###-###-####',
  },
];
