export default [
  {
    field: 'address',
    method: 'isEmpty',
    validWhen: false,
    message: 'Street address is required.',
  },
  {
    field: 'city',
    method: 'isEmpty',
    validWhen: false,
    message: 'City is required.',
  },
  {
    field: 'state',
    method: 'isEmpty',
    validWhen: false,
    message: 'State/Province is required.',
  },
  {
    field: 'postalCode',
    method: 'isEmpty',
    validWhen: false,
    message: 'Zip/Postal code is required.',
  },
  {
    field: 'postalCode',
    method: 'isPostalCode',
    validWhen: true,
    message: 'Zip/Postal code is invalid.',
    options: ['any'],
  },
  {
    field: 'residenceStatus',
    method: 'isEmpty',
    validWhen: false,
    message: 'Residence status is required.',
  },
  {
    field: 'monthlyResidenceCost',
    method: 'isFloat',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Monthly residence cost must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    field: 'durationAtResidence',
    method: 'isInt',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Duration at residence must be a valid number (in months).',
    options: [{ min: 0 }],
  },
];
