import React, { useState } from 'react';
import {
  Spinner,
} from 'reactstrap';
import FormValidator from '../../../utils/FormValidator';
import { useUser, useValidation } from './MaintainUserModal.hooks';
import MaintainUserModalView from './MaintainUserModal.view';
import ResultOfSavingModal from './ResultOfSavingModal';
import useUserAttributes, { NEW_USER_SUB } from '../../../hooks/useUserAttributes';

const validationConfig = [
  {
    field: 'given_name',
    method: 'isEmpty',
    validWhen: false,
    message: 'First name is required.',
  },
  {
    field: 'family_name',
    method: 'isEmpty',
    validWhen: false,
    message: 'Last name is required.',
  },
  {
    field: 'phone_number',
    method: 'isMobilePhone',
    options: ['en-US'],
    validWhen: true,
    skipIfEmpty: true,
    message: 'Phone number must be in format ###-###-####',
  },
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: 'Email is required.',
  },
  {
    field: 'email',
    method: 'isEmail',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Email must be a valid email address.',
  },
  {
    field: 'email',
    method: 'isLowercase',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Email must be lower case.',
  },
];

const validator = new FormValidator(validationConfig);

const mapPhoneNumberToDisplay = (number) => {
  if (!number) return '';
  const areaCode = number.slice(2, 5);
  const middle = number.slice(5, 8);
  const last = number.slice(8);
  return [areaCode, middle, last].join('-');
};

const mapPhoneNumberFromDisplay = (display) => {
  if (!display) return '';
  const parts = display.split('-');
  return '+1'.concat(parts.join(''));
};

const mapUser = (user, fromDisplay = true) => {
  if (fromDisplay) {
    return {
      ...user,
      attributes: {
        ...user.attributes,
        phone_number: mapPhoneNumberFromDisplay(user.attributes.phone_number),
      },
      groups: {
        ...user.groups,
      },
    };
  }
  return {
    ...user,
    attributes: {
      given_name: user.attributes.given_name || '',
      family_name: user.attributes.family_name || '',
      email: user.attributes.email || '',
      phone_number: mapPhoneNumberToDisplay(user.attributes.phone_number),
      sub: user.attributes.sub,
    },
    groups: {
      ...user.groups,
    },
  };
};

export default function (props) {
  const [user, onCognitoAttributeChanged, onGroupsChanged, onBlurEvent] = useUser(mapUser(props.user, false));
  const { userAttributes, updateUserAttributes, userAttributesPendingUpdates } = useUserAttributes(user.attributes.sub ?? NEW_USER_SUB);
  const [validation, onValidationChange] = useValidation(validationConfig);
  const [isLoading, setIsLoading] = useState(false);
  const [flowBuilderRole, setFlowbuilderRole] = useState(props.flowBuilderRole.toLowerCase());

  const onUserAttributeChanged = (e) => {
    const { target: { id: key, value } } = e;
    updateUserAttributes({
      [key]: value,
    });
  };

  const validateAndSubmit = async () => {
    setIsLoading(true);
    const validationResult = validator.validate(validationConfig.reduce((result, { field }) => ({ ...result, [field]: user.attributes[field] || '' }), {}));
    if (validationResult.isInvalid) {
      onValidationChange(validationResult);
      setIsLoading(false);
      return;
    }
    await props.saveOrCreateUser(
      mapUser(user),
      flowBuilderRole,
      userAttributesPendingUpdates,
    ).finally(() => setIsLoading(false));
  };

  if (props.success !== undefined) {
    return (
      <ResultOfSavingModal
        success={props.success}
        buttons={[{
          id: 'cancel',
          color: 'secondary',
          onClick: props.onClose,
          text: 'Close',
        }]}
      />
    );
  }

  return (
    <MaintainUserModalView
      action={props.action}
      canAddFlowbuilderEditor={props.canAddFlowbuilderEditor}
      isFlowbuilderEnabled={props.isFlowbuilderEnabled}
      groups={props.groups}
      locations={props.locations}
      success={props.success}
      user={user}
      userAttributes={userAttributes}
      flowBuilderRole={flowBuilderRole}
      onGroupsChanged={onGroupsChanged}
      onFlowbuilderRoleChanged={(event) => setFlowbuilderRole(event.target.value)}
      onCognitoAttributeChanged={onCognitoAttributeChanged}
      onUserAttributeChanged={onUserAttributeChanged}
      onBlurEvent={onBlurEvent}
      validation={validation}
      isAdminLogin={props.isAdminLogin}
      buttons={[
        {
          id: 'save',
          color: 'primary',
          onClick: validateAndSubmit,
          text: isLoading ? (<Spinner className="d-flex" size="sm" color="primary" />) : (props.action.charAt(0).toUpperCase() + props.action.slice(1)),
          outline: isLoading,
          disabled: isLoading,
        },
        {
          id: 'cancel',
          color: 'secondary',
          onClick: props.onClose,
          text: 'Close',
        },
      ]}
    />
  );
}
