import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  CardFooter,
  CardBody,
  Collapse,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SCORING_USE_CASES from '../../config/scoringRequestUseCases';

import renderFullName from '../../utils/renderFullName';
import Loader from '../Loader';
import ScoringReportSection from './ScoringReportSection';
import NoReports from './NoReports';

import styles from './ScoreCard.module.scss';
import ScoreReasons from '../../containers/ScoringRequestDetails/ScoreReasons/ScoreReasons.view';

const customerId = (id) => {
  if (id) {
    return (
      <span className={styles.requestIdField}>
        <b>Customer ID:</b>
        &nbsp;
        {id}
      </span>
    );
  }

  return null;
};

const loanReferenceId = (id) => {
  if (id) {
    return (
      <span className={styles.requestIdField}>
        <b>Loan Reference ID:</b>
        &nbsp;
        {id}
      </span>
    );
  }

  return null;
};

const findLatestReport = (champion, challenger) => {
  if (!champion) return challenger;
  return challenger.scoringDate > champion.scoringDate ? challenger : champion;
};

export default function (props) {
  const {
    clientReports,
    applicant,
    loanInfo,
    refreshScoresHandler,
    scoring,
    waitForReports,
    features,
  } = props;
  const reportsCount = clientReports?.length;
  const [isCollapsed, toggleCollapse] = useState(false);
  const { useCase, generateReport } = scoring;

  const renderLoader = () => {
    if (clientReports.length > 0) {
      return null;
    }

    return (
      <CardBody>
        <Loader />
      </CardBody>
    );
  };

  const renderSubsequentReports = () => (
    clientReports.map((report, index) => {
      if (index === 0) return null;

      return (
        <ScoringReportSection
          key={index} // eslint-disable-line react/no-array-index-key
          reportIndex={index}
          score={report.score}
          scoreComponents={report.components}
          scoreQualifiers={report.scoreQualifier}
          timestamp={report.scoringDate}
        />
      );
    })
  );

  const renderScoresSections = () => {
    if (!reportsCount) return null;
    const latestReport = clientReports.reduce(findLatestReport, false);
    return (
      <>
        <ScoringReportSection
          isLatest
          displayButton={reportsCount > 1}
          reportIndex={0}
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          score={latestReport.score}
          scoreComponents={latestReport.components}
          scoreQualifiers={latestReport.scoreQualifier}
          timestamp={latestReport.scoringDate}
        />
        <Collapse isOpen={isCollapsed} className={styles.printable}>
          { renderSubsequentReports() }
        </Collapse>
      </>
    );
  };

  const renderScoreCardBody = () => {
    if (waitForReports && useCase === SCORING_USE_CASES.BANKING_ONLY) {
      return (
        <NoReports message="The scoring request was banking only" />
      );
    }
    if (!waitForReports && clientReports.length === 0) {
      return (
        <NoReports message="No reports were generated for this scoring request" />
      );
    }
    if (generateReport === false) {
      return (
        <NoReports message="No report was requested for this scoring request" />
      );
    }
    return (
      <>
        { renderLoader() }
        { renderScoresSections() }
      </>
    );
  };

  const renderRefreshIcon = (clickHandler) => {
    if (!clickHandler) {
      return null;
    }

    return <FontAwesomeIcon icon="sync-alt" onClick={clickHandler} />;
  };

  return (
    <Card className={
        [
          styles.cardWrapper,
          props.displayAsInline ? styles.inline : '',
        ].join(' ')
      }
    >
      <CardTitle tag="h3" className={styles.cardTitle}>
        Six°Score™ for
        { renderFullName(applicant.firstName, applicant.middleName, applicant.lastName) }
        { renderRefreshIcon(refreshScoresHandler) }
      </CardTitle>
      { renderScoreCardBody() }
      <CardFooter className={styles.cardFooter}>
        <span className={styles.loanReferenceText}>
          <span className={styles.id}>{ loanReferenceId(loanInfo.loanId) }</span>
          <span className={styles.id}>{ customerId(loanInfo.customerId) }</span>
          <ScoreReasons
            enabled={!waitForReports && features && features.scoreReasons}
            reports={clientReports}
          />
        </span>
      </CardFooter>
    </Card>
  );
}
