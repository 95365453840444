import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';
import useUserAttributes from '../../../../hooks/useUserAttributes';
import { CURRENT_ENVIRONMENT_NAME, UAT_ENVIRONMENT_NAME } from '../../../../config/console-configuration';

const prodAccessKey = 'prodAccessSwitch';
const uatAccessKey = 'uatAccessSwitch';

export default function EnvironmentAccessField(props) {
  const { userAttributes: { prodAccess, uatAccess, cognitoUserIdSub } } = useUserAttributes();

  const { userAttributes, onUserAttributeChanged } = props;

  const isCurrentUser = userAttributes.cognitoUserIdSub === cognitoUserIdSub;

  return (
    <FormGroup key={prodAccessKey}>
      <div className="row">
        <div className="col-4">
          <Label htmlFor={prodAccessKey}>
            {CURRENT_ENVIRONMENT_NAME}
            {' '}
            Access
          </Label>
        </div>
        <div className="col-3 px-0">
          <Toggle
            id={prodAccessKey}
            active={!!userAttributes.prodAccess}
            on="Yes"
            off="No"
            size="xs"
            onstyle="success"
            handlestyle="default"
            offstyle="custom"
            // Only allow the user to change if they have access to the PROD environment
            // And prevent the user from locking themselves out of the system
            disabled={!prodAccess || isCurrentUser}
            onClick={(value) => prodAccess && onUserAttributeChanged({ target: { id: 'prodAccess', value } })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <Label htmlFor={uatAccessKey}>
            {UAT_ENVIRONMENT_NAME}
            {' '}
            Access
          </Label>
        </div>
        <div className="col-3 px-0">
          <Toggle
            id={uatAccessKey}
            active={!!userAttributes.uatAccess}
            on="Yes"
            off="No"
            size="xs"
            onstyle="success"
            handlestyle="default"
            offstyle="custom"
            // Only allow the user to change if they have access to the UAT environment
            // And prevent the user from locking themselves out of the system
            disabled={!uatAccess || isCurrentUser}
            onClick={(value) => uatAccess && onUserAttributeChanged({ target: { id: 'uatAccess', value } })}
          />
        </div>
      </div>
    </FormGroup>
  );
}
