import React from 'react';

import styles from './loader.module.scss';

export default function ({ className }) {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.loader} data-cy="loader" />
    </div>
  );
}
