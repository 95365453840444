import React from 'react';
import {
  FormGroup,
  Input,
  InputGroup,
  Label,
} from 'reactstrap';
import { useEnabledFeatures } from '../../../../hooks/useEnabledFeatures';

export default function GroupSelection(props) {
  const {
    groups,
    onGroupsChanged,
    user: {
      groups: userGroups,
    },
  } = props;
  const {
    allowSwitchingAccounts,
    trustScienceSupport,
  } = useEnabledFeatures({ env: 'prod' });

  return Object.keys(groups).map((groupsKey) => {
    if (groupsKey === 'canSwitchAccounts' && !allowSwitchingAccounts) return null;
    if (groupsKey === 'trustScienceSupport' && !trustScienceSupport) return null;
    return (
      <FormGroup check key={groupsKey}>
        <InputGroup>
          <Label check>
            <Input
              type="checkbox"
              id={groupsKey}
              onChange={onGroupsChanged}
              checked={userGroups[groupsKey] || false}
              disabled={!props.isAdminLogin && groupsKey === 'admin'}
            />
            {' '}
            {groups[groupsKey].name}
          </Label>
        </InputGroup>
      </FormGroup>
    );
  });
}
