import React from 'react';
import { Container } from 'reactstrap';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import BaseVehicleInputGroup, { fieldNames } from '../BaseVehicleInputGroup';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

const formGroupFieldNames = [
  ...fieldNames,
  'tradeInAllowance',
];

class TradeInInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
    super.skipValidationOnGroupIfEmpty = false;

    this.validator = new FormValidator(validationConfig);
    this.tradeInVehicleInputGroup = React.createRef();
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="deal-information-input-group">
              <h4>Trade-In</h4>
              <div className="form-group-wrapper">
                <Container>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="tradeInAllowance"
                    id="tradeInAllowance"
                    label="Trade-In Allowance"
                    required
                    highlightEssentialInputs
                    isCurrency
                    valid={!this.state.tradeInAllowanceFieldInvalid}
                    validationMessage={this.state.tradeInAllowanceFieldValidationMessage}
                    value={previousState.tradeInAllowance || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <BaseVehicleInputGroup
                    ref={this.tradeInVehicleInputGroup}
                    state={this.state}
                    previousState={previousState}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default TradeInInputGroup;
