import React, { useState } from 'react';

import ConfigMessageModal from '../ConfigMessageModal';
import saveConfigPatchToServer from '../utils/saveConfigPatchToServer';
import SanitizedTextInput from '../../../../components/SanitizedTextInput';

const validateTenantName = (newName) => newName.replace(/\s+/g, '') === '';

export default function (props) {
  const [tenantName, changeTenantName] = useState(props.config.displayName);
  const [tenantNameChanged, toggleTenantNameChanged] = useState(false);
  const [displayMessage, setMessageDialog] = useState(false);
  const [modalType, setModalPopupType] = useState('');

  const cancelTenantNameChange = () => {
    toggleTenantNameChanged(false);
    changeTenantName(props.config.displayName);
  };

  const errorHandler = (message) => {
    setModalPopupType('error');
    setMessageDialog(message);
  };

  const onTenantNameSave = () => {
    const payload = [
      {
        op: 'replace',
        path: '/displayName',
        value: tenantName,
      },
    ];
    saveConfigPatchToServer(payload, props.isUat)
      .then((res) => {
        props.setConfig(res);
      }).catch((error) => {
        errorHandler(error.message);
        changeTenantName(props.config.displayName);
      }).finally(() => {
        toggleTenantNameChanged(false);
        props.togglePerformingSave(false);
      });
  };

  const onSaveButtonClicked = () => {
    if (props.performingSave) {
      return;
    }

    if (validateTenantName(tenantName)) {
      errorHandler('Please enter a valid display name.');
      return;
    }

    props.togglePerformingSave(true);
    onTenantNameSave();
  };

  const onTenantNameChanged = (event) => {
    const newName = event.target.value;
    const changed = newName !== props.config.displayName;
    toggleTenantNameChanged(changed);
    changeTenantName(newName);
  };

  return (
    <>
      {
        displayMessage
          ? (
            <ConfigMessageModal
              title="Problem saving configuration."
              onModalTransitionedOut={() => setMessageDialog(false)}
              modalType={modalType}
            >
              {displayMessage}
            </ConfigMessageModal>
          )
          : null
      }

      <div className={`${props.performingSave ? 'configRow buttons-disabled' : 'configRow'}`}>
        <div className="label">Display Name</div>
        <SanitizedTextInput
          type="text"
          className="codeStyleInput wide"
          name="displayName"
          onChange={onTenantNameChanged}
          value={tenantName}
        />
        {
          tenantNameChanged
            ? (
              <div className="buttonRow">
                <button type="button" className="edit btn btn-success" onClick={onSaveButtonClicked}>
                  Update
                </button>
                <button type="button" className="edit btn btn-cancel" color="secondary" onClick={cancelTenantNameChange}>
                  Cancel
                </button>
              </div>
            )
            : null
        }
      </div>
    </>
  );
}
