import React, { createElement } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styleVariables from '../../styles/variables.scss';

import './markdown.scss';

const colorMap = {
  red: styleVariables.errorColor,
  green: styleVariables.primaryColor,
  yellow: styleVariables.adminColor,
};

const colorComponent = ({ children, node, ...rest }) => {
  if (children?.[0]?.startsWith?.('\\color{')) {
    const color = children[0].match(/\\color{(.*)}/)[1];
    return createElement(
      node.tagName,
      {
        ...rest,
        style: {
          color: colorMap[color] || color,
        },
      },
      children[0].replace(/\\color{.*}/, ''),
      ...children.slice(1),
    );
  }
  return createElement(node.tagName, rest, children);
};

function Markdown({ children }) {
  return (
    <div className="markdown-container">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        className="prose mt-1"
        components={{
          p: colorComponent,
          td: colorComponent,
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
}

export default Markdown;
