export default [
  {
    field: 'firstName',
    method: 'isEmpty',
    validWhen: false,
    message: 'First name is required.',
  },
  {
    field: 'lastName',
    method: 'isEmpty',
    validWhen: false,
    message: 'Last name is required.',
  },
  {
    field: 'homePhoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    validWhen: true,
    message: 'Phone number must be in format ###-###-####',
  },
  {
    field: 'email1',
    method: 'isEmpty',
    validWhen: false,
    message: 'Email is required.',
  },
  {
    field: 'email1',
    method: 'isEmail',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Email must be a valid email address.',
  },
];
