import React from 'react';

function UnsecuredPage() {
  return (
    <div className="frame-busting">
      <h1>Warning: Insecure Page</h1>
      <div className="message">
        The link you clicked is insecure and you are possibly under a clickjacking attack.
        Please contact Trust Science support with information about the link you clicked to get to this page.
      </div>
      <div className="action">
        Click
        <a href={window.self.location.href} title="Trust Console" target="blank">here</a>
        to access the Trust Console safely.
      </div>
    </div>
  );
}

export default function WithFrameBusting(Inner) {
  if (window.self !== window.top) {
    return UnsecuredPage;
  }
  return Inner;
}
