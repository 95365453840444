import React from 'react';

import styles from './AttributesTable.module.scss';

export default function (props) {
  return (
    <div
      id={props.id}
      className={`${styles.AttributesTable} ${props.extraWideLeft ? styles.extraWideLeft : ''}`}
    >
      {props.children}
    </div>
  );
}
