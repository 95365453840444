import React from 'react';

import styles from './SignOut.module.scss';

export default function SignOut(props) {
  const {
    isAuthenticated,
    signout,
  } = props;
  if (isAuthenticated) signout();
  return (
    <div className={styles.container}>
      <div>
        You have been signed out of the Trust Console.
      </div>
      <div>
        Click here to
        <a href="/">sign back in.</a>
      </div>
    </div>
  );
}
