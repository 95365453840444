import React from 'react';

import './TopHeaderNotification.scss';

export default function () {
  return (
    <div className="top-header-notification closed">
      <div className="message">
        Welcome to Trust Console!
      </div>
    </div>
  );
}
