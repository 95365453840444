import React, { useContext, useMemo } from 'react';

const BankingAnnotationsContext = React.createContext();

export function BankingAnnotationsProvider({
  children, bankingAnnotations, addAnnotation, requestInfo, isUat, user,
}) {
  const contextData = useMemo(() => ({
    annotations: bankingAnnotations ?? [],
    addAnnotation,
    requestInfo,
    isUat,
    user,
  }), [bankingAnnotations, addAnnotation, requestInfo, isUat, user]);

  return (
    <BankingAnnotationsContext.Provider value={contextData}>
      {children}
    </BankingAnnotationsContext.Provider>
  );
}

export const useBankingAnnotations = () => useContext(BankingAnnotationsContext);

export default useBankingAnnotations;
