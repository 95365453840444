import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import styles from './TransUnionView.module.scss';

export default function () {
  return (
    <>
      <Logo className={styles.logo} />
      <h4>
        TransUnion
        <br />
        Consumer Credit
        <br />
        Report
      </h4>
    </>
  );
}
