import api from './api';

const reports = {
  bankingAnalysisReport: 'BankingView',
  bankingTransactions: 'BankingTransactionsView',
  bureauReport: 'RequestBureauReport',
};

export const getRequestDetails = async (requestId, isUat) => {
  const response = await api('RequestHistoryList', {
    requestId,
    isUat,
  });
  const result = JSON.parse(response.body);
  if (!result.person) {
    throw new Error('Unable to retrieve request history list. Missing person.');
  }
  return result;
};

export const getReport = async (requestId, reportName, isUat, options = {}) => {
  const reportAPI = reports[reportName];
  if (!reportAPI) throw new Error(`Invalid report name ${reportName}`);
  const response = await api(reportAPI, {
    requestId,
    isUat,
    ...options,
  });
  if (!response.body) {
    throw new Error('Unable to retrieve report. Missing response body.');
  }
  if (response.statusCode === '404') {
    return null;
  }
  return response.body;
};

export const getBankingStatus = (requestId, isUat) => api('GetBankingStatus', { requestId, isUat });

export const getTrustStartInviteData = (requestId, isUat) => api('GetTrustStartInviteData', { requestId, isUat });
