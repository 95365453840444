import React from 'react';
import { FormGroup } from 'reactstrap';

import PersonBlockEssentialInformation from '../PersonBlockEssentialInformation';
import PersonBlockOptionalInformation from '../PersonBlockOptionalInformation';
import ResidenceInformation from '../ResidenceInformation';

class PersonInputGroup extends React.Component {
  personBlockInputGroup = React.createRef();

  personBlockOptionalInputGroup = React.createRef();

  residenceBlockInputGroup = React.createRef();

  formGroupContainsInput() {
    let formGroupContainsInput = false;

    if (this.personBlockInputGroup.current.formGroupContainsInput()) {
      formGroupContainsInput = true;
    }

    if (this.personBlockOptionalInputGroup.current.formGroupContainsInput()) {
      formGroupContainsInput = true;
    }

    if (this.residenceBlockInputGroup.current.formGroupContainsInput()) {
      formGroupContainsInput = true;
    }

    return formGroupContainsInput;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  runValidation() {
    if (!this.formGroupContainsInput() && !this.props.highlightEssentialInputs) {
      return false;
    }

    const personBlockInvalid = this.personBlockInputGroup.current.runValidation(true);
    const optionalPersonInfoInvalid = this.personBlockOptionalInputGroup.current.runValidation();
    const residenceBlockInvalid = this.residenceBlockInputGroup.current.runValidation();

    return personBlockInvalid || optionalPersonInfoInvalid || residenceBlockInvalid;
  }

  render() {
    return (
      <div className="person-input-group">
        <h4 className="">{this.props.title}</h4>
        <FormGroup className="form-group-wrapper">
          <PersonBlockEssentialInformation
            highlightEssentialInputs={this.props.highlightEssentialInputs}
            ref={this.personBlockInputGroup}
            dataTreeKey={`${this.props.fieldIdPrefix}applicant`}
            fieldIdPrefix={this.props.fieldIdPrefix}
            allowMobileInvites={this.props.allowMobileInvites}
            history={this.props.history}
            allowEmptyEmails={this.props.allowEmptyEmails}
          />

          <PersonBlockOptionalInformation
            ref={this.personBlockOptionalInputGroup}
            dataTreeKey={`${this.props.fieldIdPrefix}applicant`}
            fieldIdPrefix={this.props.fieldIdPrefix}
            requireSsnInForms={this.props.requireSsnInForms}
            requireDobInForms={this.props.requireDobInForms}
          />

          <ResidenceInformation
            highlightEssentialInputs={this.props.requireAddressInForms}
            ref={this.residenceBlockInputGroup}
            dataTreeKey={`${this.props.fieldIdPrefix}applicant`}
            fieldIdPrefix={this.props.fieldIdPrefix}
            required={this.props.requireAddressInForms}
          />
        </FormGroup>
      </div>
    );
  }
}

export default PersonInputGroup;
