import React from 'react';

import InputItemRow from '../InputItemRow';
import { blockIsEmpty } from '../../../utils/object';
import { getCountryNameByAbbreviation } from '../../../utils/checkCountryByState';
import formatCurrency from '../../../utils/formatCurrency';
import styles from './InputDisplayGroup.module.scss';

const nonNumericFields = [
  'year',
  'socialInsuranceNumber',
  'driversLicense',
  'phoneNumber',
  'homePhoneNumber',
  'cellPhoneNumber',
  'workPhoneNumber',
];

const sendMobileInvite = 'sendMobileInvite';
const noAnswerDisplayValue = '';
const noAmountIndicated = '$ -';
const noSelectionMade = 'No Selection';

const formatValue = (key, value, units = '') => {
  if (key.match(/^storeNumber$/)) return value;
  const numericValue = Number(value);
  const isBoolean = value === true || value === false;
  const isNumber = !Number.isNaN(numericValue) && !isBoolean;

  if (isNumber && !nonNumericFields.includes(key)) {
    const isDollarValue = key.match(/income|amount|cost|allowance|value|charge/ig);
    if (isDollarValue) {
      return `$${formatCurrency(numericValue)}`;
    }

    if (key.match(/duration/ig)) {
      return `${formatCurrency(numericValue)} Months`;
    }

    if (key.match(/peryear/ig)) {
      return `${formatCurrency(numericValue)} Payments`;
    }

    if (key.match(/odometer/ig)) {
      return `${formatCurrency(Number(value))} ${units}`;
    }

    if (key.match(/postal|loanId|customerId/ig)) {
      return value;
    }

    if (key.match(/interestrate/ig)) {
      return `${numericValue}%`;
    }

    return formatCurrency(numericValue);
  }

  if (key === 'country') {
    return getCountryNameByAbbreviation(value);
  }

  if (key === sendMobileInvite) {
    // Logic will not reach this point if `sendMobileInvite` is not set to `true`
    return 'Send to primary email';
  }

  if (key.match(/residence/i) && value.match(/own/i)) {
    return 'Own';
  }

  if (key.match(/phonenumber/i) && !value.match(/-/)) {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (isBoolean) {
    return value.toString();
  }

  return value;
};

const createDataBlock = (labels, data) => {
  const excludedProperties = ['defaultedFields'].concat(data.defaultedFields);
  return Object.keys(data)
    .filter((k) => !!labels[k])
    .filter((k) => !excludedProperties.includes(k))
    .reduce((result, current) => ({
      ...result,
      [current]: data[current],
    }), {});
};

const checkValueCapitalization = (key) => !!key.match(/purpose|status|current/ig);

const formatCustom = (value) => {
  if (typeof value !== 'object') return value.toString();
  return JSON.stringify(value, null, 1);
};

const renderStaticFields = ({
  labels,
  data,
  beforeScoringScreen,
  fullWidth,
}, isCustom = false) => (
  Object.keys(labels).map((key) => {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      return null;
    }
    if (!beforeScoringScreen && key === sendMobileInvite) {
      return null;
    }
    const value = isCustom ? formatCustom(data[key]) : formatValue(
      key,
      data[key],
      (key === 'odometer') ? data.odometerUnits : null,
    );

    return (
      <InputItemRow
        capitalized={checkValueCapitalization(key)}
        label={labels[key]}
        key={`${data[key]}-${key}`}
        value={value}
        fullWidth={fullWidth}
      />
    );
  })
);

const buildKey = (name) => `custom_${name}`;

const formatTypeMap = {
  block: (isKeySet, value) => (value ? 'Yes' : 'No'),
  boolean: (isKeySet, value) => (value ? 'Yes' : 'No'),
  currency: (isKeySet, value) => (isKeySet && !Number.isNaN(parseFloat(value)) ? `$${formatCurrency(parseFloat(value))}` : noAmountIndicated),
  text: (isKeySet, value) => (isKeySet ? value : noAnswerDisplayValue),
  select: (isKeySet, value) => (isKeySet ? value : noSelectionMade),
};

const flattenActiveBlocks = (data) => (flat, field) => {
  const { name, type } = field;
  if (type !== 'block' || !data[buildKey(name)]) return [...flat, field];
  return [
    ...flat,
    field,
    ...field.block,
  ];
};

const renderCustomFields = ({
  customFields,
  data,
  fullWidth,
}) => (
  customFields
    .reduce(flattenActiveBlocks(data), [])
    .map((field) => {
      const { label, name, type } = field;
      const key = buildKey(name);
      const isKeySet = Object.prototype.hasOwnProperty.call(data, key);
      const formatDisplayValue = formatTypeMap[type] ?? formatTypeMap.text;
      const displayValue = formatDisplayValue(isKeySet, data[key]);
      return (
        <InputItemRow
          capitalized={checkValueCapitalization(name)}
          label={label}
          key={key}
          value={displayValue}
          fullWidth={fullWidth}
        />
      );
    })
);

const renderCustomBlocks = (blocks, fullWidth) => (
  blocks?.map((block, index) => (
    <div>
      {blocks.length > 1 && (
      <div>
        {'Block '}
        {index + 1}
        :
      </div>
      )}
      {renderStaticFields({ ...block, fullWidth }, true)}
    </div>
  ))
);

const areAllBlocksEmpty = (blocks) => blocks.every((block) => blockIsEmpty(createDataBlock(block.labels, block.data)));

export default function InputDisplayGroup(props) {
  const mainBlock = {
    ...props,
    labels: props.labels ?? {},
    data: props.data ?? {},
  };
  const allBlocks = [
    ...(props.blocks ?? []),
    mainBlock,
  ];

  if (areAllBlocksEmpty(allBlocks)) {
    return null;
  }

  return (
    <div className={
      [
        styles.inputDataGroup,
        props.fullWidth ? styles.fullWidth : null,
      ].join(' ')
    }
    >
      <h5>{props.title}</h5>
      {renderStaticFields(mainBlock)}
      {props.customFields && renderCustomFields(props)}
      {props.blocks && renderCustomBlocks(props.blocks, props.fullWidth)}
    </div>
  );
}
