import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import api from '../../services/api';
import styles from './ManageIntegrations.module.scss';
import ManageIntegrationsView from './ManageIntegrations.view';
import { withUseModal } from '../../hooks/useModal';

class ManageIntegrationsContainer extends React.Component {
  originalCredentials = {};

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedIntegration: null,
      modalOpened: false,
      integrationValues: {},
      savingIntegration: false,
      submitResponseMessage: null,
      responseIsError: false,
      showNoConfigurableIntegrations: false,
    };
  }

  async componentDidMount() {
    const { configurableIntegrations } = this.props;
    if (!configurableIntegrations || !configurableIntegrations.length) {
      this.setState({ loading: false, showNoConfigurableIntegrations: true });
      return;
    }

    const data = {
      integrations: configurableIntegrations,
    };

    const integrationValues = await api('ListIntegrationSecrets', data);
    if (integrationValues.errorMessage) {
      this.props.useModalProp.showModal({
        header: 'Error accessing integration configuration',
        body: integrationValues.errorMessage,
      });
      this.setState({ loading: false });
      return;
    }
    this.setState({ integrationValues, loading: false });
  }

  onInputChange = (event) => {
    const { value, id } = event.target;
    const nestedKey = event.target.getAttribute('nestedKey');
    const integrationName = id.substr(0, id.indexOf('-input'));
    const fieldName = id.substr(id.indexOf('input-'), id.length).replace('input-', '');

    const newState = {
      integrationValues: {
        ...this.state.integrationValues,
        [integrationName]: {
          ...this.state.integrationValues[integrationName],
        },
      },
    };

    if (nestedKey) {
      if (!newState.integrationValues[integrationName][nestedKey]) {
        newState.integrationValues[integrationName][nestedKey] = {};
      }
      newState.integrationValues[integrationName][nestedKey][fieldName] = value;
    } else {
      newState.integrationValues[integrationName][fieldName] = value;
    }

    this.setState(newState);
  };

  saveCredentials = async (integration) => {
    if (this.state.savingIntegration) return;

    this.setState({ savingIntegration: true });

    const newStateObj = { savingIntegration: false };
    const data = {
      integration: integration.key,
      newSecretString: JSON.stringify(this.state.integrationValues[integration.key]),
    };

    try {
      await api('WriteIntegrationSecrets', data);
      this.originalCredentials = { ...this.state.integrationValues[integration.key] };
      newStateObj.submitResponseMessage = 'Credentials updated.';
      newStateObj.responseIsError = false;
    } catch (error) {
      console.error('Problem saving information to Secrets Manager', error);
      newStateObj.submitResponseMessage = `${error}. Please verify your input and try again.`;
      newStateObj.responseIsError = true;
    }
    this.setState({ ...newStateObj });
  };

  showIntegrationEditor = (selectedIntegration) => {
    this.setState({ modalOpened: true, selectedIntegration });
    this.originalCredentials = JSON.parse(JSON.stringify(this.state.integrationValues[selectedIntegration.key]));
  };

  hideIntegrationEditor = () => {
    // Restore credentials to last saved values, and reset modal state.
    const { selectedIntegration } = this.state;

    const newStateObj = {
      modalOpened: false,
      selectedIntegration: null,
      integrationValues: {
        ...this.state.integrationValues,
        [selectedIntegration.key]: { ...this.originalCredentials },
      },
      responseIsError: false,
      submitResponseMessage: null,
      savingIntegration: false,
    };

    if (this.originalCredentials.private) {
      newStateObj.integrationValues[selectedIntegration.key].private = {
        ...this.originalCredentials.private,
      };
    }

    this.setState(newStateObj);
  };

  renderEditor = () => {
    const { selectedIntegration } = this.state;
    if (!selectedIntegration) return null;
    const integrationForm = selectedIntegration.component;

    const formProps = {
      key: selectedIntegration.key,
      credentials: this.state.integrationValues[selectedIntegration.key],
      onInputChange: this.onInputChange,
      logoSrc: selectedIntegration.image,
    };

    const selectedIntegrationName = selectedIntegration.name;

    const saveLabel = this.state.savingIntegration
      ? 'Saving Settings'
      : `Save ${selectedIntegrationName} Settings`;

    const responseMessageStyles = [
      styles.responseMessage,
      this.state.responseIsError ? styles.error : styles.success,
    ].join(' ');

    return (
      <Modal isOpen={this.state.modalOpened} className={styles.modalWrapper}>
        <ModalHeader toggle={this.hideIntegrationEditor} className={styles.modalHeader}>
          {`Edit Integration For ${selectedIntegrationName}`}
        </ModalHeader>
        <ModalBody>
          { React.createElement(integrationForm, formProps) }
        </ModalBody>
        {
          this.state.submitResponseMessage
            ? (
              <ModalBody className={responseMessageStyles}>
                { this.state.submitResponseMessage }
              </ModalBody>
            )
            : null
        }
        <ModalFooter className={styles.modalFooter}>
          <Button color="secondary" onClick={this.hideIntegrationEditor}>
            Cancel
          </Button>
          <Button
            disabled={this.state.savingIntegration}
            color="success"
            onClick={() => this.saveCredentials(selectedIntegration)}
          >
            { saveLabel }
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    if (Object.keys(this.state.integrationValues).length === 0) {
      return <h3 className="text-center">No available integrations</h3>;
    }

    return (
      <ManageIntegrationsView
        loading={this.state.loading}
        showNoConfigurableIntegrations={this.state.showNoConfigurableIntegrations}
        configurableIntegrations={this.props.configurableIntegrations}
        saveCredentials={this.saveCredentials}
        showIntegrationEditor={this.showIntegrationEditor}
        renderEditor={this.renderEditor}
      />
    );
  }
}

export default withUseModal(ManageIntegrationsContainer);
