import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ExpandCollapseIcon.module.scss';

export default function ({ isCollapsed, toggleIsCollapsed }) {
  return (
    <FontAwesomeIcon
      className={[styles.icon, (isCollapsed ? '' : styles.flippedIcon)].join(' ')}
      icon="angle-double-down"
      onClick={toggleIsCollapsed}
      size="lg"
    />
  );
}
