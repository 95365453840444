import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import Loader from '../../components/Loader';
import ClientConfig from './ClientConfig';
import ClientLogosList from './ClientLogosList';
import UploadLogo from '../../components/UploadLogo';
import getClientConfig from '../../utils/get-client-config';

import styles from './ManageAccount.module.scss';
import { KeySection } from './KeySection';
import { useEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { useModal } from '../../hooks/useModal';
import CollapseConfig from './CollapseConfig';
import { CURRENT_ENVIRONMENT_NAME, UAT_ENVIRONMENT_NAME } from '../../config/console-configuration';

const uploadImage = ({ state, setState, showModal }) => {
  const uploadFile = state.uploadFile[0];
  if (!/\.(jpe?g|png|gif)$/i.test(uploadFile.name)) {
    setState({ uploadError: 'The file selected does not appear to be a valid image.' });
    return;
  }

  const reader = new FileReader();
  reader.onloadend = async () => {
    const imageBase64 = reader.result.replace(/^data:.+\/.+;base64,/, '');
    const payload = {
      filename: uploadFile.name,
      size: uploadFile.size,
      type: uploadFile.type,
      image: imageBase64,
    };

    const { success } = await api('UploadLogo', payload);

    setState((prevState) => ({
      ...prevState,
      isUploadOpen: false,
      uploadFile: null,
      uploadError: null,
      logo: uploadFile.name,
    }));

    if (!success) {
      showModal({
        header: 'Upload Logo failed',
        body: 'Could not upload logo. Please contact support if the problem persists.',
      });
    }
  };
  reader.readAsDataURL(uploadFile);
};

function ManageLogo({ displayName, logo, clientId }) {
  const { showModal } = useModal();

  const [state, setState] = useState({
    isUploadOpen: false,
    uploadFile: null,
    uploadError: null,
    logo,
  });

  return (
    <>
      <div className={[styles.logos, styles.section].join(' ')}>
        <div className="row">
          <div className="col">
            <h3>Logos</h3>
          </div>
          <div className="col d-flex flex-row-reverse">
            <button
              type="button"
              id="uploadLogo"
              className={['btn', 'btn-primary', styles.editAction].join(' ')}
              onClick={() => setState({ ...state, isUploadOpen: true })}
            >
              Upload
            </button>
            <UploadLogo
              isUploadOpen={state.isUploadOpen}
              uploadFile={state.uploadFile}
              uploadError={state.uploadError}
              onCancel={() => setState({
                isUploadOpen: false,
                uploadFile: null,
                uploadError: null,
              })}
              onFileSelected={(event) => setState({ ...state, uploadFile: event.target.files })}
              onSubmit={() => uploadImage({ state, setState, showModal })}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <ClientLogosList
          displayName={displayName}
          logo={state.logo}
          clientId={clientId}
        />
      </div>
    </>
  );
}

function ClientSettings(props) {
  // Support both permissions formats until APP-4532
  const {
    allowManageAPIKeysUAT: allowManageAPIKeysUATLegacy,
    allowManageAPIKeysPROD: allowManageAPIKeysPRODLegacy,
    useUatProdFlags: useNewPermissions,
  } = useEnabledFeatures({ env: 'prod' });

  const { allowManageAPIKeys: allowManageAPIKeysPROD, settings: hasPRODSettingsAccess } = useEnabledFeatures({ env: 'prod' });
  const { allowManageAPIKeys: allowManageAPIKeysUAT, settings: hasUATSettingsAccess } = useEnabledFeatures({ env: 'uat' });
  const legacyUATSettingsAccess = !useNewPermissions && hasPRODSettingsAccess;

  const [state, setState] = useState({
    loading: true,
    apiKeys: [],
    clientId: props.clientId,
    clientConfig: {},
    uatConfig: {},
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [apiKeys, config] = await Promise.all([
          api('ListApiKeys'),
          getClientConfig({ includeUat: true }),
        ]);
        setState((oldState) => ({
          ...oldState,
          apiKeys: JSON.parse(apiKeys.body),
          clientConfig: config.result.clientConfig,
          uatConfig: config.result.uatClientConfig,
          loading: false,
        }));
      } catch (error) {
        console.error(error);
        setState((oldState) => ({ ...oldState, loading: false }));
      }
    }
    fetchData();
  }, []);

  if (state.loading) return <Loader />;

  return (
    <>
      <h3>API Keys</h3>
      <KeySection
        title={CURRENT_ENVIRONMENT_NAME}
        keys={state.apiKeys.production}
        environmentKey="prod"
        allowAPIKeyManagement={allowManageAPIKeysPROD || allowManageAPIKeysPRODLegacy}
      />
      {(hasUATSettingsAccess || state.uatConfig) && (
      <KeySection
        title={UAT_ENVIRONMENT_NAME}
        keys={state.apiKeys.uat}
        environmentKey="uat"
        allowAPIKeyManagement={allowManageAPIKeysUAT || allowManageAPIKeysUATLegacy}
      />
      )}
      <div className="settings section">
        {hasPRODSettingsAccess && (
        <ClientConfig
          title={`${CURRENT_ENVIRONMENT_NAME} Settings`}
          config={state.clientConfig}
          enabledFeatures={props.enabledFeatures}
          updateConfig={(newConfig) => setState({ ...state, clientConfig: newConfig })}
        />
        )}
        {(hasUATSettingsAccess || legacyUATSettingsAccess) && (
        <ClientConfig
          title={`${UAT_ENVIRONMENT_NAME} Settings`}
          config={state.uatConfig}
          enabledFeatures={props.enabledFeatures}
          updateConfig={(newConfig) => setState({ ...state, uatConfig: newConfig })}
          isUat
        />
        )}
        {hasPRODSettingsAccess && (
          <CollapseConfig
            clientConfig={state.clientConfig}
          />
        )}
        {hasPRODSettingsAccess && (
        <ManageLogo
          displayName={state.clientConfig.displayName}
          logo={state.clientConfig.logo}
          clientId={state.clientId}
        />
        )}
      </div>
    </>
  );
}

export default ClientSettings;
