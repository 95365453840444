import React from 'react';
import { IMAGE_URL, ENVIRONMENT, S3_BUCKET } from '../../../config/console-configuration';

import styles from './Image.module.scss';

const appServicesDocs = 'appservices-docs';
const defaultClientLogoWidth = 500;

const buildImageUrl = ({ width, docType, src }) => {
  const imageRequest = {
    bucket: S3_BUCKET,
    key: [
      appServicesDocs,
      ENVIRONMENT,
      docType,
      src,
    ].join('/'),
    edits: {
      resize: {
        width: Number(width) || defaultClientLogoWidth,
        fit: 'contain',
      },
    },
  };
  return [IMAGE_URL, btoa(JSON.stringify(imageRequest))].join('/');
};

export default function Image(props) {
  const { alt, width } = props;
  const imageSrc = buildImageUrl(props);
  return (
    <div className={styles.Image}>
      <img src={imageSrc} alt={alt} width={width} />
    </div>
  );
}
