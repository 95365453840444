import { Mutex, withTimeout } from 'async-mutex';
import api from '../../../../services/api';

// Wait until mutex release (or 5s timeout) to prevent race conditions saving annotations
const clientMutex = withTimeout(new Mutex(), 5_000);

const saveAnnotation = async ({ annotationData, isUat }) => {
  await clientMutex.runExclusive(async () => {
    await api('SaveBankingAnnotation', {
      ...annotationData,
      requestId: annotationData.source.requestId,
      isUat,
    });
  });
};

export default saveAnnotation;
