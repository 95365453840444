export default (assetData) => {
  const assetValue = Number(assetData.assetValue);

  if (Number.isNaN(assetValue) || !assetData.assetPurpose) {
    return undefined;
  }

  return {
    assetValue,
    assetPurpose: assetData.assetPurpose,
  };
};
