import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

const renderCallbackModalBody = ({
  modalType,
  tag,
  url,
  version,
}) => {
  if (modalType === 'error') {
    return null;
  }

  return (
    <>
      <ModalBody>
        <h6>Tag</h6>
        <code>{tag}</code>
      </ModalBody>
      <ModalBody>
        <h6>URL</h6>
        <code>{url}</code>
      </ModalBody>
      <ModalBody>
        <h6>Version</h6>
        <code>{version}</code>
      </ModalBody>
    </>
  );
};

const renderModalFooter = ({
  modalType,
  onModalCloseWithOK,
  performingSave,
}, setModalOpen) => (
  <ModalFooter>
    {
      modalType === 'update' || modalType === 'delete'
        ? (
          <>
            <Button color="primary" onClick={onModalCloseWithOK} className="wideButton">
              { performingSave ? <Spinner size="sm" color="white" /> : `Yes, ${modalType}` }
            </Button>
            <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
          </>
        )
        : <Button color="primary" onClick={() => setModalOpen(false)}>OK</Button>
    }
  </ModalFooter>
);

export default function (props) {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => setModalOpen(!modalOpen)}
      onClosed={() => props.onModalTransitionedOut(typeof props.tag === 'undefined')}
    >
      <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
        { props.title }
      </ModalHeader>
      <ModalBody>
        { props.children }
      </ModalBody>
      { renderCallbackModalBody(props) }
      { renderModalFooter(props, setModalOpen) }
    </Modal>
  );
}
