import React, { useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { useKeyboardEvent } from '@react-hookz/web';
import categoriesConfig from '../../../../config/banking-categories.json';

import styles from './AnnotationModal.module.scss';
import { maxAnnotationDepth, specialCategories } from './annotation-config';

const searchInputSelector = '#annotationSearchBox input[type="text"]';
const clearButtonSelector = '#annotationSearchBox .clear-icon';

const resetSearchBox = () => {
  document.querySelector(clearButtonSelector)?.click();
};

const getCategoryOptions = (config, key, path = []) => {
  const categoryKeys = Object.keys(config).filter((category) => category.startsWith('/') && !specialCategories.includes(category));
  const subCategoryOptions = categoryKeys.reduce(
    (acc, category) => [...acc, ...getCategoryOptions(config[category], category, [...path, category])],
    [],
  );
  if (!key || config.hide) {
    return subCategoryOptions;
  }
  return [{
    id: path.join(''),
    label: config.label,
    description: config.description,
    path,
  }, ...subCategoryOptions];
};

const categoryOptions = getCategoryOptions(categoriesConfig);

const renderItem = (item) => (
  <span>
    {item.label}
    {' '}
    (
    {item.path.map((category, index) => (
      <span key={category}>
        <span>{index > 0 && ' / '}</span>
        <span
          className={`badge badge-primary mt-n4 ${styles.searchBadge}`}
        >
          {category.slice(1)}
        </span>
      </span>
    ))}
    )
  </span>
);

function AnnotationSearch({ setSearchAnnotationResult, searchBoxSelected, setSearchBoxSelected }) {
  // Select search box with "/"
  useKeyboardEvent(
    ({ key }) => key === '/',
    (event) => {
      if (searchBoxSelected) return;

      document.querySelector(searchInputSelector).focus();
      setSearchBoxSelected(true);
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    // On blur, reset search box
    document.querySelector(searchInputSelector).onblur = (event) => {
      setSearchBoxSelected(false);
      resetSearchBox();
      event.target.blur();
      event.preventDefault();
    };
  });

  return (
    <div id="annotationSearchBox" className={`col-12 mb-3 mt-n3 px-2 ${styles.searchBox}`}>
      <ReactSearchAutocomplete
        items={categoryOptions}
        fuseOptions={{ keys: ['id', 'description', 'label'] }}
        placeholder="Press / to search categories"
        resultStringKeyName="id"
        formatResult={renderItem}
        onSelect={(event) => {
          const { path } = event;
          setSearchAnnotationResult([...path, ...Array(maxAnnotationDepth - path.length).fill(null)]);
          resetSearchBox();
        }}
        onFocus={() => {
          setSearchBoxSelected(true);
          resetSearchBox();
        }}
        styling={{
          height: '34px',
          borderRadius: '4px',
          backgroundColor: 'white',
          boxShadow: 'none',
          clearIconMargin: '-3px 8px 0 0',
          zIndex: 3,
        }}
      />
    </div>
  );
}

export default AnnotationSearch;
