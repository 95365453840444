import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const flowBuilderRoleKey = 'flowBuilderRole';

export default function FlowbuilderRoleSelectField(props) {
  const {
    onChange,
    canAddEditor,
    flowBuilderRole,
    isFlowbuilderEnabled,
  } = props;
  if (!isFlowbuilderEnabled) return null;
  return (
    <FormGroup key={flowBuilderRoleKey}>
      <Label htmlFor={flowBuilderRoleKey}>
        FlowBuilder Role
      </Label>
      <Input
        name={flowBuilderRoleKey}
        id={flowBuilderRoleKey}
        type="select"
        value={flowBuilderRole}
        onChange={onChange}
      >
        <option value="none">None</option>
        <option value="viewer">Viewer</option>
        {
          (canAddEditor || flowBuilderRole === 'editor') && (
            <option value="editor">Editor</option>
          )
        }
      </Input>
    </FormGroup>
  );
}
