import React from 'react';

import { useUpdateEffect } from '@react-hookz/web';
import { useSelectedEnvironment } from '../../hooks/useSelectedEnvironment';
import EnvironmentDropDownBase from './EnvironmentDropDownBase';

export default function ({ onChange }) {
  const { environment, setEnvironment } = useSelectedEnvironment();

  useUpdateEffect(
    () => onChange?.({ environment }),
    [environment],
  );

  return (
    <EnvironmentDropDownBase
      environment={environment}
      setEnvironment={setEnvironment}
      isDisabled={false}
    />
  );
}
