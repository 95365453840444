import React from 'react';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IntegrationInputForms.module.scss';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

/* eslint-disable camelcase */
export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let url;
  let siteSlug;
  let siteId;
  let apiKey;

  if (credentials) {
    ({
      url,
      siteSlug,
    } = credentials);
  }

  if (credentials.private) {
    ({ siteId, apiKey } = credentials.private);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Inverite Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="inverite-input-url">URL:</Label>
          <SanitizedTextInput
            id="inverite-input-url"
            value={url}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inverite-input-siteSlug">Site Slug:</Label>
          <SanitizedTextInput
            id="inverite-input-siteSlug"
            value={siteSlug}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inverite-input-siteId">
            Site ID
            <FontAwesomeIcon icon="lock" />
          </Label>
          <SanitizedTextInput
            id="inverite-input-siteId"
            value={siteId}
            nestedKey="private"
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inverite-input-apiKey">
            API Key
            <FontAwesomeIcon icon="lock" />
          </Label>
          <SanitizedTextInput
            id="inverite-input-apiKey"
            value={apiKey}
            nestedKey="private"
            onChange={onInputChange}
          />
        </div>
      </div>
    </div>
  );
}
