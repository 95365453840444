import React from 'react';
import { Label } from 'reactstrap';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

import styles from './IntegrationInputForms.module.scss';

export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  const { authenticityKey, apiURL, connectURL } = credentials;

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Flinks Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="flinks-input-connectURL">Connect URL*</Label>
          <SanitizedTextInput
            id="flinks-input-connectURL"
            type="text"
            value={connectURL}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.helpText}>
          Specify your Flinks Connect URL with the format&nbsp;
          <code>https://&#123;instance&#125;-iframe.private.fin.ag/v2</code>
          . This URL is available from your Flinks account.
          The instance is a short code provided by Flinks that uniquely identifies your Flinks environment.
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="flinks-input-apiURL">API URL*</Label>
          <SanitizedTextInput
            id="flinks-input-apiURL"
            type="text"
            value={apiURL}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.helpText}>
          Specify your Flinks API URL with the format&nbsp;
          <code>https://&#123;instance&#125;-api.private.fin.ag/v3/&#123;customerId&#125;</code>
          . This URL is available from your Flinks account.
          Your customerId is a secret key associated with the instance and is provided by Flinks.
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="flinks-input-authenticityKey">Authenticity Key</Label>
          <SanitizedTextInput
            id="flinks-input-authenticityKey"
            value={authenticityKey}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.helpText}>
          This is an optional value.
          Flinks can optionally use HMAC with SHA-256 encryption to generate a validation key that will be sent within the HTTP response.
          You can request this feature from Flinks when your webhooks address is setup and it will be provided on your Flinks instance.
          When you specify the value here, Trust Science will use it to verify incoming data originates from Flinks.
        </div>
        <div className={styles.helpText}>
          * Required fields
        </div>
      </div>
    </div>
  );
}
