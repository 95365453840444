import React from 'react';
import styles from '../ProductsReport.module.scss';

const render = (product) => Object.entries(product)
  .filter(([key, value]) => (value && (key !== 'productCode')))
  .sort(([a], [b]) => {
    if (a === 'decision') {
      return -1;
    }
    if (b === 'decision') {
      return 1;
    }
    return 0;
  })
  .map(([key, value]) => (
    <div>
      <b>
        {key
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str) => str.toUpperCase())}
        :&nbsp;
      </b>
      {Array.isArray(value) ? (
        <div>
          { value.map((item) => (<li>{item.description.toString()}</li>)) }
        </div>
      ) : value.toString()}
    </div>
  ));

export default function ({ product }) {
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      {render(product)}
    </div>
  );
}
