import React, { useEffect } from 'react';

import EnvironmentDropDownBase from './EnvironmentDropDownBase';
import { useSelectedEnvironment } from '../../hooks/useSelectedEnvironment';

export default function ({ environment }) {
  const { setEnvironment } = useSelectedEnvironment();
  useEffect(() => {
    setEnvironment(environment);
  }, [environment, setEnvironment]);

  return (
    <EnvironmentDropDownBase
      environment={environment}
      isDisabled
    />
  );
}
