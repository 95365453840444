import SCORING_USE_CASES from '../../config/scoringRequestUseCases';
import initializeCustomFields from '../../utils/initialize-custom-fields';

// eslint-disable-next-line default-param-last
export default (requestUseCase, sendToAms, parentPath, onInputChange, clientConfig = {}, overridingConfig, enabledEnvironments, isUat = false) => {
  const {
    features = {},
    customApplicationFormFields = {},
  } = clientConfig;
  const result = {
    basicInfo: {
      employment: requestUseCase === SCORING_USE_CASES.SHORTTERM,
      coApplicant: false,
      tradeIn: false,
      preapproval: false,
    },
    applicant: { sendMobileInvite: false },
    employer: {
      isCurrentlyEmployed: true,
    },
    income: {},
    coapplicant: {},
    loanInfo: { loanDate: new Date().toLocaleDateString('en-CA') },
    asset: {},
    dealership: {},
    vehicle: {
      defaultedFields: ['odometerUnits'],
      appraisedValue: null,
      VIN: null,
      bodyStyle: null,
      condition: null,
      make: null,
      model: null,
      odometer: null,
      odometerUnits: null,
      trim: null,
      year: null,
      value: null,
    },
    tradeIn: {
      defaultedFields: ['tradeInAllowance'],
      VIN: null,
      bodyStyle: null,
      make: null,
      model: null,
      odometer: null,
      odometerUnits: null,
      trim: null,
      year: null,
      tradeInAllowance: 0,
    },
    clientReports: [],
    requestInitiated: false,
    highlightedSection: parentPath,
    requestUseCase,
    sendToAms,
    showUploadModal: false,
    uploadResult: null,
    onInputChange,
    clientAddresses: [],
    loading: true,
    rescoreRequestId: null,
    originalRequestData: null,
    disableHeaderButtons: false,
    showUpdateModal: false,
    modalErrorMessage: '',
    scoringPayload: null,
    smartConsentUrl: null,
    showPreviousButton: false,
    enabledEnvironments,
    clientConfig,
    enabledFeatures: features,
    lastReportDate: new Date().toISOString(),
    isUat,
  };

  if (overridingConfig) {
    Object.keys(overridingConfig).forEach((groupKey) => {
      const overrideGroup = overridingConfig[groupKey];

      Object.keys(overrideGroup).forEach((valueName) => {
        const overrideValue = overrideGroup[valueName];
        const dependencyEnabled = features[overrideValue.dependency];

        if (overrideValue.value && overrideValue.dependency && dependencyEnabled) {
          result[groupKey][valueName] = overrideValue.value;
        }

        if (typeof dependencyEnabled === 'undefined' && typeof overrideValue !== 'undefined') {
          result[groupKey][valueName] = overrideValue;
        }
      });
    });
  }

  return Object.keys(customApplicationFormFields).reduce(
    (dataTree, dataTreeKey) => ({
      ...dataTree,
      [dataTreeKey]: initializeCustomFields(
        customApplicationFormFields[dataTreeKey],
        dataTree[dataTreeKey],
      ),
    }),
    result,
  );
};
