import React from 'react';
import {
  Button,
} from 'reactstrap';
import api from '../../../services/api';
import getCurrentAuthSession from '../../../utils/get-current-auth-session';
import { useModal } from '../../../hooks/useModal';
import { useEnabledFeatures } from '../../../hooks/useEnabledFeatures';

export default function (props) {
  const { requestId, isUat } = props;

  const { showModal } = useModal();
  const { bankingButton } = useEnabledFeatures({ env: isUat ? 'uat' : 'prod' });

  if (!bankingButton || !props.bankingStatus || !props.bankingStatus.accountLogin) return null;

  const runBanking = async () => {
    showModal({
      header: 'Refresh Banking',
      body: (
        <p>
          A request to refresh banking has been made.
          It can take several minutes to receive the update. Please refresh the scoring details to see it.
        </p>
      ),
    });
    const user = await getCurrentAuthSession();
    if (!user.username) throw new Error('APP-4035 missing username');
    const payload = {
      user: user.username,
      clientId: props.clientId,
      requestId,
      integration: props.bankingStatus.integration,
    };
    await api('RunIntegration', payload);
  };

  return (
    <Button color="primary" onClick={async () => { await runBanking(); }}>
      Refresh Banking
    </Button>
  );
}
