// eslint-disable-next-line import/prefer-default-export
export const hasAmsIntegration = (integration) => {
  const amsKey = 'ams';
  switch (typeof integration) {
    case 'string':
      return integration === amsKey;
    case 'object':
      if (Array.isArray(integration)) {
        return integration.includes(amsKey);
      }
      return integration[amsKey]?.enabled;
    default:
      return false;
  }
};
