import { post } from 'aws-amplify/api';
import getCurrentAuthSession from './get-current-auth-session';

export default async (url, params = {}) => {
  const { forceRefresh, ...optionParams } = params;
  const {
    clientId,
    idToken,
    accessToken,
    user,
  } = await getCurrentAuthSession(forceRefresh);

  const options = {
    body: {
      idToken,
      ...optionParams,
    },
  };

  try {
    const request = post({
      apiName: url,
      path: '/',
      options,
    });
    const { body } = await request.response;
    const result = await body.json();
    return {
      clientId,
      user: {
        ...user,
        accessToken,
      },
      result,
    };
  } catch (e) {
    console.error('Unable to invoke lambda', e);
    throw e;
  }
};
