import { useState } from 'react';

export const useUser = (initialUser) => {
  const [user, setUser] = useState(initialUser);
  const onAttributeChanged = (e) => {
    const { target: { id: key, value } } = e;
    setUser((previous) => ({
      ...previous,
      attributes: {
        ...previous.attributes,
        [key]: value,
      },
    }));
  };
  const onGroupsChanged = (e) => {
    const { target: { id: key, checked } } = e;
    setUser((previous) => ({
      ...previous,
      groups: {
        ...previous.groups,
        [key]: checked,
      },
    }));
  };

  const onBlurEvent = (e) => {
    const { target: { id: key, value } } = e;
    setUser((previous) => ({
      ...previous,
      attributes: {
        ...previous.attributes,
        [key]: value,
      },
    }));
  };

  return [user, onAttributeChanged, onGroupsChanged, onBlurEvent];
};

export const useValidation = (validationConfig) => (
  useState(validationConfig
    .reduce(
      (result, { field }) => (
        {
          ...result,
          [field]: { isInvalid: false },
        }),
      { isInvalid: false },
    ))
);
