import React from 'react';
import styles from './ProductsReport.module.scss';

const mainProductCode = 'VANTAGE-265';
const scorePercentileRankingProductCode = 'VANTAGE-285';

export default function ({ products }) {
  const product = products.find((p) => p?.productCode === mainProductCode);
  if (!product) return null;
  const sprProduct = products.find((p) => p?.productCode === scorePercentileRankingProductCode);
  let sprValue = false;
  if (sprProduct) {
    sprValue = [].concat(sprProduct.characteristic).shift()?.value;
  }
  return (
    <div className={`${styles.content} ${styles.m}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      <div>
        Score results:&nbsp;
        {product.productScore?.results}
      </div>
      { product.productScore?.derogatoryAlert
      && (
        <div>
          Derogatory Alert:&nbsp;
          {product.productScore.derogatoryAlert === 'true' ? 'YES' : 'No'}
        </div>
      )}
      { product.productScore?.fileInquiriesImpactedScore
      && (
        <div>
          Did file inquiries impact Score:&nbsp;
          {product.productScore.fileInquiriesImpactedScore === 'true' ? 'YES' : 'No'}
        </div>
      )}
      <div>
        Score card:&nbsp;
        {product.productScore?.scoreCard}
      </div>
      { sprValue
      && (
        <div>
          Score Percentile Ranking:&nbsp;
          { Number(sprValue) }
          %
        </div>
      )}
      { !!product.productScore?.factors?.factor?.length
      && (
        <table className={styles.table}>
          <caption>Factor</caption>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {product.productScore.factors.factor.map((factor) => (
              <tr key={factor.code}>
                <td>{factor.rank}</td>
                <td>{factor.code}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
