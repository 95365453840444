import React from 'react';
import styles from './CustomAttributesReport.module.scss';
import CollapsibleCard from '../CollapsibleCard';

export default function ({ clientReports }) {
  if (!clientReports?.length) return null;
  const { customAttributes } = clientReports[0];
  if (!customAttributes) return null;

  return (
    <CollapsibleCard title="Custom Attributes" collapseKey="customAttributes">
      <div className={`${styles.content} ${styles.l}`}>
        <table className={`${styles.table} ${styles.l}`}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {customAttributes.map((customAttribute) => (
              <tr key={customAttribute.productCode}>
                <td>{customAttribute.name}</td>
                <td>{customAttribute.description}</td>
                <td>{customAttribute.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
}
