import React from 'react';
import {
  Button,
} from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ProductConfiguration.module.scss';

const expandAdditionalProducts = (config) => Object.keys(config.additionalProducts || {})
  .filter((x) => !!x)
  .map((productCode) => ({
    key: config.key.concat(productCode),
    productCode,
    enabled: config.enabled,
    isAdditionalProduct: true,
    description: `Additional product on ${config.productCode}`,
  }));

const collectAllConfiguredProducts = (results, current) => ([
  ...results,
  current,
  ...expandAdditionalProducts(current),
]);

const byProductCodeAsc = (a, b) => (a.productCode < b.productCode ? -1 : 1);

const renderEnabledStatusText = ({
  enabled,
}) => (
  enabled ? 'Enabled' : 'Disabled'
);

const renderActions = (productConfig, onDeleteProductConfig, onToggleEnabledState) => {
  const {
    productCode,
    enabled,
    isAdditionalProduct,
  } = productConfig;
  if (isAdditionalProduct) return null;
  return (
    <>
      <Button
        color="link"
        size="sm"
        className="pr-2"
        onClick={() => onToggleEnabledState(productCode)}
      >
        { enabled ? 'Disable' : 'Enable' }
      </Button>
      <Button color="secondary" outline size="sm" onClick={() => onDeleteProductConfig(productCode)}>
        <FontAwesomeIcon icon={faTrash} size="sm" />
      </Button>
    </>
  );
};

export default function ProductConfigurationListView(props) {
  const {
    productConfigurations,
    isEditMode,
    flows,
    onDeleteProductConfig,
    onToggleEnabledState,
  } = props;

  const allConfigurations = productConfigurations
    .reduce(collectAllConfiguredProducts, [])
    .sort(byProductCodeAsc);

  const renderProductConfiguration = (productConfig) => {
    const {
      key,
      productCode,
      description,
      flowId,
    } = productConfig;
    const matchingFlow = flows.find((f) => f.id === flowId);
    return (
      <div key={key} className={styles.productRow}>
        <span className={styles.productCodeDisplayColumn}>{ productCode }</span>
        <span className={styles.flowDisplayColumn}>{ matchingFlow?.name }</span>
        <span className={styles.descriptionDisplayColumn}>{ description }</span>
        <span className={styles.addButtonColumn}>
          { isEditMode ? renderActions(productConfig, onDeleteProductConfig, onToggleEnabledState) : renderEnabledStatusText(productConfig) }
        </span>
      </div>
    );
  };

  return (
    <div>
      {
        allConfigurations.map(renderProductConfiguration)
      }
    </div>
  );
}
