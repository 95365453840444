import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';

import styles from './AssetInformation.module.scss';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

const formGroupFieldNames = ['assetValue', 'assetPurpose'];

class AssetInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
    super.skipValidationOnGroupIfEmpty = true;

    this.validator = new FormValidator(validationConfig);
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="deal-information-input-group">
              <h4>Asset</h4>
              <div className="form-group-wrapper">
                <ValidatedTextInput
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...this.props}
                  name="assetValue"
                  id="assetValue"
                  label="Value"
                  inputClass={styles.customWidthWithUnits}
                  wrapperClass={styles.currencyLineWrapper}
                  required
                  type="number"
                  labelsMarkedRequired={this.state.labelsMarkedRequired}
                  valid={!this.state.assetValueFieldInvalid}
                  validationMessage={this.state.assetValueFieldValidationMessage}
                  value={previousState.assetValue || ''}
                  onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  beforeInput={<span className={styles.currencySymbol}>$</span>}
                />
                <FormGroup>
                  <Label htmlFor="assetPurpose">
                    Purpose
                    {this.state.labelsMarkedRequired ? '*' : null}
                  </Label>
                  <Input
                    className={this.state.assetPurposeFieldInvalid ? 'is-invalid' : ''}
                    type="select"
                    name="assetPurpose"
                    id="assetPurpose"
                    defaultValue={previousState.assetPurpose || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  >
                    <option value="">Please Select...</option>
                    <option value="collateral">Collateral</option>
                    <option value="purchase">Purchase</option>
                    <option value="lease">Lease</option>
                  </Input>
                  <FormFeedback>{ this.state.assetPurposeFieldValidationMessage }</FormFeedback>
                </FormGroup>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default AssetInputGroup;
