import React from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

function LocationSelector(props) {
  if (!props.addresses || !props.addresses.length) {
    return 'No locations are defined. Please review your settings.';
  }

  const selectableAddresses = props.addresses.filter((a) => !!a.storeNumber);
  if (!selectableAddresses.length) {
    const message = 'Store number is missing from locations.';
    console.error(message);
    return `${message} Please review your settings.`;
  }

  return (
    <FormGroup>
      <Label htmlFor={props.attributeName}>
        {props.labelName}
        {props.required ? '*' : null}
      </Label>
      <Input
        name={props.attributeName}
        id={props.attributeName}
        type="select"
        value={props.selectedLocationId}
        disabled={props.disabled}
        onChange={props.onChange}
        readOnly={props.readOnly}
      >
        <option value="">{props.firstOptionText}</option>
        {
          selectableAddresses.map((address) => (
            <option
              value={address.storeNumber}
              key={address.storeNumber}
            >
              {address.name}
            </option>
          ))
        }
      </Input>
    </FormGroup>
  );
}

LocationSelector.defaultProps = {
  firstOptionText: 'Please select...',
  labelName: 'Locations',
  required: false,
  selectedLocationId: null,
};

export default LocationSelector;
