import React, { useState } from 'react';
import {
  CardBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import TransactionFilter from './TransactionsFilter';
import AccountSection from './AccountSection';

import styles from './BankingAccountCard.module.scss';
import CollapsibleCard from '../CollapsibleCard';

const noAccount = Object.freeze({ accountNO: '' });

function Transactions({
  accounts,
  additionalTransactions,
  transactionFilter,
  onTransactionFilterChange,
}) {
  const accountTypes = ['savings', 'checking', 'chequing'];
  const filteredAccounts = accounts.sort((a, b) => (
    accountTypes.indexOf(b.type) - accountTypes.indexOf(a.type)
  ));

  const accountsMap = new Map();
  filteredAccounts.forEach((account) => {
    if (accountsMap.has(account.accountNO)) {
      const count = accountsMap.get(account.accountNO) + 1;
      // eslint-disable-next-line no-param-reassign
      account.accountNO = `${account.accountNO} (${count})`;
      accountsMap.set(account.accountNO, count);
    } else {
      accountsMap.set(account.accountNO, 0);
    }
  });

  const selectedAccount = filteredAccounts.length === 0 ? noAccount : filteredAccounts[0];
  const [activeTab, setActiveTab] = useState(selectedAccount.accountNO !== null ? selectedAccount.accountNO : selectedAccount.id);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const checkNullId = (tab) => (tab.accountNO !== null ? tab.accountNO : tab.id);

  return (
    <CardBody>
      <TransactionFilter
        selectedValue={transactionFilter}
        onChange={onTransactionFilterChange}
      />
      <Nav tabs className={styles.tabBar}>
        {filteredAccounts.map((tab) => (
          <NavItem key={checkNullId(tab)} className={styles.navItem}>
            <NavLink
              className={activeTab === checkNullId(tab) ? styles.active : ''}
              active={activeTab === checkNullId(tab)}
              onClick={() => toggle(checkNullId(tab))}
            >
              {`${tab.type} ${tab.accountNO !== null ? tab.accountNO : ''}`}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {filteredAccounts.map((tab) => (
          <TabPane tabId={checkNullId(tab)} key={checkNullId(tab)} className={styles.bankingCards}>
            <AccountSection accounts={[tab]} transactionFilter={transactionFilter} additionalTransactions={additionalTransactions} />
          </TabPane>
        ))}
      </TabContent>
    </CardBody>
  );
}

export default function (props) {
  const {
    accounts,
    transactions,
    transactionFilter,
    onTransactionFilterChange,
  } = props;

  if (!accounts?.length) return null;

  return (
    <CollapsibleCard title="Banking Accounts" collapseKey="bankingAccounts">
      <Transactions
        accounts={accounts}
        additionalTransactions={transactions}
        transactionFilter={transactionFilter}
        onTransactionFilterChange={onTransactionFilterChange}
      />
    </CollapsibleCard>
  );
}
