import customerLoanIDGenerator from '../customer-loan-id-generator';

const buildCustom = (loanData) => {
  const re = /^custom_/;
  const custom = Object
    .keys(loanData)
    .filter((k) => re.test(k))
    .reduce((result, key) => ({
      ...result,
      [key.replace(re, '')]: loanData[key],
    }), {});
  if (Object.keys(custom).length) {
    return [custom];
  }
  return undefined;
};

export default (loanData) => {
  const clientCustomerId = (!loanData.customerId)
    ? customerLoanIDGenerator('customer')
    : loanData.customerId;

  const clientLoanReferenceId = (!loanData.loanId)
    ? customerLoanIDGenerator('loan')
    : loanData.loanId;

  const { downPaymentAmount } = loanData;

  let dateOriginated;
  if (loanData.loanDate) {
    dateOriginated = loanData.loanDate.replace(/\//g, '-');
  }

  let principalAmount;
  if (loanData.principalAmount) {
    principalAmount = Number(loanData.principalAmount);
  }

  let feeAmount;
  if (loanData.feeAmount) {
    feeAmount = Number(loanData.feeAmount);
  }

  let downPayment;
  if (downPaymentAmount) {
    downPayment = Number(downPaymentAmount) || undefined; // If $0, we want to assign `undefined`.
  }

  let annualInterestRate;
  if (loanData.annualInterestRate) {
    annualInterestRate = Number(loanData.annualInterestRate);
    if (Number.isNaN(annualInterestRate)) {
      annualInterestRate = undefined;
    }
  }

  let paymentAmount;
  if (loanData.paymentAmount) {
    paymentAmount = Number(loanData.paymentAmount) || undefined;
  }

  let paymentsPerYear;
  if (loanData.paymentsPerYear) {
    paymentsPerYear = Number(loanData.paymentsPerYear) || undefined;
  }

  let totalNumberOfPayments;
  if (loanData.totalNumberOfPayments) {
    totalNumberOfPayments = Number(loanData.totalNumberOfPayments) || undefined;
  }

  return {
    dateOriginated,
    clientCustomerId,
    clientLoanReferenceId,
    principalAmount,
    feeAmount,
    custom: buildCustom(loanData),
    downPayment,
    annualInterestRate,
    paymentAmount,
    paymentsPerYear,
    totalNumberOfPayments,
    storeNumber: loanData.storeNumber,
    storeState: loanData.storeState,
  };
};
