import React from 'react';
import styles from './ProductsReport.module.scss';

import Fico from './Fico.view';
import Vantage from './Vantage.view';
import {
  SmallGroups,
  ReasonTable,
  AD,
  RLA,
  DefaultProductUI,
  MPT,
  LEAD,
  KYC,
} from './ProductsUI';
import CollapsibleCard from '../CollapsibleCard';

const excludeProductsWithCustomViews = ({ productCode }) => !/^(fico)|(vantage)-/i.test(productCode);

const smallProductsCodes = {
  'MLA-': (product) => ({
    description: `Military lending act search status: ${product.militaryLendingActSearch['@searchStatus']}`,
    ...product,
  }),
  'OFAC-': (product) => ({
    description: `OFAC name search status: ${product.ofacNameScreen['@searchStatus']}`,
    ...product,
  }),
};

const normalizeSmallProducts = (smallProductsGroup) => smallProductsGroup.map((product) => {
  const productCode = Object.keys(smallProductsCodes).find((c) => product.productCode.startsWith(c));
  if (!productCode || product.productStatus === 'notAvailable') return null;
  return smallProductsCodes[productCode](product);
});

const splitProducts = (products) => {
  const filteredProducts = products
    .filter(excludeProductsWithCustomViews)
    .filter((p) => !Object.keys(smallProductsCodes).some((v) => p.productCode.startsWith(v)));
  const smallProductsGroup = products
    .filter(excludeProductsWithCustomViews)
    .filter((product) => Object.keys(smallProductsCodes).some((v) => product.productCode.startsWith(v)));

  return { filteredProducts, smallProducts: normalizeSmallProducts(smallProductsGroup) };
};

export default function ({ clientReports }) {
  if (!clientReports.length || !clientReports[0].products) {
    return null;
  }

  const { products } = clientReports[0];

  const { filteredProducts, smallProducts } = splitProducts(products);

  return (
    <CollapsibleCard className={styles.card} title="Products" collapseKey="products">
      { smallProducts.length ? <SmallGroups products={smallProducts} /> : null }
      <Fico products={products} />
      <Vantage products={products} />
      {filteredProducts.map((product) => {
        if (product.productCode.startsWith('BANK-')) {
          return null;
        }

        if (product.productCode.startsWith('PD-') || product.productCode.startsWith('CL-')) {
          return <ReasonTable key={product.productCode} product={product} />;
        }
        if (product.productCode.startsWith('AD-')) {
          return <AD key={product.productCode} product={product} />;
        }
        if (product.productCode.startsWith('RLA-')) {
          return <RLA key={product.productCode} product={product} />;
        }
        if (product.productCode.startsWith('MPT-')) {
          return <MPT key={product.productCode} product={product} />;
        }
        if (product.productCode.startsWith('LEAD-')) {
          return <LEAD key={product.productCode} product={product} />;
        }
        if (product.productCode.startsWith('KYC-')) {
          return <KYC key={product.productCode} product={product} />;
        }
        return (
          <DefaultProductUI key={product.productCode} product={product} />
        );
      })}
    </CollapsibleCard>
  );
}
