export default [
  {
    field: 'name',
    method: 'isEmpty',
    validWhen: false,
    message: 'Employer name / Income Source Name is required.',
  },
  {
    field: 'grossIncome',
    method: 'isFloat',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Gross income must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    field: 'netIncome',
    method: 'isFloat',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Net income must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    field: 'paymentsPerYear',
    method: 'isInt',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Number of payments per year must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    fields: ['grossIncome', 'netIncome'],
    method: (fields, inputFormState) => fields.some((field) => inputFormState[field] !== ''),
    validWhen: true,
    message: 'Either Gross Income or Net Income is required.',
  },
];
