export default [
  {
    field: 'jobTitle',
    method: 'isEmpty',
    validWhen: false,
    message: 'Job title is required.',
  },
  {
    field: 'employmentType',
    method: 'isEmpty',
    validWhen: false,
    message: 'Employment type is required.',
  },
  {
    field: 'postalCode',
    method: 'isPostalCode',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Zip/Postal code is invalid.',
    options: ['any'],
  },
  {
    field: 'phoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    skipIfEmpty: true,
    validWhen: true,
    message: 'Phone number must be in the format ###-###-####.',
  },
  {
    field: 'durationAtEmployer',
    method: 'isInt',
    skipIfEmpty: true,
    validWhen: true,
    message: 'Duration at employer must be a valid number (in months).',
    options: [{ min: 0 }],
  },
];
