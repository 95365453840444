import React from 'react';
import { FormGroup } from 'reactstrap';
import { useEnabledFeatures } from '../../../../hooks/useEnabledFeatures';
import LocationSelector from '../../../../components/LocationSelector';

export default function LocationSelectField(props) {
  const {
    displayName,
    attributeKey,
    locations,
    onUserAttributeChanged,
    userAttributes,
  } = props;
  const { enableLocations } = useEnabledFeatures({ env: 'prod' });
  if (!enableLocations) return null;

  return (
    <FormGroup key={attributeKey}>
      <LocationSelector
        addresses={locations}
        attributeName={attributeKey}
        labelName={displayName}
        onChange={onUserAttributeChanged}
        required={false}
        selectedLocationId={userAttributes[attributeKey] || ''}
      />
    </FormGroup>
  );
}
