import React from 'react';
import {
  CardBody,
} from 'reactstrap';
import CallbackHistoryItemView from './CallbackHistoryItemView';

import styles from './CallbackHistoryView.module.scss';
import CollapsibleCard from '../CollapsibleCard';

export default function CallbackHistoryCard(props) {
  const { message, history } = props;
  return (
    <CollapsibleCard className="_lr-hide" print={false} title="Callback History" collapseKey="callbackHistory">
      <CardBody className="text-left">
        <div className={styles.callbackHistoryMessage}>{ message }</div>
        <div>
          {
              history.map((h) => (<CallbackHistoryItemView key={h.id} detail={h} />))
            }
        </div>
      </CardBody>
    </CollapsibleCard>
  );
}
