import React from 'react';
import TrustScienceLogo from '../../TrustScienceLogo';

import styles from './PrintTitlePage.module.scss';

const printTitlePage = () => (
  <section className={styles.printTitlePage}>
    <div className={styles.titleBlock}>
      <TrustScienceLogo />
      <h1>
        Six°Score™ API Documentation
      </h1>
    </div>
    <div className={styles.disclaimer}>
      <h2>
        Disclaimer
      </h2>
      This document is for reference purposes only.
      <br />
      Use the latest version online in your Trust Console account
      for technical implementation:
      <br />
      <a href="https://console.trustscience.com/documentation">https://console.trustscience.com/documentation</a>
      <br />
      <br />
      All Rights Reserved.
      <br />
      No part of this document may be reproduced, stored in a retrieval
      system or transmitted in any forms by any means, electronic, mechanical,
      photocopying, recording or otherwise, without the prior permission of the
      owner, Trust Science® Inc.
    </div>
  </section>
);

export default printTitlePage;
