import React, { useState } from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ProductConfiguration.module.scss';

const renderMessage = (message) => (
  <div className="w-100 text-center font-italic text-muted">
    { message }
  </div>
);

const renderFlowSelectOption = (flow) => (
  <option key={flow.id} value={flow.id}>{flow.name}</option>
);

export default function ProductConfiguration(props) {
  const {
    existingProductCodes,
    canAddProductConfig,
    onAddProductConfig,
    flows,
  } = props;
  const [productCode, setProductCode] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFlow, setSelectedFlow] = useState(flows[0]);
  const [validationMessage, setValidationMessage] = useState('');
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const handleOnProductCodeChange = (event) => {
    const value = event.target.value.trim();
    setProductCode(value);
    if (value && !/^[a-z0-9_-]+$/i.test(value)) {
      setValidationMessage('Product code contains invalid characters. Please use letters, numbers, dash and underscore.');
      setIsAddButtonDisabled(true);
    } else if (existingProductCodes[value]) {
      setValidationMessage('Product code already exists');
      setIsAddButtonDisabled(true);
    } else {
      setValidationMessage('');
      setIsAddButtonDisabled(!value);
    }
  };

  const handleSelectedFlowChange = (event) => (
    setSelectedFlow(flows.find((f) => f.id === event.target.value))
  );

  const handleAddProductConfig = () => {
    onAddProductConfig(productCode, selectedFlow, description);
    setProductCode('');
    setSelectedFlow(flows[0]);
    setDescription('');
  };

  if (!canAddProductConfig) {
    return renderMessage('New product configs cannot be added in this environment');
  }

  if (!flows.length) {
    return renderMessage('This deployment has no callable flows');
  }

  return (
    <div>
      <Form className="d-flex justify-content-between">
        <FormGroup className={styles.productCodeInputColumn}>
          <Label for="productCode">Product Code</Label>
          <Input
            type="text"
            name="productCode"
            id="productCode"
            bsSize="sm"
            value={productCode}
            onChange={handleOnProductCodeChange}
          />
          <FormFeedback>
            { validationMessage }
          </FormFeedback>
        </FormGroup>
        <FormGroup className={styles.flowSelectColumn}>
          <Label for="flowSelect">Flow</Label>
          <Input
            id="flowSelect"
            name="flowSelect"
            type="select"
            bsSize="sm"
            onChange={handleSelectedFlowChange}
          >
            { flows.map(renderFlowSelectOption) }
          </Input>
          <FormFeedback>
            { validationMessage }
          </FormFeedback>
        </FormGroup>
        <FormGroup className={styles.descriptionInputColumn}>
          <Label for="description">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            bsSize="sm"
            rows="1"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </FormGroup>
        <FormGroup className={styles.addNewProductConfigColumn}>
          <Button
            color={isAddButtonDisabled ? 'secondary' : 'success'}
            outline
            disabled={isAddButtonDisabled}
            onClick={handleAddProductConfig}
          >
            <FontAwesomeIcon icon={faPlus} className="pr-1" />
            Add
          </Button>
        </FormGroup>
      </Form>
      {
        validationMessage && (
          <div className={styles.validationMessage}>
            { validationMessage }
          </div>
        )
      }
    </div>
  );
}
