import React from 'react';

import FormContext from '../ScoringRequestForm/FormContext';
import ScoringRequestInputSummary from '../../components/ScoringRequestInputSummary';
import ClientLetterhead from '../../components/ClientLetterhead';
import ScoreCard from '../../components/ScoreCard';

import styles from './ScoringReport.module.scss';
import SmartConsentCard from '../ScoringRequestDetails/SmartConsentCard';

const sortClientReportsByTime = (clientReports) => clientReports.sort((a, b) => {
  if (a.scoringDate && b.scoringDate) {
    const dateA = new Date(a.scoringDate).getTime();
    const dateB = new Date(b.scoringDate).getTime();
    return (dateA > dateB) ? -1 : 1;
  }
  return 1;
});

const letterheadBlock = (clientId, clientConfig, userAccount) => {
  if (clientId && clientConfig && userAccount) {
    return (
      <ClientLetterhead
        clientId={clientId}
        logo={clientConfig.logo}
        companyName={clientConfig.companyName}
        staffFirstName={userAccount.given_name}
        staffLastName={userAccount.family_name}
        staffEmail={userAccount.email}
      />
    );
  }

  return null;
};

export default function (props) {
  return (
    <FormContext.Consumer>
      {(context) => (
        <>
          {letterheadBlock(props.clientId, props.clientConfig, props.userAccount)}
          <div className={styles.scoreDisplayScreen}>
            <ScoreCard
              applicant={context.applicant}
              clientReports={sortClientReportsByTime(context.clientReports)}
              loanInfo={context.loanInfo}
              refreshScoresHandler={props.refreshScores}
              userAccount={props.userAccount}
              scoring={props.scoring}
              waitForReports={props.waitForReports}
            />

            <ScoringRequestInputSummary
              isUploadOpen={props.isUploadOpen}
              onCancel={props.onCancel}
              onSubmit={props.onSubmit}
              uploadResult={props.uploadResult}
              onDisplayUploadModal={props.onDisplayUploadModal}
              clientConfig={props.clientConfig}
            />

            <SmartConsentCard
              features={props.clientConfig.features}
              requestId={props.requestId}
              tsBankingURL={props.smartConsentUrl}
              hideEvents
              isUat={false} // SvdO: This should be updated in APP-4394
            />
          </div>
        </>
      )}
    </FormContext.Consumer>
  );
}
