import validator from 'validator';

class FormValidator {
  constructor(validations) {
    this.validations = validations;
  }

  validate(inputFormState) {
    const validation = this.checkFormValidity();

    this.validations.forEach((rule) => {
      if (typeof inputFormState[rule.field] !== 'undefined') {
        let fieldValue = '';
        if (inputFormState[rule.field]) {
          fieldValue = inputFormState[rule.field].toString();
        }

        if (!!rule.skipIfEmpty && fieldValue === '') {
          return;
        }

        const options = rule.options || [];
        const validationMethod = typeof rule.method === 'string'
          ? validator[rule.method]
          : rule.method;

        if (validationMethod(fieldValue, ...options, inputFormState) !== rule.validWhen) {
          validation[rule.field] = {
            isInvalid: true,
            message: rule.message,
          };
          validation.isInvalid = true;
        }
      } else if (rule.fields && rule.fields.every((field) => typeof inputFormState[field] !== 'undefined')) {
        if (typeof rule.method !== 'function') {
          return;
        }
        const validationMethod = rule.method;

        if (validationMethod(rule.fields, inputFormState) !== rule.validWhen) {
          rule.fields.forEach((field) => {
            validation[field] = {
              isInvalid: true,
              message: rule.message,
            };
            validation.isInvalid = true;
          });
        }
      }
    });

    return validation;
  }

  checkFormValidity() {
    const validation = {};

    this.validations.map((rule) => {
      validation[rule.field] = { isInvalid: false, message: '' };
      return validation[rule.field];
    });

    return { isInvalid: false, ...validation };
  }
}

export default FormValidator;
