import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NavButton.module.scss';
import dropdownStyles from '../NavDropdown/NavDropdown.module.scss';

export default function NavButton(props) {
  const { navItem } = props;
  const buttonClasses = [styles.headerNavItem];

  if ((navItem.labelSuffix || '').includes('beta')) {
    buttonClasses.push(styles.betaSuffix);
  }

  if (navItem.icon) {
    buttonClasses.push(dropdownStyles.iconNav);
  } else if (props.selected) {
    buttonClasses.push(styles.selected);
  }

  const buttonClassString = buttonClasses.join(' ');
  const inlinePlacementOrderStyle = { order: navItem.navOrder };

  const buttonJSX = (
    <button
      key={`nav-button-${navItem.label}`}
      type="button"
      className={buttonClassString}
      onClick={() => props.clickHandler(navItem.path)}
      style={inlinePlacementOrderStyle}
    >
      { navItem.icon ? <FontAwesomeIcon icon={navItem.icon} color={styles.iconColour} /> : props.label}
      { navItem.labelSuffix
        ? <span>{navItem.labelSuffix}</span>
        : null}
    </button>
  );

  const wrapperClasses = [
    dropdownStyles.iconNavContainer,
    dropdownStyles.navSubmenuDropdown,
    styles.navIconWrapper,
    'm-0',
  ];

  if (props.selected) {
    wrapperClasses.push(styles.selected);
  }

  if (navItem.icon) {
    return (
      <div
        className={wrapperClasses.join(' ')}
        style={inlinePlacementOrderStyle}
      >
        {buttonJSX}
      </div>
    );
  }

  return buttonJSX;
}
