import validator from 'validator';

const normalizeOptions = {
  gmail_remove_subaddress: false,
  gmail_remove_dots: false,
  outlookdotcom_remove_subaddress: false,
  yahoo_remove_subaddress: false,
  icloud_remove_subaddress: false,
};
export default (email) => validator.normalizeEmail(email, normalizeOptions);
