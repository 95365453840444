import React from 'react';
import { Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IntegrationInputForms.module.scss';

/* eslint-disable camelcase */
export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let partnerId;
  let partnerSecret;
  let appKey;

  if (credentials) {
    ({
      partnerId,
    } = credentials);
  }

  if (credentials.private) {
    ({ partnerSecret, appKey } = credentials.private);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Finicity Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="finicity-input-partnerId">Partner ID</Label>
          <Input id="finicity-input-partnerId" value={partnerId} onChange={onInputChange} />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="finicity-input-partnerSecret">
            Partner Secret
            <FontAwesomeIcon icon="lock" />
          </Label>
          <Input id="finicity-input-partnerSecret" value={partnerSecret} nestedKey="private" onChange={onInputChange} />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="finicity-input-appKey">
            App Key
            <FontAwesomeIcon icon="lock" />
          </Label>
          <Input id="finicity-input-appKey" value={appKey} nestedKey="private" onChange={onInputChange} />
        </div>
      </div>
    </div>
  );
}
