/* eslint-disable camelcase */
import React from 'react';

import Card from '../Card';
import { useChatContext } from '../../context';
import Loader from '../../../../components/Loader';

import { ChatMessage } from './components/ChatMessage';
import { useChatMessages } from './hooks/useChatMessages';

export function ChatMessages() {
  const { chatListRef } = useChatMessages();
  const { history } = useChatContext();

  return (
    <Card
      className="p-4 w-100 d-flex flex-column overflow-auto scrollbar"
      ref={chatListRef}
    >
      <div className="flex-1">
        {history.length === 0 ? (
          <div
            className="text-center opacity-50"
          >
            <Loader />
          </div>
        ) : (
          history.map(
            ({
              message_id, message_content, message_role,
            }) => (
              <ChatMessage
                key={`${message_role}-${message_id}`}
                speaker={message_role}
                text={message_content}
              />
            ),
          )
        )}
      </div>
    </Card>
  );
}

export default ChatMessages;
