import React from 'react';
import {
  Container,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import BaseVehicleInputGroup, { fieldNames } from '../BaseVehicleInputGroup';

const formGroupFieldNames = [
  ...fieldNames,
  'value',
  'appraisedValue',
  'condition',
];

class VehicleInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;

    this.validator = new FormValidator(validationConfig);
    this.vehicleInputGroup = React.createRef();
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="deal-information-input-group">
              <h4>Vehicle</h4>
              <div className="form-group-wrapper">
                <Container>
                  <BaseVehicleInputGroup
                    ref={this.vehicleInputGroup}
                    state={this.state}
                    previousState={previousState}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <FormGroup>
                    <Label htmlFor="value">
                      Value
                    </Label>
                    <Input
                      className={this.state.valueFieldInvalid ? 'is-invalid' : ''}
                      type="number"
                      name="value"
                      id="value"
                      defaultValue={previousState.value || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="appraisedValue">
                      Appraised Value
                    </Label>
                    <Input
                      className={this.state.appraisedValueFieldInvalid ? 'is-invalid' : ''}
                      type="number"
                      name="appraisedValue"
                      id="appraisedValue"
                      defaultValue={previousState.appraisedValue || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="condition">
                      Condition
                    </Label>
                    <Input
                      className={this.state.conditionFieldInvalid ? 'is-invalid' : ''}
                      type="select"
                      name="condition"
                      id="condition"
                      defaultValue={previousState.condition || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    >
                      <option value="">Please select...</option>
                      <option value="Used">Used</option>
                      <option value="New">New</option>
                    </Input>
                  </FormGroup>
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default VehicleInputGroup;
