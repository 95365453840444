import Toggle from 'react-bootstrap-toggle';
import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { useQueue } from '@react-hookz/web';

import { Mutex, withTimeout } from 'async-mutex';
import styles from './CollapseConfig.module.scss';
import { useEnabledFeatures } from '../../hooks/useEnabledFeatures';
import saveConfigPatchToServer from './ClientConfig/utils/saveConfigPatchToServer';
import getCollapseConfig from '../../utils/get-collapse-config';

const collapseCards = [
  {
    label: 'Products',
    requiredFeatures: [],
    key: 'products',
  },
  {
    label: 'Custom Attributes',
    requiredFeatures: ['customAttributes'],
    key: 'customAttributes',
  },
  {
    label: 'Income Sources',
    requiredFeatures: ['bankingAnalysisReport'],
    key: 'incomeSources',
  },
  {
    label: 'Banking Balance',
    requiredFeatures: ['bankingBalanceReport'],
    key: 'bankingBalance',
  },
  {
    label: 'Banking Summary',
    requiredFeatures: ['bankingAnalysisReport'],
    key: 'bankingSummary',
  },
  {
    label: 'Banking Accounts',
    requiredFeatures: ['bankingAnalysisReport'],
    key: 'bankingAccounts',
  },
  {
    label: 'Bureau Report',
    requiredFeatures: ['bureauReport'],
    key: 'bureauReport',
  },
  {
    label: 'Smart Consent™',
    requiredFeatures: ['viewInviteEmailEvents'],
    key: 'smartConsent',
  },
  {
    label: 'Application Summary',
    requiredFeatures: [],
    key: 'applicationSummary',
  },
  {
    label: 'Raw Information for Developers',
    requiredFeatures: [],
    key: 'developerInfo',
  },
  {
    label: 'Callback History',
    requiredFeatures: ['callbackHistory'],
    key: 'callbackHistory',
  },
];

const saveMutex = withTimeout(new Mutex(), 5_000);

const savePatches = async (patches) => {
  await saveMutex.runExclusive(async () => {
    await saveConfigPatchToServer(patches, false);
  });
};

function CollapseConfig({ clientConfig }) {
  const enabledFeatures = useEnabledFeatures({ env: 'any' });

  const [configState, setConfigState] = useState(getCollapseConfig(clientConfig));
  const [hasInitValue, setHasInitValue] = useState(!!clientConfig.consoleScoringDetailsCollapse);

  const {
    first: nextPendingPatch,
    add: enqueuePendingPatch,
    remove: dequeuePendingPatch,
  } = useQueue();

  useEffect(() => {
    if (nextPendingPatch) {
      const initPatches = [];
      if (!hasInitValue) {
        setHasInitValue(true);
        initPatches.push({
          op: 'add',
          path: '/consoleScoringDetailsCollapse',
          value: {},
        });
      }
      savePatches([
        ...initPatches,
        dequeuePendingPatch(),
      ]);
    }
  }, [nextPendingPatch, dequeuePendingPatch, hasInitValue, setHasInitValue]);

  const updateToggle = (key) => {
    enqueuePendingPatch({
      op: 'replace',
      path: `/consoleScoringDetailsCollapse/${key}`,
      value: !configState[key],
    });
    setConfigState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <>
      <div className="configSectionHeader">
        <h3>
          Automatically Expand
          Scoring Details Cards
        </h3>
      </div>
      <div className="card">
        <div className="configEditorWrapper">
          <div>
            <div className="row mx-3">
              {collapseCards.map((card) => {
                const id = `${card.key}Toggle`;
                const show = card.requiredFeatures.every((feature) => enabledFeatures[feature]);

                if (!show) {
                  return null;
                }

                return (
                  <div
                    key={id}
                    className={`col-12 col-sm-6 col-md-4 row mx-0 ${styles.configItem}`}
                  >
                    <Label htmlFor={id} className="col-8">{card.label}</Label>
                    <Toggle
                      id={id}
                      active={!configState[card.key]}
                      on="Yes"
                      off="No"
                      size="xs"
                      onstyle="success"
                      offstyle="custom"
                      // SvdO 2024-07-04: Width and height need to be set or jest will go into an infinite loop
                      // See: https://github.com/arnthor3/react-bootstrap-toggle/issues/31
                      width={50}
                      height={24.5}
                      onClick={() => {
                        updateToggle(card.key);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollapseConfig;
