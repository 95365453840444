import React from 'react';
import ReactJson from 'react-json-view';

import styles from '../ProductsReport.module.scss';

export { default as RLA } from './RLA.view';
export { default as MPT } from './MPT.view';
export { default as LEAD } from './LEAD.view';
export { default as KYC } from './KYC.view';

export function ReasonTable({ product }) {
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      <div>
        Score:&nbsp;
        {product.score}
      </div>
      { !!product.scoreReasons?.length
    && (
      <table className={styles.table}>
        <caption>Score Reasons</caption>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {product.scoreReasons.map((reason) => (
            <tr key={reason.code}>
              <td>{reason.code}</td>
              <td>{reason.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
    </div>
  );
}

const renderReasons = (type, reasons) => {
  if (!reasons?.length) return null;
  return (
    <table className={styles.table}>
      <caption>
        { type }
        &nbsp;Reasons
      </caption>
      <thead>
        <tr>
          <th>Code</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {reasons.map((reason) => (
          <tr key={reason.code}>
            <td>{reason.code}</td>
            <td>{reason.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export function AD({ product }) {
  return (
    <div className={`${styles.content} ${styles.m}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      <div>
        Decision:&nbsp;
        <b>
          {product.decision}
        </b>
      </div>
      { renderReasons('Decline', product.declineReasons) }
      { renderReasons('Decision', product.decisionReasons) }
    </div>
  );
}

export function SmallGroups({ products }) {
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <table className={`${styles.table} ${styles.l}`}>
        <thead>
          <tr>
            <th>ProductID</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {products.map((product) => (
            product?.productCode
              ? (
                <tr key={product.productCode}>
                  <td className={styles.productCode}>{product.productCode}</td>
                  <td>{product.description}</td>
                </tr>
              )
              : null
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function DefaultProductUI({ product }) {
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      <ReactJson
        src={product}
        name={product.productCode}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapsed
      />
    </div>
  );
}
