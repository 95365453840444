import React from 'react';
import { Button } from 'reactstrap';
import AddNewAddressBox from './AddNewAddressBox';
import AddressBox from './AddressBox';
import Loader from '../../components/Loader';
import AddressForm from './AddressForm';
import AddressDeleteModal from './AddressDeleteModal';
import UploadFileModal from '../../components/UploadFileModal';
import getClientLocations from '../../utils/get-client-locations';
import uploadFileToAPI from '../../utils/upload-file';

import saveLocationsToServer from './saveLocationToServer';

import styles from './ManageLocations.module.scss';

import { addressEditModes } from '../../constants';

const uploadModalDefaultState = {
  isModalOpen: false,
  uploadFile: '',
  uploadResult: undefined,
  loading: false,
};

class ManageLocations extends React.Component {
  generateEditClickHandler = this.generateEditClickHandler.bind(this);

  generateDeleteClickHandler = this.generateDeleteClickHandler.bind(this);

  onAddClick = this.onAddClick.bind(this);

  onDeletionCancelClick = this.onDeletionCancelClick.bind(this);

  onEditComplete = this.onEditComplete.bind(this);

  renderAddressBoxes = this.renderAddressBoxes.bind(this);

  renderAddressModalForm = this.renderAddressModalForm.bind(this);

  renderDeletionModal = this.renderDeletionModal.bind(this);

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      deleteConfirmation: false,
      addOrEdit: undefined,
      selectedAddress: {},
      existingLocationNames: (this.props.locations || []).map((address) => address.name),
      loading: true,
      deletionProgress: {
        loading: false,
        error: false,
      },
      editingProgress: {
        loading: false,
        error: false,
      },
      uploadModal: uploadModalDefaultState,
    };
  }

  componentDidMount() {
    getClientLocations()
      .then(({ locations }) => {
        this.setState({ loading: false, locations });
      }).catch((error) => {
        console.error('Error getting locations:', error);
        this.setState({ loading: false });
      });
  }

  // eslint-disable-next-line no-dupe-class-members
  onAddClick() {
    this.setState({ selectedAddress: undefined, addOrEdit: addressEditModes.ADD });
  }

  // eslint-disable-next-line no-dupe-class-members
  onDeletionCancelClick() {
    this.setState({ deleteConfirmation: false });
  }

  // eslint-disable-next-line no-dupe-class-members
  onEditComplete(newLocation, action) {
    const newLocationsList = [...this.state.locations];
    let stateKey;

    const findModifiedIndex = () => this.state.locations.findIndex(
      (address) => address.storeNumber === this.state.selectedAddress.storeNumber,
    );

    switch (action) {
      case addressEditModes.EDIT:
        stateKey = 'editingProgress';
        newLocationsList[findModifiedIndex()] = { ...newLocation };
        break;
      case addressEditModes.DELETE:
        stateKey = 'deletionProgress';
        newLocationsList.splice(findModifiedIndex(), 1);
        break;
      case addressEditModes.ADD:
      default:
        stateKey = 'editingProgress';
        newLocationsList.push(newLocation);
        break;
    }

    this.setState({ [stateKey]: { loading: true, error: false } });

    saveLocationsToServer(newLocation, action)
      .then((response) => {
        if (response.errorMessage) throw new Error(response.errorMessage);
        this.setState({
          locations: newLocationsList,
          addOrEdit: undefined,
          deleteConfirmation: false,
          [stateKey]: { loading: false, error: false },
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ [stateKey]: { loading: false, error: true } });
      });
  }

  // eslint-disable-next-line no-dupe-class-members
  generateEditClickHandler(address) {
    return () => this.setState({ selectedAddress: address, addOrEdit: addressEditModes.EDIT });
  }

  // eslint-disable-next-line no-dupe-class-members
  generateDeleteClickHandler(address) {
    return () => this.setState({ selectedAddress: address, deleteConfirmation: true });
  }

  // eslint-disable-next-line no-dupe-class-members
  renderAddressBoxes() {
    if (!this.state.locations.length) {
      return null;
    }

    return (
      this.state.locations
        .sort((addressA, addressB) => (
          addressA.name < addressB.name ? -1 : 1
        ))
        .map(
          (address) => (
            <AddressBox
              address={address}
              key={address.storeNumber}
              hasAmsIntegration={this.props.hasAmsIntegration}
              onEditClick={this.generateEditClickHandler(address)}
              onDeleteClick={this.generateDeleteClickHandler(address)}
            />
          ),
        )
    );
  }

  // eslint-disable-next-line no-dupe-class-members
  renderAddressModalForm() {
    if (!this.state.addOrEdit) {
      return null;
    }

    return (
      <AddressForm
        addOrEdit={this.state.addOrEdit}
        setAddOrEdit={(newStatus) => this.setState({ addOrEdit: newStatus })}
        selectedAddress={this.state.selectedAddress}
        hasAmsIntegration={this.props.hasAmsIntegration}
        onEditComplete={this.onEditComplete}
        existingLocationNames={this.state.existingLocationNames}
        editingProgress={this.state.editingProgress}
        locations={this.state.locations}
      />
    );
  }

  // eslint-disable-next-line no-dupe-class-members
  renderDeletionModal() {
    if (!this.state.deleteConfirmation) {
      return null;
    }

    return (
      <AddressDeleteModal
        onCancel={this.onDeletionCancelClick}
        onDeleteConfirm={this.onEditComplete}
        selectedAddress={this.state.selectedAddress}
        deletionProgress={this.state.deletionProgress}
      />
    );
  }

  renderUploadModalWithButton = () => {
    const buttonAndHeaderText = 'Upload CSV';

    const onButtonClick = () => {
      this.setState((prevState) => ({
        uploadModal: {
          ...prevState.uploadModal,
          isModalOpen: true,
        },
      }));
    };

    const onCancel = () => {
      this.setState(() => ({
        uploadModal: uploadModalDefaultState,
      }));
    };

    const onFileSelected = (event) => {
      this.setState((prevState) => ({
        uploadModal: {
          ...prevState.uploadModal,
          uploadFile: event.target.files[0],
        },
      }));
    };

    const uploadCSVToAPI = () => {
      this.setState((prevState) => ({
        uploadModal: {
          ...prevState.uploadModal,
          loading: true,
        },
      }));

      const onSuccess = (result) => {
        const errorMessages = [];
        if (result.error) {
          if (result.data?.some((e) => e.schemaPath)) {
            result.data.forEach((element) => {
              if (element.keyword === 'enum') errorMessages.push(`${element.instancePath.replace('/', '')} ${element.message}: ${element.params.allowedValues.join(', ')}`);
              else errorMessages.push(element.message);
            });
          } else if (typeof result.error === 'string') errorMessages.push(result.error);
          this.setState((prevState) => ({
            uploadModal: {
              ...prevState.uploadModal,
              loading: false,
              uploadResult: {
                success: false,
                message: errorMessages.join('\n'),
              },
            },
          }));
        } else {
          this.setState((prevState) => ({
            locations: result,
            uploadModal: {
              ...prevState.uploadModal,
              loading: false,
              uploadResult: {
                success: true,
                message: 'The file uploaded successfully. Please wait a few moments before refreshing the page for your locations list to be updated.',
              },
            },
          }));
        }
      };

      const onError = () => {
        this.setState((prevState) => ({
          uploadModal: {
            ...prevState.uploadModal,
            uploadResult: {
              success: false,
              message: 'An unexpected error occurred while uploading locations.',
            },
          },
        }));
      };

      uploadFileToAPI(this.state.uploadModal.uploadFile, ['csv'], 'UploadLocations', onSuccess, onError);
    };

    return (
      <>
        <Button
          color="primary"
          onClick={onButtonClick}
        >
          {buttonAndHeaderText}
        </Button>
        <UploadFileModal
          isModalOpen={this.state.uploadModal.isModalOpen}
          isLoading={this.state.uploadModal.loading}
          fileIsSelected={!!this.state.uploadModal.uploadFile}
          uploadResult={this.state.uploadModal.uploadResult}
          onCancel={onCancel}
          onFileSelected={onFileSelected}
          onSubmit={uploadCSVToAPI}
          headerText={buttonAndHeaderText}
          bodyText={`
            Upload a CSV file containing your locations. 
            This will update the list of locations you and 
            your organization's users can select from when 
            submitting application requests.
          `}
          inputFileTypesAccepted=".csv, text/csv"
        />
      </>
    );
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        { this.renderAddressModalForm() }
        { this.renderDeletionModal() }
        <h4>Manage Locations</h4>
        { this.props.enabledFeatures.locationsCSVImport ? this.renderUploadModalWithButton() : null }
        <div className={styles.addressesWrapper}>
          <AddNewAddressBox onAddClick={this.onAddClick} />
          { this.renderAddressBoxes() }
        </div>
      </>
    );
  }
}

export default ManageLocations;
