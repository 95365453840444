import React from 'react';
import {
  Button,
} from 'reactstrap';

import styles from './ScoreReasons.module.scss';
import { useModal } from '../../../hooks/useModal';

function canShow({ enabled, reports }) {
  return enabled
    && reports
    && reports.length
    && reports[0].scoreReasons
    && reports[0].scoreReasons.length;
}

function renderScoreReason(item) {
  if (typeof item === 'object') {
    const displayReasonCode = `${item.code}: ${item.description}`;
    return (<p key={item.code}>{ displayReasonCode }</p>);
  }
  return (<p key={item}>{ item }</p>);
}

export default function (props) {
  if (!canShow(props)) return null;
  const { scoreReasons } = props.reports[0];
  const { showModal } = useModal();

  const showReasonsModal = () => {
    showModal({
      header: 'Score Reason Codes',
      body: <div className="_lr-hide">{scoreReasons.map(renderScoreReason)}</div>,
    });
  };

  return (
    <Button
      color="link"
      className={styles.button}
      onClick={() => showReasonsModal()}
    >
      <span>Reason Codes</span>
    </Button>
  );
}
