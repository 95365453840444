import React from 'react';
import parser from 'fast-xml-parser';
import ReportTitle from './ReportTitle';
import TransUnionViewCa from './TransUnionView.ca';
import TransUnionViewUs from './TransUnionView.us';

const reportsByRegion = {
  ca: TransUnionViewCa,
  us: TransUnionViewUs,
};

const lookupRegionalView = (report, region) => {
  const RegionalReportViewComponent = reportsByRegion[region];
  if (!RegionalReportViewComponent) {
    return (<ReportTitle message="Unknown report format." />);
  }
  return (<RegionalReportViewComponent report={report} />);
};

export default function (props) {
  const { region } = props;
  const rawReport = props.report.replace(/\\"/g, '"');
  const validate = parser.validate(rawReport);
  if (validate !== true) {
    console.info('Report', rawReport);
    console.error('Parse error', validate);
    return (<ReportTitle message="Unable to display report." />);
  }

  const report = parser.parse(rawReport, {
    ignoreAttributes: false,
    attributeNamePrefix: '@',
    stopNodes: ['Time', 'PaymentPattern', 'Code', 'StatusCode', 'DischargeCode'],
  });

  return lookupRegionalView(report, region);
}
