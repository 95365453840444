export default (productCode, description, deployment, flow) => ({
  op: 'add',
  path: `/flowConfig/${productCode}`,
  value: {
    productCode,
    description,
    enabled: true,
    apiKey: deployment.accessKey,
    flow: [deployment.flow, flow.path].join(''),
    flowId: flow.id,
    includeOnReport: true,
  },
});
