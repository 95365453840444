import React from 'react';
import { getCountryCodeByState } from '../../utils/checkCountryByState';

const clearValidation = (fieldName) => ({
  [`${fieldName}FieldInvalid`]: false,
  [`${fieldName}FieldValidationMessage`]: '',
});

class InputGroupClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelsMarkedRequired: false,
    };

    this.userInputValues = {};
    this.formGroupFieldNames = [];
    this.skipValidationOnGroupIfEmpty = true;

    this.formGroupFieldNames.forEach((fieldName) => {
      this.setState(clearValidation(fieldName));
    });
  }

  componentDidMount() {
    const groupContainsInput = this.formGroupContainsInput();

    if (groupContainsInput) {
      this.setState({ labelsMarkedRequired: groupContainsInput });
    }
  }

  componentDidUpdate() {
    const groupContainsInput = this.formGroupContainsInput();

    let performStateChange = false;
    if (groupContainsInput && !this.state.labelsMarkedRequired) {
      performStateChange = true;
    } else if (!groupContainsInput && this.state.labelsMarkedRequired) {
      performStateChange = true;
    }

    if (performStateChange) {
      /*
        This is a legal operation if setState() is wrapped in a condition.
        But ESLint couldn't detect this, so have to disable linter for next line.
      */

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ labelsMarkedRequired: groupContainsInput });
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  setDropdownSelectedValue(value) { // eslint-disable-line class-methods-use-this
    let selectedValue;

    if (value === true) selectedValue = 'true';
    else if (value === false) selectedValue = 'false';
    else if (typeof value === 'undefined') selectedValue = '';
    else selectedValue = value;

    return selectedValue;
  }

  resetClassField = (name) => {
    const fieldName = `${name}FieldInvalid`;
    if (this.state[fieldName]) {
      this.setState(clearValidation(fieldName));
    }
  };

  // eslint-disable-next-line class-methods-use-this
  formatToPhone = (value = '') => {
    const input = value.replace(/\D/g, '').substring(0, 10);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) { return `${first}-${middle}-${last}`; }
    if (input.length > 3) { return `${first}-${middle}`; }
    if (input.length > 0) { return `${first}`; }
    return '';
  };

  // eslint-disable-next-line class-methods-use-this,react/no-unused-class-component-methods
  handleKeyPress = (e) => {
    const keyPressed = e.key;
    if (/\D/g.test(keyPressed)) {
      e.preventDefault();
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handlePhoneNumber = (context, dataTreeKey) => (event) => {
    event.preventDefault();
    const { target } = event;
    const { name } = target;
    const { value } = target;
    const caretPosition = target.selectionStart;

    context.onInputChange({ name, value: this.formatToPhone(value), dataTreeKey }, () => {
      if (value.length > (context[dataTreeKey][name] || '').length) {
        if (value.length === 8) {
          target.setSelectionRange(caretPosition + 1, caretPosition + 1);
        } else if (value.length === 4) {
          target.setSelectionRange(caretPosition + 1, caretPosition + 1);
        } else {
          target.setSelectionRange(caretPosition, caretPosition);
        }
      } else {
        target.setSelectionRange(caretPosition, caretPosition);
      }
    });
    this.resetClassField(name);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  generateFieldOnChangeEvent = (context, dataTreeKey, lookupCountry, triggerMethod) => (event) => {
    let inputValue = event.target.value;

    // To accommodate string-only values set in HTML
    if (inputValue === 'true') inputValue = true;
    else if (inputValue === 'false') inputValue = false;

    if (lookupCountry) {
      const country = getCountryCodeByState(inputValue);
      context.onInputChange({ name: 'country', value: country, dataTreeKey });
    }

    context.onInputChange({ name: event.target.name, value: inputValue, dataTreeKey });

    this.resetClassField(event.target.name);

    if (triggerMethod) {
      triggerMethod(event);
    }
  };

  formGroupContainsInput() { // eslint-disable-line class-methods-use-this
    let formGroupContainsInput = false;

    this.formGroupFieldNames.forEach((fieldname) => {
      if (this.userInputValues[fieldname] !== '' && typeof this.userInputValues[fieldname] !== 'undefined') {
        formGroupContainsInput = true;
      }
    });
    return formGroupContainsInput;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  runValidation() {
    if (!this.formGroupContainsInput() && this.skipValidationOnGroupIfEmpty) {
      return;
    }

    this.formGroupFieldNames.forEach((fieldname) => {
      if (!this.userInputValues[fieldname]) this.userInputValues[fieldname] = '';
    });

    const validation = this.validator.validate(this.userInputValues);
    const validationStates = {};

    Object.keys(validation).forEach((item) => {
      if (item === 'isInvalid') return;
      validationStates[`${item}FieldInvalid`] = validation[item].isInvalid;
      validationStates[`${item}FieldValidationMessage`] = validation[item].message;
    });

    this.setState({ ...validationStates });

    return validation.isInvalid; // eslint-disable-line consistent-return
  }
}

export default InputGroupClass;
