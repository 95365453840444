import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import styles from './UploadFileModal.module.scss';

function UploadFileModal(props) {
  const renderSuccess = (message) => (<Alert color="primary">{message}</Alert>);

  const renderError = (message) => (<Alert color="danger">{message}</Alert>);

  const displayAlert = (uploadResult) => {
    if (!uploadResult) {
      return null;
    }
    const { success, message } = uploadResult;
    return success ? renderSuccess(message) : renderError(message);
  };

  return (
    <div>
      <Modal isOpen={props.isModalOpen} toggle={props.onCancel} className="uploadLogo">
        <ModalHeader>{props.headerText}</ModalHeader>
        <ModalBody>{props.bodyText}</ModalBody>
        <ModalBody>
          <input type="file" accept={props.inputFileTypesAccepted} onChange={props.onFileSelected} />
          { displayAlert(props.uploadResult) }
        </ModalBody>
        <ModalFooter>
          { !props.uploadResult?.success
            && (
              <Button className={styles.confirmButton} color="primary" onClick={props.onSubmit} disabled={!props.fileIsSelected || props.isLoading}>
                { props.isLoading ? <Spinner size="sm" color="white" /> : 'Upload' }
              </Button>
            )}
          {' '}
          <Button color="secondary" onClick={props.onCancel}>
            { props.uploadResult?.success ? 'Close' : 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

UploadFileModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  fileIsSelected: PropTypes.bool.isRequired,
  inputFileTypesAccepted: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  uploadResult: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
};

UploadFileModal.defaultProps = {
  uploadResult: undefined,
};

export default UploadFileModal;
