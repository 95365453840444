import React from 'react';
import { Container, Label } from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

import styles from './PersonBlockEssentialInformation.module.scss';

const formGroupFieldNames = [
  'firstName', 'lastName', 'homePhoneNumber', 'email1',
];

class PersonEssentialInfoInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;

    if (props.highlightEssentialInputs) {
      super.skipValidationOnGroupIfEmpty = false;
    }
    const filteredConfig = this.props.allowEmptyEmails
      ? validationConfig.filter((v) => !(v.field === 'email1' && v.method === 'isEmpty'))
      : validationConfig;

    this.validator = new FormValidator(filteredConfig);
  }

  toggle = (field, context, dataTreeKey) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));

    const event = {
      target: { value: !this.state[field], name: field },
    };

    this.generateFieldOnChangeEvent(context, dataTreeKey)(event);
  };

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = { ...previousState };
          return (
            <Container>
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="firstName"
                id={`${this.props.fieldIdPrefix}ApplicantFirstName`}
                label="First Name"
                required
                labelsMarkedRequired={this.state.labelsMarkedRequired}
                valid={!this.state.firstNameFieldInvalid}
                validationMessage={this.state.firstNameFieldValidationMessage}
                value={previousState.firstName || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="middleName"
                id={`${this.props.fieldIdPrefix}ApplicantMiddleName`}
                label="Middle Name"
                valid={!this.state.middleNameFieldInvalid}
                validationMessage={this.state.middleNameFieldValidationMessage}
                value={previousState.middleName || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="lastName"
                id={`${this.props.fieldIdPrefix}ApplicantLastName`}
                label="Last Name"
                required
                valid={!this.state.lastNameFieldInvalid}
                validationMessage={this.state.lastNameFieldValidationMessage}
                value={previousState.lastName || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="homePhoneNumber"
                id={`${this.props.fieldIdPrefix}ApplicantHomePhoneNumber`}
                label="Home Phone"
                required
                valid={!this.state.homePhoneNumberFieldInvalid}
                validationMessage={this.state.homePhoneNumberFieldValidationMessage}
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                maxLength="12"
                helpText="###-###-####"
                value={previousState.homePhoneNumber || ''}
                onChange={this.handlePhoneNumber(context, dataTreeKey)}
                onKeyPress={this.handleKeyPress}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="email1"
                id={`${this.props.fieldIdPrefix}ApplicantEmail1`}
                label="Email 1"
                required={!this.props.allowEmptyEmails}
                valid={!this.state.email1FieldInvalid}
                validationMessage={this.state.email1FieldValidationMessage}
                type="text"
                value={previousState.email1 || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                smallBottomMargin={this.props.allowMobileInvites}
              />
              {this.props.allowMobileInvites && (
                <div className={styles.inviteBlock}>
                  <Toggle
                    id="sendTrustStartInviteToggle"
                    active={previousState.sendMobileInvite === true}
                    className={styles.inviteToggle}
                    on="Yes"
                    off="No"
                    size="xs"
                    onstyle="success"
                    handlestyle="default"
                    offstyle="custom"
                    onClick={() => this.toggle('sendMobileInvite', context, dataTreeKey)}
                  />
                  <Label htmlFor="sendTrustStartInviteToggle">Send Smart Consent™ Invite?</Label>
                </div>
              )}
            </Container>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default PersonEssentialInfoInputGroup;
