import React from 'react';
import {
  Card,
  CardTitle,
  CardBody,
} from 'reactstrap';
import InputDisplayGroup from '../ScoringRequestInputSummary/InputDisplayGroup';
import { personInfo } from '../../config/inputFieldLabelMapping';
import { formatPerson } from '../../utils/requestDetailsMapper';

import styles from './ApplicantInfoCard.module.scss';

export default function (props) {
  const { details } = props;

  if (details.error) {
    return 'There was an error retrieving request details.';
  }

  return (
    <Card className={[styles.cardWrapper, styles.inline].join(' ')}>
      <CardTitle tag="h3" className={styles.cardTitle}>
        Applicant Info
      </CardTitle>
      <CardBody>
        <InputDisplayGroup fullWidth labels={personInfo} data={formatPerson(details.person)} />
      </CardBody>
    </Card>
  );
}
