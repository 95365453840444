export default [
  {
    field: 'prefilledLocations', // This is dynamically set to use in class constructor.
    method: 'isEmpty',
    validWhen: false,
    message: 'Location information is required.',
  },
  {
    field: 'name',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location name is required.',
  },
  {
    field: 'address',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location address is required.',
  },
  {
    field: 'city',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location city is required.',
  },
  {
    field: 'postalCode',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location zip/postal code is required.',
  },
  {
    field: 'postalCode',
    method: 'isPostalCode',
    validWhen: true,
    message: 'Location zip/postal code is not correct.',
    options: ['any'],
  },
  {
    field: 'storeNumber',
    method: 'isEmpty',
    validWhen: false,
    message: 'Store number is required.',
  },
  {
    field: 'state',
    method: 'isEmpty',
    validWhen: false,
    message: 'Please select the state/province for this location.',
  },
];
