import formatCurrency from './formatCurrency';
import bankingReportFieldMap from '../config/banking-report-fields';

const passThroughFormatter = (value) => (value);

const formatters = {
  boolean: (value) => ((!value || value === '0') ? 'FALSE' : 'TRUE'),
  currency: (value) => (`$${formatCurrency(Number(value), true)}`),
  count: passThroughFormatter,
  date: (value) => {
    if (!value) return 'NA';
    try {
      return new Date(value * 1000).toISOString().substring(0, 10);
    } catch {
      return passThroughFormatter(value);
    }
  },
};

const fieldExists = (fieldMap, fieldName) => (fieldName in fieldMap);
const fieldIsObject = (fieldMap, fieldName) => (typeof fieldMap[fieldName] === 'object');

const getField = (fieldMap, fieldName) => {
  if (fieldIsObject(fieldMap, fieldName)) return fieldMap[fieldName];
  return {
    label: fieldMap[fieldName] || fieldName,
  };
};

const getFormatter = (fieldMap, fieldName) => {
  if (!fieldIsObject(fieldMap, fieldName) || !fieldMap[fieldName].type) return passThroughFormatter;
  const result = formatters[fieldMap[fieldName].type];
  if (!result) {
    console.error('Unable to find formatter for field', { fieldName, ...fieldMap[fieldName] });
    return passThroughFormatter;
  }
  return result;
};

const displayField = (fieldMap, fieldName) => ({
  key: fieldName,
  hide: !fieldExists(fieldMap, fieldName),
  ...getField(fieldMap, fieldName),
  format: (value) => getFormatter(fieldMap, fieldName)(value),
});

// eslint-disable-next-line import/prefer-default-export
export const bankingAnalysisField = (fieldName, bankingAttributesDictionary) => displayField(bankingAttributesDictionary, fieldName);
export const bankingReportField = (fieldName) => displayField(bankingReportFieldMap, fieldName);
