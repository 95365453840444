import React, { useState } from 'react';
import api from '../../../../services/api';
import SanitizedTextInput from '../../../../components/SanitizedTextInput';
import saveConfigPatchToServer from '../utils/saveConfigPatchToServer';
import ConfigMessageModal from '../ConfigMessageModal';

import styles from './IPAllowlist.module.scss';

// eslint-disable-next-line security/detect-unsafe-regex
const ipAddressRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

const stringToArray = (input) => (input ? input.replace(/\s/g, '').split(',') : null);

const isValidInput = (input) => {
  try {
    const results = stringToArray(input);
    if (!results) return true;
    return results.reduce((result, ip) => (result && ipAddressRegex.test(ip)), true);
  } catch (error) {
    return false;
  }
};

export default function (props) {
  if (!props.config || !props.config.features || !props.config.features.enableIpAllowlist) return null;
  const requiresUsIpAddress = props.config.lexisNexis && props.config.lexisNexis.enabled;
  const [ipAllowlist, changeIPAllowlist] = useState(props.config.ipAllowlist);
  const [whitelistChanged, toggleWhitelistChanged] = useState(false);
  const [displayMessage, setMessageDialog] = useState(false);
  const [modalType, setModalPopupType] = useState('');

  const cancelWhitelistChange = () => {
    toggleWhitelistChanged(false);
    changeIPAllowlist(props.config.ipAllowlist);
  };

  const onSaveWhitelist = () => {
    setMessageDialog(null);
    const payload = [
      {
        op: 'replace',
        path: '/ipAllowlist',
        value: stringToArray(ipAllowlist),
      },
    ];
    saveConfigPatchToServer(payload, props.isUat)
      .then((res) => {
        props.setConfig(res);
      })
      .catch((error) => {
        setModalPopupType('error');
        setMessageDialog(error.message);
      })
      .finally(() => {
        toggleWhitelistChanged(false);
        props.togglePerformingSave(false);
      });
  };

  const onSaveButtonClicked = async () => {
    if (props.performingSave) {
      return;
    }

    if (!isValidInput(ipAllowlist)) {
      setModalPopupType('error');
      setMessageDialog('Not valid input.');
      return;
    }

    if (requiresUsIpAddress) {
      const ipValidationResponse = await api('ValidateIpAddress', { ipAllowlist });
      if (ipValidationResponse.isInvalid) {
        const { ipAddresses } = ipValidationResponse;
        if (!ipAddresses || !ipAddresses.length) {
          setMessageDialog((
            <>
              <p>Please ensure each IP address is valid and if specifying multiples they should be separated by commas.</p>
              <p>Your account requires all IP addresses originate in the US but we were unable to verify the addresses you input.</p>
            </>
          ));
        } else {
          const ipAddressList = ipAddresses
            .filter((i) => i.country !== 'US')
            .map((i) => `${i.ipAddress} (${i.country})`)
            .map((i) => (<li>{i}</li>));
          setMessageDialog((
            <>
              <p>Please ensure each IP address is valid and if specifying multiples they should be separated by commas.</p>
              <p>Your account requires all IP addresses originate in the US. The following addresses are invalid:</p>
              <ul className={styles.ipAddressList}>
                { ipAddressList }
              </ul>
            </>
          ));
        }
        setModalPopupType('error');
        setMessageDialog(true);
        return;
      }
    }

    props.togglePerformingSave(true);
    onSaveWhitelist();
  };

  const onWhitelistChange = (event) => {
    const newName = event.target.value;
    const changed = newName !== props.config.ipAllowlist;
    toggleWhitelistChanged(changed);
    changeIPAllowlist(newName);
  };

  return (
    <>
      {
        displayMessage
          ? (
            <ConfigMessageModal
              title="Problem saving configuration."
              onModalTransitionedOut={() => setMessageDialog(false)}
              modalType={modalType}
            >
              { displayMessage }
            </ConfigMessageModal>
          )
          : null
      }

      <div className="configRow mt-5">
        <div className="label">IP Allow List</div>
        <SanitizedTextInput
          type="text"
          className="codeStyleInput wide"
          name="ipAllowlist"
          onChange={onWhitelistChange}
          value={ipAllowlist || ''}
        />
        {
          whitelistChanged
            ? (
              <div className="buttonRow">
                <button type="button" className="edit btn btn-success" onClick={onSaveButtonClicked}>
                  Update
                </button>
                <button type="button" className="edit btn btn-cancel" color="secondary" onClick={cancelWhitelistChange}>
                  Cancel
                </button>
              </div>
            )
            : null
        }
      </div>
      <div className="text-muted help-text">
        Enter multiple IP addresses separated by commas. Each address must be in valid IPv4 or CIDR format.
      </div>
    </>
  );
}
