import { useContext } from 'react';

import { ChatContext } from '../ChatProvider';

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (context === undefined) {
    throw new Error('useChatContext must be used inside ChatProvider');
  }

  return context;
};

export default useChatContext;
