import React from 'react';

import styles from './InputItemRow.module.scss';

export default function InputItemRow(props) {
  return (
    <div className={
    [
      styles.wrapper,
      props.fullWidth ? styles.fullWidth : null,
    ].join(' ')
    }
    >
      <span>{props.label}</span>
      <span className={[props.capitalized ? styles.capitalized : '', '_lr-hide'].join(' ')}>{props.value}</span>
    </div>
  );
}
