import buildCurrentEmploymentBlock from './employmentDataBlock';
import buildLoanApplicationBlock from './loanApplicationDataBlock';
import buildAutoLoanBlock from './autoLoanDataBlock';
import buildPersonBlock from './personDataBlock';
import buildAssetBlock from './assetDataBlock';
import trimInputStrings from '../trimInputStrings';

import SCORING_USE_CASES from '../../config/scoringRequestUseCases';
import findContactWithKey from '../find-contact-with-key';

const createRequestPayload = (basePayload, formData, generateReport) => {
  const payload = { ...basePayload };

  payload.userInput.person = buildPersonBlock(formData.applicant);

  if (formData.coapplicant) {
    payload.userInput.cosignPerson = buildPersonBlock(formData.coapplicant);
  }

  payload.userInput.currentEmployment = buildCurrentEmploymentBlock(formData.employer, formData.income);
  payload.userInput.loanApplication = buildLoanApplicationBlock(formData.loanInfo);
  payload.userInput.asset = buildAssetBlock(formData.asset);

  const phoneType = formData.requestUseCase === SCORING_USE_CASES.BANKING_ONLY ? 'mobile' : 'home';

  if (formData.applicant.sendMobileInvite) {
    const { person } = payload.userInput;
    payload.userInput.invite = {
      firstName: person.firstName,
      lastName: person.lastName,
      email: findContactWithKey(person.emails, 'primary'),
      phone: findContactWithKey(person.phones, phoneType),
    };
  }

  if (formData.requestUseCase === SCORING_USE_CASES.AUTO) {
    payload.userInput.autoLoan = buildAutoLoanBlock(formData);
  }

  if (formData.sendToAms) {
    payload.sendToAms = true;
    payload.amsLotId = formData.dealership.amsLotId;
  }

  payload.requestId = formData.rescoreRequestId || undefined;
  payload.generateReport = generateReport;

  return payload;
};

const addLocationInfoToLoanInfo = (formData) => {
  const newFormData = { ...formData };
  newFormData.loanInfo = {
    ...formData.loanInfo,
    storeNumber: formData.loanInfo?.storeNumber || formData.dealership?.storeNumber,
    storeState: formData.loanInfo?.storeState || formData.loanInfo?.state || formData.dealership?.state,
  };
  return newFormData;
};

const buildScoringRequestPayload = (formData, generateReport = true) => {
  const sortedFormData = (formData.dealership?.storeNumber || formData.loanInfo?.storeNumber) ? addLocationInfoToLoanInfo(formData) : { ...formData };
  const finalFormData = trimInputStrings(sortedFormData);

  const payload = {
    useCase: finalFormData.requestUseCase || (finalFormData.scoring && finalFormData.scoring.useCase),
    userInput: {},
  };

  if (!payload.useCase.match(/-preapproval/ig) && finalFormData.basicInfo.preapproval) {
    payload.useCase += '-Preapproval';
  }

  return createRequestPayload(payload, finalFormData, generateReport);
};

export { buildScoringRequestPayload }; // eslint-disable-line import/prefer-default-export
