import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dataSources from './data-sources';
import styles from './UploadDataSource.module.scss';
import scoringReportStyles from '../../containers/ScoringReport/ScoringReport.module.scss';
import withFeatureFlag from '../../hoc/WithFeatureFlag';

// actual limit is 1048576, but need room for other items in payload
const whDataSizeLimit = 1000 * 1024;

const getDataSource = (source) => dataSources.find((ds) => ds.source === source);

const renderResult = (uploadResult, validationMessage) => {
  if (!uploadResult && !validationMessage) return null;
  const isError = validationMessage || uploadResult.error;
  const alertStyle = isError ? styles.alertDanger : styles.alertSuccess;
  return (
    <div className={alertStyle}>
      {validationMessage || uploadResult.message}
    </div>
  );
};

const onDataSourceChanged = (e, setDataSource, clearSelectedFile) => {
  clearSelectedFile();
  setDataSource(getDataSource(e.target.value));
};

const validateJsonFile = (file, onSuccess, onInvalidJson) => {
  const reader = new FileReader(); // eslint-disable-line no-undef
  reader.onloadend = () => {
    const base64 = reader.result.replace(/^data:.+\/.+;base64,/, '');
    const decoded = Buffer.from(base64, 'base64').toString('utf8');
    try {
      JSON.parse(decoded);
      onSuccess();
    } catch {
      onInvalidJson();
    }
  };
  reader.readAsDataURL(file);
};

const onFileSelected = (files, dataSource, setMessage, setUploadFile, setInputKey) => {
  setMessage(null);
  if (!files || files.length <= 0) return;
  if (files[0].size > whDataSizeLimit) {
    setMessage('The file size must be under 1MB');
    setInputKey(Date.now());
    return;
  }
  if (dataSource.dataType !== 'JSON') {
    setUploadFile(files[0]);
    return;
  }
  validateJsonFile(files[0], () => setUploadFile(files[0]), () => {
    setMessage('The file is not valid JSON.');
    setInputKey(Date.now());
  });
};

const renderOptions = () => (
  dataSources.map((dataSource) => (
    <option key={dataSource.source} value={dataSource.source}>{dataSource.displayName}</option>
  ))
);

const renderButton = (onDisplayUploadModal) => (
  <button
    type="button"
    color="primary"
    className={
      [styles.uploadButton, `submit btn ${scoringReportStyles.btnPrimary} mb-2`].join(' ')
    }
    onClick={onDisplayUploadModal}
  >
    Upload Banking / Bureau Data
    <FontAwesomeIcon icon="upload" className={styles.uploadDataSourceIcon} />
  </button>
);

function UploadDataSource(props) {
  const [inputKey, setInputKey] = useState(Date.now());
  const [uploadFile, setUploadFile] = useState(false);
  const [dataSource, setDataSource] = useState(dataSources[0]);
  const [message, setMessage] = useState('');

  if (!props.isUploadOpen) {
    return renderButton(props.onDisplayUploadModal);
  }

  const clearSelectedFile = () => {
    setMessage(null);
    setUploadFile(null);
    setInputKey(Date.now());
  };

  const onClose = () => {
    clearSelectedFile();
    props.onCancel();
  };

  return (
    <>
      { renderButton(props.onDisplayUploadModal) }
      <Modal
        className={styles.uploadModal}
        isOpen={props.isUploadOpen}
        toggle={onClose}
      >
        <ModalHeader toggle={onClose}>
          Upload Data Source
        </ModalHeader>
        <ModalBody>
          Upload banking transactions, bureau report or other
          attachments to include in the scoring request.
          An updated version of the client report will
          appear once the data source has been processed.
        </ModalBody>
        <ModalBody>
          <form>
            <select
              className="{styles.sourceSelect}"
              value={dataSource.source}
              onChange={(e) => onDataSourceChanged(e, setDataSource, clearSelectedFile)}
              disabled={props.uploadResult}
            >
              {renderOptions()}
            </select>
            <input
              type="file"
              key={inputKey}
              accept={dataSource.allowedFileTypes}
              onChange={(e) => onFileSelected(
                e.target.files,
                dataSource,
                setMessage,
                setUploadFile,
                setInputKey,
              )}
              className={styles.chooseFileInput}
              disabled={props.uploadResult}
            />
          </form>
          { renderResult(props.uploadResult, message) }
        </ModalBody>
        <ModalFooter>
          {
            !props.uploadResult ? (
              <Button color="primary" onClick={() => props.onSubmit(dataSource, uploadFile)} disabled={!uploadFile}>Upload</Button>
            ) : null
          }
          {' '}
          <Button color="secondary" onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default withFeatureFlag(UploadDataSource, 'uploadDataSource');
