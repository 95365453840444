import React from 'react';

const renderMonthlyAmount = (monthlyAmount) => (
  <span className="font-weight-bold">
    $
    {Math.round(monthlyAmount)}
    <wbr />
    /month
  </span>
);

export default renderMonthlyAmount;
