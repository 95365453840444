import { enabledFeatures, nonConfigurableFeatures } from '../../features';
import { PARENT_NAV_SETTINGS, getNavOrder } from '../../config/sitePaths';

const navItemsSorter = (a, b) => {
  if (a.parentNavNode > b.parentNavNode) return -1;
  if (a.parentNavNode < b.parentNavNode) return 1;
  if (!a.parentNavNode && b.parentNavNode) return -1;
  if (a.parentNavNode && !b.parentNavNode) return 1;
  if (a.parentNavNode > b.parentNavNode) return 1;
  if (a.parentNavNode < b.parentNavNode) return -1;
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export default (enabledFeaturesAny, displayLocation) => {
  const navItems = enabledFeatures(enabledFeaturesAny).sort(navItemsSorter)
    .concat(nonConfigurableFeatures).concat(Object.values(PARENT_NAV_SETTINGS));

  /* eslint-disable no-param-reassign */
  navItems.forEach((navItem) => {
    navItem.items = navItems.filter((x) => x.parentNavNode === navItem.name)
      .filter((x) => !x.doNotRenderInHeader);
    navItem.type = navItem.items.length > 1 ? 'multiple' : 'single';
  });
  /* eslint-enable no-param-reassign */

  const displayLocationItems = navItems.filter((x) => x.parentNavNode === displayLocation)
    .map((displayItem) => {
      /*
        Consolidate the tree; nodes with a single child are merged with its child
      */
      if (displayItem.items && displayItem.items.length === 1) {
        const arrayItem = displayItem.items[0];
        return {
          ...arrayItem,
          ...displayItem,
          navOrder: getNavOrder(displayItem.name, displayLocation),
        };
      }
      if (displayItem.items && displayItem.items.length === 0) {
        return null;
      }

      return { ...displayItem, navOrder: getNavOrder(displayItem.name, displayLocation) };
    }).filter((x) => x);

  const tree = Object.fromEntries(displayLocationItems.map((item) => [item.label, item]));
  return tree;
};
