import React from 'react';

import formatCurrency from '../../../../utils/formatCurrency';

import styles from './ReportIncomeSummarySection.module.scss';

const incomeSummaryColumns = ['accountId', 'description', 'subcategory', 'count', 'sum'];

export default function ({ incomeSummary }) {
  if (!incomeSummary.length) {
    return null;
  }

  return (
    <>
      <h5 className={styles.title}>90 Day income Summary</h5>
      <div className="_lr-hide">
        <table className={[styles.table, styles.headerTable].join(' ')}>
          <tbody>
            <tr className={styles.header}>
              {
                incomeSummaryColumns.map((column) => (
                  <td type={column} key={column}>{column}</td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={[styles.tableWrapper, '_lr-hide'].join(' ')}>
        <table className={styles.table}>
          <tbody>
            {
              incomeSummary.map((rows) => (
                <React.Fragment key={rows.description + rows.subcategory}>
                  <tr key={`${rows.description + rows.subcategory}-tablerow`}>
                    {
                      incomeSummaryColumns.map((column) => {
                        let columnValue = rows[column];
                        if (typeof columnValue === 'undefined' || columnValue === null) {
                          columnValue = '';
                        }

                        if (columnValue !== null && column.match(/sum/ig)) {
                          columnValue = `$${formatCurrency(Number(columnValue), true)}`;
                        }

                        return (
                          <td type={column} key={`${rows.sum}-${column}`}>{columnValue}</td>
                        );
                      })
                    }
                  </tr>
                </React.Fragment>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  );
}
