export default [
  {
    field: 'VIN',
    method: 'isLength',
    validWhen: true,
    message: 'VIN must be 17 characters long.',
    options: [{ min: 17, max: 17 }],
    skipIfEmpty: true,
  },
  {
    field: 'odometer',
    method: 'isFloat',
    validWhen: true,
    message: 'Odometer must be a valid number.',
    skipIfEmpty: true,
    options: [{ min: 0 }],
  },
  {
    field: 'value',
    method: 'isFloat',
    validWhen: true,
    message: 'Value must be a valid number.',
    skipIfEmpty: true,
    options: [{ min: 0 }],
  },
  {
    field: 'appraisedValue',
    method: 'isFloat',
    validWhen: true,
    message: 'Appraised value must be a valid number.',
    skipIfEmpty: true,
    options: [{ min: 0 }],
  },
  {
    fields: ['odometer', 'odometerUnits'],
    method: (fields, inputFormState) => fields.every((field) => inputFormState[field]) || fields.every((field) => !inputFormState[field]),
    validWhen: true,
    message: 'Both odometer and units must be specified',
  },
];
