import React from 'react';
import { Route } from 'react-router-dom';

class RouteWithProps extends React.Component {
  propsWrapper = {};

  component = null;

  componentRef = React.createRef();

  // eslint-disable-next-line react/no-unused-class-component-methods
  getWrappedComponent() {
    return this.componentRef.current;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getWrappedComponentState() {
    return this.componentRef.current.state;
  }

  generateRenderFunction() {
    const { propsWrapper, componentRef } = this;
    // eslint-disable-next-line react/no-unstable-nested-components,react/jsx-props-no-spreading
    const withoutRender = (props) => <this.component {...propsWrapper} {...props} />;
    // eslint-disable-next-line react/no-unstable-nested-components,react/jsx-props-no-spreading
    const withRender = (props) => <this.component ref={componentRef} {...propsWrapper} {...props} />;
    // Functional components cannot be given `refs` in render methods for react-router-dom
    if (!this.component.render) return withoutRender;
    return withRender;
  }

  render() {
    this.component = this.props.component;
    this.propsWrapper = {
      ...this.props,
      component: undefined,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...this.propsWrapper} render={this.generateRenderFunction(this.props.noRef)} />;
  }
}

export default RouteWithProps;
