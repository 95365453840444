import React from 'react';
import {
  Alert,
  CardBody,
} from 'reactstrap';

import BankingAnalysisSection from '../../../components/BankingAnalysisSection';
import ReportIncomeSummarySection from './ReportIncomeSummarySection';

import styles from './BankingInfoCard.module.scss';
import CollapsibleCard from '../CollapsibleCard';

const renderSkippedReason = (skippedReasonDetail) => (
  <div>
    <div>
      This step was skipped
      {
        skippedReasonDetail.timestamp ? ` on ${(new Date(skippedReasonDetail.timestamp)).toLocaleString()} ` : ' '
      }
      with the following reason:
    </div>
    <blockquote className={styles.quote}>
      { skippedReasonDetail.displayText }
    </blockquote>
  </div>
);

function AnalysisBody({
  bankingData,
  bankingStatus,
  features: {
    incomeSummary,
  },
  skippedReason,
}) {
  if (skippedReason) {
    return (
      <CardBody>
        { renderSkippedReason(bankingStatus.skipped) }
      </CardBody>
    );
  }

  return (
    <CardBody>
      {
        [].concat(bankingStatus?.alerts).filter((x) => !!x).map((a) => (
          <Alert key={a.at} color={a.level}>
            <span className={styles.alertAt}>
              { new Date(a.at).toLocaleString() }
            </span>
            { a.message }
          </Alert>
        ))
      }
      {
        incomeSummary && bankingData?.incomeSummary && (<ReportIncomeSummarySection incomeSummary={bankingData.incomeSummary} />)
      }
      {
        bankingData?.bankingAnalysis && (<BankingAnalysisSection analysis={bankingData.bankingAnalysis} bankingAttributes={bankingData.bankingAttributes} />)
      }
    </CardBody>
  );
}

const cardShouldDisplay = (applicant, bankingData, bankingStatus, skippedReason, missingBankingData) => {
  if (!applicant || (missingBankingData && !skippedReason)) return false;
  if (bankingData) return true;
  return !!(bankingStatus.accountLogin || bankingStatus.skipped);
};

export default function (props) {
  const {
    applicant,
    bankingData,
    bankingStatus,
    features,
  } = props;

  const skippedReason = !!(bankingStatus && bankingStatus.skipped && bankingStatus.skipped.displayText);
  const missingBankingData = !bankingData?.bankingAnalysis || (!bankingData.accounts && Object.keys(bankingData.bankingAnalysis).length === 0);

  if (!cardShouldDisplay(applicant, bankingData, bankingStatus, skippedReason, missingBankingData)) return null;

  return (
    <CollapsibleCard title="Banking Summary" collapseKey="bankingSummary">
      <AnalysisBody
        bankingData={bankingData}
        bankingStatus={bankingStatus}
        features={features}
        skippedReason={skippedReason}
      />
    </CollapsibleCard>
  );
}
