import React from 'react';
import ReactJson from 'react-json-view';
import { formatFullTimestamp } from '../../../utils/format-date';

import styles from './CallbackHistoryView.module.scss';

function SafeRenderJSON(props) {
  const { src, name, collapsed } = props;
  if (typeof src !== 'object') return null;
  return (
    <ReactJson
      src={src}
      name={name}
      collapsed={collapsed}
    />
  );
}

export default function CallbackHistoryItemView(props) {
  const { detail } = props;
  return (
    <div key={detail.id} className={styles.callbackHistoryItem}>
      <div className={styles.eventHeader}>
        <div>
          <div className={styles.eventTimestamp}>{ formatFullTimestamp(detail.startAt) }</div>
        </div>
        <div>
          <span className={styles.eventHeaderItem}>
            { detail.response?.status }
          </span>
          <span className={styles.eventHeaderItem}>
            { detail.response?.statusText }
          </span>
          <span className={styles.eventHeaderItem}>
            (
            { detail.duration }
            ms)
          </span>
        </div>
      </div>
      <div className={styles.eventDetails}>
        <div className={styles.callbackUrl}>
          POST&nbsp;
          { detail.clientCallbackUrl }
        </div>
        <SafeRenderJSON
          src={detail.headers}
          name="Request Headers"
          collapsed="true"
        />
        <SafeRenderJSON
          src={detail.report}
          name="Request Body"
          collapsed="true"
        />
        <SafeRenderJSON
          src={detail.response?.headers}
          name="Response Headers"
          collapsed="true"
        />
        <SafeRenderJSON
          src={detail.response?.data}
          name="Response Body"
          collapsed="true"
        />
        {
          detail.error && (
            <div className={styles.error}>
              { detail.error }
            </div>
          )
        }
      </div>
    </div>
  );
}
