import validator from 'validator';

export default (config) => {
  const [tag, callback] = config;

  if (typeof tag === 'undefined' || typeof callback?.url === 'undefined') {
    return false;
  }

  return validator.isURL(callback.url, {
    require_protocol: true,
    require_valid_protocol: true,
    protocols: ['https'],
  }) && !validator.isEmpty(tag);
};
