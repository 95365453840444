const narratives = {
  C: { description: 'Registered lien', derogatory: false },
  AB: { description: 'Abuse/automated banking machine', derogatory: true },
  AC: { description: 'Account closed/rating non derogatory', derogatory: false },
  AI: { description: 'Application for interest relief in progress', derogatory: false },
  AM: { description: 'Some type of arrangements made to pay account', derogatory: false },
  AP: { description: 'Arrangements paid', derogatory: false },
  AU: { description: 'Authorized user', derogatory: false },
  AV: { description: 'First payment never received', derogatory: true },
  BC: { description: 'Conditional discharge/conditions applied to discharge', derogatory: true },
  BD: { description: 'Bankruptcy discharged', derogatory: true },
  BF: { description: 'Bridge financing/temporary financing', derogatory: false },
  BK: { description: 'Consumer bankrupt', derogatory: true },
  BM: { description: 'Business loan/loan for a business purpose opened under consumer name', derogatory: false },
  BN: { description: 'Balance not available from credit grantor', derogatory: false },
  BP: { description: 'Included in proposal', derogatory: true },
  BR: { description: 'Account included in bankruptcy', derogatory: true },
  BS: { description: 'Overlimit/returned payment', derogatory: false },
  CA: { description: 'Cancelled account/non-derog rating', derogatory: false },
  CB: { description: 'Contract breach/contract wasn&apos;t met', derogatory: true },
  CC: { description: 'Credit counselling', derogatory: true },
  CG: { description: 'Account cancelled by credit grantor with derogatory rating', derogatory: true },
  CL: { description: 'Card lost or stolen', derogatory: false },
  CO: { description: 'Carry on business/filed on a consumer operating as a business', derogatory: false },
  CP: { description: 'Co-signer paid or paying/borrower not paying', derogatory: true },
  CR: { description: 'Closed by credit grantor', derogatory: true },
  CS: { description: 'Construction lien/lien in default, consumer made a party to claim', derogatory: true },
  CY: { description: 'Credit line closed', derogatory: false },
  CZ: { description: 'Closed at consumer&apos;s request', derogatory: false },
  DC: { description: 'Deceased', derogatory: false },
  DD: { description: 'Discharged', derogatory: false },
  DE: { description: 'Demand loan', derogatory: false },
  DF: { description: 'OPD (orderly payment of debt) in default/breach of agreement', derogatory: true },
  DL: { description: 'Delinquent letter/letters sent by bureau', derogatory: true },
  DP: { description: 'Partial discontinuance/partial settlement filed', derogatory: true },
  DS: { description: 'Disputed account', derogatory: false },
  DT: { description: 'Debt consolidation', derogatory: false },
  EE: { description: 'Empty envelope deposit', derogatory: true },
  FA: { description: 'Family support', derogatory: false },
  FC: { description: 'Foreclosure/mortgage payments default', derogatory: true },
  FD: { description: 'Fraud/account used by unauthorized person', derogatory: false },
  FI: { description: 'Interim financing', derogatory: false },
  FO: { description: 'Forgiven account', derogatory: false },
  GA: { description: 'Garnishee/wages garnisheed for payment', derogatory: true },
  GF: { description: 'Good fluctuation/banking account fluctuates, considered a rating of acct', derogatory: false },
  GP: { description: 'Claim paid by government', derogatory: true },
  GU: { description: 'Guarantor/additional security on account', derogatory: false },
  HC: { description: 'Indicates high credit', derogatory: false },
  HS: { description: 'Has co-signer', derogatory: false },
  IC: { description: 'Internal collection department handling', derogatory: true },
  IF: { description: 'NSF/non-sufficient funds', derogatory: true },
  IG: { description: 'Interest relief – paid by government', derogatory: false },
  IN: { description: 'Inactive account', derogatory: false },
  IO: { description: 'Account included in OPD arrangement', derogatory: true },
  IR: { description: 'Involuntary surrender redeemed', derogatory: true },
  IS: { description: 'Is co-signer on account', derogatory: false },
  IV: { description: 'Included in voluntary deposit', derogatory: false },
  JP: { description: 'Joint account', derogatory: false },
  JR: { description: 'Joint contractual responsibility', derogatory: false },
  JT: { description: 'Joint account', derogatory: false },
  KI: { description: 'Kiting', derogatory: true },
  LA: { description: 'Loss amount after repossession deficiency', derogatory: true },
  LC: { description: 'Line of credit', derogatory: false },
  LE: { description: 'Legal/account taken to court', derogatory: true },
  LF: { description: 'Loan forgiven by creditor', derogatory: false },
  LH: { description: 'Lease and charges', derogatory: false },
  LP: { description: 'Loan in default – paid by guarantor', derogatory: true },
  LS: { description: 'Lease', derogatory: false },
  LU: { description: 'Student line of credit', derogatory: false },
  MG: { description: 'Mortgage', derogatory: false },
  NB: { description: 'No balance', derogatory: false },
  NC: { description: 'No further credit', derogatory: true },
  NF: { description: 'No NSF cheques', derogatory: false },
  NT: { description: 'Nothing further in court', derogatory: false },
  NU: { description: 'Not used/credit granted not used', derogatory: false },
  NW: { description: 'Renewed', derogatory: false },
  OB: { description: 'On behalf of account/authorization on someone else’s behalf', derogatory: false },
  OD: { description: 'Overdrawn/account in negative balance', derogatory: true },
  OP: { description: 'Overdraft protection', derogatory: false },
  OV: { description: 'Overdraft/account allowed negative balance', derogatory: false },
  PA: { description: 'Proposal accepted by creditors', derogatory: false },
  PB: { description: 'Proposal failed', derogatory: true },
  PD: { description: 'Paid', derogatory: false },
  PF: { description: 'Partial satisfaction of judgment', derogatory: true },
  PH: { description: 'Prescribed', derogatory: true },
  PI: { description: 'Paid by insurance', derogatory: false },
  PN: { description: 'Paid not rated', derogatory: false },
  PO: { description: 'Possession/return of security', derogatory: true },
  PP: { description: 'Pre-paid/paid before due date', derogatory: false },
  PS: { description: 'Proposal', derogatory: true },
  PU: { description: 'Paid out by third party', derogatory: false },
  PW: { description: 'Power of sale/default on mortgage payments, property seized', derogatory: true },
  PX: { description: 'Proposal annulled', derogatory: false },
  PY: { description: 'Payroll deduction', derogatory: false },
  PZ: { description: 'Proposal satisfied', derogatory: true },
  RD: { description: 'Repo paid by dealer', derogatory: true },
  RE: { description: 'Rental', derogatory: false },
  RM: { description: 'Repossession redeemed/security returned', derogatory: false },
  RN: { description: 'Rent arrears', derogatory: true },
  RP: { description: 'Repossession', derogatory: true },
  RR: { description: 'RRSP loan', derogatory: false },
  RW: { description: 'Account/loan rewritten', derogatory: false },
  RY: { description: 'Making partial monthly payment', derogatory: true },
  SA: { description: 'Satisfactory account status', derogatory: false },
  SB: { description: 'Secured', derogatory: false },
  SE: { description: 'Settled/arrangements made with creditor', derogatory: true },
  SF: { description: 'Satisfied', derogatory: true },
  SG: { description: 'Settlement agreement', derogatory: true },
  SH: { description: 'Sold account', derogatory: false },
  SI: { description: 'Single payment loan', derogatory: false },
  SK: { description: 'Skip account/unable to locate consumer', derogatory: true },
  SM: { description: 'Second mortgage', derogatory: false },
  SO: { description: 'Still owing/account not paid', derogatory: true },
  SS: { description: 'Services rendered', derogatory: false },
  ST: { description: 'Student loan', derogatory: false },
  SU: { description: 'Suspended/account not to be used until brought up to date', derogatory: true },
  TB: { description: 'To be discharged/notification of discharge date', derogatory: true },
  TC: { description: 'Third party collection/account turned over to collection agency', derogatory: true },
  TD: { description: 'Trustee discharged', derogatory: true },
  TR: { description: 'Account transferred', derogatory: false },
  TX: { description: 'Taxes/reason for action', derogatory: false },
  UA: { description: 'U.S. funds/type of currency', derogatory: false },
  UD: { description: 'Undesignated/type of account', derogatory: false },
  US: { description: 'Unsatisfactory', derogatory: true },
  VO: { description: 'Voluntary repossession by consumer', derogatory: true },
  WA: { description: 'Wage assignment', derogatory: false },
  WO: { description: 'Bad debt write-off', derogatory: true },
};

export default (code) => (
  (code in narratives)
    ? narratives[code]
    : { description: code || '', derogatory: false }
);
