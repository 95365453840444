export const WS_ENDPOINT = 'wss://appservices-ws-uat.trustscience.com/clientconsole';
export const IMAGE_URL = 'https://d3p56cllgt1gum.cloudfront.net';
export const COGNITO_DOMAIN = 'auth.console-uat.trustscience.com';
export const DOCS_URL = 'https://appservices-uat.trustscience.com/docs';
export const S3_BUCKET = 'application-services-static-websites';
export const ENVIRONMENT = 'uat';
export const LOGROCKET_APPID = '1uegt8/clientconsole';
export const FLOWBUILDER_URL = 'https://web2.flowbuilder.trustscience.com';
export const FLOWBUILDER_API_URL = 'https://ug84i61urg.execute-api.us-west-2.amazonaws.com/';
export const LOGOUT_URL = false;
export const CURRENT_ENVIRONMENT_NAME = 'UAT';
export const UAT_ENVIRONMENT_NAME = 'Fake UAT';
export const USE_CHATBOT_LOCAL_FETCH_STRATEGY = false;
