import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './AddNewAddressBox.module.scss';

export default function (props) {
  return (
    <button type="button" className={styles.newAddressBox} onClick={props.onAddClick}>
      <FontAwesomeIcon icon="plus" />
      Add Location
    </button>
  );
}
