import React from 'react';
import Modal from '../Modal';
import {
  TextInputField,
  LocationSelectField,
  GroupSelection,
} from './fields';
import normalizeEmail from '../../../utils/normalizeEmail';
import FlowbuilderRoleSelectField from './fields/FlowbuilderRoleSelectField';
import EnvironmentAccessField from './fields/EnvironmentAccessField';
import { useEnabledFeatures } from '../../../hooks/useEnabledFeatures';

export default function MaintainUserModal(props) {
  const { useUatProdFlags } = useEnabledFeatures('prod');

  const {
    buttons,
  } = props;
  return (
    <Modal
      isOpen
      title="User Properties"
      buttons={buttons}
    >
      <TextInputField
        attributeKey="given_name"
        displayName="First Name"
        action={props.action}
        onAttributeChanged={props.onCognitoAttributeChanged}
        onBlurEvent={props.onBlurEvent}
        user={props.user}
        validation={props.validation}
      />
      <TextInputField
        attributeKey="family_name"
        displayName="Last Name"
        action={props.action}
        onAttributeChanged={props.onCognitoAttributeChanged}
        onBlurEvent={props.onBlurEvent}
        user={props.user}
        validation={props.validation}
      />
      <TextInputField
        attributeKey="email"
        displayName="Email"
        action={props.action}
        onAttributeChanged={props.onCognitoAttributeChanged}
        onBlurEvent={props.onBlurEvent}
        user={props.user}
        validation={props.validation}
        preventUpdate
        helpText="joe@email.com"
        normalizer={normalizeEmail}
      />
      <TextInputField
        attributeKey="phone_number"
        displayName="Phone"
        action={props.action}
        onAttributeChanged={props.onCognitoAttributeChanged}
        onBlurEvent={props.onBlurEvent}
        user={props.user}
        validation={props.validation}
        helpText="###-###-####"
      />
      <LocationSelectField
        attributeKey="homeLocationId"
        displayName="Home Location"
        locations={props.locations}
        onUserAttributeChanged={props.onUserAttributeChanged}
        userAttributes={props.userAttributes}
      />
      <LocationSelectField
        attributeKey="preferredLocationId"
        displayName="Preferred Location"
        locations={props.locations}
        onUserAttributeChanged={props.onUserAttributeChanged}
        userAttributes={props.userAttributes}
      />
      <FlowbuilderRoleSelectField
        onChange={props.onFlowbuilderRoleChanged}
        canAddEditor={props.canAddFlowbuilderEditor}
        flowBuilderRole={props.flowBuilderRole}
        isFlowbuilderEnabled={props.isFlowbuilderEnabled}
      />
      {useUatProdFlags && (
      <EnvironmentAccessField
        userAttributes={props.userAttributes}
        onUserAttributeChanged={props.onUserAttributeChanged}
      />
      )}
      <GroupSelection
        groups={props.groups}
        onGroupsChanged={props.onGroupsChanged}
        user={props.user}
        isAdminLogin={props.isAdminLogin}
      />
    </Modal>
  );
}
