import api from '../services/api';

const uploadFileToAPI = async (uploadFile, allowableFileTypes, apiMethod, onSuccess, onError) => {
  let fileTypesStr = '';
  allowableFileTypes.forEach((type, index) => {
    if (index === 0) {
      fileTypesStr += type;
      return;
    }
    fileTypesStr += `|${type}`;
  });
  const regex = new RegExp(`.(${fileTypesStr})`);
  if (!regex.test(uploadFile.name)) {
    this.setState({ uploadError: `The file selected is not one of the following allowableFileTypes: ${allowableFileTypes}` });
    return;
  }

  const reader = new FileReader();
  reader.onloadend = async () => {
    const body = {
      filename: uploadFile.name,
      size: uploadFile.size,
      type: uploadFile.type,
      csvContents: reader.result,
    };

    try {
      const response = await api(apiMethod, body);
      onSuccess(response);
    } catch (error) {
      const message = 'Error encountered while uploading file.';
      console.error(message, error);
      onError(message);
    }
  };
  reader.readAsText(uploadFile);
};

export default uploadFileToAPI;
