import { useCallback, useEffect, useRef } from 'react';

import { useChat } from '../../../hooks/useChat';

const chatInputHeightEstimation = 220;

export const useChatMessages = () => {
  const chatListRef = useRef(null);
  const { history } = useChat();

  const scrollToBottom = useCallback(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTo?.({
        top: chatListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const computeCardHeight = () => {
      if (chatListRef.current) {
        const cardTop = chatListRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const cardHeight = windowHeight - cardTop - chatInputHeightEstimation;
        chatListRef.current.style.height = `${cardHeight}px`;
        chatListRef.current.style.minHeight = '8rem';
      }
    };

    computeCardHeight();
    window.addEventListener('resize', computeCardHeight);

    return () => {
      window.removeEventListener('resize', computeCardHeight);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [history, scrollToBottom]);

  return {
    chatListRef,
  };
};

export default useChatMessages;
