import he from 'he';

const months = [
  '-',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatTime = (time) => (
  `${[time.slice(0, 2), time.slice(2, 4)].join(':')} ET`
);

export const decodeString = (name) => {
  if (!name) return '';
  if (typeof name !== 'string') return name.toString();
  return he.decode(name);
};

export const formatGivenNames = ({ FirstName, MiddleName }) => (
  MiddleName ? `${FirstName}/${MiddleName}` : FirstName
);

export const formatCurrency = (value) => {
  if (!value) return '$0';
  return `$${Math.round(value).toLocaleString()}`;
};

export const parseDate = (rawDate) => {
  const date = (rawDate instanceof String) ? rawDate : rawDate.toString();
  return [
    date.slice(0, 4),
    date.slice(4, 6),
    date.slice(-2),
  ];
};

export const formatAsDateTime = (date, time) => (
  `${parseDate(date).join('-')} ${formatTime(time || '')}`
);

export const formatAsTuDate = (date) => {
  if (!date) return '';
  const [year, month, day] = parseDate(date);
  return `${day}${months[parseInt(month, 10)]}${year}`;
};

export const formatMonthYear = (date) => {
  if (!date) return '';
  const [year, month] = parseDate(date);
  return `${months[parseInt(month, 10)]}${year}`;
};

export const formatCountAndDate = (list, dateProperty) => {
  const count = list.length;
  if (count < 1) return '0-0000000';
  if (!list[0][dateProperty]) return count;
  return `${count}-${formatMonthYear(list[0][dateProperty])}`;
};
