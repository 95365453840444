import React from 'react';
import LoanInformationView from './LoanInformation.view';
import InputGroupClass from '../InputGroupClass';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import FormValidator from '../../../utils/FormValidator';

const formGroupFieldNames = [
  'loanDate',
  'principalAmount',
  'feeAmount',
  'customerId',
  'loanId',
  'downPaymentAmount',
];

const canSkipEnteringPrincipalAmount = ({ isPreapproval, requirePrincipalAmount }) => (
  isPreapproval || !requirePrincipalAmount
);

const buildBlockSubFieldDependency = ({ block, name, label }) => (
  block
    .filter(({ required }) => !!required)
    .map((subField) => ({
      field: `custom_${subField.name}`,
      method: (field, form) => (!form[`custom_${name}`] || !!form[`custom_${subField.name}`]),
      validWhen: true,
      message: `Required when ${label} block is enabled.`,
    }))
);

const buildBlockExclusiveWithDependency = ({ exclusiveWith, name }, customLoanFields) => {
  if (!exclusiveWith) return false;
  const ruleFieldNames = [].concat(exclusiveWith);
  if (!ruleFieldNames?.length) return false;
  const exclusiveWithFields = customLoanFields.filter((f) => ruleFieldNames.includes(f.name));
  if (!exclusiveWithFields?.length) {
    console.error(`Invalid custom field config. Unable to find custom ${exclusiveWith} fields.`);
  }
  const ruleFieldLabels = exclusiveWithFields.map((f) => f.label);
  return {
    field: `custom_${name}`,
    method: (value, form) => (
      !!value
      && ruleFieldNames.reduce((r, f) => (r || !!form[`custom_${f}`]), false)
    ),
    validWhen: false,
    message: `Cannot be enabled when ${ruleFieldLabels.join(', ')} ${ruleFieldNames.length > 1 ? 'are' : 'is'} set.`,
  };
};

class LoanInformationContainer extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
    super.skipValidationOnGroupIfEmpty = false;

    this.togglePaymentsPerYearToolTip = this.togglePaymentsPerYearToolTip.bind(this);

    let filteredConfig = validationConfig;
    if (canSkipEnteringPrincipalAmount(props)) {
      filteredConfig = filteredConfig.filter(
        (v) => (!(v.field === 'principalAmount' && v.method === 'isEmpty')),
      );
    }

    const customLoanFields = [].concat(this.props.customLoanFields || []);
    const customFieldValidationConfig = customLoanFields
      .filter(({ required }) => required)
      .map((field) => ({
        field: `custom_${field.name}`,
        method: 'isEmpty',
        validWhen: false,
        message: `${field.label} is required.`,
      })).concat(
        ...customLoanFields
          .filter(({ type }) => type === 'block')
          .map((f) => ([
            buildBlockExclusiveWithDependency(f, customLoanFields),
            ...buildBlockSubFieldDependency(f),
          ]).filter((r) => !!r)),
      );
    this.validator = new FormValidator(filteredConfig.concat(customFieldValidationConfig));
  }

  togglePaymentsPerYearToolTip() {
    this.setState((prevState) => (
      {
        paymentsPerYearToolTipOpen: !prevState.paymentsPerYearToolTipOpen,
      }
    ));
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <LoanInformationView
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...this.props}
              canSkipEnteringPrincipalAmount={canSkipEnteringPrincipalAmount(this.props)}
              currentState={this.state}
              onTogglePaymentsPerYear={this.togglePaymentsPerYearToolTip}
              onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              previousState={previousState}
            />
          );
        }}
      </FormContext.Consumer>
    );
  }
}
export default LoanInformationContainer;
