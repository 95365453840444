import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useModal } from '../../../hooks/useModal';
import capitalize from '../../../utils/capitalize';
import Markdown from '../../../components/Markdown';
import api from '../../../services/api';
import styles from './RuleCard.module.scss';

function RuleCard({ rule, updateRuleState }) {
  const {
    id,
    name,
    version,
    summary,
    chatId,
    enabled,
    managed,
    hide,
  } = rule;

  const { showModal, hideModal } = useModal();

  if (hide) {
    return null;
  }

  const disableEnableText = enabled ? 'disable' : 'enable';
  const displayName = `${name}:${version}`;

  const deleteRule = async () => {
    updateRuleState({
      ...rule,
      hide: true,
    });
    hideModal();
    const result = await api('ManageGeneratedRules', {
      action: 'delete',
      ruleId: id,
    });
    if (!result.success) {
      showModal({
        header: 'Delete rule failed',
        body: 'Please try again later.',
      });
      console.error(`Delete Rule failed: ${result.error}`);
      // Restore state
      updateRuleState({
        ...rule,
        hide: false,
      });
    }
  };

  const enableDisableRule = async () => {
    updateRuleState({
      ...rule,
      enabled: !enabled,
    });
    hideModal();
    let result;
    if (enabled) {
      result = await api('ManageGeneratedRules', {
        action: 'disable',
        ruleId: id,
      });
    } else {
      result = await api('ManageGeneratedRules', {
        action: 'enable',
        ruleId: id,
      });
    }
    if (!result.success) {
      showModal({
        header: `${capitalize(disableEnableText)} rule failed`,
        body: 'Please try again later.',
      });
      console.error(`Enable/Disable rule failed: ${result.error}`);
      // Restore state
      updateRuleState({
        ...rule,
        enabled,
      });
    }
  };

  return (
    <div className="card w-75 my-2">
      <div className="card-body">
        <div className="row">
          <div className="col-8 col-lg-10">
            <div>
              <h3>
                {displayName}
                {managed && (
                  ' (TS Managed)'
                )}
              </h3>
            </div>
            <div>
              <Markdown>
                {summary}
              </Markdown>
            </div>
          </div>
          <div className="col-4 col-lg-2 h-auto d-flex align-items-center">
            <div>
              {!managed && (
              <div className={styles.sourceRow}>
                <a className="p-0 ml-2" href={`/rule-builder/${chatId}`} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon="external-link-alt" size="sm" className="mr-2" />
                  <span className={styles.sourceText}>Source</span>
                </a>
              </div>
              )}
              <div className="custom-control custom-switch align-middle">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={enabled}
                  readOnly
                  id={`customSwitch-${id}`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    showModal({
                      header: `${capitalize(disableEnableText)} Rule`,
                      body: (
                        <p>
                          {'Are you sure you want to '}
                          {disableEnableText}
                          {' '}
                          {displayName}
                          ?
                          <br />
                          {!enabled && <b>Only one rule with the same name can be enabled, all other versions will be disabled.</b>}
                        </p>
                      ),
                      buttons: [
                        {
                          color: 'danger',
                          text: `Yes, ${disableEnableText}`,
                          onClick: enableDisableRule,
                        },
                        {
                          color: 'secondary',
                          text: 'Cancel',
                          onClick: () => {
                            hideModal();
                          },
                        },
                      ],
                    });
                  }}
                />
                <label
                  className={`custom-control-label align-middle ${styles.enableDisableLabel}`}
                  htmlFor={`customSwitch-${id}`}
                >
                  { enabled ? 'Enabled' : 'Disabled'}
                </label>
              </div>
              <div>
                <button
                  className="btn text-danger ml-2 border-0 p-0 align-middle"
                  type="button"
                  onClick={() => {
                    showModal({
                      header: 'Delete Rule',
                      body: `Are you sure you want to delete ${displayName}?`,
                      buttons: [
                        {
                          color: 'danger',
                          text: 'Yes, delete',
                          onClick: deleteRule,
                        },
                        {
                          color: 'secondary',
                          text: 'Cancel',
                          onClick: () => {
                            hideModal();
                          },
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon="trash" size="sm" className="mr-2" />
                  <span className={styles.deleteText}>{' '}</span>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RuleCard;
