export const blockIsEmpty = (block) => Object
  .keys(block)
  .every((key) => !block[key] && block[key] !== 0);

export const nestedObjectOrArrayExists = (obj, level, ...rest) => {
  if (obj === undefined) {
    return false;
  }

  // eslint-disable-next-line eqeqeq, no-prototype-builtins
  if (rest.length === 0 && obj.hasOwnProperty(level)) {
    return true;
  }

  if (Array.isArray(obj) && Number.isInteger(level)) {
    if (typeof obj[level] !== 'undefined') {
      return true;
    }
  }

  return nestedObjectOrArrayExists(obj[level], ...rest);
};
