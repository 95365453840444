import buildAddressDataBlock from '../FormHelpers/buildAddressDataBlock';
import { sanitizePhoneNumber } from '../phoneNumberHelpers';

export default (employmentData, incomeData) => {
  let employer = {
    name: incomeData.name,
    address: buildAddressDataBlock(employmentData),
    phone: undefined,
  };

  let { isCurrentlyEmployed } = employmentData;

  if (employmentData.phoneNumber) {
    employer.phone = sanitizePhoneNumber(employmentData.phoneNumber);
  }

  if (!employer.name && !employer.address && !employer.phone) {
    employer = undefined;
  }

  let monthlyIncomeNet = incomeData.netIncome
    ? Number(incomeData.netIncome)
    : undefined;

  let monthlyIncomeGross = incomeData.grossIncome
    ? Number(incomeData.grossIncome)
    : undefined;

  if (incomeData.name && typeof isCurrentlyEmployed === 'undefined') {
    isCurrentlyEmployed = false;
  }

  if (isCurrentlyEmployed && typeof monthlyIncomeNet === 'undefined' && typeof monthlyIncomeGross === 'undefined') {
    monthlyIncomeNet = 0;
    monthlyIncomeGross = 0;
  }

  const employmentMonthCount = employmentData.durationAtEmployer
    ? Number(employmentData.durationAtEmployer)
    : undefined;

  const paymentsPerYear = incomeData.paymentsPerYear
    ? Number(incomeData.paymentsPerYear)
    : undefined;

  const employmentDataBlock = {
    employer,
    jobTitle: employmentData.jobTitle,
    monthlyIncomeNet,
    monthlyIncomeGross,
    employmentMonthCount,
    paymentsPerYear,
    employmentType: employmentData.employmentType,
  };

  const employmentBlockIsEmpty = Object.keys(employmentDataBlock)
    .find((info) => typeof employmentDataBlock[info] !== 'undefined') === undefined;

  if (employmentBlockIsEmpty) {
    return undefined;
  }

  return { ...employmentDataBlock, isCurrentlyEmployed };
};
