import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
} from 'reactstrap';
import { formatLongDateTime } from '../../../utils/format-date';
import Loader from '../../../components/Loader';
import promotionPath from '../promotion-path';
import ProductConfiguration from '../ProductConfiguration';

import styles from './DeploymentDetails.module.scss';

export default function DeploymentDetailsCardView(props) {
  const {
    environmentId,
    environments,
    deployment,
    deployment: {
      branchName,
      commitId,
      status,
      step,
      createTime,
      updateTime,
      name,
      projectName,
      projectId,
      pu: usedSlots,
    },
    prodFlowConfig,
    uatFlowConfig,
    uatOtherProducts,
    prodOtherProducts,
    prodConfigurations,
    uatConfigurations,
    getDeploymentsByCommit,
    onCreateDeployment,
    onDeploymentUpdated,
    isAnyEditModeActive,
    onEditModeChange,
    isLoadingFlows,
    flows,
  } = props;
  const promotionTarget = promotionPath[environmentId];
  const nextEnvironment = environments.find((e) => e.id === promotionTarget?.id);
  const otherDeployments = getDeploymentsByCommit(projectId, commitId);

  const renderOtherPromotions = () => {
    const activeDeploymentInOtherEnvs = otherDeployments
      .filter((d) => d.environmentId > environmentId)
      .filter((d) => d.status !== 'Removed');
    if (!activeDeploymentInOtherEnvs.length) return null;
    return (
      <div className="p-2">
        Active deployments in&nbsp;
        { activeDeploymentInOtherEnvs.map((d) => d.environmentName).join(', ') }
      </div>
    );
  };

  const renderPromoteButton = () => {
    if (!nextEnvironment) return null;
    const hasActiveDeploymentInTargetEnv = !!otherDeployments
      .filter((d) => d.environmentId === promotionTarget?.id)
      .filter((d) => d.status !== 'Removed')
      .length;
    const isDisabled = nextEnvironment.availableSlots <= 0
      || status !== 'Running'
      || hasActiveDeploymentInTargetEnv;
    return (
      <Button
        onClick={() => onCreateDeployment(promotionTarget, deployment)}
        disabled={isDisabled}
        color="success"
      >
        Promote to&nbsp;
        { promotionTarget.name }
      </Button>
    );
  };

  const renderProductConfigurations = () => {
    if (isLoadingFlows) {
      return (
        <div className={styles.loaderSpacing}>
          <Loader />
        </div>
      );
    }
    return (
      <div className="d-flex flex-column justify-content-around mt-3">
        <ProductConfiguration
          deployment={deployment}
          environmentId={environmentId}
          trustScienceConfigEnv="Production"
          flowConfig={prodFlowConfig}
          otherProducts={prodOtherProducts}
          productConfigurations={prodConfigurations}
          onDeploymentUpdated={onDeploymentUpdated}
          isAnyEditModeActive={isAnyEditModeActive}
          onEditModeChange={onEditModeChange}
          flows={flows}
        />
        <ProductConfiguration
          deployment={deployment}
          environmentId={environmentId}
          trustScienceConfigEnv="UAT"
          flowConfig={uatFlowConfig}
          otherProducts={uatOtherProducts}
          productConfigurations={uatConfigurations}
          onDeploymentUpdated={onDeploymentUpdated}
          isAnyEditModeActive={isAnyEditModeActive}
          onEditModeChange={onEditModeChange}
          flows={flows}
        />
      </div>
    );
  };

  return (
    <Card className="w-75 m-auto">
      <CardTitle className={styles.deploymentDetailsTitle}>
        <h3>
          { name }
        </h3>
      </CardTitle>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <span className={styles.cardBodyLabel}>Project: </span>
              <span>{projectName}</span>
            </div>
            <div>
              <span className={styles.cardBodyLabel}>Branch: </span>
              <span>{branchName}</span>
            </div>
            <div>
              <span className={styles.cardBodyLabel}>Commit: </span>
              <span>{commitId}</span>
            </div>
            <div>
              <span className={styles.cardBodyLabel}>Slots: </span>
              <span>{usedSlots}</span>
            </div>
          </div>
          <div>
            <div>
              <span className={styles.cardBodyLabel}>Status: </span>
              <span>{status}</span>
            </div>
            {
              !!step && (
                <div>
                  <span className={styles.cardBodyLabel}>Step: </span>
                  <span>{step}</span>
                </div>
              )
            }
            <div>
              <span className={styles.cardBodyLabel}>Created: </span>
              <span>{formatLongDateTime(createTime)}</span>
            </div>
            <div>
              <span className={styles.cardBodyLabel}>Updated: </span>
              <span>{formatLongDateTime(updateTime)}</span>
            </div>
          </div>
        </div>
        { renderProductConfigurations() }
      </CardBody>
      <CardFooter className="d-flex flex-row justify-content-between">
        {renderPromoteButton()}
        {renderOtherPromotions()}
      </CardFooter>
    </Card>
  );
}
