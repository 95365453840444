import React from 'react';

import styles from './Column.module.scss';

export default function (props) {
  return (
    <aside className={styles.Column}>
      {props.children}
    </aside>
  );
}
