import React, { Component } from 'react';
import {
  Container,
  FormGroup,
  Label,
  Input,
  Tooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SCORING_USE_CASES from '../../../config/scoringRequestUseCases';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';
import CustomFields from './CustomFields';
import StatesAndProvincesOptions from '../StatesAndProvincesOptions';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

import styles from './LoanInformation.module.scss';

class LoanInformationView extends Component {
  renderAuto() {
    if (this.props.useCase !== SCORING_USE_CASES.AUTO) return null;
    const {
      currentState: {
        downPaymentAmountFieldInvalid,
        downPaymentAmountFieldValidationMessage,
      },
      onChange,
      previousState,
    } = this.props;
    return (
      <ValidatedTextInput
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...this.props}
        name="downPaymentAmount"
        id="downPaymentAmount"
        label="Down Payment"
        isCurrency
        valid={!downPaymentAmountFieldInvalid}
        validationMessage={downPaymentAmountFieldValidationMessage}
        value={previousState.downPaymentAmount || ''}
        onChange={onChange}
      />
    );
  }

  renderInstallment() {
    if (this.props.useCase !== SCORING_USE_CASES.INSTALLMENT) return null;
    const {
      currentState: {
        annualInterestRateFieldInvalid,
        paymentAmountFieldInvalid,
        paymentsPerYearFieldInvalid,
        paymentsPerYearToolTipOpen,
        totalNumberOfPaymentsFieldInvalid,
      },
      onChange,
      onTogglePaymentsPerYear,
      previousState,
    } = this.props;
    return (
      <>
        <FormGroup>
          <Label htmlFor="paymentAmount">Payment Amount</Label>
          <div className={styles.currencyLineWrapper}>
            <span className={styles.currencySymbol}>$</span>
            <SanitizedTextInput
              className={
                [
                  'short-width',
                  (paymentAmountFieldInvalid ? 'is-invalid' : ''),
                  styles.customShortWidthWithUnits,
                ].join(' ')
              }
              name="paymentAmount"
              id="paymentAmount"
              type="number"
              defaultValue={previousState.paymentAmount || ''}
              onChange={onChange}
            />
            <span className={styles.valueUnit}>for each payment</span>
          </div>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="annualInterestRate" className={styles.wideLabel}>
            Annual Interest Rate (%)
          </Label>
          <div className={styles.currencyLineWrapper}>
            <SanitizedTextInput
              className={`short-width ${annualInterestRateFieldInvalid ? 'is-invalid' : ' short-width'}`}
              name="annualInterestRate"
              id="annualInterestRate"
              type="number"
              maxLength="2"
              defaultValue={previousState.annualInterestRate || ''}
              onChange={onChange}
            />
            <span className={styles.valueUnit}>%</span>
          </div>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="paymentsPerYear">Number of Payments per Year</Label>
          <span className={`input-units block ${styles.shortInputGroup}`}>
            <SanitizedTextInput
              className={`short-width ${paymentsPerYearFieldInvalid ? 'is-invalid' : ''}`}
              name="paymentsPerYear"
              id="paymentsPerYear"
              type="number"
              defaultValue={previousState.paymentsPerYear || ''}
              onChange={onChange}
            />
            <FontAwesomeIcon
              className={styles.toolTipIcon}
              icon="info-circle"
              id="loanPaymentsPerYearToolTip"
              tabIndex="-1"
            />
            <Tooltip
              placement="right"
              className={styles.tooltipBox}
              isOpen={paymentsPerYearToolTipOpen}
              target="loanPaymentsPerYearToolTip"
              toggle={onTogglePaymentsPerYear}
            >
              Enter 12 if payments are made monthly.
              <br />
              Enter 24 if payments are made semi-monthly.
              <br />
              Enter 26 if payments are made bi-weekly.
            </Tooltip>
          </span>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="totalNumberOfPayments">Total Number Of Payments</Label>
          <SanitizedTextInput
            className={`${totalNumberOfPaymentsFieldInvalid ? 'is-invalid' : ''}`}
            name="totalNumberOfPayments"
            id="totalNumberOfPayments"
            type="number"
            defaultValue={previousState.totalNumberOfPayments || ''}
            onChange={onChange}
          />
        </FormGroup>
      </>
    );
  }

  renderShortTermPayday() {
    if (this.props.useCase !== SCORING_USE_CASES.PAYDAY && this.props.useCase !== SCORING_USE_CASES.SHORTTERM) {
      return null;
    }
    const {
      currentState: {
        feeAmountFieldInvalid,
        feeAmountFieldValidationMessage,
      },
      previousState,
      onChange,
    } = this.props;

    return (
      <ValidatedTextInput
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...this.props}
        name="feeAmount"
        id="feeAmount"
        label="Fee Amount"
        highlightEssentialInputs
        isCurrency
        valid={!feeAmountFieldInvalid}
        validationMessage={feeAmountFieldValidationMessage}
        value={previousState.feeAmount || ''}
        onChange={onChange}
      />
    );
  }

  render() {
    const {
      canSkipEnteringPrincipalAmount,
      currentState: {
        loanDateFieldInvalid,
        loanDateFieldValidationMessage,
        principalAmountFieldInvalid,
        principalAmountFieldValidationMessage,
      },
      customLoanFields,
      enabledFeatures: {
        enableLocations,
      },
      hidePrincipalAmountField,
      onChange,
      previousState,
      region,
    } = this.props;
    return (
      <div className="deal-information-input-group">
        <h4>Loan</h4>
        <div className="form-group-wrapper">
          <Container>
            <ValidatedTextInput
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...this.props}
              name="loanDate"
              id="loanDate"
              label="Date"
              helpText="YYYY-MM-DD"
              required
              highlightEssentialInputs
              valid={!loanDateFieldInvalid}
              validationMessage={loanDateFieldValidationMessage}
              value={previousState.loanDate || ''}
              onChange={onChange}
            />
            {!hidePrincipalAmountField && (
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="principalAmount"
                id="principalAmount"
                label="Principal Amount"
                required={!canSkipEnteringPrincipalAmount}
                highlightEssentialInputs
                isCurrency
                valid={!principalAmountFieldInvalid}
                validationMessage={principalAmountFieldValidationMessage}
                value={previousState.principalAmount || ''}
                onChange={onChange}
              />
            )}
            {this.renderShortTermPayday()}
            {this.renderAuto()}
            {this.renderInstallment()}
            <FormGroup>
              <Label htmlFor="customerId">Customer ID</Label>
              <SanitizedTextInput
                name="customerId"
                id="customerId"
                type="text"
                defaultValue={previousState.customerId || ''}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="loanId">Loan ID</Label>
              <SanitizedTextInput
                name="loanId"
                id="loanId"
                type="text"
                defaultValue={previousState.loanId || ''}
                onChange={onChange}
              />
            </FormGroup>
            {
              !enableLocations
                ? (
                  <FormGroup>
                    <Label htmlFor="storeNumber">Store Number</Label>
                    <SanitizedTextInput
                      name="storeNumber"
                      id="storeNumber"
                      type="text"
                      defaultValue={previousState.storeNumber || ''}
                      onChange={onChange}
                    />
                    <Label>
                      Store
                      {/^can/i.test(region) ? ' Province' : ' State'}
                    </Label>
                    <Input
                      type="select"
                      name="storeState"
                      id="storeState"
                      onChange={onChange}
                      defaultValue={previousState.storeState}
                    >
                      <StatesAndProvincesOptions region={region} />
                    </Input>
                  </FormGroup>
                ) : null
            }
            <CustomFields
              customLoanFields={customLoanFields}
              previousState={previousState}
              state={this.props.currentState}
              onChange={onChange}
            />
          </Container>
        </div>
      </div>
    );
  }
}

export default LoanInformationView;
