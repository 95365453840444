import { useCallback, useEffect, useState } from 'react';

import { useChatApi } from '../api/useChatApi';
import { useChatContext } from '../context/ChatProvider/hooks/useChatContext';

import { useQuestion } from './useQuestion';
import api from '../../../services/api';
import { useModal } from '../../../hooks/useModal';

export const useChat = () => {
  const [isLoading, setLoading] = useState(false);

  const {
    history, setHistory,
    chatStatus, setChatStatus,
    chatId, publish,
  } = useChatContext();
  const { getHistory } = useChatApi();

  const { addQuestion: addQuestionAPI } = useQuestion();

  const { showModal } = useModal();

  const fetchHistory = useCallback(async () => {
    try {
      const chatHistory = await getHistory(chatId);

      chatHistory.forEach((message) => {
        if (message.done) {
          setChatStatus('done');
        }
      });

      if (chatHistory.length > 0) {
        setHistory(chatHistory);
      }
    } catch (error) {
      console.warn('An error occurred while fetching chat history', error);
      showModal({
        title: 'Error',
        body: 'An error occurred while fetching chat history',
      });
    }
  }, [chatId, setHistory, getHistory, showModal, setChatStatus]);

  // Automatically fetch history when component is mounted
  useEffect(fetchHistory, [fetchHistory]);

  const addQuestion = async (question, callback) => {
    try {
      setLoading(true);

      await addQuestionAPI(chatId, {
        question,
      });

      callback?.();
    } catch (error) {
      publish({
        variant: 'danger',
        text: 'Error occurred while getting answer',
      });
    } finally {
      setLoading(false);
    }
  };

  const saveRule = async (ruleName, enabled) => {
    try {
      setLoading(true);

      return await api('SaveGeneratedRule', {
        chatId,
        ruleName,
        enabled,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    history,
    addQuestion,
    isLoading,
    chatId,
    publish,
    chatStatus,
    setChatStatus,
    fetchHistory,
    saveRule,
  };
};

export default useChat;
