/*
  List of allowed keys we can display at the front end after receiving raw data about a particular
  scoring request.
*/

export default [
  'person',
  'loanApplication',
  'autoLoan',
  'currentEmployment',
  'cosignPerson',
  'asset',
];
