import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';
import {
  decodeString,
  formatAsDateTime,
  formatAsTuDate,
  formatCountAndDate,
  formatCurrency,
  formatGivenNames,
  formatMonthYear,
} from '../TransUnionView.formats';
import TransUnionBranding from '../TransUnionBranding';
import TextReportView from './TextReportView';
import mapper from './mapper';
import lookup from './lookups';

import styles from '../TransUnionView.module.scss';

const renderAddresses = (addresses) => {
  if (!addresses || !addresses.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        {`Residence${addresses.length > 0 ? 's' : ''}`}
      </h5>
      <Row className={styles.label}>
        <Col>Street</Col>
        <Col sm="2">City</Col>
        <Col sm="1">Prov</Col>
        <Col sm="2">Postal</Col>
        <Col sm="2" />
        <Col sm="2">Since</Col>
      </Row>
      {
        addresses.map((a) => (
          <Row key={`${a.Street}_${a.City}`}>
            <Col>{a.Street}</Col>
            <Col sm="2">{a.City}</Col>
            <Col sm="1">{a.Prov}</Col>
            <Col sm="2">{a.Postal}</Col>
            <Col sm="2" />
            <Col sm="2">{formatMonthYear(a.DateReported)}</Col>
          </Row>
        ))
      }
    </Container>
  );
};

const renderEmployments = (employments) => {
  if (!employments || !employments.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Employment
      </h5>
      <Row className={styles.label}>
        <Col sm="5">Employer&apos;s Name & Address</Col>
        <Col sm="3">Occupation</Col>
        <Col sm="2">Since</Col>
        <Col sm="2">Cnfrm</Col>
      </Row>
      {
        employments.map((e) => (
          <Row key={`${decodeString(e.EmpName).toLowerCase()}_${decodeString(e.Occupation).toLowerCase()}_${formatMonthYear(e.DateVerified)}_${formatMonthYear(e.DateHired)}`}>
            <Col sm="5">{decodeString(e.EmpName)}</Col>
            <Col sm="3">{decodeString(e.Occupation)}</Col>
            <Col sm="2">{formatMonthYear(e.DateHired)}</Col>
            <Col sm="2">{formatMonthYear(e.DateVerified)}</Col>
          </Row>
        ))
      }
    </Container>
  );
};

const formatPercentageAvailable = (item) => {
  if (item.Type !== 'R') return 'n/a';
  return `${item.PercentageAvailable}%`;
};

const renderCreditSummaryDetail = (items) => (
  items === null
    ? null
    : (
      <>
        <br />
        <Row>
          <Col sm="1" className={styles.label}>Type</Col>
          <Col sm="1" className={styles.label}>Count</Col>
          <Col sm="1" className={styles.label}>H.Cred</Col>
          <Col sm="2" className={styles.label}>CredLimit</Col>
          <Col sm="1" className={styles.label}>Balance</Col>
          <Col sm="2" className={styles.label}>PastDue</Col>
          <Col sm="2" className={styles.label}>Payment</Col>
          <Col sm="2" className={styles.label}>Available</Col>
        </Row>
        {
          ['R', 'O', 'I', 'M', 'T'].map((key) => (
            <Row key={key}>
              <Col sm="1" className={styles.label}>{ key }</Col>
              <Col sm="1" className={styles.label}>{ items[key].Count }</Col>
              <Col sm="1" className={styles.label}>{ key === 'R' ? 'n/a' : formatCurrency(items[key].HighCredit) }</Col>
              <Col sm="2" className={styles.label}>{ ['O', 'I', 'M'].includes(key) ? 'n/a' : formatCurrency(items[key].CreditLimit) }</Col>
              <Col sm="1" className={styles.label}>{ formatCurrency(items[key].Balance) }</Col>
              <Col sm="2" className={styles.label}>{ formatCurrency(items[key].PastDue) }</Col>
              <Col sm="2" className={styles.label}>{ formatCurrency(items[key].MonthlyPayment) }</Col>
              <Col sm="2" className={styles.label}>{ formatPercentageAvailable(items[key]) }</Col>
            </Row>
          ))
        }
      </>
    )
);

const renderMessageRows = (title, items) => (
  items.map((m) => (
    <Row key={`${title.replace(/\W/g, '_').toLowerCase()}_${m.Code}`}>
      <Col sm="3" className={styles.label}>{ `${title}:` }</Col>
      <Col>{m.Text || 'CLEAR'}</Col>
    </Row>
  ))
);

const renderMessages = (alerts, messages) => {
  if (!messages || !messages.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Messages
      </h5>
      { renderMessageRows('ID Mismatch Alert', alerts) }
      { renderMessageRows('High Risk Fraud Alert', messages) }
    </Container>
  );
};

const renderBanking = (items) => {
  if (!items || !items.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Banking - Closed For Cause
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Reptd</Col>
        <Col sm="2" className={styles.label}>Open</Col>
        <Col sm="2" className={styles.label}>WriteOff</Col>
        <Col sm="2" className={styles.label}>Amount</Col>
        <Col sm="4" className={styles.label}>Reason</Col>
      </Row>
      {
        items.map((i) => (
          <>
            <Row key={`${i.AccountNumber}0`}>
              <Col sm="1" className={styles.pullRight}>{ i.IndustryCode }</Col>
              <Col sm="11">{ i.SubscriberName }</Col>
            </Row>
            <Row key={`${i.AccountNumber}1`}>
              <Col sm="2">{ formatMonthYear(i.DateVerified) }</Col>
              <Col sm="2">{ formatMonthYear(i.DateOpened) }</Col>
              <Col sm="2">{ formatMonthYear(i.DateClosed) }</Col>
              <Col sm="2">{ i.Balance }</Col>
              <Col sm="4">{ lookup.banking(i.StatusCode) }</Col>
            </Row>
          </>
        ))
      }
    </Container>
  );
};

const renderBankruptcies = (items) => {
  if (!items || !items.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Bankruptcy and Insolvency
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Rvsd</Col>
        <Col sm="2" className={styles.label}>Reptd</Col>
        <Col sm="4" className={styles.label}>Trustee</Col>
        <Col sm="2" className={styles.label}>Assets</Col>
        <Col sm="2" className={styles.label}>Liab</Col>
      </Row>
      {
        items.map((i) => (
          <>
            <Row key={`${i.FilingNumber}_${i.Reference}_0`}>
              <Col sm="2">{ formatMonthYear(i.DateRevised) }</Col>
              <Col sm="2">{ formatAsTuDate(i.DateReported) }</Col>
              <Col sm="4">{ `${decodeString(i.TrusteeName)} ${decodeString(i.TrusteeCompany)}` }</Col>
              <Col sm="2">{ i.Assets }</Col>
              <Col sm="2">{ i.Liabilities }</Col>
            </Row>
            <Row key={`${i.FilingNumber}_${i.Reference}_1`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="6">{ lookup.narrative(i.StatusCode).description }</Col>
              <Col sm="2">{ formatAsTuDate(i.DateDischarged) }</Col>
            </Row>
            <Row key={`${i.FilingNumber}_${i.Reference}_2`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="6">{ lookup.discharge(i.DischargeCode) }</Col>
              <Col sm="2">{ i.FilingNumber }</Col>
            </Row>
            <Row key={`${i.FilingNumber}_${i.Reference}_3`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="8">{ `${decodeString(i.ThirdParty)} ${lookup.narrative(i.Narrative).description}` }</Col>
            </Row>
          </>
        ))
      }
    </Container>
  );
};

const renderScores = (scores) => {
  const productMap = {
    93: 'CreditVision Risk Score',
    94: 'CV Bankruptcy Score',
  };
  if (!scores || !scores.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        { `Score${scores.length > 1 ? 's' : ''}` }
      </h5>
      {
        scores.map((s) => (
          (s.Product in productMap)
            ? (
              <div key={s.Product}>
                <Row>
                  <Col sm="3" className={styles.label}>{`${productMap[s.Product]}:`}</Col>
                  <Col className={styles.label}>{s.Score || 'Unauthorized'}</Col>
                </Row>
                <Row>
                  <Col sm="3" />
                  <Col>{s.Factor1}</Col>
                </Row>
                <Row>
                  <Col sm="3" />
                  <Col>{s.Factor2}</Col>
                </Row>
                <Row>
                  <Col sm="3" />
                  <Col>{s.Factor3}</Col>
                </Row>
                <Row>
                  <Col sm="3" />
                  <Col>{s.Factor4}</Col>
                </Row>
              </div>
            ) : null
        ))
      }
    </Container>
  );
};

const renderTrades = (trades) => {
  if (!trades || !trades.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        { `Trade${trades.length > 1 ? 's' : ''}` }
      </h5>
      <Row>
        <Col sm="2" className={[styles.label, styles.shortDate].join(' ')}>Reptd</Col>
        <Col sm="2" className={[styles.label, styles.shortDate].join(' ')}>Open</Col>
        <Col sm="2" className={[styles.label, styles.shortDate].join(' ')}>Last</Col>
        <Col sm="1" className={styles.label}>H.Credit</Col>
        <Col sm="1" className={styles.label}>Balance</Col>
        <Col sm="1" className={styles.label}>P.Due</Col>
        <Col sm="1" className={styles.label}>Terms</Col>
        <Col className={[styles.label, styles.pullRight].join(' ')}>
          Payment Pattern
          <br />
          30/60/90/#M MOP
        </Col>
      </Row>
      {
        trades.map((t) => (
          <>
            <Row key={`${t.MemberCode}${t.Account}0`}>
              <Col sm="2" className={styles.shortDate}>{ t.MemberCode.slice(0, 2) }</Col>
              <Col sm="8" className={styles.label}>{ decodeString(t.MemberName)}</Col>
              <Col className={styles.pullRight}>{ t.PaymentPattern ? t.PaymentPattern.toString().slice(0, 24) : '' }</Col>
            </Row>
            <Row key={`${t.MemberCode}${t.Account}1`}>
              <Col sm="2" className={styles.shortDate}>{ formatMonthYear(t.DateLastActivity) }</Col>
              <Col sm="2" className={styles.shortDate}>{ formatMonthYear(t.DateOpened) }</Col>
              <Col sm="2" className={styles.shortDate}>{ formatMonthYear(t.DateLastActivity) }</Col>
              <Col sm="1">{ t.hCreditCol }</Col>
              <Col sm="1">{ t.Balance }</Col>
              <Col sm="1">{ t.PastDue }</Col>
              <Col sm="1">{ `${t.Payment}/${t.Frequency}` }</Col>
              <Col className={styles.pullRight}>{ `${t.Plus30}/${t.Plus60}/${t.Plus90}/${t.MonthsReviewed} ${t.Type}${t.MOP}` }</Col>
            </Row>
            <Row key={`${t.MemberCode}${t.Account}2`}>
              <Col sm="2" className={styles.shortDate} />
              <Col sm="2" className={styles.shortDate} />
              <Col sm="2" className={styles.shortDate} />
              <Col sm="3">{t.Factor1}</Col>
              <Col>{ lookup.narrative(t.Narrative || t.Narrative1).description.toUpperCase() }</Col>
            </Row>
            {
              t.Narrative2 ? (
                <Row key={`${t.MemberCode}${t.Account}3`}>
                  <Col sm="2" className={styles.shortDate} />
                  <Col sm="2" className={styles.shortDate} />
                  <Col sm="2" className={styles.shortDate} />
                  <Col sm="3" />
                  <Col>{ lookup.narrative(t.Narrative2).description.toUpperCase() }</Col>
                </Row>
              ) : null
            }
            <br />
          </>
        ))
      }
    </Container>
  );
};

const renderCollections = (collections) => {
  if (!collections || !collections.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        { `Collection${collections.length > 1 ? 's' : ''}` }
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Rvsd</Col>
        <Col sm="2" className={styles.label}>Reptd</Col>
        <Col sm="5" className={styles.label}>Creditor&apos;s Name</Col>
        <Col sm="1" className={styles.label}>Amount</Col>
        <Col sm="1" className={styles.label}>Balance</Col>
        <Col sm="1" />
      </Row>
      {
        collections.map((c) => (
          <Row key={c.Account}>
            <Col sm="2">{ formatMonthYear(c.DateVerified) }</Col>
            <Col sm="2">{ formatMonthYear(c.DateReported) }</Col>
            <Col sm="5">{ `${decodeString(c.MemberName)}/${c.Creditor}` }</Col>
            <Col sm="1">{ c.OriginalAmount }</Col>
            <Col sm="1">{ c.CurrentBalance }</Col>
            <Col sm="1">{ c.Remarks }</Col>
          </Row>
        ))
      }
    </Container>
  );
};

const renderRegisteredItems = (items) => {
  if (!items || !items.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        { `Registered Item${items.length > 1 ? 's' : ''}` }
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Reptd</Col>
        <Col sm="2" className={styles.label}>Open</Col>
        <Col sm="2" className={styles.label}>Matur</Col>
        <Col sm="4" className={styles.label}>Amount</Col>
        <Col sm="2" className={styles.label}>Security</Col>
      </Row>
      {
        items.map((c) => (
          <>
            <Row key={`${c.MemberCode}0`}>
              <Col sm="2">{ c.MemberCode ? c.MemberCode.slice(0, 2) : '' }</Col>
              <Col>{ decodeString(c.MemberName) }</Col>
            </Row>
            <Row key={`${c.MemberCode}1`}>
              <Col sm="2">{ formatMonthYear(c.DateRevised) }</Col>
              <Col sm="2">{ formatMonthYear(c.DateFiled) }</Col>
              <Col sm="2">{ formatMonthYear(c.DateMatures) }</Col>
              <Col sm="4">{ c.OriginalBalance }</Col>
              <Col sm="2">{ c.Security }</Col>
            </Row>
            <Row key={`${c.MemberCode}3`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="6">{ lookup.narrative(c.Narrative || c.Narrative1).description.toUpperCase() }</Col>
            </Row>
            <Row key={`${c.MemberCode}4`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="6">{ lookup.narrative(c.Narrative2).description.toUpperCase() }</Col>
            </Row>
          </>
        ))
      }
    </Container>
  );
};

const renderLegal = (items) => {
  if (!items || !items.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        { `Legal Item${items.length > 1 ? 's' : ''}` }
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Rvsd</Col>
        <Col sm="2" className={styles.label}>Reptd</Col>
        <Col sm="4" className={styles.label}>Plaintiff&apos;s Name</Col>
        <Col sm="2" className={styles.label}>Amount</Col>
        <Col sm="2" className={styles.label}>Balance</Col>
      </Row>
      {
        items.map((i) => (
          <>
            <Row key={`${i.CaseNumber}0`}>
              <Col sm="2">{ formatMonthYear(i.DateRevised) }</Col>
              <Col sm="2">{ formatMonthYear(i.DateReported) }</Col>
              <Col sm="4">{ decodeString(i.Plaintiff) }</Col>
              <Col sm="2">{ i.OriginalAmount }</Col>
              <Col sm="2">{ (i.Narrative1 === 'BN') ? 'NA' : i.CurrentBalance }</Col>
            </Row>
            <Row key={`${i.CaseNumber}1`}>
              <Col sm="2" />
              <Col sm="2" />
              <Col sm="4">{ `${i.Type}, ${i.CaseNumber}` }</Col>
              <Col sm="2">{ lookup.narrative(i.StatusCode).description.toUpperCase() }</Col>
              <Col sm="2">{ i.DateSatisfied ? formatMonthYear(i.DateSatisfied) : '' }</Col>
            </Row>
          </>
        ))
      }
    </Container>
  );
};

const renderInquiries = (items) => {
  if (!items || !items.length) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Inquiries
      </h5>
      <Row>
        <Col sm="2" className={styles.label}>Date</Col>
        <Col sm="10" className={styles.label}>Credit Grantor</Col>
      </Row>
      {
        items.map((i) => (
          <Row key={`${i.Date}${i.MemberCode}`}>
            <Col sm="2">{ formatAsTuDate(i.Date) }</Col>
            <Col sm="1">{ i.MemberCode.slice(0, 2) }</Col>
            <Col sm="9">{ decodeString(i.MemberName) }</Col>
          </Row>
        ))
      }
    </Container>
  );
};

const renderRemarks = (remarks) => {
  if (!remarks) return null;
  return (
    <Container className={styles.container}>
      <h5>
        Remarks
      </h5>
      <Row>
        <Col>{ remarks }</Col>
      </Row>
    </Container>
  );
};

const renderOtherNames = (otherNames) => (
  otherNames.map((n) => (
    <Row key={`${n['@NameType']}_${n.LastName}_${n.FirstName}`}>
      <Col sm="1" className={styles.label}>{ n['@NameType'] }</Col>
      <Col sm="4">{ n.LastName }</Col>
      <Col sm="5">{ formatGivenNames(n) }</Col>
      <Col sm="2" />
    </Row>
  ))
);

const renderFormattedReport = (data) => (
  <>
    <Container className={styles.container}>
      <h5>
        Order Results
      </h5>
      <Row className={styles.label}>
        <Col sm="1" />
        <Col sm="4">Surname</Col>
        <Col sm="5">Given Name(s)</Col>
        <Col sm="2">Birth</Col>
      </Row>
      <Row>
        <Col sm="1" className={styles.label}>Subject</Col>
        <Col sm="4">{ data.primaryName.LastName }</Col>
        <Col sm="5">{ formatGivenNames(data.primaryName) }</Col>
        <Col sm="2">{ formatAsTuDate(data.birthday) }</Col>
      </Row>
      { renderOtherNames(data.otherNames) }
    </Container>
    <Container className={styles.container}>
      <Row className={styles.label}>
        <Col>On File</Col>
        <Col>Last Inq</Col>
        <Col>Current Residence</Col>
        <Col>Telephone</Col>
      </Row>
      <Row>
        <Col>{ formatAsTuDate(data.onFileDate) }</Col>
        <Col>{ data.inquiries.length ? formatAsTuDate(data.inquiries[0].Date) : '' }</Col>
        <Col>{ data.currentResidence }</Col>
        <Col>{ data.personalPhoneNumber }</Col>
      </Row>
    </Container>
    { renderAddresses(data.addresses) }
    { renderEmployments(data.employments) }
    <Container className={styles.container}>
      <h5>
        File Summary
      </h5>
      <Row>
        <Col sm="3" className={styles.label}>Bankruptcies</Col>
        <Col sm="3">{ formatCountAndDate(data.bankruptcies, 'DateReported') }</Col>
        <Col sm="3" className={styles.label}>Registered Items</Col>
        <Col sm="3">{ formatCountAndDate(data.registeredItems, 'DateFiled') }</Col>
      </Row>
      <Row>
        <Col sm="3" className={styles.label}>Legal Items</Col>
        <Col sm="3">{ formatCountAndDate(data.legals, 'DateReported') }</Col>
        <Col sm="3" className={styles.label}>Inquiries</Col>
        <Col sm="3">{ formatCountAndDate(data.inquiries, 'Date') }</Col>
      </Row>
      <Row>
        <Col sm="3" className={styles.label}>Collections</Col>
        <Col sm="3">{ formatCountAndDate(data.collections, 'DateReported') }</Col>
        <Col sm="3" className={styles.label}>Inquiries within 6 Months</Col>
        <Col sm="3">{ data.countInquiriesWithin6Months }</Col>
      </Row>
      <Row>
        <Col sm="3" className={styles.label}>Banking Closed For Cause</Col>
        <Col sm="3">{ formatCountAndDate(data.bankings, 'DateClosed') }</Col>
        <Col sm="3" />
        <Col sm="3" />
      </Row>
      <br />
      <Row>
        <Col sm="3" className={styles.label}>Trade First Opened</Col>
        <Col sm="3">{ formatMonthYear(data.tradeOpened) }</Col>
        <Col sm="3" className={styles.label}>Current Negative Trade</Col>
        <Col sm="3">{ data.tradeNegativeCount }</Col>
      </Row>
      <Row>
        <Col sm="3" className={styles.label}>Trade Last Opened</Col>
        <Col sm="3">{ formatMonthYear(data.tradeLast) }</Col>
        <Col sm="3" className={styles.label}>Paid Trade</Col>
        <Col sm="3">{ data.tradePaidCount }</Col>
      </Row>
      { renderCreditSummaryDetail(data.creditSummaryDetail) }
    </Container>
    { renderBanking(data.bankings) }
    { renderBankruptcies(data.bankruptcies) }
    { renderMessages(data.alerts, data.messages) }
    { renderScores(data.scores) }
    { renderTrades(data.trades) }
    { renderCollections(data.collections) }
    { renderRegisteredItems(data.registeredItems) }
    { renderLegal(data.legals) }
    { renderInquiries(data.inquiries) }
    { renderRemarks(data.remarks) }
    <br />
    { `This completes the file for ${data.primaryName.FirstName} ${data.primaryName.LastName}` }
  </>
);

export default function FormattedReportView({ report }) {
  const [showRawText, toggleShowRawText] = useState(false);
  const data = mapper(report);
  return (
    <>
      <div className={styles.reportHeader}>
        <TransUnionBranding />
        <div className={styles.order}>
          <div className={styles.orderHeader}>
            <div>
              This Order
            </div>
            <div>
              <Button color="primary" onClick={() => toggleShowRawText(!showRawText)}>
                { showRawText ? 'View Formatted' : 'View Raw Text' }
              </Button>
            </div>
          </div>
          <div className={styles.orderDetail}>
            <span className={styles.label}>Subject:</span>
            { `${data.primaryName.FirstName} ${data.primaryName.LastName}`}
          </div>
          <div className={styles.orderDetail}>
            <span className={styles.label}>Results Issued:</span>
            { formatAsDateTime(data.issuedDate, data.issuedTime) }
          </div>
        </div>
      </div>
      <div className={[styles.reportBody, '_lr-hide'].join(' ')}>
        { showRawText ? (<TextReportView report={report} />) : renderFormattedReport(data) }
      </div>
    </>
  );
}
