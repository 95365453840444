import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

export default function (props) {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        {props.buttons.map((button) => (
          <Button
            key={button.id}
            id={button.id}
            className={button.className}
            color={button.color}
            onClick={button.onClick}
            disabled={button.disabled}
            outline={button.outline}
          >
            {button.text}
          </Button>
        ))}
      </ModalFooter>
    </Modal>
  );
}
