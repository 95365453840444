import React, { useContext } from 'react';

const EnabledFeaturesContext = React.createContext();

export function EnabledFeaturesProvider({
  children, enabledFeatures, enabledFeaturesUat, userAttributes, overrides = {}, overridesUat = {},
}) {
  let enabledFeaturesProdConfig = enabledFeatures ?? {};
  let enabledFeaturesUatConfig = enabledFeaturesUat ?? {};

  if (enabledFeatures?.useUatProdFlags === true) {
    if (!userAttributes.prodAccess) {
      enabledFeaturesProdConfig = {
        useUatProdFlags: true,
      };
    }
    if (!userAttributes.uatAccess) {
      enabledFeaturesUatConfig = {};
    }
  } else {
    // UAT is only supported with the new permission system
    enabledFeaturesUatConfig = {};
  }

  enabledFeaturesProdConfig = { ...enabledFeaturesProdConfig, ...overrides };
  enabledFeaturesUatConfig = { ...enabledFeaturesUatConfig, ...overridesUat };

  const enabledFeaturesAnyConfig = {};
  [enabledFeaturesProdConfig, enabledFeaturesUatConfig].forEach((config) => {
    Object.keys(config).forEach((key) => {
      if (config[key] === true) {
        enabledFeaturesAnyConfig[key] = true;
      }
    });
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <EnabledFeaturesContext.Provider value={{
      enabledFeaturesProd: enabledFeaturesProdConfig,
      enabledFeaturesUat: enabledFeaturesUatConfig,
      enabledFeaturesAny: enabledFeaturesAnyConfig,
    }}
    >
      {children}
    </EnabledFeaturesContext.Provider>
  );
}

export const useEnabledFeatures = ({ env = 'any' } = {}) => {
  const context = useContext(EnabledFeaturesContext);
  if (context === undefined) {
    throw new Error('useEnabledFeatures must be used within a EnabledFeaturesProvider');
  }
  const {
    enabledFeaturesProd,
    enabledFeaturesUat,
    enabledFeaturesAny,
  } = context;
  const enabledFeaturesMap = {
    prod: enabledFeaturesProd,
    uat: enabledFeaturesUat,
    any: enabledFeaturesAny,
  };
  return enabledFeaturesMap[env];
};

// Allows you to use the useEnabledFeatures hook to be used in (legacy) class components
export const withUseEnabledFeatures = (Component) => React.forwardRef((props, ref) => (
  <Component
    ref={ref}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    useEnabledFeaturesAnyProp={useEnabledFeatures({ env: 'any' })}
    useEnabledFeaturesProdProp={useEnabledFeatures({ env: 'prod' })}
    useEnabledFeaturesUatProp={useEnabledFeatures({ env: 'uat' })}
  />
));

export default useEnabledFeatures;
