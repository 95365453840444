import {
  formatPerson,
  formatCurrentEmployment,
  formatLoadDetails,
  formatAutoLoan,
  formatTradeInVehicle,
  formatDealership,
  formatCurrentIncome,
  formatCustomFields,
  employedToggleState,
} from './requestDetailsMapper';

export const payloadToFormState = (details, customFieldsConfig) => {
  const requestSummaryData = { basicInfo: {} };

  if (details.person) {
    requestSummaryData.applicant = formatPerson(details.person, false);

    if (requestSummaryData.applicant.residenceStatus) {
      switch (requestSummaryData.applicant.residenceStatus.toLowerCase()) {
        case 'own with mortgage':
        case 'own free & clear':
        case 'own':
          requestSummaryData.applicant.residenceStatus = 'Own';
          break;
        case 'parent':
        case 'family':
          requestSummaryData.applicant.residenceStatus = 'Family';
          break;
        case 'rent':
          requestSummaryData.applicant.residenceStatus = 'Rent';
          break;
        default:
          requestSummaryData.applicant.residenceStatus = 'Other';
          break;
      }
    }
  }

  if (details.loanApplication
    && details.loanApplication.coApplicants
    && details.loanApplication.coApplicants[0]
  ) {
    requestSummaryData.coapplicant = formatPerson(details.loanApplication.coApplicants[0], false);
    requestSummaryData.basicInfo.coApplicant = true;
  }

  if (details.currentEmployment) {
    requestSummaryData.employer = formatCurrentEmployment(details.currentEmployment, false);
    requestSummaryData.income = formatCurrentIncome(details.currentEmployment);
    requestSummaryData.basicInfo.employment = employedToggleState(details.currentEmployment);
  }

  if (details.loanApplication) {
    requestSummaryData.loanInfo = formatLoadDetails(details.loanApplication, customFieldsConfig);
    requestSummaryData.customFields = formatCustomFields(details.loanApplication);
  }

  if (details.autoLoan) {
    requestSummaryData.vehicle = formatAutoLoan(details.autoLoan);
  }

  if (details.autoLoan && details.autoLoan.dealer) {
    requestSummaryData.dealership = formatDealership(details.autoLoan.dealer);
  }

  if (
    details.autoLoan
    && details.autoLoan.tradeInVehicle
    && Object.keys(details.autoLoan.tradeInVehicle).length
  ) {
    requestSummaryData.tradeIn = formatTradeInVehicle(details.autoLoan.tradeInVehicle);
    requestSummaryData.basicInfo.tradeIn = true;

    requestSummaryData.tradeIn.tradeInAllowance = details.autoLoan.tradeInAllowance;
  }

  requestSummaryData.asset = details.asset || {};

  if (details.scoring.useCase.match(/preapproval/ig)) {
    requestSummaryData.basicInfo.preapproval = true;
  }

  return requestSummaryData;
};

export const payloadToSummaryState = (details, customFieldsConfig) => {
  const requestSummaryData = {};

  if (details.person) {
    requestSummaryData.person = formatPerson(details.person);
  }

  if (details.loanApplication
    && details.loanApplication.coApplicants
    && details.loanApplication.coApplicants[0]
  ) {
    requestSummaryData.cosignPerson = formatPerson(details.loanApplication.coApplicants[0]);
  }

  if (details.currentEmployment) {
    requestSummaryData.currentEmployment = formatCurrentEmployment(details.currentEmployment);
  }

  if (details.loanApplication) {
    requestSummaryData.loanApplication = formatLoadDetails(details.loanApplication, customFieldsConfig);
    requestSummaryData.customFields = formatCustomFields(details.loanApplication);
  }

  if (details.autoLoan) {
    requestSummaryData.autoLoan = formatAutoLoan(details.autoLoan);
  }

  if (details.autoLoan && details.autoLoan.dealer) {
    requestSummaryData.dealership = formatDealership(details.autoLoan.dealer);
  }

  if (details.autoLoan && details.autoLoan.tradeInVehicle) {
    requestSummaryData.tradeInVehicle = formatTradeInVehicle(details.autoLoan.tradeInVehicle);
  }

  const isoNow = new Date().toISOString();
  if (details.reports) {
    const latestReport = details.reports[details.reports.length - 1];
    requestSummaryData.lastReportDate = latestReport?.scoringDate ?? isoNow;
  } else {
    requestSummaryData.lastReportDate = isoNow;
  }

  requestSummaryData.asset = details.asset;

  return requestSummaryData;
};
