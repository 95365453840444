import React from 'react';

import styles from './AddressBox.module.scss';

const renderOptionalValue = (label, value) => {
  if (!value) {
    return null;
  }

  return (
    <div className={styles.optionalLabelAndValue}>
      {label}
      &nbsp;
      <b className={styles.boldedValue}>{value}</b>
    </div>
  );
};

export default function (props) {
  const { address } = props;

  return (
    <div className={styles.addressBox}>
      <div>
        <div className={styles.locationName}>{address.name}</div>
        <div>{address.address}</div>
        <div>{`${address.city}, ${address.state}`}</div>
        <div>{address.postalCode}</div>
      </div>
      <div className={styles.addressBoxBottom}>
        { renderOptionalValue('Phone Number:', address.phoneNumber) }
        { renderOptionalValue('Store Number:', address.storeNumber) }
        { renderOptionalValue('Location Type:', address.type) }
        { props.hasAmsIntegration && renderOptionalValue('AMS Lot ID:', address.amsLotId) }
        <div className={styles.editWrapper}>
          <button type="button" onClick={props.onEditClick}>Edit</button>
          <span className={styles.buttonSeparator}>|</span>
          <button type="button" onClick={props.onDeleteClick}>Delete</button>
        </div>
      </div>
    </div>
  );
}
