import React from 'react';
import DeploymentView from './Deployment.view';

import styles from './ManageDeployments.module.scss';

const byProjectThenNameAsc = (left, right) => {
  if (left.projectName > right.projectName) return 1;
  if (left.projectName < right.projectName) return -1;
  return left.name > right.name ? 1 : -1;
};

export default function EnvironmentView(props) {
  const {
    prodFlowConfig,
    uatFlowConfig,
    uatOtherProducts,
    prodOtherProducts,
    totalSlots,
    usedSlots,
    availableSlots,
    environments,
    deployments: {
      environmentId,
      name,
      type,
      deployments = [],
    },
    getDeploymentsByCommit,
    onCreateDeployment,
    onDeploymentUpdated,
    isAnyEditModeActive,
    onEditModeChange,
    showAllDeployments,
  } = props;

  return (
    <div className={styles.environmentContainer}>
      <div className="d-flex justify-content-between">
        <h3 className="text-primary">
          { name }
          &nbsp;(
          { type }
          )
        </h3>
        <div className="mt-1 text-muted">
          Using&nbsp;
          { usedSlots }
          &nbsp;of&nbsp;
          { totalSlots }
          &nbsp;slots (
          { availableSlots }
          &nbsp;available)
        </div>
      </div>
      <div>
        <div className={styles.fieldHeader}>
          <div className={styles.projectField}>Project</div>
          <div className={styles.nameField}>Deployment Name</div>
          <div className={styles.statusField}>Status</div>
          <div className={styles.configuredField}>Configured</div>
          <div className={styles.actionsField} />
        </div>
        {
          deployments
            .filter((d) => (showAllDeployments || d.status === 'Starting' || d.status === 'Running'))
            .sort(byProjectThenNameAsc)
            .map((d) => (
              <DeploymentView
                key={d.id}
                environmentId={environmentId}
                environments={environments}
                deployment={d}
                prodFlowConfig={prodFlowConfig}
                uatFlowConfig={uatFlowConfig}
                uatOtherProducts={uatOtherProducts}
                prodOtherProducts={prodOtherProducts}
                getDeploymentsByCommit={getDeploymentsByCommit}
                onCreateDeployment={onCreateDeployment}
                onDeploymentUpdated={onDeploymentUpdated}
                isAnyEditModeActive={isAnyEditModeActive}
                onEditModeChange={onEditModeChange}
              />
            ))
        }
      </div>
    </div>
  );
}
