export default [
  {
    field: 'assetValue',
    method: 'isEmpty',
    validWhen: false,
    message: 'Asset value is required.',
  },
  {
    field: 'assetPurpose',
    method: 'isEmpty',
    validWhen: false,
    message: 'Asset purpose is required',
  },
];
