import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const renderTitle = (props) => {
  if (props.error) return 'Deployment Promotion Error';
  return 'Deployment Promotion Started';
};

const renderError = (error) => {
  if (typeof error === 'string') return error;
  if (error.message) return error.message;
  return JSON.stringify(error.message);
};

const renderMessage = (props) => {
  if (props.error) {
    return (
      <>
        <div>
          { props.message }
        </div>
        <div className="mt-2">
          { renderError(props.error) }
        </div>
      </>
    );
  }
  return (
    <div className="p-4">
      { props.message }
    </div>
  );
};

export default function DeploymentMessageView(props) {
  return (
    <Modal isOpen>
      <ModalHeader>
        { renderTitle(props) }
      </ModalHeader>
      <ModalBody>
        <div className="w-100 text-center">
          { renderMessage(props) }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={props.onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
