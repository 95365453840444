import React, { useState } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import api from '../../../services/api';
import SmartConsentBankingURL from './SmartConsentBankingURL';

export default function CreateMobileInviteModalView(props) {
  const {
    requestId,
    person,
    isOpen,
    onClose,
  } = props;

  const [mobileInviteUrl, setMobileInviteUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedInviteOption, setSelectedInviteOption] = useState('generateOnly');
  const [inviteSent, setInviteSent] = useState(false);

  const primaryEmail = [].concat(person?.emails).find((email) => email?.primary)?.primary;

  const handleCreateMobileInvite = async () => {
    setIsLoading(true);
    try {
      const payload = { requestId };
      if (selectedInviteOption === 'generateAndSend') {
        payload.invite = {
          firstName: person.firstName,
          lastName: person.lastName,
          email: primaryEmail,
        };
      }
      const response = await api('CreateInvite', payload);
      if (response.statusCode !== 200) {
        console.error('Error while creating mobile invite', response);
        setErrorMessage('Invalid response from server. Please try again later.');
        return;
      }
      if (selectedInviteOption === 'generateAndSend') {
        setInviteSent(true);
        return;
      }
      const { url } = JSON.parse(response.body);
      setMobileInviteUrl(url);
    } catch (error) {
      console.error('Error while creating mobile invite', error);
      setErrorMessage('An error occurred while creating mobile invite');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseClicked = () => {
    setErrorMessage(null);
    setMobileInviteUrl(null);
    setInviteSent(false);
    setIsLoading(false);
    onClose();
  };

  const getCloseButtonText = () => ((errorMessage || mobileInviteUrl || inviteSent) ? 'Close' : 'Cancel');

  const renderCreateInviteButton = (option) => {
    if (mobileInviteUrl || inviteSent || errorMessage || isLoading) return null;
    let buttonText = 'Create Invite';
    if (option === 'generateAndSend') {
      buttonText = 'Create and Send Invite';
    }
    return (
      <Button
        color="primary"
        onClick={handleCreateMobileInvite}
        className="mt-2"
      >
        { buttonText }
      </Button>
    );
  };

  return (
    <Modal
      className="text-center"
      isOpen={isOpen}
      size="lg"
    >
      <ModalHeader>Create New Mobile Invite</ModalHeader>
      <ModalBody>
        <div className="pb-2">
          Creating a new mobile invite will expire any existing invites.
        </div>
        <div className="d-flex justify-content-center">
          <FormGroup className="w-50">
            <Input
              name="inviteOptions"
              id="inviteOptions"
              type="select"
              value={selectedInviteOption}
              onChange={({ target: { value } }) => setSelectedInviteOption(value)}
              disabled={!primaryEmail || inviteSent || mobileInviteUrl || errorMessage || isLoading}
            >
              <option value="generateOnly">Create a new invite URL</option>
              <option value="generateAndSend">Create and send a new invite</option>
            </Input>
          </FormGroup>
        </div>
        {
          selectedInviteOption === 'generateAndSend' && (
            <div>
              An email will be sent to&nbsp;
              {person.firstName}
              &nbsp;at&nbsp;
              {
                primaryEmail
              }
              .
            </div>
          )
        }
        {renderCreateInviteButton(selectedInviteOption)}
        {
          isLoading && (
            <div className="mt-2">
              Please wait...
            </div>
          )
        }
        {
          errorMessage && (
            <div className="text-danger">
              {errorMessage}
            </div>
          )
        }
        {
          mobileInviteUrl && (
            <SmartConsentBankingURL
              url={mobileInviteUrl}
              urlType="mobile"
            />
          )
        }
        {
          inviteSent && (
            <div className="mt-2">
              The invite was created and sent.
            </div>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleCloseClicked}
        >
          {getCloseButtonText() }
        </Button>
      </ModalFooter>
    </Modal>
  );
}
