import React, { Component } from 'react';
import TSAlert from '../../components/TSAlert';

import './AlertsWrapper.scss';

class AlertsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closedAlertsCount: 0,
    };
  }

  // Delayed function to be called after Bootstrap <Alert> transition completes
  handleClosedAlerts = (count) => {
    this.setState({ closedAlertsCount: count + 1 });
  };

  renderAlerts = (messages) => messages.map(
    (message) => (
      <TSAlert
        colorStyle="danger"
        message={message.description}
        key={message.id}
        onAlertDismissed={() => this.handleClosedAlerts(this.state.closedAlertsCount)}
      />
    ),
  );

  render() {
    const { messages } = this.props;

    if (this.state.closedAlertsCount === messages.length) {
      return null;
    }

    return (
      <div className="alerts-wrapper">
        <span className="wrapper-label">Important Messages</span>
        <h6 className="wrapper-title">System Alerts</h6>
        {this.renderAlerts(messages)}
      </div>
    );
  }
}

export default AlertsWrapper;
