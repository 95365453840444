import callGetFunction from './call-function-with-authenticated-user';
import { GET_CLIENT_LOCATIONS } from './functions-path';

export default async (params) => {
  const locationResults = await callGetFunction(GET_CLIENT_LOCATIONS, params);
  return {
    user: locationResults.user,
    clientId: locationResults.clientId,
    locations: locationResults.result,
  };
};
