import { getCountryCodeByState } from '../checkCountryByState';

export default (data) => {
  if (data.address && data.city && data.state && data.postalCode) {
    return {
      streetAddress: data.address,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      country: getCountryCodeByState(data.state),
    };
  }

  return undefined;
};
