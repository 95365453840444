import api from '../services/api';

export const validatePhoneNumber = (phone) => api('ValidatePhoneNumber', { phoneNumber: phone });

export const formatUSPhoneNumber = (phoneNumberString) => {
  let match = phoneNumberString.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  match = phoneNumberString.match(/(\+)(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}${match[2]} (${match[3]}) ${match[4]}-${match[5]}`;
  }

  return phoneNumberString;
};

// eslint-disable-next-line security/detect-unsafe-regex
export const isValidFormattedPhoneNumber = (phoneNumber) => /^([+]?\d{1,2}[.\s-]?)?(\d{3}[.-]?){2}\d{4}$/.test(phoneNumber);

export const sanitizePhoneNumber = (phoneNumber) => {
  let workingNumber = phoneNumber;
  if (workingNumber.startsWith('+1')) {
    // eslint-disable-next-line prefer-destructuring
    workingNumber = workingNumber.split('+1')[1];
  }
  let sanitizedNumber = `${(workingNumber).replace(/[^0-9]/g, '')}`;
  if (!sanitizedNumber.startsWith('+')) {
    sanitizedNumber = `+1${sanitizedNumber}`;
  }
  if (sanitizedNumber.length !== 12) return null;

  return sanitizedNumber;
};
