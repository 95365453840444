const trimInputStrings = (payload) => {
  if (!payload) return {};
  const skipProperty = ['clientLocations'];
  const inputKeys = Object.keys(payload);
  const trimmedPayload = {};

  const isString = (param) => (typeof param === 'string');
  const isArray = (param) => (Array.isArray(param));
  const isObject = (param) => (typeof param === 'object');
  const isJson = (param) => (isObject(param) && !isArray(param));
  const jsonHasProperty = (param) => (Object.keys(param).length > 0);
  const shouldSkip = (param) => (skipProperty.includes(param));
  const continueTrimming = (param) => (param.length > 0);
  const convertResponseToArray = isArray(payload);

  function proceedTrim(key) {
    let currentProperty = payload[key];
    if (currentProperty && !shouldSkip(key)) {
      if (isString(currentProperty)) {
        currentProperty = currentProperty.trim();
      }
      if (isArray(currentProperty)) {
        currentProperty = currentProperty.map((obj) => trimInputStrings(obj));
      }
      if (isJson(currentProperty)) {
        if (jsonHasProperty(currentProperty)) {
          currentProperty = trimInputStrings(currentProperty);
        }
      }
    }
    trimmedPayload[key] = currentProperty;
    if (continueTrimming(inputKeys)) {
      return proceedTrim(inputKeys.shift());
    }

    if (convertResponseToArray) {
      const convertedResponse = Object.keys(trimmedPayload).map((arrayKey) => (trimmedPayload[arrayKey]));
      return convertedResponse;
    }

    return trimmedPayload;
  }

  return proceedTrim(inputKeys.shift());
};

export default trimInputStrings;
