import categoriesConfig from './banking-categories.json';

const getCategoryLabels = (config) => Object.keys(config).reduce((acc, category) => {
  if (category.startsWith('/')) {
    const categoryKey = category.slice(1);

    // Don't override current category with deprecated one
    if (acc[categoryKey] && config[category].hide) {
      return acc;
    }

    return {
      ...acc,
      ...getCategoryLabels(config[category]),
      [category.slice(1)]: config[category].label,
    };
  }
  return acc;
}, {});

const categoryLabels = getCategoryLabels(categoriesConfig);

export default categoryLabels;
