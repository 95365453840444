import React from 'react';
import {
  Container,
  FormGroup,
  Input,
  Label,
  Tooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';
import ValidatedIncome from './IncomeInformation.validatedIncome';

import styles from './IncomeInfo.module.scss';
import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';

const formGroupFieldNames = [
  'name', 'grossIncome', 'netIncome', 'paymentsPerYear',
];

class IncomeInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;

    if (props.highlightEssentialInputs) {
      super.skipValidationOnGroupIfEmpty = false;
    }
    this.togglePaymentsPerYearToolTip = this.togglePaymentsPerYearToolTip.bind(this);
    const requireFields = Object.keys(props.overrides)
      .filter((k) => props.overrides[k].required)
      .map((k) => ({
        field: k,
        method: 'isEmpty',
        validWhen: false,
        message: 'A value is required.',
      }));
    this.validator = new FormValidator(validationConfig.concat(requireFields));
  }

  togglePaymentsPerYearToolTip() {
    this.setState((prevState) => (
      {
        paymentsPerYearToolTipOpen: !prevState.paymentsPerYearToolTipOpen,
      }
    ));
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <div className="income-input-group">
              <h4>Income</h4>
              <div className="form-group-wrapper">
                <Container>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="name"
                    required
                    id="employerName"
                    label="Employer Name / Type of Income"
                    valid={!this.state.nameFieldInvalid}
                    validationMessage={this.state.nameFieldValidationMessage}
                    value={previousState.name || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <ValidatedIncome
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="grossIncome"
                    id="grossIncome"
                    label="Gross Income"
                    valid={!this.state.grossIncomeFieldInvalid}
                    validationMessage={this.state.grossIncomeFieldValidationMessage}
                    value={previousState.grossIncome || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <ValidatedIncome
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="netIncome"
                    id="netIncome"
                    label="Net Income"
                    valid={!this.state.netIncomeFieldInvalid}
                    validationMessage={this.state.netIncomeFieldValidationMessage}
                    value={previousState.netIncome || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                  />
                  <FormGroup>
                    <Label htmlFor="payChequesPerYear">Number of Payments per Year</Label>
                    <Input
                      className={`short-width ${this.state.paymentsPerYearFieldInvalid ? 'is-invalid' : ''}`}
                      name="paymentsPerYear"
                      id="payChequesPerYear"
                      type="number"
                      required
                      defaultValue={previousState.paymentsPerYear || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    />
                    <span className="input-units block">
                      <FontAwesomeIcon
                        className={styles.toolTipIcon}
                        icon="info-circle"
                        id="paymentsPerYearToolTip"
                        tabIndex="-1"
                      />
                      <Tooltip
                        placement="right"
                        isOpen={this.state.paymentsPerYearToolTipOpen}
                        target="paymentsPerYearToolTip"
                        toggle={this.togglePaymentsPerYearToolTip}
                      >
                        Enter 12 if paid monthly,
                        <br />
                        24 if paid semi-monthly,
                        <br />
                        or 26 if paid bi-weekly.
                      </Tooltip>
                    </span>
                  </FormGroup>
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default IncomeInputGroup;
