import React, { useState } from 'react';
import { Alert } from 'reactstrap';

function TSAlert(props) {
  const [isOpen, setIsOpen] = useState(true);

  const closeAlert = () => {
    setIsOpen(false);
    if (props.onAlertDismissed) {
      setTimeout(() => props.onAlertDismissed(), 150);
    }
  };

  return (
    <Alert
      fade
      color={props.colorStyle}
      isOpen={isOpen}
      toggle={closeAlert}
    >
      {props.message}
    </Alert>
  );
}

export default TSAlert;
