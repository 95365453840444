import buildAddressDataBlock from '../FormHelpers/buildAddressDataBlock';
import { sanitizePhoneNumber } from '../phoneNumberHelpers';

export default (personData) => {
  const address = buildAddressDataBlock(personData);

  let identifiers;
  if (personData.socialInsuranceNumber) {
    identifiers = [
      { type: 'Social Insurance Number', value: personData.socialInsuranceNumber },
    ];
  }

  const phones = [];
  if (personData.homePhoneNumber) {
    phones.push({
      home: sanitizePhoneNumber(personData.homePhoneNumber),
    });
  }

  if (personData.cellPhoneNumber) {
    phones.push({
      mobile: sanitizePhoneNumber(personData.cellPhoneNumber),
    });
  }

  if (personData.workPhoneNumber) {
    phones.push({
      work: sanitizePhoneNumber(personData.workPhoneNumber),
    });
  }

  const emails = [];
  if (personData.email1) {
    emails.push({ primary: personData.email1 });
  }

  if (personData.email2) {
    emails.push({ other: personData.email2 });
  }

  let isCurrentResidence;
  if (personData.isCurrentResidence === 'yes') {
    isCurrentResidence = true;
  } else if (personData.isCurrentResidence === 'no') {
    isCurrentResidence = false;
  }

  const applicantAddress = {
    ...address,
    isCurrentResidence,
    monthsAtResidence: personData.durationAtResidence
      ? Number(personData.durationAtResidence)
      : undefined,
    monthlyHousingCost: personData.monthlyResidenceCost
      ? Number(personData.monthlyResidenceCost)
      : undefined,
    residenceStatus: personData.residenceStatus,
  };

  let addresses;
  if (applicantAddress.streetAddress) {
    addresses = [applicantAddress];
  }

  const personDataBlock = {
    firstName: personData.firstName,
    lastName: personData.lastName,
    aliases: personData.aliases ? personData.aliases.split(',') : undefined,
    phones: phones.length ? phones : undefined,
    emails: emails.length ? emails : undefined,
    identifiers,
    addresses,
    birthday: personData.dateOfBirth ? personData.dateOfBirth.replace(/\//g, '-') : undefined,
  };

  if (personData.middleName) personDataBlock.middleName = personData.middleName;

  const personBlockIsEmpty = Object.keys(personDataBlock)
    .find((info) => typeof personDataBlock[info] !== 'undefined') === undefined;

  if (personBlockIsEmpty) {
    return undefined;
  }

  return personDataBlock;
};
