import React from 'react';
import { bankingAnalysisField } from '../../utils/display-field';
import InputItemRow from '../ScoringRequestInputSummary/InputItemRow';
import styles from './BankingAnalysisSection.module.scss';

const renderAnalysisItems = (analysis, bankingAttributes) => Object.keys(analysis)
  .map((flag) => (bankingAnalysisField(flag, bankingAttributes)))
  .filter((field) => !field.hide)
  .map((field) => (<InputItemRow key={field.key} label={field.label} value={field.format(analysis[field.key])} />));

export default function ({ analysis, bankingAttributes }) {
  if (Object.getOwnPropertyNames(analysis).length === 0) {
    return null;
  }
  const attributesDictionaryIsEmpty = (Object.keys(bankingAttributes).length <= 0);
  return (
    <section className={styles.bankingAnalysisSection}>
      <h5>Banking Analysis</h5>
      {
        (attributesDictionaryIsEmpty)
          ? (
            <div>
              <h6>
                Unable to load the banking attribute labels at the moment. Please try refreshing this page. If the problem persists please contact support for assistance.
              </h6>
            </div>
          )
          : (
            <div>
              <InputItemRow label="Transaction Type" value="Occurrence (or aggregated value)" />
              { renderAnalysisItems(analysis, bankingAttributes) }
            </div>
          )
      }
    </section>
  );
}
