// 2023-05-03 Note that the order of these keys determines the sort order in the Banking Account card
export default {
  title: 'Account Name',
  accountNO: 'Account Number',
  realAccountNumber: 'ACH Account Number',
  transitNO: 'Transit Number',
  routingNumber: 'Routing Number',
  holder: 'Account Holder Name',
  institution: 'Institution',
  institutionNO: 'Institution Number',
  streetAddress: 'Address',
  city: 'City',
  state: 'State / Province',
  postalCode: 'Zip / Postal Code',
  country: 'Country',
  phone: 'Phone Number',
  email: 'E-Mail',
  overdraftLimit: {
    label: 'Overdraft Limit',
    type: 'currency',
  },
  availableBalance: {
    label: 'Available Balance',
    type: 'currency',
  },
  currentBalance: {
    label: 'Current Balance',
    type: 'currency',
  },
  balanceLimit: {
    label: 'Balance Limit',
    type: 'currency',
  },
  type: 'Account Type',
  currency: 'Currency',
  category: 'Category',
  provider: 'Provider',
  detail: 'Request Status',
  timestamp: 'Timestamp',
};
