import React from 'react';
import { Button } from 'reactstrap';

export default function CallToActionWhenDeploymentHasNoProductConfigurations(props) {
  const {
    canAddProductConfig,
    configurations,
    isHidden,
    isDisabled,
    trustScienceConfigEnv,
    onAddProductConfig,
    flows,
  } = props;
  if (!canAddProductConfig) return null;
  if (isHidden) return null;
  if (configurations.length) return null;
  if (!flows?.length) {
    return (
      <div className="text-center">
        There are no callable flows in this deployment.
      </div>
    );
  }
  return (
    <div className="text-center">
      <Button
        color="link"
        onClick={onAddProductConfig}
        disabled={isDisabled}
      >
        { `Add product configuration for ${trustScienceConfigEnv}` }
      </Button>
    </div>
  );
}
