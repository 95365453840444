import React from 'react';

function report({ data }) {
  if (!data) return null;

  return (
    <div className="reportTable">
      <h4>Monthly Report</h4>
      <table className="table table-striped">
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th> </th>
            <th scope="col">Avg. Daily Requests</th>
            <th scope="col">Avg. Score</th>
            <th scope="col">Scorable Requests</th>
            <th scope="col">No Score Requests</th>
            <th scope="col">Unscorable Requests</th>
            <th scope="col">Total Requests</th>
            <th scope="col">Scorable percent</th>
            <th scope="col">No Score Percent</th>
            <th scope="col">Unscorable Percent</th>
          </tr>
        </thead>

        <tbody>
          {data.map((element) => (
            <tr key={element.date_range + element.avg_daily_requests}>
              <th className="row-title" scope="row">{element.date_range}</th>
              <td>{element.avg_daily_requests}</td>
              <td>{element.avg_score}</td>
              <td>{element.scorable_requests}</td>
              <td>{element.no_score_requests}</td>
              <td>{element.unscorable_requests}</td>
              <td>{element.total_requests}</td>
              <td>{element.scorable_percent}</td>
              <td>{element.no_score_percent}</td>
              <td>{element.unscorable_percent}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default report;
