import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTags } from '@fortawesome/free-solid-svg-icons';

import styles from './TransactionsTable.module.scss';
import AnnotationModal from '../AnnotationModal';
import { formatDefaultColumn, formatCurrencyColumn } from '../../../../utils/format-columns';

import { useBankingAnnotations } from '../../hooks/useBankingAnnotations';
import getSubCategories from '../../../../utils/get-banking-sub-categories';

library.add(faTags);

const formatCategoryColumn = (transaction, column, annotation, setModalData) => {
  const rootCategory = transaction.category;
  const subCategories = getSubCategories(transaction.categoryProperties);
  const fullCategory = [rootCategory, ...subCategories];
  return (
    <>
      {fullCategory.join(' / ')}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className="btn btn-link"
        title="Suggest Change"
        onClick={() => {
          setModalData({
            rawTransaction: transaction,
            annotation,
            date: formatDefaultColumn(transaction, 'date'),
            description: formatDefaultColumn(transaction, 'description'),
            debit: formatCurrencyColumn(transaction, 'debit'),
            credit: formatCurrencyColumn(transaction, 'credit'),
            balance: formatCurrencyColumn(transaction, 'balance'),
            category: fullCategory,
            isOpen: true,
          });
        }}
      >
        <FontAwesomeIcon color={`var(--${annotation ? 'secondary' : 'primary'})`} icon={faTags} />
      </button>
    </>
  );
};

export const columnFormats = new Map([
  ['date', formatDefaultColumn],
  ['description', formatDefaultColumn],
  ['debit', formatCurrencyColumn],
  ['credit', formatCurrencyColumn],
  ['balance', formatCurrencyColumn],
  ['category', formatCategoryColumn],
]);

function TransactionsTable({ transactionData, accountInfo }) {
  const [modalData, setModalData] = useState({
    isOpen: false,
  });
  const closeModal = () => setModalData({ isOpen: false });

  const { accountId } = accountInfo;
  const { annotations } = useBankingAnnotations();
  const sameAccountAnnotations = useMemo(() => annotations.filter(
    // Note: If view is regenerated, annotations will become unlinked (SvdO 2024-06-24)
    (annotation) => annotation.source.accountId === accountId,
  ), [annotations, accountId]);
  const annotationsMap = useMemo(
    () => sameAccountAnnotations.reduce((acc, annotation) => {
      if (annotation.source.transactionIndex === undefined) {
        return acc;
      }
      acc[annotation.source.transactionIndex] = annotation;
      return acc;
    }, {}),
    [sameAccountAnnotations],
  );

  if (!transactionData.length) {
    return <p>No transaction records found from this account.</p>;
  }
  return (
    <>
      <div className="_lr-hide">
        {modalData.isOpen && (
        <AnnotationModal
          modalData={modalData}
          accountInfo={accountInfo}
          closeModal={closeModal}
        />
        )}
        <table className={[styles.table, styles.headerTable].join(' ')}>
          <tbody>
            <tr className={styles.header}>
              {
                Array.from(columnFormats.keys()).map((column) => (
                  <td type={column} key={column}>{column}</td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={[styles.tableWrapper, '_lr-hide'].join(' ')}>
        <table className={styles.table}>
          <tbody>
            {
              transactionData.map((transaction, index) => {
                const id = transaction.id || index;

                const annotation = annotationsMap[transaction.transactionIndex];
                return (
                  <tr key={`${id}-tablerow`}>
                    {
                      Array.from(columnFormats.entries()).map(([column, formatFunction]) => (
                        <td type={column} key={column}>
                          {formatFunction(transaction, column, annotation, setModalData)}
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TransactionsTable;
