import { useMemo } from 'react';
import { USE_CHATBOT_LOCAL_FETCH_STRATEGY } from '../../../config/console-configuration';
import { useChatContext } from '../context';
import api from '../../../services/api';

const fetchBaseLocal = {
  get: async (url, headers) => fetch(url, { method: 'GET', headers }),
  post: async (url, body, headers) => fetch(url, { method: 'POST', body, headers }),
};

const toResponseFormat = ({ body, headers, statusCode }) => new Response(body, {
  headers,
  status: statusCode,
});

const fetchAWSLambdaBase = {
  get: async (url, headers) => api('SendChatRequest', {
    url,
    headers,
    method: 'GET',
  }).then(toResponseFormat),
  post: async (url, body, headers) => api('SendChatRequest', {
    url, body, headers, method: 'POST',
  }).then(toResponseFormat),
};

export const useFetch = () => {
  let fetchBase;
  let backendUrl;
  if (!USE_CHATBOT_LOCAL_FETCH_STRATEGY) {
    fetchBase = fetchAWSLambdaBase;
    backendUrl = '';
  } else {
    fetchBase = fetchBaseLocal;
    backendUrl = `http://${window.location.hostname}:5050`;
  }

  const {
    clientId,
  } = useChatContext();

  const fetchInstance = useMemo(() => ({
    get: async (url, headers) => fetchBase.get(`${backendUrl}${url}`, {
      ...headers,
      'client-id': clientId,
    }),
    post: async (url, body, headers) => fetchBase.post(`${backendUrl}${url}`, body, {
      ...headers,
      'client-id': clientId,
    }),
  }), [backendUrl, clientId, fetchBase]);

  return { fetchInstance };
};

export default useFetch;
