import { parseDate } from '../TransUnionView.formats';

const forceToArray = (items, key) => {
  if (!items || !items[key]) return [];
  if (items[key] instanceof Array) return items[key];
  return [items[key]];
};

const addMonthsToDate = (date, monthDelta) => {
  const [year, month, day] = parseDate(date);
  return new Date(year, (month - 1) + monthDelta, day);
};

const byAscending = (left, right) => ((left < right) ? -1 : 1);
const byDescending = (left, right) => ((left > right) ? -1 : 1);

const sortItems = (items, listKey, dateKey, sort = byAscending) => (
  forceToArray(items, listKey)
    .sort((left, right) => sort(left[dateKey], right[dateKey]))
);

const tradesSummary = (items) => {
  if (!items.length) return ['', '', 0, 0];
  let championOpen = 99999999;
  let championLast = 0;
  let negativeCount = 0;
  let paidCount = 0;
  items.forEach((t) => {
    if (t.DateOpened < championOpen) championOpen = t.DateOpened;
    if (t.DateOpened > championLast) championLast = t.DateOpened;
    if ((t.Balance <= 0) && (t.Plus30 === 0)) paidCount += 1;
    if (t.Plus30 > 0) negativeCount += 1;
  });
  return [championOpen, championLast, negativeCount, paidCount];
};

const formatCreditSummary = ({ CreditSummary: summary, CreditSummaryDetails: creditSummaryDetails }) => {
  if (!summary || !creditSummaryDetails) return null;
  const { CreditSummaryDetail: details } = creditSummaryDetails;
  if (!details) return null;
  const results = details.reduce((result, detail) => ({ ...result, [detail.Type]: detail }), {});
  results.R.Count = results.R.Count || summary.NumberOfRevolvingTrades;
  results.I.Count = results.I.Count || summary.NumberOfInstallmentTrades;
  results.M.Count = results.M.Count || summary.NumberOfMortgageTrades;
  results.O.Count = results.O.Count || summary.NumberOfOpenTrades;
  results.T.Count = results.T.Count || summary.NumberOfTrades;
  if (results.R.PercentageAvailable < 0) {
    results.R.PercentageAvailable = Math.trunc(((results.R.CreditLimit - results.R.Balance) / results.R.CreditLimit) * 100);
  }
  return results;
};

const dateWithin = (date, withinDate) => {
  const [year, month, day] = parseDate(date);
  const checkDate = new Date(year, month - 1, day);
  return checkDate >= withinDate;
};

const getPersonalPhoneNumber = (items, key) => (
  forceToArray(items, key)
    .find((p) => (/personal|personnel/i.test(p['@PhoneType'])))
);

export default ({
  REL4Report: {
    Date: issuedDate,
    Time: issuedTime,
    TU_FFR_Report: report,
  },
}) => {
  const names = forceToArray(report.Names, 'Name');
  const primaryName = names.find((n) => /primary/i.test(n['@NameType']));
  const otherNames = names.filter((n) => !(/primary/i.test(n['@NameType'])));

  const personalPhoneNumber = getPersonalPhoneNumber(report.Telephones, 'Telephone');

  const addresses = sortItems(report.Addresses, 'Address', 'DateReported', byDescending)
    .filter((a) => (/personal|personnel/i.test(a['@AddrType'])));

  const employments = sortItems(report.Employments, 'Employment', 'DateHired');
  const bankings = sortItems(report.Bankings, 'Banking', 'DateClosed');
  const bankruptcies = sortItems(report.Bankruptcies, 'Bankruptcy', 'DateRevised');
  const registeredItems = sortItems(report.RegisteredItems, 'RegisteredItem', 'DateFiled');
  const legals = sortItems(report.Legals, 'Legal', 'DateReported');
  const inquiries = sortItems(report.Inquiries, 'Inquiry', 'Date', byDescending);
  const collections = sortItems(report.Collections, 'Collection', 'DateReported');
  const sixMonthsFromIssuedDate = addMonthsToDate(issuedDate, -6);
  const countInquiriesWithin6Months = inquiries
    .reduce((count, i) => (
      dateWithin(i.Date, sixMonthsFromIssuedDate) ? count + 1 : count
    ), 0);
  const trades = sortItems(report.Trades, 'Trade', 'DateLastActivity', byDescending)
    .map((t) => ({
      ...t,
      hCreditCol: t.HighCredit || t.CreditLimit,
    }));
  const [tradeOpened, tradeLast, tradeNegativeCount, tradePaidCount] = tradesSummary(trades);
  const creditSummaryDetail = formatCreditSummary(report);
  const messages = forceToArray(report.Messages, 'Message');
  const alerts = forceToArray(report.TransAlerts, 'TransAlert');
  const scores = forceToArray(report.ScoreProducts, 'ScoreProduct');
  const remarks = forceToArray(report.ConsumerStatements, 'ConsumerStatement')
    .map((i) => (i.Text))
    .join('');

  return {
    issuedDate,
    issuedTime,
    primaryName,
    otherNames,
    birthday: report.PersonalInformation.DOB,
    onFileDate: report.OnFileDate,
    lastInquiry: inquiries.length ? inquiries[0] : '',
    personalPhoneNumber: personalPhoneNumber ? personalPhoneNumber.Number : '',
    currentResidence: addresses.length ? addresses[0].Street : '',
    addresses,
    employments,
    bankings,
    bankruptcies,
    registeredItems,
    legals,
    inquiries,
    countInquiriesWithin6Months,
    collections,
    trades,
    tradeOpened,
    tradeLast,
    tradeNegativeCount,
    tradePaidCount,
    creditSummaryDetail,
    messages,
    scores,
    remarks,
    alerts,
  };
};
