import React, { Suspense, useEffect, useState } from 'react';
import api from '../../services/api';
import ClientLogo from '../../components/ClientLogo';
import Loader from '../../components/Loader';
import Report from './Report';

import DashboardChart from './DashboardChart';
import './home.scss';

function Home(props) {
  const [data, setData] = useState({});
  const {
    logo, displayName, dashboard, features,
  } = props.clientInfo;
  const { clientId } = props;

  useEffect(() => {
    async function fetchData() {
      if (
        features.dashboard
      && dashboard?.reports
      && Object.keys(dashboard.reports).some((key) => dashboard.reports[key])
      ) {
        const response = await api('GetChartsData');
        setData(response);
      } else {
        setData({ notAuthorized: true });
      }
    }
    fetchData();
  }, [dashboard, features]);

  return (
    <>
      <div className="home-container">
        <div className="trade-mark-slogan">
          Welcome to
          <span className="green-text">Credit Bureau+™/Credit Bureau 2.0®</span>
        </div>
        <ClientLogo clientId={clientId} companyLogo={logo} companyName={displayName} />
      </div>
      <div className="print-header">
        <ClientLogo clientId={clientId} companyLogo={logo} companyName={displayName} />
        <div className="centered">
          <p>{displayName}</p>
          <p>
            Generated on
            {' '}
            {new Date(Date.now()).toLocaleString()}
          </p>
        </div>
      </div>
      {!Object.keys(data).length && <Loader />}
      {data.noClientData && (
        <p className="d-flex justify-content-center">No metrics data available.</p>
      )}
      {data.reportTableData && (
        <>
          <Report data={data.reportTableData} />
          <div className="apex">
            {data.chartsData?.map((chartOptions, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Suspense fallback={<Loader />} key={index}>
                <div className="col-6">
                  <DashboardChart options={chartOptions} />
                </div>
              </Suspense>
            ))}
          </div>
        </>
      )}
      <div className="print-only-doc-footer">
        Trust Science
        <sup>®</sup>
        {' '}
        monthly report for
        {' '}
        {displayName}
      </div>
    </>
  );
}

export default Home;
