export default [
  {
    field: 'tradeInAllowance',
    method: 'isFloat',
    validWhen: true,
    message: 'Trade-in value must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    fields: ['odometer', 'odometerUnits'],
    method: (fields, inputFormState) => fields.every((field) => inputFormState[field]) || fields.every((field) => !inputFormState[field]),
    validWhen: true,
    message: 'Both odometer and units must be specified',
  },
];
