import React from 'react';
import {
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import SanitizedTextInput from '../../../../components/SanitizedTextInput';
import HelpText from './HelpText';

const doNothing = (x) => x;

const isReadOnly = (action, preventUpdate) => (action === 'update' ? !!preventUpdate : false);

export default function TextInputField(props) {
  const {
    action,
    displayName,
    attributeKey,
    normalizer = doNothing,
    onAttributeChanged,
    onBlurEvent,
    preventUpdate,
    user,
    validation,
  } = props;
  const validationResult = validation[attributeKey];
  const value = user.attributes[attributeKey];
  const readonly = isReadOnly(action, preventUpdate);

  return (
    <FormGroup key={attributeKey}>
      <Row>
        <Col sm="auto" className="label-main">
          <Label htmlFor={attributeKey}>
            {displayName}
          </Label>
        </Col>
        <HelpText helpText={props.helpText} />
      </Row>
      <SanitizedTextInput
        id={attributeKey}
        name={attributeKey}
        type="text"
        value={value}
        onChange={onAttributeChanged}
        onBlur={onBlurEvent}
        normalizer={normalizer}
        invalid={validationResult.isInvalid}
        readOnly={readonly}
      />
      <FormFeedback>{ validationResult.message }</FormFeedback>
    </FormGroup>
  );
}
