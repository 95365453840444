import React from 'react';
import ValidatedTextInput from '../../../ScoringRequestForm.validatedTextInput';

export default function ({
  name,
  label,
  required,
  defaultValue,
}, getValidation, previousState, onChange) {
  const fieldName = `custom_${name}`;
  const validation = getValidation(name);
  return (
    <ValidatedTextInput
      key={fieldName}
      name={fieldName}
      id={fieldName}
      label={label}
      required={required}
      highlightEssentialInputs
      isCurrency
      valid={validation.isValid}
      validationMessage={validation.validationMessage}
      value={previousState[fieldName] ?? defaultValue}
      onChange={onChange}
    />
  );
}
