import React, {
  useEffect, useState,
} from 'react';
import Markdown from 'markdown-to-jsx';
import fetchDocumentation from '../../utils/fetchDocumentation';
import Loader from '../../components/Loader';
import File from '../../components/Documentation/File';
import Image from '../../components/Documentation/Image';
import InfoBlock from '../../components/Documentation/InfoBlock';
import Column from '../../components/Documentation/Column';
import AttributesTable from '../../components/Documentation/AttributesTable';
import AppendixTable from '../../components/Documentation/AppendixTable';
import TableRowItem from '../../components/Documentation/TableRowItem';
import PrintTitlePage from '../../components/Documentation/PrintTitlePage';
import AllowedDataSourceList from '../../components/Documentation/AllowedDataSourceList';

import './Documentation.scss';

const formatDocVer = () => {
  const today = new Date();
  const formattedMonth = `0${today.getMonth() + 1}`.slice(-2);
  const formattedDate = `0${today.getDate()}`.slice(-2);
  return `${today.getFullYear()}${formattedMonth}${formattedDate}`;
};

function Documentation(thisProps) {
  const [state, setState] = useState({
    fetching: true,
    docs: '',
  });

  useEffect(() => {
    fetchDocumentation('index.md', thisProps.docType, thisProps.featureFlag)
      .then((docs) => {
        setState({ docs, fetching: false });
      })
      .catch((error) => {
        setState({ fetching: false });
        console.error('Unable to load docs:', error);
      });
  }, [thisProps.docType, thisProps.featureFlag]);

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) element.scrollIntoView();
    }
  }, [state.fetching]);

  if (state.fetching) return <Loader />;

  return (
    <div className="documentation-container">
      <PrintTitlePage />
      <Markdown
        options={{
          overrides: {
            AllowedDataSourceList: {
              // eslint-disable-next-line react/no-unstable-nested-components
              component: () => (<AllowedDataSourceList clientConfig={thisProps.clientConfig} />),
            },
            File: {
              // eslint-disable-next-line react/no-unstable-nested-components
              component: (props) => <File name={props.name} docType={thisProps.docType} featureFlag={thisProps.featureFlag} />,
            },
            Image: {
              // eslint-disable-next-line react/no-unstable-nested-components
              component: (props) => <Image src={props.src} alt={props.alt} docType={thisProps.docType} width={props.width} />,
            },
            InfoBlock: { component: InfoBlock },
            Column: { component: Column },
            AttributesTable: { component: AttributesTable },
            AppendixTable: { component: AppendixTable },
            TableRowItem: { component: TableRowItem },
            // eslint-disable-next-line react/no-unstable-nested-components
            table: { component: (props) => (<div>{props.children}</div>) },
            thead: { component: () => null },
            // eslint-disable-next-line react/no-unstable-nested-components
            tbody: { component: (props) => (<div>{props.children}</div>) },
            // eslint-disable-next-line react/no-unstable-nested-components
            tr: { component: (props) => (<section>{props.children}</section>) },
            // eslint-disable-next-line react/no-unstable-nested-components
            td: { component: (props) => (<span>{props.children}</span>) },
            FeatureFlagBlock: {
              component: (props) => {
                const { featureFlag } = props;
                if (!featureFlag) return null;
                if (!thisProps.featureFlags) return null;

                const enabled = thisProps.featureFlags[featureFlag];
                if (!enabled) return null;

                return props.children;
              },
            },
          },
        }}
      >
        { state.docs }
      </Markdown>
      <div className="print-only-doc-footer">
        Trust Science
        <sup>®</sup>
        &nbsp;
        Six°Score™ API Documentation [DocVer:
        {formatDocVer()}
        ]
      </div>
    </div>
  );
}

export default Documentation;
