import api from '../../../../services/api';

export default async (payload, isUat) => {
  const result = await api('PatchClientConfig', {
    patches: payload,
    isUat,
  });
  if (result.error) {
    throw new Error('Unable to update the config. Contact Trust Science support.');
  }
  return result;
};
