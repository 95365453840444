import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Spinner,
} from 'reactstrap';
import ReactJson from 'react-json-view';

import api from '../../../services/api';

export default function (props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureBlocks, setFeatureBlocks] = useState('');
  const [products, setProducts] = useState('');
  const [flowBuilderPayload, setFlowBuilderPayload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { requestId, isUat } = props;

  const runGenerateFlowBuilder = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const result = await api('RunGenerateFlowBuilder', {
        requestId,
        featureBlocks: featureBlocks.split(',').map((f) => f.trim()),
        products: products.split(',').map((f) => f.trim()),
        isUat,
      });

      if (result.error) {
        setError(result.error);
        return;
      }
      const response = await fetch(result.url);
      if (!response.ok) {
        setError(`S3 error ${response.status}`);
        return;
      }
      const { payload } = await response.json();
      setFlowBuilderPayload(payload);
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Button onClick={toggle} color="primary">
        Generate FlowBuilder
      </Button>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        size="xl"
      >
        <ModalHeader toggle={toggle}>
          Generate FlowBuilder Data Input
        </ModalHeader>
        <ModalBody className="m-4">
          <FormGroup row>
            <Label sm={3}>Feature Blocks</Label>
            <Col sm={9}>
              <Input
                type="text"
                value={featureBlocks}
                onChange={(e) => setFeatureBlocks(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Products</Label>
            <Col sm={9}>
              <Input
                type="text"
                value={products}
                onChange={(e) => setProducts(e.target.value)}
              />
            </Col>
          </FormGroup>
          <div className="d-flex justify-content-center pt-3">
            { isLoading ? <Spinner /> : null}
            { flowBuilderPayload ? <ReactJson src={flowBuilderPayload} name={null} /> : null}
            { error ? <div className="flex-fill alert alert-warning" role="alert">{ error }</div> : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={runGenerateFlowBuilder}
            disabled={isLoading}
          >
            Generate
          </Button>
          {' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
