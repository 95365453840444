import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import styles from './Reports.module.scss';
import getSignedSigmaEmbeds from '../../utils/get-signed-sigma-embeds';

function Report() {
  const [isLoading, setLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [activeReport, setActiveReport] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const sigmaEmbedResponse = await getSignedSigmaEmbeds();
      if (!sigmaEmbedResponse.embedUrls) throw new Error(`Unexpected response ${JSON.stringify(sigmaEmbedResponse)}`);
      setLinks(sigmaEmbedResponse.embedUrls);
    };
    fetchData()
      .catch((error) => console.error('Error retrieving sigma embed urls: ', error))
      .finally(setLoading(false));
  }, []);

  useEffect(() => {
    if (links.length > 0) setActiveReport(links[0].reportName);
  }, [links]);

  function selectLink(e) {
    e.preventDefault();
    const title = e.target.innerText;
    setActiveReport(title);
  }

  if (isLoading) return <Loader />;

  return (
    <div className="d-flex flex-column">
      <h4 className="h4 text-center">{activeReport}</h4>
      <div className={`${styles.nh} d-flex flex-row flex-wrap`}>
        <ul className="nav nav-pills bg-light flex-column">
          {links.map((reportObj) => (
            <li className="nav-item" key={reportObj.reportName}>
              <a className={`nav-link ${reportObj.reportName === activeReport ? styles.active : ''}`} href={reportObj.url} onClick={selectLink}>{reportObj.reportName}</a>
            </li>
          ))}

        </ul>
        {links.map((reportObj) => (
          <iframe
            key={reportObj.reportName}
            title={reportObj.reportName}
            className={`flex-grow-1 embed-responsive-item ${reportObj.reportName !== activeReport ? styles.invisible : ''}`}
            src={reportObj.url}
          />
        ))}
      </div>
    </div>
  );
}

export default Report;
