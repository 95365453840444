import React, { Component } from 'react';
import { Spinner, Table } from 'reactstrap';
import Filters from '../../components/RequestHistory/Filters';
import ExportCSV from '../../components/RequestHistory/ExportCSV';
import TableRow from '../../components/RequestHistory/TableRow';
import TableFooter from '../../components/Table/TableFooter';
import TableHeader from '../../components/Table/TableHeader';
import whiteList from '../../config/scoringRequestDetailsWhitelist';
import api from '../../services/api';
import { getRequestDetails } from '../../services/scoring-request-services';

import EnvironmentDropDown from '../../components/DropDown/EnvironmentDropDown';
import EnvironmentDropDownFixed from '../../components/DropDown/EnvironmentDropDownFixed';
import { withUseEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { withUseSelectedEnvironment } from '../../hooks/useSelectedEnvironment';
import styles from './RequestHistory.module.scss';

const messageRowShell = (columnCount, content) => (
  <tr>
    <td colSpan={columnCount} className={styles.noRecords}>
      {content}
    </td>
  </tr>
);

const getIsUat = (props) => {
  const { environment } = props.useSelectedEnvironmentProp;
  return environment === 'uat';
};

class RequestHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      requests: [],
      requestDetails: {},
      count: 0,
      pageSize: 18,
      currentPage: 0,
      sortBy: {
        column: 'create_at',
        dec: true,
      },
      filters: {},
      search: {},
    };
  }

  componentDidMount() {
    this.requestTableData();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.useSelectedEnvironmentProp.environment !== this.props.useSelectedEnvironmentProp.environment) {
      this.requestTableData();
    }
  }

  changePage = (newPage) => {
    this.setState({ currentPage: newPage }, this.requestTableData);
  };

  tableHeaderClick = (event) => {
    const { target: { dataset: { id } } } = event;
    if (!id) {
      event.stopPropagation();
      return null;
    }

    this.setState((prevState) => ({
      sortBy: {
        column: id,
        asc: prevState.sortBy.column === id ? !prevState.sortBy.asc : true,
      },
    }), this.requestTableData);

    return null;
  };

  listItemClick = (item) => {
    const requestDetails = this.state.requestDetails[item.request_id];
    const show = !(requestDetails && requestDetails.show);
    this.updateRequestDetailsInState(item.request_id, {
      show,
      loading: show,
    });

    if (!show) {
      return;
    }

    if (requestDetails && requestDetails.person) {
      // Most likely is cached. No need to make an extra request.
      this.updateRequestDetailsInState(item.request_id, {
        loading: false,
      });
      return;
    }

    getRequestDetails(item.request_id, getIsUat(this.props))
      .then((details) => {
        this.updateRequestDetailsInState(item.request_id, {
          ...details,
          loading: false,
          error: !!details.error,
        });
      })
      .catch((err) => {
        console.error('Getting request details', err);
        this.updateRequestDetailsInState(item.request_id, {
          loading: false,
          error: true,
        });
      });
  };

  performSearch = (filters, search) => {
    const mappedFilters = {};
    const rules = {
      any: () => { },
      yes: (name) => { mappedFilters[name] = true; },
      no: (name) => { mappedFilters[name] = false; },
    };

    Object.entries(filters).forEach(([key, value]) => {
      if (rules[value.toLowerCase()]) rules[value.toLowerCase()](key);
      else mappedFilters[key] = value || undefined;
    });

    this.setState({ filters: mappedFilters, search, currentPage: 0 }, this.requestTableData);
  };

  updateRequestDetailsInState(requestId, details, callback) {
    if (!requestId || !details) {
      return;
    }

    const scoringApplication = {};

    Object
      .keys(details)
      .filter((key) => whiteList.includes(key))
      .forEach((key) => {
        scoringApplication[key] = details[key];
      });

    this.setState((prevState) => ({
      requestDetails: {
        ...prevState.requestDetails,
        [requestId]: {
          ...prevState.requestDetails[requestId],
          ...details,
          scoringApplication,
        },
      },
    }), () => {
      if (callback) callback();
    });
  }

  async requestTableData() {
    const requestTimestamp = Date.now();
    this.setState({
      loading: true,
      error: false,
      requests: [],
      count: 0,
      mostRecentRequest: requestTimestamp,
    });

    const body = {
      size: this.state.pageSize,
      pageNumber: this.state.currentPage,
      sortBy: this.state.sortBy,
      filters: this.state.filters,
      search: this.state.search,
      isUat: getIsUat(this.props),
    };

    try {
      const response = await api('RequestHistoryList', body);
      if (this.state.mostRecentRequest !== requestTimestamp) {
        return;
      }
      const result = JSON.parse(response.body);
      this.setState({
        loading: false,
        error: false,
        requests: result.requests,
        count: result.total,
      });
    } catch (error) {
      if (this.state.mostRecentRequest !== requestTimestamp) {
        return;
      }
      console.error('Error:', error);
      this.setState({
        error: true,
        loading: false,
      });
    }
  }

  renderRows(columnCount) {
    if (this.state.loading) {
      return messageRowShell(columnCount, <Spinner />);
    }

    if (this.state.error) {
      return messageRowShell(columnCount, 'There was an error retrieving your scoring requests history. Please try again.');
    }

    if (this.state.count === 0) {
      return messageRowShell(columnCount, 'No requests were found. Try modifying your filter criteria.');
    }

    if (!this.state.requests) {
      return null;
    }

    return this.state.requests.map((item) => (
      <TableRow
        key={item.id}
        item={item}
        requestDetails={this.state.requestDetails[item.request_id]}
        click={this.listItemClick}
        columnCount={columnCount}
        isUat={getIsUat(this.props)}
      />
    ));
  }

  render() {
    const totalRequestsCount = this.state.count || 0;
    const pageCount = Math.ceil(totalRequestsCount / this.state.pageSize);
    const headerItems = [
      /* eslint-disable object-curly-newline */
      { id: 'first_name', name: 'First Name', header: true, search: true },
      { id: 'last_name', name: 'Last Name', header: true, search: true },
      { id: 'birthday', name: 'Birthday', header: false, search: true, type: 'date' },
      { id: 'identifier', name: 'SSN / SIN', header: false, search: this.props.isSSNSearchAllowed, helpText: 'XXXXXXXXX', isAFilter: true },
      { id: 'client_customer_id', name: 'Customer ID', header: true, search: true },
      { id: 'client_loan_reference_id', name: 'Loan Reference ID', header: true, search: true },
      { id: 'score', name: 'Score', header: true },
      { id: 'user_id', name: 'Prepared By', header: true, search: true },
      { id: 'from', name: 'Date From', header: false, search: true, type: 'date' },
      { id: 'to', name: 'Date To', header: false, search: true, type: 'date' },
      { id: 'phone', name: 'Phone', header: false, search: true, helpText: '+19999999999' },
      { id: 'street_address', name: 'Address', header: false, search: true },
      { id: 'city', name: 'City', header: false, search: true },
      { id: 'state', name: 'Province/State', header: false, search: true },
      { id: 'postal_code', name: 'Post/Zip Code', header: false, search: true },
      { id: 'email', name: 'Email', header: false, search: true },
      { id: 'store_number', name: 'Store Number', header: true, search: true, isAFilter: true },
      { id: 'store_state', name: 'Store State', header: true },
      { id: 'create_at', name: 'Created At', header: true },
      { id: '', name: 'Link', header: true },
      /* eslint-enable object-curly-newline */
    ];

    const isUat = getIsUat(this.props);
    const requestHistoryAccessUat = this.props.useEnabledFeaturesUatProp.requestHistory;
    const requestHistoryAccessProd = this.props.useEnabledFeaturesProdProp.requestHistory;
    const requestHistoryAccessBoth = requestHistoryAccessUat && requestHistoryAccessProd;
    const enableExportCsv = isUat
      ? this.props.useEnabledFeaturesUatProp.enableExportRequestHistoryCsv
      : this.props.useEnabledFeaturesProdProp.enableExportRequestHistoryCsv;

    return (
      <div className={styles.requestsHistoryContainer}>
        <div className="row">
          <div className="col-3 d-flex align-items-center">
            <Filters
              performSearch={this.performSearch}
              colSpan={headerItems.length}
              items={headerItems}
              filters={this.state.filters}
              searchTerms={this.state.search}
              region={this.props.region}
            />
            {enableExportCsv && (
            <ExportCSV
              sortBy={this.state.sortBy}
              filters={this.state.filters}
              search={this.state.search}
              isUat={isUat}
            />
            )}
          </div>
          <div className="col-9 d-flex justify-content-end align-items-center">
            {requestHistoryAccessBoth ? <EnvironmentDropDown /> : (
              <EnvironmentDropDownFixed environment={requestHistoryAccessUat ? 'uat' : 'prod'} />
            )}
          </div>
        </div>
        <Table striped size="sm">
          <TableHeader
            items={headerItems.filter((item) => item.header)}
            sortBy={this.state.sortBy}
            sortClick={this.tableHeaderClick}
          />
          <tbody>
            {this.renderRows(headerItems.length)}
          </tbody>
        </Table>
        <TableFooter
          headerItemCount={headerItems.length}
          pageCount={pageCount}
          currentPage={this.state.currentPage}
          changePage={this.changePage}
        />
      </div>
    );
  }
}

export default withUseEnabledFeatures(
  withUseSelectedEnvironment(
    RequestHistory,
  ),
);
