import React from 'react';

function withErrorWrapper(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        info: '',
      };
    }

    componentDidCatch(error, info) {
      this.setState({
        hasError: true,
        message: error.message,
        info,
      });
    }

    render() {
      if (this.state.hasError) {
        const { info, message } = this.state;
        console.error(message, info);
        return null;
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...this.props} />;
    }
  };
}

export default withErrorWrapper;
