import React, { useState } from 'react';
import {
  faClone, faEye, faEyeSlash, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '../../../hooks/useModal';
import api from '../../../services/api';

import styles from '../ManageAccount.module.scss';
import { useEnabledFeatures } from '../../../hooks/useEnabledFeatures';
import Loader from '../../../components/Loader';
import { formatLongDateTime } from '../../../utils/format-date';
import capitalize from '../../../utils/capitalize';

library.add(faClone, faEye, faEyeSlash, faTrash);

function copyToClipboard(value) {
  const tempElement = document.createElement('input');
  tempElement.value = value;
  document.body.appendChild(tempElement);
  tempElement.select();
  document.execCommand('copy');
  tempElement.remove();
}

function ShowButton({ apiKey, title, toggleShow }) {
  const [icon, text] = apiKey.isShown ? [faEyeSlash, 'Hide'] : [faEye, 'Show'];
  return (
    <button id={`show-btn-${apiKey.id}`} data-cy={`show-btn-apikeys-${title}`} type="button" onClick={() => toggleShow(apiKey.id)}>
      <FontAwesomeIcon icon={icon} size="sm" />
      <span>{ text }</span>
    </button>
  );
}

const ApiKeys = ({
  keys, title, toggleShow, setState, environmentKey, allowAPIKeyManagement,
}) => {
  const { settingsDeleteAPIKey: settingsDeleteAPIKeyNew } = useEnabledFeatures({ env: environmentKey });
  // Deprecated Remove in APP-4532
  const { settingsDeleteAPIKey: settingsDeleteAPIKeyLegacy } = useEnabledFeatures({ env: 'prod' });
  const settingsDeleteAPIKey = settingsDeleteAPIKeyNew || settingsDeleteAPIKeyLegacy;
  const { showModal, hideModal } = useModal();

  return keys.map((apiKey) => {
    if (apiKey.hidden) return null;
    return (
      <div className={['row', 'mt-3', styles.apiKey].join(' ')} key={`api-key-${environmentKey}-${apiKey.id}`}>
        <div className="col-9">
          {'Created: '}
          {formatLongDateTime(apiKey.createdDate)}
          <br />
          {allowAPIKeyManagement ? (
            <div className="custom-control custom-switch align-middle">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={apiKey.enabled}
                disabled={!settingsDeleteAPIKey}
                readOnly
                id={`customSwitch-${apiKey.id}`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const disableEnableText = apiKey.enabled ? 'disable' : 'enable';
                  showModal({
                    header: `${capitalize(disableEnableText)} API Key`,
                    body: `Are you sure you want to ${disableEnableText} this API key?`,
                    buttons: [
                      {
                        color: 'danger',
                        text: `Yes, ${disableEnableText}`,
                        onClick: async () => {
                          setState((prevState) => ({
                            ...prevState,
                            apiKeys: prevState.apiKeys.map((key) => ({
                              ...key,
                              enabled: key.id === apiKey.id ? !key.enabled : key.enabled,
                            })),
                          }));
                          hideModal();
                          let response;
                          if (apiKey.enabled) {
                            response = await api('DisableApiKey', {
                              environmentKey,
                              keyId: apiKey.id,
                            });
                          } else {
                            response = await api('EnableApiKey', {
                              environmentKey,
                              keyId: apiKey.id,
                            });
                          }
                          if (response.errorMessage) {
                            showModal({
                              header: `${capitalize(disableEnableText)} API Key failed`,
                              body: 'Please try again later.',
                            });
                            console.error(`Enable/Disable API Key failed: ${response.errorMessage}`, response);
                            // Restore state
                            setState((prevState) => ({
                              ...prevState,
                              apiKeys: prevState.apiKeys.map((key) => ({
                                ...key,
                                enabled: key.id === apiKey.id ? apiKey.enabled : key.enabled,
                              })),
                            }));
                          } else if (disableEnableText === 'enable') {
                            showModal({
                              header: 'API Key Enabled',
                              body: 'API key may take up to a minute to become active again.',
                            });
                          }
                        },
                      },
                      {
                        color: 'secondary',
                        text: 'Cancel',
                        onClick: () => {
                          hideModal();
                        },
                      },
                    ],
                  });
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="custom-control-label align-middle" htmlFor={`customSwitch-${apiKey.id}`}>
                { apiKey.enabled ? 'Enabled' : 'Disabled'}
              </label>
              <button
                className="btn text-danger ml-4 border-0 p-0 align-middle"
                type="button"
                hidden={!settingsDeleteAPIKey}
                onClick={() => {
                  showModal({
                    header: 'Delete API Key',
                    body: 'Are you sure you want to delete this API key?',
                    buttons: [
                      {
                        color: 'danger',
                        text: 'Yes, delete',
                        onClick: async () => {
                          setState((prevState) => ({
                            ...prevState,
                            apiKeys: prevState.apiKeys.map((key) => ({
                              ...key,
                              hidden: key.id === apiKey.id ? true : key.hidden,
                            })),
                          }));
                          hideModal();
                          const response = await api('DeleteApiKey', {
                            environmentKey,
                            keyId: apiKey.id,
                          });
                          if (response.errorMessage) {
                            showModal({
                              header: 'Delete API Key failed',
                              body: 'Please try again later.',
                            });
                            console.error(`Delete API Key failed: ${response.errorMessage}`, response);
                            // Restore state
                            setState((prevState) => ({
                              ...prevState,
                              apiKeys: prevState.apiKeys.map((key) => ({
                                ...key,
                                hidden: key.id === apiKey.id ? undefined : key.hidden,
                              })),
                            }));
                          } else {
                          // Remove key if delete was successful
                            setState((prevState) => ({
                              ...prevState,
                              apiKeys: prevState.apiKeys.filter((key) => (key.id !== apiKey.id)),
                            }));
                          }
                        },
                      },
                      {
                        color: 'secondary',
                        text: 'Cancel',
                        onClick: () => {
                          hideModal();
                        },
                      },
                    ],
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
                {' '}
                Delete
              </button>
            </div>
          ) : null}
          <span id={apiKey.id} data-cy={`inputSpace-apikeys-${title}`} className={[styles.value, '_lr-hide'].join(' ')}>
            { apiKey.isShown ? apiKey.value : '*'.repeat(apiKey.value.length) }
          </span>
          <ShowButton apiKey={apiKey} title={title} toggleShow={toggleShow} />
          <button type="button" onClick={() => copyToClipboard(apiKey.value)}>
            <FontAwesomeIcon icon={faClone} size="sm" />
            <span>Copy</span>
          </button>
        </div>
      </div>
    );
  });
};

export function KeySection({
  title, keys, environmentKey, allowAPIKeyManagement,
}) {
  const { showModal } = useModal();

  const [state, setState] = useState({ apiKeys: keys || [], isGeneratingKey: false });

  const { apiKeys, isGeneratingKey } = state;
  const renderKeys = apiKeys.length > 0;

  if (allowAPIKeyManagement === false && renderKeys === false) return null;

  const toggleShow = (targetKeyId) => {
    setState((prevState) => {
      const { apiKeys: prevApiKeys } = prevState;
      return {
        ...prevState,
        apiKeys: prevApiKeys.map((key) => ({
          ...key,
          isShown: key.id === targetKeyId ? !key.isShown : key.isShown,
        })),
      };
    });
  };

  return (
    <div className="card mt-2">
      <div className="card-header">
        <div className="d-flex">
          <div>
            { title }
          </div>
          {allowAPIKeyManagement ? (
            <div className="ml-auto">
              <button
                type="button"
                className={['btn', 'btn-primary', 'btn-block'].join(' ')}
                onClick={async () => {
                  setState((prevState) => ({
                    ...prevState,
                    isGeneratingKey: true,
                  }));
                  const response = await api('CreateApiKey', {
                    environmentKey,
                  });
                  if (response.errorMessage) {
                    showModal({
                      header: 'Create API Key failed',
                      body: `Error creating API key: ${response.errorMessage}`,
                    });
                    console.error(`Create API Key failed: ${response.errorMessage}`, response);
                    setState((prevState) => ({
                      ...prevState,
                      isGeneratingKey: false,
                    }));
                  } else {
                    showModal({
                      header: 'API Key Created',
                      body: 'API key may take up to a minute to become active.',
                    });
                    const apiKey = JSON.parse(response.body);
                    apiKey.isShown = true;
                    setState((prevState) => ({
                      ...prevState,
                      isGeneratingKey: false,
                      apiKeys: [apiKey, ...prevState.apiKeys],
                    }));
                  }
                }}
              >
                Generate New API Key
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {renderKeys ? (
        <div className="card-body">
          {isGeneratingKey ? (
            <div className="row mt-3 mb-5">
              <div className="col-9 mb-5">
                <Loader />
              </div>
            </div>
          ) : null}
          <ApiKeys keys={apiKeys} title={title} toggleShow={toggleShow} setState={setState} environmentKey={environmentKey} allowAPIKeyManagement={allowAPIKeyManagement} />
        </div>
      ) : null}
    </div>
  );
}

export default KeySection;
