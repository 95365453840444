import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownSubMenu from '../DropdownSubMenu';

import styles from './NavDropdown.module.scss';

export default function NavDropdown(props) {
  const [dropdownOpened, toggleDropdownOpen] = useState(false);
  const { navItem } = props;
  const subMenuItems = navItem.items;
  const inlinePlacementOrderStyle = { order: navItem.navOrder };
  const buttonClasses = [styles.navSubmenuDropdown];

  const navDropdownClickHandler = (path) => {
    toggleDropdownOpen(!dropdownOpened);
    props.clickHandler(path);
  };

  if (props.selected) {
    buttonClasses.push(styles.selected);
  }

  if (props.iconNav) {
    buttonClasses.push(styles.iconNav);
  }

  return (
    <Dropdown
      isOpen={dropdownOpened}
      className={buttonClasses.join(' ')}
      style={inlinePlacementOrderStyle}
      toggle={() => toggleDropdownOpen(!dropdownOpened)}
    >
      <DropdownToggle color="white" outline={false}>
        {props.label}
      </DropdownToggle>
      <DropdownMenu right>
        {
          subMenuItems.map((item, index) => {
            if (item.type === 'multiple') {
              return (
                <DropdownSubMenu
                  key={item.name}
                  label={item.label}
                  icon={navItem.icon || ''}
                  displayIcon={false}
                  navItem={item}
                  clickHandler={navDropdownClickHandler}
                  selected={props.selected}
                  dropdownState={toggleDropdownOpen}
                />
              );
            }
            if (item.type === 'single') {
              return (
                <DropdownItem onClick={() => props.clickHandler(item.path)} key={item.label}>
                  <span className="mr-2">
                    { item.label }
                  </span>
                  {
                    item.displayIcon && (
                      <FontAwesomeIcon icon={item.displayIcon} color={styles.iconColour} size="sm" />
                    )
                  }
                </DropdownItem>
              );
            }
            if (item.type === 'divider') {
              // eslint-disable-next-line react/no-array-index-key
              return <hr key={`divider${index}`} />;
            }

            return null;
          })
        }
      </DropdownMenu>
    </Dropdown>
  );
}
