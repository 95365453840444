import { formatUSPhoneNumber } from './phoneNumberHelpers';
import initializeCustomFields from './initialize-custom-fields';

const employerIncomeFields = [
  'name',
];

const incomeFields = [
  'employer',
  'monthlyIncomeGross',
  'monthlyIncomeNet',
  'paymentsPerYear',
];

function processPhoneNumber(phoneType, phones) {
  const phone = phones.find((p) => p[phoneType]);
  return phone ? phone[phoneType].slice(1) : '';
}

const safeGetElement = (key, obj, index = 0) => {
  if (!(key in obj)) return '';
  if (!Array.isArray(obj[key])) return '';
  if (obj[key].length <= index) return '';
  return obj[key][index];
};

const findEmailByType = (person, type) => {
  if (!person.emails) return '';
  if (!Array.isArray(person.emails)) return '';
  const result = person.emails.find((e) => (type in e));
  if (!result) return '';
  return result[type];
};

export function formatPerson(person, formatPhoneNumber = true) {
  const residence = safeGetElement('addresses', person);
  const primaryEmail = findEmailByType(person, 'primary');
  const otherEmail = findEmailByType(person, 'other');
  const personData = {
    firstName: person.firstName,
    lastName: person.lastName,
    middleName: person.middleName,
    dateOfBirth: person.birthday,
    aliases: safeGetElement('aliases', person),
    address: residence && residence.streetAddress,
    city: residence && residence.city,
    state: residence && residence.state,
    postalCode: residence && residence.postalCode,
    country: residence && residence.country,
    isCurrentResidence: '',
    monthlyResidenceCost: residence && residence.monthlyHousingCost,
    durationAtResidence: residence && residence.monthsAtResidence,
    email1: primaryEmail,
    email2: otherEmail,
  };

  if (residence && residence.residenceStatus) {
    switch (residence.residenceStatus.toLowerCase()) {
      case 'own':
        personData.residenceStatus = 'Own';
        break;
      case 'rent':
        personData.residenceStatus = 'Rent';
        break;
      case 'family':
        personData.residenceStatus = 'Family';
        break;
      default:
        personData.residenceStatus = 'Other';
    }
  }

  if (residence) {
    if (typeof residence.isCurrentResidence !== 'undefined') {
      personData.isCurrentResidence = residence.isCurrentResidence ? 'yes' : 'no';
    }
  }

  if (person.phones) {
    personData.homePhoneNumber = processPhoneNumber('home', person.phones);
    personData.cellPhoneNumber = processPhoneNumber('mobile', person.phones);
    personData.workPhoneNumber = processPhoneNumber('work', person.phones);

    if (formatPhoneNumber) {
      personData.homePhoneNumber = formatUSPhoneNumber(personData.homePhoneNumber);
      personData.cellPhoneNumber = formatUSPhoneNumber(personData.cellPhoneNumber);
      personData.workPhoneNumber = formatUSPhoneNumber(personData.workPhoneNumber);
    } else {
      personData.homePhoneNumber = personData.homePhoneNumber
        .substring(1, personData.homePhoneNumber.length);
      personData.cellPhoneNumber = personData.cellPhoneNumber
        .substring(1, personData.cellPhoneNumber.length);
      personData.workPhoneNumber = personData.workPhoneNumber
        .substring(1, personData.workPhoneNumber.length);
    }
  }

  if (person.identifiers && Array.isArray(person.identifiers)) {
    person.identifiers.forEach((identifier) => {
      if (identifier.value && identifier.type && identifier.type.match(/driver/i)) {
        personData.driversLicense = identifier.value;
        return;
      }

      if (
        identifier.value
        && identifier.type
        && (
          identifier.type.match(/insurance|security/i)
          || identifier.type.toLowerCase() === 'ssn'
          || identifier.type.toLowerCase() === 'sin'
        )
      ) {
        personData.socialInsuranceNumber = identifier.value;
      }
    });
  }

  return personData;
}

export function formatLoadDetails(loanData, customFieldsConfig) {
  const customFieldsFromRequest = [].concat(loanData.custom)
    .filter((c) => c)
    .reduce((result, c) => ({
      ...result,
      ...Object.keys(c)
        .reduce((collector, name) => ({ ...collector, [`custom_${name}`]: c[name] }), {}),
    }), {});
  const customFields = initializeCustomFields(
    customFieldsConfig?.loanInfo,
    customFieldsFromRequest,
  );
  return {
    loanDate: (loanData?.dateOriginated || loanData?.dateApplied),
    loanId: loanData.clientLoanReferenceId,
    customerId: loanData.clientCustomerId,
    principalAmount: loanData.principalAmount,
    downPaymentAmount: loanData.downPayment,
    annualInterestRate: loanData.annualInterestRate,
    paymentsPerYear: loanData.paymentsPerYear,
    totalNumberOfPayments: loanData.totalNumberOfPayments,
    paymentAmount: loanData.paymentAmount,
    serviceCharge: loanData.serviceCharge,
    feeAmount: loanData.feeAmount,
    termMonth: loanData.termMonth,
    storeNumber: loanData.storeNumber,
    storeState: loanData.storeState,
    ...customFields,
  };
}

export function formatCurrentEmployment(currentEmployment, formatPhoneNumber = true) {
  const employerInfo = {
    jobTitle: currentEmployment.jobTitle,
    employmentType: currentEmployment.employmentType,
    address: currentEmployment.employer
      && currentEmployment.employer.address && currentEmployment.employer.address.streetAddress,
    city: currentEmployment.employer
      && currentEmployment.employer.address && currentEmployment.employer.address.city,
    state: currentEmployment.employer
      && currentEmployment.employer.address && currentEmployment.employer.address.state,
    postalCode: currentEmployment.employer
      && currentEmployment.employer.address && currentEmployment.employer.address.postalCode,
    country: currentEmployment.employer
      && currentEmployment.employer.address && currentEmployment.employer.address.country,
    durationAtEmployer: currentEmployment.employmentMonthCount,
    name: currentEmployment.employer?.name,
    grossIncome: currentEmployment.monthlyIncomeGross,
    netIncome: currentEmployment.monthlyIncomeNet,
    paymentsPerYear: currentEmployment.paymentsPerYear,
  };

  if (currentEmployment.employer && currentEmployment.employer.phone) {
    employerInfo.phoneNumber = currentEmployment.employer.phone;

    if (formatPhoneNumber) {
      employerInfo.phoneNumber = formatUSPhoneNumber(employerInfo.phoneNumber);
    } else {
      employerInfo.phoneNumber = employerInfo.phoneNumber.split('+1').pop();
    }
  }

  return employerInfo;
}

export function formatCurrentIncome(currentEmployment) {
  return {
    name: currentEmployment.employer?.name,
    grossIncome: currentEmployment.monthlyIncomeGross,
    netIncome: currentEmployment.monthlyIncomeNet,
    paymentsPerYear: currentEmployment.paymentsPerYear,
  };
}

export function formatAutoLoan(autoLoan) {
  return {
    tradeInAllowance: autoLoan.tradeInAllowance,
    VIN: autoLoan.vehicle && autoLoan.vehicle.VIN,
    appraisedValue: autoLoan.vehicle && autoLoan.vehicle.appraisedValue,
    bodyStyle: autoLoan.vehicle && autoLoan.vehicle.bodyStyle,
    year: autoLoan.vehicle && autoLoan.vehicle.year,
    make: autoLoan.vehicle && autoLoan.vehicle.make,
    model: autoLoan.vehicle && autoLoan.vehicle.model,
    trim: autoLoan.vehicle && autoLoan.vehicle.trim,
    condition: autoLoan.vehicle && autoLoan.vehicle.condition,
    odometer: autoLoan.vehicle?.odometer?.value,
    odometerUnits: autoLoan.vehicle?.odometer?.units,
    value: autoLoan.vehicle && autoLoan.vehicle.value,
  };
}

export function formatTradeInVehicle(tradeIn) {
  return {
    tradeInAllowance: tradeIn.tradeInAllowance,
    VIN: tradeIn.VIN,
    appraisedValue: tradeIn.appraisedValue,
    bodyStyle: tradeIn.bodyStyle,
    year: tradeIn.year,
    make: tradeIn.make,
    model: tradeIn.model,
    trim: tradeIn.trim,
    condition: tradeIn.condition,
    odometer: tradeIn?.odometer?.value,
    odometerUnits: tradeIn?.odometer?.units,
    value: tradeIn.value,
  };
}

export function formatDealership(dealership) {
  return {
    name: dealership.name,
    address: dealership.address && dealership.address.streetAddress,
    city: dealership.address && dealership.address.city,
    state: dealership.address && dealership.address.state,
    postalCode: dealership.address && dealership.address.postalCode,
    country: dealership.address && dealership.address.country,
    type: dealership.type,
  };
}

const hasNonIncomeValues = (item, fields) => {
  const employmentFields = Object.keys(item).filter((k) => !fields.includes(k));
  return !!employmentFields.map((k) => item[k]).filter((v) => !!v).length;
};

export const employedToggleState = (currentEmployment) => (
  hasNonIncomeValues(currentEmployment, incomeFields)
  || hasNonIncomeValues(currentEmployment.employer, employerIncomeFields)
);

export const formatCustomFields = (loanApplication) => loanApplication?.custom?.map((custom) => ({
  data: custom,
  labels: Object.fromEntries(Object.keys(custom).map((key) => [key, key])),
})) ?? [];
