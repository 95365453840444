import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import api from '../../services/api';
import getClientConfig from '../../utils/get-client-config';

const extractParams = (query) => {
  // APP-4055 12-19-2023 could not use URLSearchParams as it strips `+` from emails
  const params = query.slice(1).split('&');
  const extractedParams = {};
  params.forEach((param) => {
    const [key, value] = param.split('=');
    extractedParams[key] = value;
  });
  return extractedParams;
};

export default function (props) {
  const { onClose, userAccount, searchParams } = props;
  const urlParams = extractParams(searchParams);
  const { code, email } = urlParams;
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  window.history.pushState({}, '', `/email-verification${searchParams}`);

  useEffect(() => {
    const errorMessages = {
      ExpiredCodeException: 'Verification link has expired. Please request a new link.',
      CodeMismatchException: 'Verification link is invalid.',
      LimitExceededException: 'Please try again later.',
    };
    const isVerified = async () => {
      if (userAccount.email !== email) {
        setVerificationStatus('Email could not be verified. Please ensure you are logged in to the correct account.');
        return false;
      }

      if (userAccount.email_verified) {
        setVerificationStatus('Email is already verified.');
        return true;
      }

      const result = await api('CheckEmailVerification', {
        accessToken: userAccount.accessToken,
        attributeName: 'email',
        code,
      });

      if (result?.$metadata?.httpStatusCode === 200) {
        setVerificationStatus('Email verified successfully!');
        await getClientConfig({ forceRefresh: true });
        return true;
      }
      console.error(result.errorType, result.errorMessage);
      setVerificationStatus(`Email could not be verified. ${errorMessages[result.errorType] || ''}`);
      return false;
    };
    isVerified();
  }, [code, email, userAccount]);

  return (
    <Modal isOpen>
      <ModalHeader>Email Verification</ModalHeader>
      <ModalBody>
        <p>
          {verificationStatus}
        </p>

      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
