import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

const renderNoteAboutDateRanges = ({ from, to }) => {
  if (from) return null;
  const date = to || 'today';
  return (
    <div className="mt-3">
      <strong>Note:</strong>
      The date range is not set. The export will include the last 30 days of data from&nbsp;
      {date}
      .
    </div>
  );
};

export default function ExportCSVModalView(props) {
  return (
    <Modal isOpen>
      <ModalHeader>Export Request History to CSV</ModalHeader>
      <ModalBody>
        <div>
          Use the current search filters to generate a CSV export of the scoring request history.
          Exports are limited to 100,000 records and larger exports can take several minutes to complete.
          The CSV file will automatically download once ready.
        </div>
        {
          renderNoteAboutDateRanges(props.search)
        }
        {
          props.isExporting && !props.exportResult && (
            <div className="mt-3">
              The CSV is being generated. Please keep this window open until it completes.
            </div>
          )
        }
        {
          props.exportResult?.error && (
            <div className="mt-3 text-danger">
              {props.exportResult.error}
            </div>
          )
        }
        {
          props.exportResult?.success && (
            <>
              <div className="mt-3 text-success">
                The CSV was successfully generated.
              </div>
              <div className="mt-3">
                If the file did not automatically download, you can
                <a href={props.exportResult.url}>click here</a>
                to download it.
              </div>
            </>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={props.onSubmit}
          disabled={props.isExporting || props.exportResult?.success}
        >
          { props.isExporting ? <Spinner size="sm" color="white" /> : 'Export CSV' }
        </Button>
        {' '}
        <Button color="secondary" onClick={props.onClose}>
          { props.exportResult ? 'Close' : 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
