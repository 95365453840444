import React from 'react';

import { useSessionStorageValue } from '@react-hookz/web';
import { useEnabledFeatures } from './useEnabledFeatures';

export const useSelectedEnvironment = () => {
  const { value: environment, set: setEnvironment } = useSessionStorageValue('selected-environment', {
    defaultValue: 'prod',
  });

  const { useUatProdFlags } = useEnabledFeatures();

  if (environment !== 'prod' && !useUatProdFlags) {
    setEnvironment('prod'); // UAT is only supported with the new permission system
  }

  return {
    environment,
    setEnvironment,
  };
};

// Allows you to use the useSelectedEnvironment hook to be used in (legacy) class components
export const withUseSelectedEnvironment = (Component) => React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Component ref={ref} {...props} useSelectedEnvironmentProp={useSelectedEnvironment()} />
));

export default useSelectedEnvironment;
