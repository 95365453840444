import { useEffect, useState } from 'react';
import * as userService from '../services/user-services';

export const NEW_USER_SUB = 'newUser';

const useUserAttributes = (sub = null) => {
  const [userAttributes, setUserAttributes] = useState({});
  const [userAttributesPendingUpdates, setUserAttributesPendingUpdates] = useState({});

  const updateUserAttributes = (updates) => {
    setUserAttributes((previous) => ({
      ...previous,
      ...updates,
    }));
    setUserAttributesPendingUpdates((previous) => ({
      ...previous,
      ...updates,
    }));
  };

  useEffect(() => {
    if (sub !== NEW_USER_SUB) {
      const fetchData = async () => {
        setUserAttributes(await userService.getUserAttributes(sub));
      };
      fetchData();
    }
  }, [sub]);

  const syncUserAttributesUpdates = () => new Promise((resolve, reject) => {
    setUserAttributesPendingUpdates((pendingUpdates) => {
      if (Object.keys(pendingUpdates).length) {
        userService.updateUserAttributes(pendingUpdates, sub).then(resolve).catch(reject);
      }
      resolve();
      return {};
    });
  });

  return {
    userAttributes,
    userAttributesPendingUpdates,
    updateUserAttributes,
    syncUserAttributesUpdates,
  };
};

export default useUserAttributes;
