import React from 'react';
import { Input, Label } from 'reactstrap';

import styles from './IntegrationInputForms.module.scss';

/* eslint-disable camelcase */
export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let organizationId;

  if (credentials) {
    ({
      organizationId,
    } = credentials);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Urjanet Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="urjanet-input-organizationId">Organization ID:</Label>
          <Input id="urjanet-input-organizationId" value={organizationId} onChange={onInputChange} />
        </div>
      </div>
    </div>
  );
}
