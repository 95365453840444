import React from 'react';

import styles from './IncomeTransactionsTable.module.scss';
import { formatDefaultColumn, formatCurrencyColumn } from '../../../../utils/format-columns';

export const columnFormats = new Map([
  ['date', formatDefaultColumn],
  ['description', formatDefaultColumn],
  ['credit', formatCurrencyColumn],
]);

function IncomeTransactionsTable({ transactions }) {
  return (
    <>
      <div className="_lr-hide">
        <table className={[styles.table, styles.headerTable].join(' ')}>
          <tbody>
            <tr className={styles.header}>
              {
                Array.from(columnFormats.keys()).map((column) => (
                  <td type={column} key={column}>{column}</td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={[styles.tableWrapper, '_lr-hide'].join(' ')}>
        <table className={styles.table}>
          <tbody>
            {
              transactions.map((transaction, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`${index}-tablerow`}>
                  {
                      Array.from(columnFormats.entries()).map(([column, formatFunction]) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <td type={column} key={`${index}-${column}`}>
                          {formatFunction(transaction, column)}
                        </td>
                      ))
                    }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  );
}

export default IncomeTransactionsTable;
