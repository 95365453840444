import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addressEditModes } from '../../../constants';

import styles from './AddressDeleteModal.module.scss';

const renderOptionalValue = (label, value) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      {label}
      &nbsp;
      <b className={styles.boldedValue}>{value}</b>
    </div>
  );
};

const renderErrorMessage = (errorOccured) => {
  if (!errorOccured) {
    return null;
  }

  return (
    <span className={styles.errorMessage}>
      There is a problem deleting this address.
      <br />
      Please try again in a moment.
    </span>
  );
};

export default function (props) {
  const {
    name,
    address,
    city,
    postalCode,
    state,
    type,
    amsLotId,
    storeNumber,
  } = props.selectedAddress;

  const { loading, error } = props.deletionProgress;

  return (
    <Modal isOpen>
      <ModalHeader>
        Delete Address Confirmation
      </ModalHeader>
      <ModalBody>
        <span className={styles.question}>
          <FontAwesomeIcon icon="exclamation-triangle" />
          Are you sure you want to delete the following address?
        </span>
        <div className={styles.addressDisplay}>
          <span className={styles.locationName}>{name}</span>
          <span>{address}</span>
          <span>{`${city}, ${state}`}</span>
          <span>{postalCode}</span>
        </div>
        { renderOptionalValue('Dealership Type:', type) }
        { renderOptionalValue('AMS Lot ID:', amsLotId) }
      </ModalBody>
      <ModalFooter>
        { renderErrorMessage(error) }
        <Button disabled={loading} className={styles.confirmButton} color="danger" onClick={() => props.onDeleteConfirm({ storeNumber }, addressEditModes.DELETE)}>
          { loading ? <Spinner size="sm" color="white" /> : 'Yes, Delete' }
        </Button>
        <Button color="secondary" onClick={props.onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
