import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import CallbackHistoryCard from './CallbackHistoryCard';

const loadingView = Object.freeze({ message: 'Loading callback events', history: [] });
const emptyView = Object.freeze({ message: 'No callback events found', history: [] });

const loadViewData = async (requestId, isUat) => {
  const response = await api(
    'CallbackHistoryView',
    {
      requestId,
      isUat,
    },
  );
  if (!response?.body || response.statusCode !== '200') {
    return emptyView;
  }
  return response.body;
};

export default function CallbackHistoryViewContainer(props) {
  const { features: { callbackHistory } = {}, requestId, isUat } = props;

  const [viewData, setViewData] = useState(loadingView);
  useEffect(() => {
    async function fetchData() {
      const result = await loadViewData(requestId, isUat);
      setViewData(result);
    }
    if (callbackHistory) {
      fetchData();
    }
  }, [requestId, isUat, callbackHistory]);

  if (!callbackHistory) return null;

  return (
    <CallbackHistoryCard
      message={viewData.message}
      history={viewData.history}
    />
  );
}
