import React, { useState, useMemo } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../components/Loader';
import {
  findById,
} from '../environments';
import ProductConfigurationHeader from './ProductConfigurationHeader.view';
import ProductConfigurationList from './ProductConfigurationList.view';
import AddProductConfiguration from './AddProductConfiguration.view';
import CallToActionWhenDeploymentHasNoProductConfigurations
  from './CallToActionWhenDeploymentHasNoProductConfigurations.view';
import {
  addProductConfig,
  removeProductConfig,
  setEnabledState,
  initializeFlowConfig,
} from './commands';

import styles from './ProductConfiguration.module.scss';

const canAddProductConfig = (environmentId, deployment) => {
  const deploymentEnv = findById(environmentId);
  if (!/running/i.test(deployment.status)) return false;
  return !!deploymentEnv?.trustScienceStage;
};

const isDeploymentEnvSameAsTrustScienceEnv = (environmentId, trustScienceConfigEnv) => {
  const deploymentEnvironment = findById(environmentId);
  if (!deploymentEnvironment.trustScienceStage) return false;
  return new RegExp(trustScienceConfigEnv, 'i').test(deploymentEnvironment.trustScienceStage);
};

const buildLookupOfExistingProductCodes = (flowConfig, otherProducts) => {
  const findProducts = (products) => (
    Object.keys(products).reduce((results, key) => {
      const { productCode, additionalProducts = {} } = products[key];
      return {
        ...results,
        [productCode]: true,
        ...additionalProducts,
      };
    }, {})
  );

  return {
    ...findProducts(flowConfig),
    ...findProducts(otherProducts),
  };
};

export default function ProductConfigurationContainer(props) {
  const {
    deployment,
    environmentId,
    trustScienceConfigEnv,
    productConfigurations,
    onDeploymentUpdated,
    flowConfig,
    otherProducts,
    isAnyEditModeActive,
    onEditModeChange,
    flows,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [pendingProductConfigurations, setPendingProductConfigurations] = useState(productConfigurations);
  const [updateCommands, setUpdateCommands] = useState([]);
  const [updateStatus, setUpdateStatus] = useState();
  const existingProductCodes = useMemo(() => buildLookupOfExistingProductCodes(flowConfig, otherProducts), [flowConfig, otherProducts]);

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    onEditModeChange(false);
    setPendingProductConfigurations(productConfigurations);
    setUpdateCommands([]);
  };

  const handleDeleteProductConfig = (productCode) => {
    setUpdateCommands([
      ...updateCommands,
      removeProductConfig(productCode),
    ]);
    setPendingProductConfigurations(
      pendingProductConfigurations
        .filter((p) => p.productCode !== productCode)
        .filter(({ additionalProducts = {} }) => !Object.hasOwn(additionalProducts, productCode)),
    );
  };

  const handleToggleEnabledState = (productCode) => {
    const foundProductConfig = pendingProductConfigurations
      .find((p) => p.productCode === productCode);
    if (!foundProductConfig) {
      console.error('Unable to find product config to enable', productCode);
      return;
    }
    const pendingEnabledState = !foundProductConfig.enabled;
    setUpdateCommands([
      ...updateCommands,
      setEnabledState(productCode, pendingEnabledState),
    ]);
    setPendingProductConfigurations([
      ...pendingProductConfigurations.filter((p) => p.productCode !== productCode),
      {
        ...foundProductConfig,
        enabled: pendingEnabledState,
      },
    ]);
  };

  const handleSavePendingChanges = async () => {
    setIsLoading(true);
    const result = await onDeploymentUpdated(environmentId, deployment, updateCommands, trustScienceConfigEnv);
    if (result.error) {
      setPendingProductConfigurations(productConfigurations);
      setUpdateStatus({ success: false, message: result.error });
    } else {
      setUpdateStatus({ success: true, message: 'The changes have been sucessfully saved' });
    }
    setUpdateCommands([]);
    setIsEditMode(false);
    onEditModeChange(false);
    setIsLoading(false);
  };

  const handleAddProductConfig = (productCode, flow, description) => {
    setUpdateCommands([
      ...updateCommands,
      addProductConfig(productCode, description, deployment, flow),
    ]);
    setPendingProductConfigurations([
      ...pendingProductConfigurations,
      {
        key: productCode,
        productCode,
        description,
        flowId: flow.id,
        enabled: true,
      },
    ]);
  };

  const handleInitializeFlowConfig = () => {
    if (!Object.keys(flowConfig).length) {
      setUpdateCommands([
        ...updateCommands,
        initializeFlowConfig(),
      ]);
    }
    setIsEditMode(true);
    onEditModeChange(true);
  };

  const handleStartEditMode = () => {
    setIsEditMode(true);
    onEditModeChange(true);
  };

  if (isLoading) {
    return (
      <div className={styles.loaderSpacing}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-100">
      <CallToActionWhenDeploymentHasNoProductConfigurations
        canAddProductConfig={canAddProductConfig(environmentId, deployment)}
        configurations={productConfigurations}
        environmentId={environmentId}
        isHidden={isEditMode || !isDeploymentEnvSameAsTrustScienceEnv(environmentId, trustScienceConfigEnv)}
        isDisabled={isAnyEditModeActive}
        onAddProductConfig={handleInitializeFlowConfig}
        trustScienceConfigEnv={trustScienceConfigEnv}
        flows={flows}
      />
      <ProductConfigurationHeader
        configurations={productConfigurations}
        disableEditButton={isAnyEditModeActive || !flows?.length}
        isEditMode={isEditMode}
        hasPendingUpdates={!!updateCommands.length}
        onStartEditMode={handleStartEditMode}
        onCancelEditMode={handleCancelEditMode}
        onSavePendingChanges={handleSavePendingChanges}
        trustScienceConfigEnv={trustScienceConfigEnv}
      />
      {
        isEditMode && isDeploymentEnvSameAsTrustScienceEnv(environmentId, trustScienceConfigEnv) && (
          <AddProductConfiguration
            canAddProductConfig={canAddProductConfig(environmentId, deployment)}
            existingProductCodes={existingProductCodes}
            onAddProductConfig={handleAddProductConfig}
            flows={flows}
          />
        )
      }
      {
        updateStatus && (
          <div
            className={`alert alert-${updateStatus.success ? 'success' : 'danger'} d-flex justify-content-between`}
            role="alert"
          >
            {updateStatus.message}
            <Button
              color="secondary"
              outline
              size="sm"
              onClick={() => setUpdateStatus(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        )
      }
      <ProductConfigurationList
        environmentName={trustScienceConfigEnv}
        productConfigurations={pendingProductConfigurations}
        isEditMode={isEditMode}
        flows={flows}
        onDeleteProductConfig={handleDeleteProductConfig}
        onToggleEnabledState={handleToggleEnabledState}
      />
    </div>
  );
}
