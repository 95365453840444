import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import ApplicantInfoCard from '../../ApplicantInfoCard';
import ScoreCard from '../../ScoreCard';
import Loader from '../../Loader';

import styles from './TableRow.module.scss';

library.add(faExternalLinkAlt);

const groupLoanInfoFromApplicationData = (loanApplication) => (
  {
    loanId: loanApplication.clientLoanReferenceId,
    customerId: loanApplication.clientCustomerId,
  }
);

const generateRequestDetailsPageURL = (id, isUat) => (isUat ? `/scoring-request-details/${id}?env=uat` : `/scoring-request-details/${id}`);

const renderLoader = (isLoading, columnCount) => {
  if (!isLoading) {
    return null;
  }

  return (
    <tr className={styles.loaderWrapper}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td colSpan={columnCount}>
        <Loader />
      </td>
    </tr>
  );
};

export default function (props) {
  const { item, requestDetails, isUat } = props;

  return (
    <>
      <tr className={styles.tableRow} onClick={() => props.click(item)}>
        <td>
          {item.first_name}
        </td>
        <td className="_lr-hide">
          {item.last_name}
        </td>
        <td>
          {item.client_customer_id}
        </td>
        <td>
          {item.client_loan_reference_id}
        </td>
        <td className="_lr-hide">
          {item.score}
        </td>
        <td className="_lr-hide">
          {item.user_id}
        </td>
        <td>
          {item.store_number}
        </td>
        <td>
          {item.store_state}
        </td>
        <td>
          {new Date(item.create_at).toLocaleString()}
        </td>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td align="center">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a
            href={generateRequestDetailsPageURL(props.item.request_id, isUat)}
            rel="noopener noreferrer"
            target="_blank"
            onClick={(event) => event.stopPropagation()}
          >
            <FontAwesomeIcon icon="external-link-alt" />
          </a>
        </td>
      </tr>
      { renderLoader((requestDetails && requestDetails.loading), props.columnCount) }
      {
        requestDetails && requestDetails.show && requestDetails.reports
          ? (
            <tr className={styles.requestDetailTableRow}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td colSpan={props.columnCount}>
                <div className={styles.leftInlineCardWrapper}>
                  <ApplicantInfoCard
                    details={requestDetails}
                    developer={props.developer}
                  />
                </div>
                <div className={styles.rightInlineCardWrapper}>
                  <ScoreCard
                    displayAsInline
                    clientReports={requestDetails.reports}
                    applicant={requestDetails.person}
                    scoring={requestDetails.scoring}
                    loanInfo={groupLoanInfoFromApplicationData(requestDetails.loanApplication)}
                    useCase={item.scoring_usercase}
                  />
                </div>
              </td>
            </tr>
          )
          : null
      }
    </>
  );
}
