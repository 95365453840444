import { useMemo } from 'react';
import { useFetch } from '../hooks/useFetch';
import {
  addQuestion,
  createChat,
  getHistory,
} from './chat';
import removeMetadata from '../lib/remove-metadata';

export const useChatApi = () => {
  const { fetchInstance } = useFetch();

  const chatAPI = useMemo(() => ({
    createChat: async () => createChat(fetchInstance),
    addQuestion: async (props) => removeMetadata(await addQuestion(props, fetchInstance)),
    getHistory: async (chatId) => (await getHistory(chatId, fetchInstance)).map((message) => removeMetadata(message)),
  }), [fetchInstance]);

  return chatAPI;
};

export default useChatApi;
