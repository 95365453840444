export default [
  {
    field: 'firstName',
    method: 'isEmpty',
    validWhen: false,
    message: 'First name is required.',
  },
  {
    field: 'lastName',
    method: 'isEmpty',
    validWhen: false,
    message: 'Last name is required.',
  },
  {
    field: 'cellPhoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    validWhen: true,
    skipIfEmpty: true,
    message: 'Phone number must be in format ###-###-####',
  },
  {
    field: 'email1',
    method: 'isEmail',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Email must be a valid email address.',
  },
  {
    fields: ['email1', 'cellPhoneNumber'],
    method: (fields, inputFormState) => fields.some((field) => inputFormState[field] !== ''),
    validWhen: true,
    message: 'Either Email or Cell Phone Number is required.',
  },
];
