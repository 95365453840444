import React from 'react';
import {
  FormGroup,
  Col,
  Label,
  Row,
} from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';
import styles from '../../LoanInformation.module.scss';

export default function ({
  name,
  label,
  defaultValue,
}, getValidation, previousState, onChange) {
  const fieldName = `custom_${name}`;
  const isActive = !!(previousState[fieldName] || defaultValue);
  const handleClick = (state) => {
    onChange({ target: { name: fieldName, value: state } });
  };
  const validation = getValidation(name);
  const feedbackClass = validation.isValid ? '' : styles.validationMessage;
  return (
    <FormGroup key={fieldName}>
      <Row>
        <Col sm="auto" className="label-main">
          <Label htmlFor={fieldName}>
            { label }
          </Label>
        </Col>
      </Row>
      <Toggle
        id={fieldName}
        active={isActive}
        className={styles.booleanToggle}
        on="Yes"
        off="No"
        size="xs"
        onstyle="success"
        handlestyle="default"
        offstyle="custom"
        onClick={handleClick}
      />
      <div className={feedbackClass}>{ validation.validationMessage }</div>
    </FormGroup>
  );
}
