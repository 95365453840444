export default [
  {
    field: 'loanDate',
    method: 'isEmpty',
    validWhen: false,
    message: 'Loan date must be in format YYYY-MM-DD.',
  },
  {
    field: 'loanDate',
    method: 'isISO8601',
    validWhen: true,
    message: 'Loan date must be in format YYYY-MM-DD.',
    options: [{ strict: true }],
  },
  {
    field: 'loanDate',
    method: 'isLength',
    validWhen: true,
    message: 'Loan date must be in format YYYY-MM-DD.',
    options: [{ min: 10, max: 10 }],
  },
  {
    field: 'feeAmount',
    method: 'isFloat',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Fee amount must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    field: 'principalAmount',
    method: 'isEmpty',
    validWhen: false,
    message: 'Principal amount is required.',
  },
  {
    field: 'principalAmount',
    method: 'isFloat',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Principal amount must be a valid number.',
    options: [{ min: 0 }],
  },
  {
    field: 'downPaymentAmount',
    method: 'isFloat',
    validWhen: true,
    skipIfEmpty: true,
    message: 'Down payment amount must be a valid number.',
    options: [{ min: 0 }],
  },
];
