import React, { useState } from 'react';

import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleRight,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import InputItemRow from '../../../../components/ScoringRequestInputSummary/InputItemRow';
import { formatLongDateTime } from '../../../../utils/format-date';

import styles from './BankingEvents.module.scss';
import { SessionDetailsMap } from './SessionDetailsMap';
import { ProviderMap } from './ProviderMap';

const normalizeMessage = (message) => {
  if (['start', 'started'].includes(message)) return 'Started';
  if (['choose_bank', 'institutionSupported'].includes(message)) return 'Institution selected';
  if (['adding'].includes(message)) return 'Adding account';
  if (['added'].includes(message)) return 'Account added';
  if (['success', 'done'].includes(message)) return 'Successful login';
  return message;
};

function EventSession({ events }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);

  const { provider } = events[0];

  const sessionName = `${ProviderMap[provider] ?? provider} Session`;

  // Merge session details from all events
  const sessionDetails = events.reduce((acc, event) => ({ ...acc, ...(event.sessionDetails ?? {}) }), {});
  const isSessionDetailsAvailable = Object.keys(sessionDetails).length > 0;

  return (
    <>
      {isSessionDetailsAvailable ? (
        <div className="pb-2 pt-1">
          <button type="button" className="btn btn-link text-decoration-none text-primary" onClick={toggle}>
            {`${sessionName} `}
            <FontAwesomeIcon icon={isOpen ? faChevronCircleDown : faChevronCircleRight} />
          </button>

          <Collapse isOpen={isOpen} className={styles.printable}>
            <div className="container">
              {Object.entries(sessionDetails).map(([key, value]) => (
                <div key={`sessionDetails-${key}-${value}`} className="row">
                  <div className="col-3 col-md-4 text-right">
                    {`${SessionDetailsMap[key] ?? key}: `}
                  </div>
                  <div className="col-9 col-md-8 text-left">
                    {value}
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
      )
        : (
          <div className="pt-2 pb-1 text-primary">
            <h6>{sessionName}</h6>
          </div>
        )}
      {events.map((event) => (
        <InputItemRow
          key={`${event.timestamp}-${event.detail}-event-row`}
          label={formatLongDateTime(event.timestamp)}
          value={normalizeMessage(event.detail)}
        />
      ))}
    </>
  );
}

export default function ({ bankingStatus }) {
  if (!bankingStatus) return null;
  const bankingEvents = bankingStatus?.bankingRequestStatus?.bankingEvents?.length
    ? bankingStatus.bankingRequestStatus.bankingEvents
    : [];
  const groupedEvents = bankingEvents.reduce((acc, event) => {
    const group = acc[event.smartConsentSessionId] ?? [];
    group.push(event);
    acc[event.smartConsentSessionId] = group;
    return acc;
  }, {});
  const bankingEventsStatus = bankingStatus?.bankingRequestStatus?.bankingStatus || [];
  const loginTimestamp = bankingStatus?.timestamp
    ? `at ${formatLongDateTime(bankingStatus.timestamp)}`
    : '';
  const message = `Bank account login initiated ${loginTimestamp}`;
  let skippedReason = null;
  if (bankingStatus?.skipped) {
    const { skipped: { displayText, timestamp } } = bankingStatus;
    const timestampText = timestamp ? ` at ${formatLongDateTime(Date.parse(timestamp))}` : '';
    skippedReason = `Skipped Banking Reason: "${displayText}"${timestampText}`;
  }
  return (
    <div className={styles.inputDataGroup}>
      {
        (bankingEvents || loginTimestamp || skippedReason)
          ? <h5>Banking Events</h5>
          : ''
      }
      {
        Object.entries(groupedEvents).map(([sessionId, events]) => (<EventSession events={events} key={`${sessionId}event-session`} />))
      }
      {
        loginTimestamp
          ? (
            <InputItemRow
              label="Banking Status"
              key="Banking"
              value={message}
            />
          )
          : null
      }
      {
        bankingEventsStatus?.result
          ? (
            <InputItemRow
              label="Result"
              value={bankingEventsStatus.result.result}
            />
          ) : null
      }
      {
        skippedReason
          ? (
            <InputItemRow
              label="Banking Status"
              key="Banking"
              value={skippedReason}
            />
          )
          : null
      }
    </div>
  );
}
