import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './SmartConsentBankingURL.module.scss';

const urlTypes = {
  embedded: {
    displayLabel: 'Embedded',
    helpText: 'This URL should only be used for an automated redirect or within an embedded iFrame.',
  },
  mobile: {
    displayLabel: 'Mobile Invite',
    helpText: 'This single use URL can be shared with the applicant via email or SMS.',
  },
};

export default function (props) {
  if (!props.url) return null;
  if (!urlTypes[props.urlType]) {
    console.error('Unknown URL type. Unable to display Smart Consent URL.', props.urlType);
    return null;
  }
  const {
    displayLabel,
    helpText,
  } = urlTypes[props.urlType];
  const urlDisplayName = `Smart Consent™ ${displayLabel} URL`;

  const [feedbackFadeStyle, setFeedbackFadeStyle] = useState(styles.hidden);

  const copyURLToClipboard = () => {
    setFeedbackFadeStyle(styles.fadeIn);
    navigator.clipboard.writeText(props.url);
    setTimeout(() => {
      setFeedbackFadeStyle(styles.fadeOut);
    }, 2000);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {urlDisplayName}
      </div>
      <div className={styles.helpText}>
        <div>
          {helpText}
        </div>
        <FontAwesomeIcon
          icon="copy"
          className={styles.icon}
          onClick={copyURLToClipboard}
        />
      </div>
      <div className={[styles.url, '_lr-hide'].join(' ')}>
        <a rel="noopener noreferrer" target="_blank" href={props.url}>{props.url}</a>
      </div>
      <div className={[feedbackFadeStyle, styles.feedbackMessage].join(' ')}>
        Link Copied!
      </div>
    </div>
  );
}
