export const dev = Object.freeze({
  id: 2,
  name: 'Development',
});
export const test = Object.freeze({
  id: 3,
  name: 'Testing',
  trustScienceStage: 'UAT',
});
export const prod = Object.freeze({
  id: 4,
  name: 'Production',
  trustScienceStage: 'Production',
});
export const findById = (id) => [dev, test, prod].find((e) => e.id === id);
export const isTestEnvironment = (id) => findById(id) === test;
