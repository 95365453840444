import React from 'react';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import styles from './IncomeInformation.validatedIncome.module.scss';

const highlightRequiredFields = (required, highlightEssentialInputs) => {
  if (required && highlightEssentialInputs) return 'required-field';
  return '';
};

export default function (props) {
  const {
    highlightEssentialInputs,
    overrides = {},
  } = props;
  const { hidden, required } = overrides[props.name] || {};
  if (hidden) return null;
  const highlightClass = highlightRequiredFields(required, highlightEssentialInputs);
  return (
    <FormGroup>
      <Label htmlFor={props.name}>
        { props.label }
        { (highlightClass || props.labelsMarkedRequired) ? '*' : null }
      </Label>
      <div className={styles.incomeLineWrapper}>
        <span className={styles.currencySymbol}>$</span>
        <div>
          <Input
            className={
              [
                (props.valid ? highlightClass : 'is-invalid'),
                styles.incomeInput,
              ].join(' ')
            }
            name={props.name}
            id={props.id}
            type="number"
            defaultValue={props.value}
            onChange={props.onChange}
          />
          <FormFeedback>{ props.validationMessage }</FormFeedback>
        </div>
        <span className={styles.inputUnits}>Per Month</span>
      </div>
    </FormGroup>
  );
}
