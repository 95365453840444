import React from 'react';
import { FormFeedback } from 'reactstrap';

import styles from './InputFieldFeedback.module.scss';

export default function (props) {
  return (
    <FormFeedback className={styles.fieldValidationFeedback}>
      {props.validation ? props.validation.message : null}
    &nbsp;
    </FormFeedback>
  );
}
