import { fetchAuthSession } from 'aws-amplify/auth';
import { clientIdAttribute } from '../constants';

export default async (forceRefresh = false) => {
  let session;
  try {
    session = await fetchAuthSession({
      forceRefresh,
    });
  } catch (e) {
    console.error('Unable to fetch auth session:', e);
    throw e;
  }
  const { tokens: { idToken, accessToken } } = session;
  const clientId = idToken.payload[clientIdAttribute];
  return {
    idToken: idToken.toString(),
    accessToken: accessToken.toString(),
    user: idToken.payload,
    clientId,
  };
};
