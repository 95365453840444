export const createChat = async (
  fetchInstance,
) => {
  const createdChat = (
    await fetchInstance.post('/chat', '{}', {
      'Content-Type': 'application/json',
    })
  ).json();

  return createdChat;
};

export const addQuestion = async (
  { chatId, chatQuestion },
  fetchInstance,
) => {
  const response = await fetchInstance.post(
    `/chat/${chatId}/question`,
    JSON.stringify(chatQuestion),
    {
      'Content-Type': 'application/json',
    },
  );

  return response.json();
};

export const getHistory = async (
  chatId,
  fetchInstance,
) => {
  const response = await fetchInstance.get(`/chat/${chatId}/history`);

  if (!response.ok) {
    throw new Error('Failed to fetch chat history');
  }

  return response.json();
};
