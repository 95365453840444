import React from 'react';
import Logo from './Logo';
import * as integrationsInfo from './integrations';

import styles from './ManageIntegrations.module.scss';
import Loader from '../../components/Loader';

export default function ManageIntegrationsView(props) {
  const {
    loading,
    showNoConfigurableIntegrations,
    configurableIntegrations,
    saveCredentials,
    showIntegrationEditor,
    renderEditor,
  } = props;

  if (loading) {
    return <Loader />;
  }

  if (showNoConfigurableIntegrations) {
    return (
      <>
        <h3>Manage Third-Party Integrations</h3>
        <div className={styles.logoHolder}>
          <p className={styles.centeredText}>
            <b>No configurable integrations found.</b>
            <br />
            If you are expecting to configure a third-party integration please contact our Customer Success team for further assistance.
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <h3>Manage Third-Party Integrations</h3>
      <p>Click on the icons below to review or edit your credentials</p>
      <div className={styles.logoHolder}>
        {
          Object.values(integrationsInfo)
            .filter((i) => configurableIntegrations.includes(i.key))
            .map((i) => (
              <Logo
                key={i.key}
                name={i.name}
                saveCredentials={saveCredentials}
                openEditor={() => showIntegrationEditor(i)}
                imgsrc={i.image}
              />
            ))
        }
      </div>
      { renderEditor() }
    </>
  );
}
