import React from 'react';
import {
  Container,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

import LocationSelector from '../../../components/LocationSelector';
import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import StatesAndProvincesOptions from '../StatesAndProvincesOptions';
import { getCountryCodeByState } from '../../../utils/checkCountryByState';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

const formGroupFieldNames = ['name', 'address', 'city', 'postalCode', 'state', 'type', 'storeNumber'];

class LocationInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);
    this.attributeName = 'prefilledLocations';

    if ((props.sendToAms || props.required) && !props.forceAllInputsOptional) {
      super.skipValidationOnGroupIfEmpty = false;
    }

    if (this.props.addresses?.length) {
      formGroupFieldNames.push(this.attributeName);
    }

    super.formGroupFieldNames = formGroupFieldNames;

    this.validator = new FormValidator(validationConfig);
    this.hasInitialized = false;
  }

  shouldFieldBeDisabled = () => (this.props.sendToAms || this.props.addresses.length);

  findLocation = (locationId) => this.props.addresses.find((address) => address.storeNumber === locationId);

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;

          const prefillAddressInformation = (selectedAddress) => {
            Object.keys(selectedAddress).forEach((key) => {
              const value = selectedAddress[key];
              if (key === 'storeNumber') {
                context.onInputChange({ name: 'storeNumber', value, dataTreeKey: 'dealership' });
                context.onInputChange({ name: 'storeNumber', value, dataTreeKey: 'loanInfo' });
              } else if (key === 'state') {
                context.onInputChange({ name: 'state', value, dataTreeKey: 'dealership' });
                context.onInputChange({ name: 'state', value, dataTreeKey: 'loanInfo' });
                context.onInputChange({ name: 'storeState', value, dataTreeKey: 'loanInfo' });
              } else {
                context.onInputChange({ name: key, value, dataTreeKey });
              }
            });
            const country = getCountryCodeByState(selectedAddress.state);
            context.onInputChange({ name: 'country', value: country, dataTreeKey });
            context.onInputChange({ name: this.attributeName, value: selectedAddress.storeNumber, dataTreeKey });
          };

          const locationCallback = (event) => {
            const selectedValue = event.target.value;
            if (selectedValue === '') return;
            const selectedAddress = this.findLocation(selectedValue);
            if (selectedAddress === undefined) return;

            prefillAddressInformation(selectedAddress);
            this.props.updateUserPreferredLocation(selectedAddress);
          };

          const selectAddress = (previousStoreNumberFromForms, savedStoreNumber, userStoreNumber) => {
            if (previousStoreNumberFromForms) return this.findLocation(previousStoreNumberFromForms);
            if (savedStoreNumber) {
              const result = this.findLocation(savedStoreNumber);
              if (result) return result;
            }
            return this.findLocation(userStoreNumber);
          };

          const renderLocationsSelection = () => {
            if (!this.props.addresses?.length) {
              return null;
            }
            const selectedAddress = selectAddress(previousState.storeNumber, context.loanInfo.storeNumber, this.props.defaultLocationId);
            if (!this.hasInitialized && selectedAddress) {
              prefillAddressInformation(selectedAddress);
              this.hasInitialized = true;
            }

            let preselectedDealerLocation = '';
            if (context.rescoreRequestId) {
              preselectedDealerLocation = this.props.addresses.findIndex(
                (address) => address.storeNumber === previousState.storeNumber,
              );
              if (preselectedDealerLocation === -1) preselectedDealerLocation = '';
              previousState.prefilledLocations = preselectedDealerLocation.toString();
            }

            const locationSelectorOnChange = this.generateFieldOnChangeEvent(
              context,
              dataTreeKey,
              undefined,
              locationCallback,
            );

            return (
              <LocationSelector
                addresses={this.props.addresses}
                attributeName={this.attributeName}
                onChange={locationSelectorOnChange}
                required={this.props.sendToAms || this.props.required}
                selectedLocationId={selectedAddress?.storeNumber}
              />
            );
          };

          const renderAMSLotIDField = () => {
            if (!this.props.sendToAms) {
              return null;
            }

            return (
              <Input
                className={this.state.amsLotIdFieldInvalid ? 'is-invalid' : ''}
                type="hidden"
                name="amsLotId"
                id="amsLotId"
                defaultValue={previousState.amsLotId || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                disabled
              />
            );
          };

          return (
            <div className="deal-information-input-group">
              <h4>Location</h4>
              <div className="form-group-wrapper">
                <Container>
                  { renderLocationsSelection() }
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="name"
                    id="dealershipName"
                    label="Name"
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.nameFieldInvalid}
                    validationMessage={this.state.nameFieldValidationMessage}
                    value={previousState.name || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    disabled={this.shouldFieldBeDisabled()}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="address"
                    id="dealershipAddress"
                    label="Address"
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.addressFieldInvalid}
                    validationMessage={this.state.addressFieldValidationMessage}
                    value={previousState.address || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    disabled={this.shouldFieldBeDisabled()}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="city"
                    id="dealershipCity"
                    label="City"
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.cityFieldInvalid}
                    validationMessage={this.state.cityFieldValidationMessage}
                    value={previousState.city || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    disabled={this.shouldFieldBeDisabled()}
                  />
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="postalCode"
                    id="dealershipPostalCode"
                    label="Zip / Postal Code"
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.postalCodeFieldInvalid}
                    validationMessage={this.state.postalCodeFieldValidationMessage}
                    value={previousState.postalCode || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    disabled={this.shouldFieldBeDisabled()}
                  />
                  <FormGroup>
                    <Label htmlFor="dealershipProvince">
                      State / Province
                      {(this.state.labelsMarkedRequired || this.props.highlightEssentialInputs) ? '*' : null}
                    </Label>
                    <Input
                      className={this.state.stateFieldInvalid ? 'is-invalid' : ''}
                      type="select"
                      name="state"
                      id="dealershipProvince"
                      value={previousState.state || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey, true)}
                      disabled={this.shouldFieldBeDisabled()}
                    >
                      <StatesAndProvincesOptions />
                    </Input>
                    <FormFeedback>{ this.state.stateFieldValidationMessage }</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="dealerType">
                      Location Type
                    </Label>
                    <Input
                      className={this.state.typeFieldInvalid ? 'is-invalid' : ''}
                      type="select"
                      name="type"
                      id="dealerType"
                      value={previousState.type || ''}
                      onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                      disabled={this.shouldFieldBeDisabled()}
                    >
                      <option value="">Please Select...</option>
                      <option value="franchise">Franchise</option>
                      <option value="independent">Independent</option>
                    </Input>
                    <FormFeedback>{ this.state.typeFieldValidationMessage }</FormFeedback>
                    { renderAMSLotIDField(previousState, context, dataTreeKey) }
                  </FormGroup>
                  <ValidatedTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...this.props}
                    name="storeNumber"
                    id="locationStoreNumber"
                    label="Store Number"
                    labelsMarkedRequired={this.state.labelsMarkedRequired}
                    valid={!this.state.storeNumberFieldInvalid}
                    validationMessage={this.state.storeNumberFieldValidationMessage}
                    value={previousState.storeNumber || context.loanInfo.storeNumber || ''}
                    onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
                    disabled={this.shouldFieldBeDisabled()}
                  />
                </Container>
              </div>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default LocationInputGroup;
