import React from 'react';

import styles from './Logo.module.scss';

export default function (props) {
  return (
    <button type="button" className={styles.borderlessButton} onClick={props.openEditor}>
      <img className={styles.logo} src={props.imgsrc} alt={props.name} />
    </button>
  );
}
