import { fetchAuthSession } from '@aws-amplify/auth';
import api from '../services/api';

export default async (clientId, callback) => {
  const result = await api('Impersonate', {
    clientId,
  });
  if (result.success) {
    await fetchAuthSession({ forceRefresh: true });
    return callback(true);
  }
  console.error('Unable to change impersonation status.');
  return callback(false);
};

export const getClientId = (state) => {
  if (state.session && state.session.impersonating) {
    return state.session.impersonating;
  }
  return state.clientId;
};
