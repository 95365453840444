import React, { useState } from 'react';

import TenantNameEditor from './TenantNameEditor';
import CallbackEditor from './CallbackEditor';
import IPAllowlist from './IPAllowlist';

import './style.scss';
import SanitizedTextInput from '../../../components/SanitizedTextInput';
import InviteExpirationEditor from './InviteExpirationEditor';

function ClientConfig(props) {
  const [performingSave, togglePerformingSave] = useState(false);

  if (!props.config) {
    return null;
  }

  const callbackList = props.config.callbacks ? Object.keys(props.config.callbacks) : [];

  const setNewConfig = (newConfig, updateConfig) => {
    updateConfig(newConfig);
  };

  const checkCallbackTagAlreadyExists = (newCallback, originalCallback) => {
    /*
    Check if the tag about to be saved is already set in original client configs.
    Loop will skip through its own entry in the array, otherwise it will be impossible to update
    an existing tag (eg: update just the callback url.
    */

    const tagAlreadyExist = callbackList.some((tag) => (
      tag !== originalCallback[0]) && (tag === newCallback[0]
    ));

    return tagAlreadyExist;
  };

  return (
    <>
      <div className="configSectionHeader">
        <h3>{props.title}</h3>
      </div>
      <div className="card">
        <div className="configEditorWrapper">
          <TenantNameEditor
            config={props.config}
            isUat={props.isUat}
            setConfig={(newConfig) => setNewConfig(newConfig, props.updateConfig)}
            performingSave={performingSave}
            togglePerformingSave={togglePerformingSave}
            history={props.history}
          />

          <div className="configRow tableHeaders">
            <div className="label">Callbacks</div>
            <div className="label">Tags</div>
            <div className="label">URL</div>
            <div className="label callbackVersion">
              {
                props.enabledFeatures?.allowSwitchReportVersion
                  ? 'Report Version'
                  : ''
              }
            </div>
          </div>

          {
            callbackList.length === 0
              ? (
                <div className="configRow noCallbacks">
                  <div className="label" />
                  <SanitizedTextInput
                    type="text"
                    className="codeStyleInput wide"
                    name="displayName"
                    readOnly
                    defaultValue="No callbacks defined yet"
                  />
                </div>
              )
              : callbackList
                .sort()
                .map((key) => [key, props.config.callbacks[key]])
                .map((callback) => (
                  <CallbackEditor
                    key={callback[0]}
                    callback={callback}
                    config={props.config}
                    isUat={props.isUat}
                    performingSave={performingSave}
                    togglePerformingSave={togglePerformingSave}
                    checkCallbackTagAlreadyExists={checkCallbackTagAlreadyExists}
                    setConfig={props.updateConfig}
                    enabledFeatures={props.enabledFeatures}
                  />
                ))
          }

          <CallbackEditor
            key="add-callback"
            config={props.config}
            isUat={props.isUat}
            performingSave={performingSave}
            togglePerformingSave={togglePerformingSave}
            setConfig={props.updateConfig}
            checkCallbackTagAlreadyExists={checkCallbackTagAlreadyExists}
            enabledFeatures={props.enabledFeatures}
            callback={[]}
          />
          <IPAllowlist
            config={props.config}
            isUat={props.isUat}
            setConfig={(newConfig) => setNewConfig(newConfig, props.updateConfig)}
            performingSave={performingSave}
            togglePerformingSave={togglePerformingSave}
            history={props.history}
          />
          <InviteExpirationEditor
            config={props.config}
            isUat={props.isUat}
            setConfig={(newConfig) => setNewConfig(newConfig, props.updateConfig)}
            performingSave={performingSave}
            togglePerformingSave={togglePerformingSave}
          />
        </div>
      </div>
    </>
  );
}

export default ClientConfig;
