export default [
  {
    field: 'name',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location name is required.',
  },
  {
    field: 'address',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location address is required.',
  },
  {
    field: 'city',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location city is required.',
  },
  {
    field: 'postalCode',
    method: 'isEmpty',
    validWhen: false,
    message: 'Location zip / postal code is required.',
  },
  {
    field: 'postalCode',
    method: 'isPostalCode',
    validWhen: true,
    message: 'Location zip / postal code is not correct.',
    options: ['any'],
  },
  {
    field: 'state',
    method: 'isEmpty',
    validWhen: false,
    message: 'Please select the state / province for this location.',
  },
  {
    field: 'phoneNumber',
    method: 'isMobilePhone',
    options: ['en-US'],
    validWhen: true,
    skipIfEmpty: true,
    message: 'Phone number must be in a valid North American format (eg. ###-###-####).',
  },
  {
    field: 'storeNumber',
    method: 'isEmpty',
    validWhen: false,
    message: 'Store number is required.',
  },
  {
    field: 'amsLotId',
    method: 'isEmpty',
    validWhen: false,
    message: 'Please select the AMS lot ID number for this location.',
  },
];
