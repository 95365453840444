import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import api from '../../services/api';
import {
  createDeployment,
  getDeployments,
} from '../../services/flowbuilder-services';
import DeploymentMessageView from './DeploymentMessage.view';
import ManageDeploymentsView from './ManageDeployments.view';
import ManageDeploymentsErrorView from './ManageDeploymentsError.view';

const filterDeployments = (
  projectId,
  commitId,
) => (
  result,
  { name, environmentId, deployments },
) => (
  result
    .concat(
      deployments
        .filter((d) => d.projectId === projectId && d.commitId === commitId)
        .map((d) => ({ environmentId, environmentName: name, ...d })),
    )
    .filter((x) => !!x)
);

export default function ManageDeploymentsContainer(props) {
  const [loading, setLoading] = useState(true);
  const [deployments, setDeployments] = useState([]);
  const [deployModal, setDeployModal] = useState(false);
  const [prodFlowConfig, setProdFlowConfig] = useState(props.prodFlowConfig);
  const [uatFlowConfig, setUatFlowConfig] = useState(props.uatFlowConfig);
  const [isAnyEditModeActive, setIsAnyEditModeActive] = useState(false);

  useEffect(() => {
    const fetchDeployments = async () => {
      const results = await getDeployments();
      setDeployments(results);
      setLoading(false);
    };
    fetchDeployments();
  }, []);

  const handleDeploymentUpdated = async (environmentId, deployment, patches, trustScienceConfigEnv) => {
    const isUat = /uat/i.test(trustScienceConfigEnv);
    const result = await api('PatchClientConfig', {
      patches,
      isUat,
    });
    if (!result || result.error) {
      console.error('Failed to save changes', result);
      return {
        error: 'Unable to save changes. User may not have permission to update the configuration.',
      };
    }
    const updateFlowConfig = isUat ? setUatFlowConfig : setProdFlowConfig;
    updateFlowConfig(result.flowConfig);
    return {
      success: true,
    };
  };

  const onCreateDeployment = async (targetEnv, deployment) => {
    setLoading(true);
    const {
      projectId,
      commitId,
      name,
    } = deployment;

    const showDeploymentResult = (message, error = false) => setDeployModal({
      message,
      error,
    });

    try {
      const createResponse = await createDeployment(
        targetEnv.id,
        projectId,
        commitId,
        name,
      );
      if (createResponse.error) {
        console.error(createResponse.error);
        showDeploymentResult(`Unable to promote deployment to ${targetEnv.name}.`, createResponse.error);
        return;
      }
      showDeploymentResult(`The deployment is being promoted to ${targetEnv.name}. You can refresh the list of deployments to view the progress.`);
      const results = await getDeployments();
      setDeployments(results);
    } catch (error) {
      showDeploymentResult(`An unhandled exception was encountered while promoting deployment to ${targetEnv.name}.`, error);
    } finally {
      setLoading(false);
    }
  };

  const getDeploymentsByCommit = (projectId, commitId) => (
    deployments.reduce(filterDeployments(projectId, commitId), [])
  );

  const clearModalMessage = () => {
    setDeployModal(false);
  };

  if (loading) return <Loader />;
  if (deployModal) {
    return (
      <DeploymentMessageView
        message={deployModal.message}
        error={deployModal.error}
        onClose={clearModalMessage}
      />
    );
  }
  if (deployments.error) {
    return (
      <ManageDeploymentsErrorView
        error={deployments.error}
      />
    );
  }
  return (
    <ManageDeploymentsView
      prodFlowConfig={prodFlowConfig}
      uatFlowConfig={uatFlowConfig}
      uatOtherProducts={props.uatOtherProducts}
      prodOtherProducts={props.prodOtherProducts}
      deployments={deployments}
      getDeploymentsByCommit={getDeploymentsByCommit}
      onCreateDeployment={onCreateDeployment}
      onDeploymentUpdated={handleDeploymentUpdated}
      isAnyEditModeActive={isAnyEditModeActive}
      onEditModeChange={(value) => setIsAnyEditModeActive(value)}
    />
  );
}
