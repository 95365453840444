import React from 'react';
import { Container } from 'reactstrap';
import FormValidator from '../../../utils/FormValidator';
import validationConfig from './validationConfig';
import FormContext from '../FormContext';
import InputGroupClass from '../InputGroupClass';
import ValidatedTextInput from '../ScoringRequestForm.validatedTextInput';

const formGroupFieldNames = [
  'middleName', 'aliases', 'cellPhoneNumber',
  'workPhoneNumber', 'email', 'dateOfBirth', 'socialInsuranceNumber',
];

class PersonOptionalInfoInputGroup extends InputGroupClass {
  constructor(props) {
    super(props);

    super.formGroupFieldNames = formGroupFieldNames;
    if (this.props.requireDobInForms || this.props.requireSsnInForms) {
      super.skipValidationOnGroupIfEmpty = false;
    }
    let filteredConfig = !this.props.requireDobInForms
      ? validationConfig.filter((rule) => !(rule.field === 'dateOfBirth' && rule.method === 'isEmpty'))
      : validationConfig;

    filteredConfig = !this.props.requireSsnInForms
      ? filteredConfig.filter((rule) => !(rule.field === 'socialInsuranceNumber' && rule.method === 'isEmpty'))
      : filteredConfig;

    this.validator = new FormValidator(filteredConfig);
  }

  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          const { dataTreeKey } = this.props;
          const previousState = context[dataTreeKey];
          this.userInputValues = previousState;
          return (
            <Container>
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="aliases"
                id={`${this.props.fieldIdPrefix}ApplicantAliases`}
                label="Aliases"
                valid={!this.state.aliasesFieldInvalid}
                validationMessage={this.state.aliasesFieldValidationMessage}
                value={previousState.aliases || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="cellPhoneNumber"
                id={`${this.props.fieldIdPrefix}ApplicantCellPhoneNumber`}
                label="Cell Phone"
                valid={!this.state.cellPhoneNumberFieldInvalid}
                validationMessage={this.state.cellPhoneNumberFieldValidationMessage}
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                maxLength="12"
                helpText="###-###-####"
                value={previousState.cellPhoneNumber || ''}
                onChange={this.handlePhoneNumber(context, dataTreeKey)}
                onKeyPress={this.handleKeyPress}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="workPhoneNumber"
                id={`${this.props.fieldIdPrefix}ApplicantWorkPhoneNumber`}
                label="Work Phone"
                valid={!this.state.workPhoneNumberFieldInvalid}
                validationMessage={this.state.workPhoneNumberFieldValidationMessage}
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                maxLength="12"
                helpText="###-###-####"
                value={previousState.workPhoneNumber || ''}
                onChange={this.handlePhoneNumber(context, dataTreeKey)}
                onKeyPress={this.handleKeyPress}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="email2"
                id={`${this.props.fieldIdPrefix}ApplicantEmail2`}
                label="Email 2"
                valid={!this.state.email2FieldInvalid}
                validationMessage={this.state.email2FieldValidationMessage}
                value={previousState.email2 || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="dateOfBirth"
                id={`${this.props.fieldIdPrefix}ApplicantDateOfBirth`}
                label="Date of Birth"
                required={this.props.requireDobInForms}
                labelsMarkedRequired={this.props.requireDobInForms}
                valid={!this.state.dateOfBirthFieldInvalid}
                validationMessage={this.state.dateOfBirthFieldValidationMessage}
                helpText="YYYY-MM-DD"
                value={previousState.dateOfBirth || ''}
                onChange={this.generateFieldOnChangeEvent(context, dataTreeKey)}
              />
              <ValidatedTextInput
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...this.props}
                name="socialInsuranceNumber"
                id={`${this.props.fieldIdPrefix}ApplicantSocialInsuranceNumber`}
                label="SSN / SIN"
                required={this.props.requireSsnInForms}
                labelsMarkedRequired={this.props.requireSsnInForms}
                valid={!this.state.socialInsuranceNumberFieldInvalid}
                validationMessage={this.state.socialInsuranceNumberFieldValidationMessage}
                helpText="#########"
                value={previousState.socialInsuranceNumber || ''}
                onChange={(event) => {
                  const { value } = event.target;
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = value.replace(/\D/g, '');
                  this.generateFieldOnChangeEvent(context, dataTreeKey)(event);
                }}
              />
            </Container>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default PersonOptionalInfoInputGroup;
