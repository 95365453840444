import React from 'react';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IntegrationInputForms.module.scss';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

/* eslint-disable camelcase */
export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let environment;
  let publicKey;
  let clientId;
  let secret;
  let countryCodes;
  let url;

  if (credentials) {
    ({
      environment,
      publicKey,
      countryCodes,
      url,
    } = credentials);
  }

  if (credentials.private) {
    ({ clientId, secret } = credentials.private);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Plaid Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-url">URL:</Label>
          <SanitizedTextInput
            id="plaid-input-url"
            value={url}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-environment">Environment:</Label>
          <SanitizedTextInput
            id="plaid-input-environment"
            value={environment}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-publicKey">Public Key:</Label>
          <SanitizedTextInput
            id="plaid-input-publicKey"
            value={publicKey}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-countryCodes">Country Codes:</Label>
          <SanitizedTextInput
            id="plaid-input-countryCodes"
            value={countryCodes}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-clientId">
            Client ID
            <FontAwesomeIcon icon="lock" />
          </Label>
          <SanitizedTextInput
            id="plaid-input-clientId"
            value={clientId}
            nestedKey="private"
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="plaid-input-secret">
            Secret
            <FontAwesomeIcon icon="lock" />
          </Label>
          <SanitizedTextInput
            id="plaid-input-secret"
            value={secret}
            nestedKey="private"
            onChange={onInputChange}
          />
        </div>
      </div>
    </div>
  );
}
