const usaStates = [ // eslint-disable-line no-unused-vars
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];

const canadianProvinces = [
  'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT',
];

export const getCountryCodeByState = (selectedProvince) => {
  if (canadianProvinces.find((abbreviation) => selectedProvince === abbreviation)) {
    return 'CAN';
  }

  return 'USA';
};

export const getCountryNameByAbbreviation = (abbreviation) => {
  if (abbreviation === 'CAN') {
    return 'Canada';
  }

  return 'USA';
};
