import React from 'react';
import * as factories from './factories';

const getValidation = (state) => (name) => {
  const isValid = !state[`custom_${name}FieldInvalid`];
  const validationMessage = state[`custom_${name}FieldValidationMessage`];
  return {
    isValid,
    validationMessage,
  };
};

const fieldFactory = (fieldType) => (factories[fieldType] ?? factories.text);

const CustomFields = ({
  customLoanFields,
  onChange,
  previousState,
  state,
}) => ([].concat(customLoanFields || []).map((f, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <React.Fragment key={index}>
    {fieldFactory(f.type)(
      f,
      getValidation(state),
      previousState,
      onChange,
      fieldFactory,
    )}
  </React.Fragment>
)));

export default CustomFields;
