import formatCurrency from './formatCurrency';

export const formatDefaultColumn = (transaction, column) => {
  const columnValue = transaction[column];
  if (typeof columnValue === 'undefined' || columnValue === null) {
    return '';
  }
  return columnValue;
};

export const formatCurrencyColumn = (transaction, column) => {
  const columnValue = formatDefaultColumn(transaction, column);
  if (!columnValue) {
    return columnValue;
  }
  return `$${formatCurrency(Number(columnValue), true)}`;
};
