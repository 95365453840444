import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const renderError = (error) => (
  <div className="alert-danger">
    { error }
  </div>
);

const uploadLogo = (props) => (
  <div>
    <Modal isOpen={props.isUploadOpen} toggle={props.onCancel} className="uploadLogo">
      <ModalHeader toggle={props.onCancel}>Upload Logo</ModalHeader>
      <ModalBody>
        Upload a high resolution copy of your company logo.
        This image will be resized and used for branding in the
        Trust Start user applications and Trust Console.
        An image with a square aspect ratio will work best.
      </ModalBody>
      <ModalBody>
        <input type="file" accept="image/*" onChange={props.onFileSelected} />
        { renderError(props.uploadError) }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.onSubmit} disabled={!props.uploadFile}>Upload</Button>
        {' '}
        <Button color="secondary" onClick={props.onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </div>
);

export default uploadLogo;
