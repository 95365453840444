import React from 'react';
import { Label } from 'reactstrap';

import styles from './IntegrationInputForms.module.scss';
import SanitizedTextInput from '../../../components/SanitizedTextInput';

/* eslint-disable camelcase */
export default function (props) {
  const { onInputChange, credentials, logoSrc } = props;

  let baseUrl;
  let username;
  let password;
  let templateId;
  let templateVersionId;
  let dealerCode;

  if (credentials) {
    ({
      baseUrl,
      username,
      password,
      templateId,
      templateVersionId,
      dealerCode,
    } = credentials);
  }

  return (
    <div className={styles.integration}>
      <img src={logoSrc} alt="Inovatec Logo" />
      <div className={styles.inputGroup}>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-baseUrl">Instance URL:</Label>
          <SanitizedTextInput
            id="inovateccdp-input-baseUrl"
            value={baseUrl}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-username">Username:</Label>
          <SanitizedTextInput
            id="inovateccdp-input-username"
            value={username}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-password">
            Password:
          </Label>
          <SanitizedTextInput
            id="inovateccdp-input-password"
            value={password}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-dealerCode">
            Dealer Code:
          </Label>
          <SanitizedTextInput
            id="inovateccdp-input-dealerCode"
            value={dealerCode}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-templateId">
            Template ID
          </Label>
          <SanitizedTextInput
            id="inovateccdp-input-templateId"
            value={templateId}
            onChange={onInputChange}
          />
        </div>
        <div className={styles.textInputWithLabel}>
          <Label for="inovateccdp-input-templateVersionId">
            Template Version ID:
          </Label>
          <SanitizedTextInput
            id="inovateccdp-input-templateVersionId"
            value={templateVersionId}
            onChange={onInputChange}
          />
        </div>
      </div>
    </div>
  );
}
