import React from 'react';
import {
  Button,
  CardBody,
} from 'reactstrap';
import InputItemRow from '../../../components/ScoringRequestInputSummary/InputItemRow';
import { formatLongDateTime } from '../../../utils/format-date';
import CollapsibleCard from '../CollapsibleCard';
import SmartConsentBankingURLView from './SmartConsentBankingURL/SmartConsentBankingURLView';
import BankingEvents from './BankingEvents';

import styles from './SmartConsentCard.module.scss';

import emailDeliveryDescriptions from './emailDeliveryDescriptions.json';

const upgradeLegacyEvents = (e) => {
  if (e.data) return e;
  return {
    ...e,
    eventType: e.type,
    data: {
      ...e,
    },
  };
};

function Events({ events }) {
  return (
    <>
      {events.map(upgradeLegacyEvents)
        .sort((a, b) => new Date(a.data.timestamp) - new Date(b.data.timestamp))
        .map((event) => {
          const timestamp = event.data?.timestamp;
          const { eventType } = event;
          if (emailDeliveryDescriptions[eventType]) {
            const { label, description } = emailDeliveryDescriptions[eventType];
            const date = formatLongDateTime(timestamp) ?? '';
            return (
              <InputItemRow
                label={label}
                key={`${event.eventType}_${timestamp}`}
                value={description.replace('{date}', date)}
              />
            );
          }
          if (eventType === 'Sent') {
            if (!event.data.result || event.data.error) {
              const defaultMessage = `Unable to send email to ${event.data.email}. Please verify the email address.`;
              const message = typeof event.data.error === 'string' ? event.data.error : defaultMessage;
              return (
                <InputItemRow
                  label="Error"
                  key={`Error_${timestamp}`}
                  value={message}
                />
              );
            }
            return (
              <div key={`Sent_${timestamp}`}>
                <InputItemRow
                  label="Sent to"
                  key={`SentTo_${timestamp}`}
                  value={event.data.email}
                />
                <InputItemRow
                  label="Sent at"
                  key={`SentAt_${timestamp}`}
                  value={formatLongDateTime(timestamp)}
                />
              </div>
            );
          }
          console.error(event);
          return (
            <InputItemRow
              label={`Email ${eventType}`}
              key={`UnknownEvent_${timestamp}`}
              value="Event description unknown. Please verify the email address and contact support if the issue persists."
            />
          );
        })}
    </>
  );
}

function adjustForLocalTime(utcTimestamp) {
  const tzOffsetMins = new Date().getTimezoneOffset();
  const localTimestamp = new Date(utcTimestamp).getTime() - tzOffsetMins * 60 * 1000;
  return new Date(localTimestamp);
}

function SMSEvents({ events }) {
  return events
    .map((event) => {
      const { timestamp } = event.notification || event.data;
      const localTimestamp = adjustForLocalTime(timestamp);
      if (event.data?.error) {
        return (
          <div key={`Sent_${timestamp}`}>
            <InputItemRow
              label="Tried to send to"
              key={`SentTo_${timestamp}`}
              value={event.data.phone}
            />
            <InputItemRow
              label="Tried to send at"
              key={`SentAt_${timestamp}`}
              value={formatLongDateTime(localTimestamp)}
            />
            <InputItemRow
              label="Error"
              key={`Error_${timestamp}`}
              value={event.data.error}
            />
          </div>
        );
      }
      return (
        <div key={`Sent_${timestamp}`}>
          <InputItemRow
            label="Sent to"
            key={`SentTo_${timestamp}`}
            value={event.delivery.destination}
          />
          {event.delivery.phoneCarrier && (
            <InputItemRow
              label="Carrier"
              key={`Carrier_${timestamp}`}
              value={event.delivery.phoneCarrier}
            />
          )}
          <InputItemRow
            label="Sent at"
            key={`SentAt_${timestamp}`}
            value={formatLongDateTime(localTimestamp)}
          />
          <InputItemRow
            label="Status"
            key={`Status_${timestamp}`}
            value={event.delivery.providerResponse}
          />
        </div>
      );
    });
}

function Invites({ invites, hideEvents }) {
  if (!invites || hideEvents) return null;
  if (!Object.keys(invites).length) {
    return (
      <div className={styles.inputDataGroup} key="no_events">
        <h5>Invites</h5>
        <h6>No Events</h6>
      </div>
    );
  }
  const isMoreThanOne = Object.keys(invites).length > 1;
  return Object.keys(invites).map((key, index) => (
    <div
      className={styles.inputDataGroup}
      key={key}
      data-cy="invites"
    >
      {invites[key].events && (
        <>
          <h5>{`Email Invite ${isMoreThanOne ? index + 1 : ''}`}</h5>
          <Events events={invites[key].events} />
        </>
      )}
      {invites[key].smsEvents && (
        <>
          <h5>{`SMS Invite ${isMoreThanOne ? index + 1 : ''}`}</h5>
          <SMSEvents events={invites[key].smsEvents} />
        </>
      )}
    </div>
  ));
}

export default function SmartConsentCardView(props) {
  const {
    features,
    showEmbeddedUrl,
    bankingStatus,
    hideEvents,
    invites,
    tsBankingURL,
    onCreateInvite,
  } = props;

  return (
    <CollapsibleCard title="Smart Consent™" collapseKey="smartConsent">
      <CardBody className={styles.cardBody} data-cy="smart-consent-card">
        <div className="d-flex justify-content-end w-100">
          <Button
            color="primary"
            onClick={onCreateInvite}
            disabled={!features.allowSendingMobileInvite}
          >
            Create Invite
          </Button>
        </div>
        {
          showEmbeddedUrl && (
            <SmartConsentBankingURLView url={tsBankingURL} urlType="embedded" />
          )
        }
        <Invites invites={invites} hideEvents={hideEvents} />
        <BankingEvents bankingStatus={bankingStatus} />
      </CardBody>
    </CollapsibleCard>
  );
}
