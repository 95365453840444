import React from 'react';

import { IMAGE_URL, S3_BUCKET, ENVIRONMENT } from '../../config/console-configuration';

const fallbackImage = 'images/ts-logo.png';

export const clientLogoWidths = {
  small: 45,
  medium: 90,
  large: 135,
  extraLarge: 180,
};

const defaultClientLogoWidth = clientLogoWidths.extraLarge;

const handleMissingLogo = (e) => {
  if (e.target.src.endsWith(fallbackImage)) {
    e.target.style.display = 'none';
    return;
  }
  e.target.src = fallbackImage;
};

const buildLogoImageUrl = ({ width, clientId, companyLogo }) => {
  const imageRequest = {
    bucket: S3_BUCKET,
    key: `tenant-assets/${ENVIRONMENT}/${clientId}/images/${companyLogo}`,
    edits: {
      resize: {
        width: Number(width) || defaultClientLogoWidth,
        fit: 'contain',
      },
    },
  };
  return `${IMAGE_URL}/${btoa(JSON.stringify(imageRequest))}`; // eslint-disable-line no-undef
};

export default function (props) {
  return (
    <img
      alt={props.companyName}
      src={buildLogoImageUrl(props)}
      onError={(e) => handleMissingLogo(e)}
    />
  );
}
