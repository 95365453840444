import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import styles from './TableFooter.module.scss';

export default function (props) {
  if (props.pageCount === 0) return null;

  const morePages = '...';
  const interval = 4;
  let start = Math.max(0, props.currentPage - interval);
  let end = Math.min(props.pageCount, props.currentPage + interval + 1);

  if (props.currentPage < interval) {
    end += interval - props.currentPage;
    end = Math.min(end, props.pageCount);
  }

  if (props.pageCount - props.currentPage < interval) {
    start -= interval - (props.pageCount - props.currentPage);
    start = Math.max(start, 0);
  }

  if (Number.isNaN(end)) {
    return null;
  }

  const pages = [...Array(end - start)].map((e, i) => start + i);

  if (start > 0) {
    pages.unshift(morePages);
  }
  if (end < props.pageCount) {
    pages.push(morePages);
  }

  return (
    <div className={styles.footerPaginationWrapper}>
      <Pagination aria-label="Page navigation">
        <PaginationItem>
          <PaginationLink
            first="true"
            onClick={() => props.changePage(0)}
            disabled={props.currentPage === 0}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            previous
            onClick={() => props.changePage(props.currentPage - 1)}
            disabled={props.currentPage === 0}
          />
        </PaginationItem>
        {pages.map((p, i) => (
          <PaginationItem
            key={i === 0 ? 'first...' : p}
            active={p === props.currentPage}
            disabled={p === morePages}
          >
            <PaginationLink onClick={() => props.changePage(p)}>
              {p === morePages ? p : p + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationLink
            next
            onClick={() => props.changePage(props.currentPage + 1)}
            disabled={props.currentPage === props.pageCount - 1}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            last="true"
            onClick={() => props.changePage(props.pageCount - 1)}
            disabled={props.currentPage === props.pageCount - 1}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
