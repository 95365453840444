import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  SVGRenderer,
} from 'echarts/renderers';
import {
  ToolboxComponent,
  VisualMapComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import {
  BarChart,
  LineChart,
  HeatmapChart,
} from 'echarts/charts';

echarts.use([SVGRenderer, TitleComponent, TooltipComponent, LegendComponent, GridComponent, BarChart, LineChart, HeatmapChart, VisualMapComponent, ToolboxComponent]);

export default function DashboardChart({ options }) {
  const newOptions = { ...options };
  if (typeof newOptions.tooltip?.valueFormatter === 'string') {
    const template = newOptions.tooltip.valueFormatter;
    newOptions.tooltip = {
      ...newOptions.tooltip,
      valueFormatter: (value) => template.replace('{value}', value.toFixed(2)),
    };
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={newOptions}
      lazyUpdate
      className="chartSize"
    />
  );
}
