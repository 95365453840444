import React from 'react';
import {
  Button,
} from 'reactstrap';
import api from '../../../services/api';
import getCurrentAuthSession from '../../../utils/get-current-auth-session';
import { useModal } from '../../../hooks/useModal';
import { useEnabledFeatures } from '../../../hooks/useEnabledFeatures';

export default function (props) {
  const { requestId, isUat, integration } = props;

  const { showModal, hideModal } = useModal();
  const { transUnionButton } = useEnabledFeatures({ env: isUat ? 'uat' : 'prod' });

  if (!transUnionButton || !integration || !integration.transunion) return null;

  const runTransUnion = async () => {
    const user = await getCurrentAuthSession();
    if (!user.username) throw new Error('APP-4035 Missing username');
    const payload = {
      user: user.username,
      clientId: props.clientId,
      requestId,
      integration: 'transunion',
    };
    return api('RunIntegration', payload);
  };

  const showPullTransUnionModal = () => {
    showModal({
      header: 'Pull TransUnion Report',
      body: (
        <>
          <p>
            Pulling a TransUnion report can result in a charge to your account from TransUnion as well as an additional
            inquiry on the customer&apos;s credit report.
          </p>
          <p>
            It can take up to a minute to retrieve your report. Please refresh the scoring details to see it.
          </p>
        </>
      ),
      buttons: [
        {
          color: 'primary',
          text: 'Pull Report',
          onClick: () => {
            runTransUnion();
            hideModal();
          },
        },
        {
          color: 'secondary',
          text: 'Cancel',
          onClick: hideModal,
        },
      ],
    });
  };

  return (
    <Button color="primary" onClick={showPullTransUnionModal}>
      Pull TransUnion
    </Button>
  );
}
