import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import fetchDocumentation from '../../../utils/fetchDocumentation';

import styles from './File.module.scss';

// eslint-disable-next-line default-param-last
const toggleDisplayFileContents = (name, fileContents, setFileContents, docType = 'v1', featureFlag) => {
  if (fileContents) {
    setFileContents(false);
    return;
  }

  fetchDocumentation(name, docType, featureFlag)
    .then((contents) => {
      const mdContents = `\`\`\`json\r\n${JSON.stringify(contents, null, 2)}\r\n\`\`\``;
      setFileContents(mdContents);
    })
    .catch((error) => console.error('ERROR:', error));
};

const renderContentsInline = (content) => (content ? (
  <div className={styles.inlineContent}>
    <Markdown>
      { content }
    </Markdown>
  </div>
) : null);

function File(props) {
  const [fileContents, setFileContents] = useState(false);

  const onFileLinkClicked = () => {
    toggleDisplayFileContents(props.name, fileContents, setFileContents, props.docType, props.featureFlag);
  };

  return (
    <>
      <span className={styles.File}>
        <span>{props.name}</span>
        <button type="button" className="btn btn-link" onClick={onFileLinkClicked}>
          { fileContents ? 'Hide File' : 'View File' }
        </button>
      </span>
      <span>
        { renderContentsInline(fileContents) }
      </span>
    </>
  );
}

export default File;
