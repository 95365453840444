import flinksLogo from './images/flinks-logo.png';
import FlinksCredentials from './IntegrationInputForms/FlinksCredentials.view';
import inovatecLogo from './images/inovatec.png';
import InovatecCdpCredentials from './IntegrationInputForms/InovatecCdpCredentials.view';
import inveriteLogo from './images/inverite-logo.png';
import InveriteCredentials from './IntegrationInputForms/InveriteCredentials.view';
import plaidLogo from './images/plaid-logo.png';
import PlaidCredentials from './IntegrationInputForms/PlaidCredentials.view';
import transunionLogo from './images/transunion-logo.png';
import TransUnionCredentials from './IntegrationInputForms/TransUnionCredentials.view';
import urjanetLogo from './images/urjanet-logo.png';
import UrjanetCredentials from './IntegrationInputForms/UrjanetCredentials.view';
import finicityLogo from './images/finicity-logo.png';
import FinicityCredentials from './IntegrationInputForms/FinicityCredentials.view';

export const finicity = {
  key: 'finicity',
  name: 'Finicity',
  image: finicityLogo,
  component: FinicityCredentials,
};

export const flinks = {
  key: 'flinks',
  name: 'Flinks',
  image: flinksLogo,
  component: FlinksCredentials,
};

export const inovatecCdp = {
  key: 'inovateccdp',
  name: 'Inovatec CDP',
  image: inovatecLogo,
  component: InovatecCdpCredentials,
};

export const inverite = {
  key: 'inverite',
  name: 'Inverite',
  image: inveriteLogo,
  component: InveriteCredentials,
};

export const plaid = {
  key: 'plaid',
  name: 'Plaid',
  image: plaidLogo,
  component: PlaidCredentials,
};

export const transunion = {
  key: 'transunion',
  name: 'TransUnion',
  image: transunionLogo,
  component: TransUnionCredentials,
};

export const urjanet = {
  key: 'urjanet',
  name: 'Urjanet',
  image: urjanetLogo,
  component: UrjanetCredentials,
};
