export const personInfo = {
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  aliases: 'Aliases',
  secondLastName: 'Second Last Name',
  dateOfBirth: 'Date of Birth',
  email1: 'Primary Email',
  email2: 'Secondary Email',
  sendMobileInvite: 'Smart Consent™ Invitation',
  homePhoneNumber: 'Home Phone',
  cellPhoneNumber: 'Cell Phone',
  workPhoneNumber: 'Work Phone',
  address: 'Address',
  city: 'City',
  state: 'State / Province',
  postalCode: 'Zip / Postal Code',
  country: 'Country',
  residenceStatus: 'Residence Status',
  isCurrentResidence: 'Is Current Residence',
  monthlyResidenceCost: 'Monthly Residence Cost',
  durationAtResidence: 'Duration at Residence',
  socialInsuranceNumber: 'SSN / SIN',
  driversLicense: 'Driver\'s License',
};

export const employerInfo = {
  address: 'Address',
  city: 'City',
  state: 'State / Province',
  postalCode: 'Zip / Postal Code',
  country: 'Country',
  phoneNumber: 'Phone',
  employmentType: 'Employment Type',
  jobTitle: 'Job Title',
  durationAtEmployer: 'Duration at Employer',
};

export const incomeInfo = {
  name: 'Employer Name / Income Type',
  grossIncome: 'Gross Income',
  netIncome: 'Net Income',
  paymentsPerYear: 'Payments per Year',

};

export const dealershipInfo = {
  name: 'Location Name',
  address: 'Address',
  city: 'City',
  state: 'State / Province',
  postalCode: 'Zip / Postal Code',
  country: 'Country',
  type: 'Location Type',
  storeNumber: 'Store Number'
};

export const vehicleInfo = {
  tradeInAllowance: 'Trade-In Allowance',
  VIN: 'VIN',
  appraisedValue: 'Appraised Value',
  bodyStyle: 'Body Style',
  year: 'Year',
  make: 'Make',
  model: 'Model',
  trim: 'Trim',
  condition: 'Condition',
  odometer: 'Odometer',
  value: 'Value',
};

export const loanDetailInfo = {
  loanDate: 'Date',
  loanId: 'Loan ID',
  customerId: 'Customer ID',
  principalAmount: 'Principal Amount',
  feeAmount: 'Fee Amount',
  downPaymentAmount: 'Downpayment Amount',
  annualInterestRate: 'Annual Interest Rate',
  paymentsPerYear: 'Payments per Year',
  totalNumberOfPayments: 'Total Number of Payments',
  paymentAmount: 'Payment Amount',
  serviceCharge: 'Service Charge',
  storeNumber: 'Store Number',
  storeState: 'Store State/Province',
};

export const assetInfo = {
  assetPurpose: 'Asset Purpose',
  assetValue: 'Asset Value',
};
