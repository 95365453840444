import React from 'react';
import styles from '../ProductsReport.module.scss';

const displayRla = (product) => {
  if (product.productStatus === 'notAvailable') return 'Not Available';
  const value = product.score || product.amount || product.value;
  if (!value) return 'No Recommended Amount';
  return `$ ${value}`;
};

const renderRLADefault = (product) => (
  <div>
    Amount:&nbsp;
    {displayRla(product)}
  </div>
);

const renderFull = (product) => (
  <div>
    <div>
      Recommended loan amount:&nbsp;
      {product.amount}
    </div>
    <div>
      State:&nbsp;
      {product.info?.state}
    </div>
    { product.info?.cashflow
      && (
        <div>
          Cash Flow:&nbsp;
          {product.info.cashflow}
        </div>
      )}
  </div>
);

const renderRLAMatrix = (product) => (
  <div className={styles.rlaMatrix}>
    <h5>Recommended Loan Amount Calculation</h5>
    <table className={`${styles.table} ${styles.alignRight}`}>
      <thead className={styles.tbody}>
        <tr>
          <th className={styles.columnLabels}>Months</th>
          {product.termMonths.map((value) => (
            <th key={value}>
              {value}
            </th>
          ))}
        </tr>
        <tr>
          <th className={styles.columnLabels}>Bi-Weekly/Loan Term</th>
          {product.termBiweekly.map((value) => (
            <th key={value}>
              {value}
            </th>
          ))}
        </tr>
        <tr>
          <th className={styles.columnLabels}>Interest Rate</th>
          {product.termMonths.map((value) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <th key={value}>&nbsp;</th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {product.interestRates.map((rate, rateIndex) => (
          <tr key={rate}>
            <th className={styles.columnLabels} key={`label-${rate}`}>
              {rate}
            </th>
            { product.termMonths.map((months, monthsIndex) => (
              <td key={`${rate}-${product.grid[monthsIndex][rateIndex]}`}>
                $&nbsp;
                { product.grid[monthsIndex][rateIndex] }
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const renderError = () => (
  <div>
    <div>Amount:&nbsp;Not Available</div>
    <div>Status:&nbsp;Error</div>
  </div>
);

const renderMap = {
  'RLA-285': renderFull,
  'RLA-507': renderRLADefault,
  'RLA-674': renderRLAMatrix,
  'RLA-678': renderRLADefault,
};

export default function RLA({ product }) {
  let render;
  if (product.productStatus === 'error') {
    render = renderError;
  } else {
    render = renderMap[product.productCode] || renderRLADefault;
  }
  return (
    <div className={`${styles.content} ${styles.l}`}>
      <h5>{`${product.productCode} ${product.primaryFlag ? '(Is Primary)' : ''}`}</h5>
      { render(product) }
    </div>
  );
}
