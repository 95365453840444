import React from 'react';
import {
  CardBody,
} from 'reactstrap';
import ReactJson from 'react-json-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import whiteList from '../../../config/scoringRequestDetailsWhitelist';

import styles from './DeveloperView.module.scss';
import CollapsibleCard from '../CollapsibleCard';

export default function ({ details }) {
  if (!details) return <div>no info</div>;

  const copyText = (text) => {
    navigator.clipboard.writeText(text)
      .catch((error) => console.error('Could not copy text: ', error));
  };

  const scoringApplication = {};
  Object
    .keys(details)
    .filter((key) => whiteList.includes(key))
    .forEach((key) => {
      scoringApplication[key] = details[key];
    });

  const latestReport = details?.reports?.[0];

  return (
    <CollapsibleCard className="_lr-hide" print={false} title="Raw Information for Developers" collapseKey="developerInfo">
      <CardBody className="text-left">
        <div className={styles.iconContainer}>
          <FontAwesomeIcon
            className={styles.copyJsonIcon}
            icon="copy"
            onClick={() => copyText(JSON.stringify(scoringApplication))}
          />
        </div>
        <ReactJson
          src={scoringApplication}
          name="Scoring Application"
        />
        <hr />
        {
            latestReport && (
              <div>
                <div className={styles.iconContainer}>
                  <FontAwesomeIcon
                    className={styles.copyJsonIcon}
                    icon="copy"
                    onClick={() => copyText(JSON.stringify(latestReport))}
                  />
                </div>
                <ReactJson
                  src={latestReport}
                  name="Latest Client Report"
                />
              </div>
            )
          }
      </CardBody>
    </CollapsibleCard>
  );
}
