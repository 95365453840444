import React from 'react';
import { CardBody } from 'reactstrap';

import styles from './NoReports.module.scss';

export default function (props) {
  return (
    <CardBody className={styles.cardBodyWrapper}>
      <h3>No Reports Generated</h3>
      <h4>{ props.message }</h4>
    </CardBody>
  );
}
