import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import styles from './TransUnionView.module.scss';

export default function ({ message }) {
  return (
    <div className={styles.reportHeader}>
      <Logo className={styles.logo} />
      <h4>
        TransUnion
        <br />
        Consumer Credit
        <br />
        Report
      </h4>
      {
      [].concat(message).filter((m) => m).map((m) => (
        <div key={m.replace(/\W/g, '_').toLowerCase()} className={styles.order}>
          <div className={styles.orderHeader}>
            { m }
          </div>
        </div>
      ))
    }
    </div>
  );
}
