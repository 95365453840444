import React, { useState } from 'react';

import ConfigMessageModal from '../ConfigMessageModal';
import saveConfigPatchToServer from '../utils/saveConfigPatchToServer';
import SanitizedTextInput from '../../../../components/SanitizedTextInput';

const INVITE_EXPIRATION_DAYS = 7;
const INVITE_MAX = 90;

export default function (props) {
  const hasSmartConsentBlock = !!props.config.smartConsent;
  const [expirationDays, setExpirationDays] = useState(props.config.smartConsent?.inviteExpirationDays || '');
  const [valueChanged, toggleValueChanged] = useState(false);
  const [displayMessage, setMessageDialog] = useState(false);
  const [modalType, setModalPopupType] = useState('');

  const cancelChange = () => {
    toggleValueChanged(false);
    setExpirationDays(props.config.smartConsent?.inviteExpirationDays);
  };

  const errorHandler = (message) => {
    setModalPopupType('error');
    setMessageDialog(message);
  };

  const onExpiryDaysSave = () => {
    const expirationDaysNum = Number(expirationDays);
    const payload = hasSmartConsentBlock ? [
      {
        op: 'add',
        path: '/smartConsent/inviteExpirationDays',
        value: expirationDaysNum,
      },
    ] : [
      {
        op: 'add',
        path: '/smartConsent',
        value: { inviteExpirationDays: expirationDaysNum },
      },
    ];
    saveConfigPatchToServer(payload, props.isUat)
      .then((res) => {
        props.setConfig(res);
      }).catch((error) => {
        errorHandler(error.message);
        setExpirationDays(props.config.smartConsent?.inviteExpirationDays);
      }).finally(() => {
        toggleValueChanged(false);
        props.togglePerformingSave(false);
      });
  };

  const onSaveButtonClicked = () => {
    if (props.performingSave) {
      return;
    }

    const expirationDaysNum = Number(expirationDays);
    if (expirationDays !== '' && (Number.isNaN(expirationDaysNum) || !Number.isInteger(expirationDaysNum) || expirationDaysNum < 1 || expirationDaysNum > INVITE_MAX)) {
      errorHandler('Please enter a positive number between 1 and 90.');
      return;
    }

    props.togglePerformingSave(true);
    onExpiryDaysSave();
  };

  const onValueChanged = (event) => {
    const newExpiration = event.target.value;
    const changed = newExpiration !== expirationDays;
    toggleValueChanged(changed);
    setExpirationDays(newExpiration);
  };

  return (
    <>
      {
        displayMessage && (
          <ConfigMessageModal
            title="Problem saving configuration."
            onModalTransitionedOut={() => setMessageDialog(false)}
            modalType={modalType}
          >
            {displayMessage}
          </ConfigMessageModal>
        )
      }

      <div className={`mt-4 ${props.performingSave ? 'configRow buttons-disabled' : 'configRow'}`}>
        <div className="label">Invite Expiration</div>
        <div className="col-md-1 pl-0">
          <SanitizedTextInput
            className="codeStyleInput"
            name="expirationDays"
            onChange={onValueChanged}
            value={expirationDays}
          />
        </div>
        {
          valueChanged && (
            <div className="buttonRow">
              <button type="button" className="edit btn btn-success" onClick={onSaveButtonClicked}>
                Update
              </button>
              <button type="button" className="edit btn btn-cancel" color="secondary" onClick={cancelChange}>
                Cancel
              </button>
            </div>
          )
        }
      </div>
      <div className="text-muted help-text">
        Enter a value in days between 1 and
        {' '}
        { INVITE_MAX }
        . If left blank, invites will expire after
        {' '}
        {INVITE_EXPIRATION_DAYS}
        {' '}
        days.
      </div>
    </>
  );
}
