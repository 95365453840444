const flattenBlocks = (results, current) => {
  const { type, block } = current;
  if (type !== 'block') return results.concat(current);
  const blockToggle = {
    ...current,
    type: 'boolean',
    required: true,
  };
  if (!block?.length) return results.concat(blockToggle);
  return block.reduce(flattenBlocks, results.concat(blockToggle));
};

export default (customFieldConfig, initialValue) => (
  [].concat(customFieldConfig)
    .filter((c) => !!c)
    .reduce(flattenBlocks, [])
    .filter((f) => f.defaultValue || f.required || f.block)
    .reduce((customFields, field) => ({
      ...customFields,
      [`custom_${field.name}`]: customFields[`custom_${field.name}`] || field.defaultValue || '',
    }), initialValue)
);
