import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from 'reactstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import updateImpersonation from '../../utils/impersonation';
import SanitizedTextInput from '../SanitizedTextInput';
import { useEnabledFeatures } from '../../hooks/useEnabledFeatures';
import api from '../../services/api';

const onStop = (setStopWasSuccessful) => (
  updateImpersonation('', setStopWasSuccessful)
);

const renderImpersonationStatusModal = (success, setSuccess, clientName, onClose) => (
  <Modal isOpen>
    <ModalHeader>Account Switching</ModalHeader>
    <ModalBody>
      {success === undefined // eslint-disable-line no-nested-ternary
        ? (
          <div>
            You have switched to the
            {` ${clientName} `}
            account
          </div>
        )
        : !success
            && (
              <div>
                There was an error while stopping switching account.
                <br />
                Please try again.
              </div>
            )}
    </ModalBody>
    <ModalFooter>
      { success === undefined
        ? (
          <Button
            color="warning"
            onClick={() => onStop(setSuccess)}
          >
            Stop
          </Button>
        )
        : null}
      <Button
        color="primary"
        onClick={onClose}
      >
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

const renderImpersonatingModal = (client, setClient, success, save, onClose, switchableAccounts, isTrustScienceClient, isLoading) => (
  <Modal isOpen>
    <ModalHeader>Account Switching</ModalHeader>
    <ModalBody>
      {success === undefined
        ? (
          <Form onSubmit={(event) => {
            event.preventDefault();
            save(client);
          }}
          >
            <FormGroup>
              <Label for="client">Client</Label>
              {isTrustScienceClient
                ? (
                  <ReactSearchAutocomplete
                    items={switchableAccounts}
                    fuseOptions={{ keys: ['id'] }}
                    resultStringKeyName="id"
                    onSearch={(id) => setClient(id)}
                    onSelect={({ id }) => save(id)}
                    onClear={() => setClient('')}
                    autoFocus
                    showIcon={false}
                    styling={{
                      height: '34px',
                      borderRadius: '4px',
                      backgroundColor: 'white',
                      boxShadow: 'none',
                      clearIconMargin: '3px 8px 0 0',
                      zIndex: 2,
                    }}
                  />
                )
                : (
                  <SanitizedTextInput
                    id="client"
                    name="client"
                    type="select"
                    onChange={(e) => setClient(e.target.value)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <option value="" key="1" />
                    {switchableAccounts && switchableAccounts.map(({ id, display }) => (<option value={id} key={id}>{display}</option>))}
                  </SanitizedTextInput>
                )}

            </FormGroup>
          </Form>
        )
        : !success
            && (
              <div>
                Unable to switch to that client.
                <br />
                Please ensure the client id is correct and that
                you have sufficient privileges.
              </div>
            )}
    </ModalBody>
    <ModalFooter>
      { success === undefined
        ? (
          <Button
            id="save"
            color="primary"
            onClick={() => save(client)}
            disabled={!client || isLoading}
            outline={isLoading}
          >
            {isLoading ? (<Spinner className="d-flex" size="sm" color="primary" />) : 'Save'}
          </Button>
        )
        : null}
        &nbsp;
      <Button
        id="cancel"
        color="secondary"
        onClick={onClose}
      >
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

const renderExpiredImpersonationModal = (setSuccess, clientId, isLoading, save) => (
  <Modal isOpen>
    <ModalHeader>Account Switching</ModalHeader>
    <ModalBody>
      <div>
        Your current session has expired.
        <br />
        Do you want to renew it?
      </div>
    </ModalBody>
    <ModalFooter>
      <Button
        id="save"
        color="primary"
        onClick={() => save(clientId)}
        outline={isLoading}
      >
        {isLoading ? (<Spinner className="d-flex" size="sm" color="primary" />) : 'Yes'}
      </Button>
        &nbsp;
      <Button
        color="secondary"
        onClick={() => onStop(setSuccess)}
      >
        No
      </Button>
    </ModalFooter>
  </Modal>
);

export default ({
  session,
  onClose,
  switchableAccounts,
  isTrustScienceClient,
  clientName,
}) => {
  const [client, setClient] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(undefined);

  const switchableAccountsTransformed = switchableAccounts && Object.entries(switchableAccounts).map(([id, display]) => ({ id, display }));
  const [switchableAccountsList, setSwitchableAccountsList] = useState(switchableAccountsTransformed);

  const { allowSwitchingAccounts } = useEnabledFeatures({ env: 'prod' });

  useEffect(() => {
    async function fetchData() {
      if (!switchableAccountsList && allowSwitchingAccounts) {
        const { clientIds } = await api('ListSwitchableAccounts');
        setSwitchableAccountsList(clientIds.map((id) => ({ id })));
      }
    }
    fetchData();
  }, [allowSwitchingAccounts, switchableAccountsList, setSwitchableAccountsList]);

  if (success) {
    window.location.reload(false);
    return null;
  }

  const save = (clientToSave) => {
    setIsLoading(true);
    updateImpersonation(clientToSave, setSuccess).finally(() => setIsLoading(false));
  };

  if (session.expired) {
    return renderExpiredImpersonationModal(setSuccess, session.impersonating, isLoading, save);
  }

  return session.impersonating
    ? renderImpersonationStatusModal(success, setSuccess, clientName, onClose)
    : renderImpersonatingModal(
      client,
      setClient,
      success,
      save,
      onClose,
      switchableAccountsList,
      isTrustScienceClient,
      isLoading,
    );
};
