import React from 'react';
import { Input } from 'reactstrap';
import dompurify from 'dompurify';

const sanitizeInput = (event) => {
  // eslint-disable-next-line no-param-reassign
  event.target.value = dompurify.sanitize(event.target.value);
  return event;
};

const normalizeInput = (event, normalizer) => {
  // eslint-disable-next-line no-param-reassign
  event.target.value = normalizer(event.target.value);
  return event;
};

const passthrough = (x) => (x);
const noop = () => {};

export default function ({
  onChange, onBlur = noop, normalizer = passthrough, children, ...props
}) {
  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={(e) => { onChange(sanitizeInput(e)); }}
      onBlur={(e) => { onBlur(normalizeInput(e, normalizer)); }}
    >
      {children}
    </Input>
  );
}
