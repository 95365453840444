import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfigMessageModal from '../ConfigMessageModal';
import { removeWhiteSpace } from '../../../../utils/string';
import { addCallback, updateCallback, removeCallback } from '../utils/callbackChanges';
import validateCallback from './validate-callback';
import SanitizedTextInput from '../../../../components/SanitizedTextInput';

const tagIndex = 0;
const urlIndex = 1;
const versionIndex = 2;
const defaultReportVersion = 'v2';

const checkNewFormat = (obj) => typeof obj !== 'string';

const checkCallbackChanged = (callback, originalCallback, isEmptyRow) => {
  const callbackSliced = callback.slice(0);
  const newTag = removeWhiteSpace(callbackSliced[0]);
  const originalTag = isEmptyRow || !checkNewFormat(originalCallback.slice(0)[urlIndex])
    ? originalCallback.slice(0)[tagIndex]
    : originalCallback.slice(0)[urlIndex].tag;

  const newUrl = removeWhiteSpace(callbackSliced[1]);
  const originalUrl = isEmptyRow || !checkNewFormat(originalCallback.slice(0)[urlIndex])
    ? originalCallback.slice(0)[urlIndex]
    : originalCallback.slice(0)[urlIndex].url;

  const newVersion = callbackSliced.length > 2
    ? removeWhiteSpace(callbackSliced[2])
    : '';
  const originalVersion = (callbackSliced.length && isEmptyRow) > 2
    ? originalCallback.slice(0)[1].version
    : '';
  return newTag !== originalTag
          || newUrl !== originalUrl
          || newVersion !== originalVersion;
};

export default function (props) {
  const [callback, setCallback] = useState(props.callback);
  const [tag, currentConfigUrl] = callback;
  const isEmptyRow = props.callback.length === 0;

  const [changesMade, setChangesMade] = useState(false);
  const [displayModal, toggleDisplayModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalPopupType] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const onCatch = (error) => {
    setModalMessage(error.message);
    setModalPopupType('error');
    setModalTitle('Problem saving configuration');
    toggleDisplayModal(true);
  };

  const onDeleteButtonClick = () => {
    setModalPopupType('delete');
    setModalTitle('Callback Removal Confirmation');
    setModalMessage('Are you sure you want to delete the following callback?');
    toggleDisplayModal(true);
  };

  const renderTrashIcon = () => (
    <div className="label editing">
      {
        !isEmptyRow
          ? (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button type="button" className="btn btn-link icon" onClick={onDeleteButtonClick}>
              <FontAwesomeIcon icon="trash" />
            </button>
          )
          : null
      }
    </div>
  );

  const fixFormat = (currentParam, type) => {
    let response = currentParam;
    if (checkNewFormat(currentParam) && type !== 'updateChange') {
      return response;
    }
    switch (type) {
      case 'tagChange':
        response = { url: callback[urlIndex], version: defaultReportVersion };
        break;
      case 'urlChange':
        response = { tag: callback[tagIndex], version: defaultReportVersion };
        break;
      case 'versionChange':
        if (checkNewFormat(currentParam)) {
          response = currentParam;
        } else {
          response = { tag: callback[tagIndex], url: currentParam };
        }
        break;
      case 'updateChange':
        if (checkNewFormat(currentParam[urlIndex])) {
          response = {
            tag: removeWhiteSpace(callback[urlIndex].tag),
            url: removeWhiteSpace(callback[urlIndex].url),
            version: defaultReportVersion,
          };
        } else {
          response = {
            tag: callback[tagIndex],
            url: callback[urlIndex],
            version: defaultReportVersion,
          };
        }
        break;
      default:
        break;
    }
    return response;
  };

  const onCallbackTagChanged = (event) => {
    const newCallback = callback.slice(0);
    newCallback[tagIndex] = removeWhiteSpace(event.target.value);
    if (isEmptyRow && newCallback.length <= 1) {
      newCallback[urlIndex] = '';
      setCallback([
        newCallback[tagIndex],
        {
          tag: newCallback[tagIndex],
          url: newCallback[urlIndex],
          version: defaultReportVersion,
        },
      ]);
      setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
      return;
    }
    newCallback[urlIndex] = removeWhiteSpace(callback[urlIndex].url);
    const callbackConfig = fixFormat(callback[urlIndex], 'tagChange');
    setCallback([newCallback[tagIndex], { ...callbackConfig, tag: newCallback[tagIndex] }]);
    setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
  };

  const onCallbackUrlChanged = (event) => {
    const newCallback = callback.slice(0);
    newCallback[1] = removeWhiteSpace(event.target.value);
    if (!isEmptyRow && newCallback.length > 1) {
      const callbackConfig = fixFormat(callback[urlIndex], 'urlChange');
      setCallback([newCallback[tagIndex], { ...callbackConfig, url: newCallback[urlIndex] }]);
      setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
      return;
    }
    newCallback[tagIndex] = newCallback[tagIndex] ? newCallback[tagIndex] : '';
    setCallback([
      newCallback[tagIndex],
      {
        tag: newCallback[tagIndex],
        url: newCallback[urlIndex],
        version: defaultReportVersion,
      },
    ]);
    setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
  };

  const handleSelectVersion = (event) => {
    const version = event.target.value;
    const newCallback = callback.slice(0);
    if (!isEmptyRow && newCallback.length > 1) {
      const cb = fixFormat(newCallback, 'updateChange');
      newCallback[tagIndex] = cb.tag;
      newCallback[urlIndex] = cb.url;
      newCallback[versionIndex] = version;
      const callbackConfig = fixFormat(callback[urlIndex], 'versionChange');
      setCallback([newCallback[tagIndex], { ...callbackConfig, version }]);
      setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
      return;
    }
    newCallback[urlIndex] = checkNewFormat(callback[urlIndex]) ? removeWhiteSpace(callback[urlIndex].url) : '';
    newCallback[versionIndex] = version;
    setCallback([newCallback[tagIndex], { ...callback[urlIndex], version }]);
    setChangesMade(checkCallbackChanged(newCallback, props.callback, isEmptyRow));
  };

  const onSaveButtonClicked = () => {
    if (props.performingSave) {
      return;
    }

    const callbackIsValid = validateCallback(callback);
    if (!callbackIsValid) {
      setModalMessage('Please check your tag and callback URL. URLs must specify the HTTPS protocol.');
      setModalPopupType('error');
      setModalTitle('Problem saving configuration');
      toggleDisplayModal(true);
      return;
    }

    if (props.checkCallbackTagAlreadyExists(callback, props.callback)) {
      setModalMessage(`The tag "${callback.slice(0)[0]}" already exists.`);
      setModalPopupType('error');
      setModalTitle('Problem saving configuration');
      toggleDisplayModal(true);
      return;
    }

    if (isEmptyRow) {
      addCallback(callback, setChangesMade, setCallback, props.togglePerformingSave, props, onCatch);
      return;
    }

    setModalPopupType('update');
    setModalTitle('Callback Update Confirmation');
    setModalMessage('Are you sure you want to update the following callback?');
  };

  const onCancelButtonClicked = () => {
    if (props.performingSave) {
      return;
    }

    setCallback(props.callback);
    setChangesMade(false);
  };

  const onModalCloseWithOK = () => {
    setModalMessage('');
    toggleDisplayModal(false);

    if (modalType === 'delete') {
      props.togglePerformingSave(true);
      removeCallback(
        setChangesMade,
        props.callback[0],
        props.togglePerformingSave,
        props,
        onCatch,
      );
    }

    if (modalType === 'update') {
      props.togglePerformingSave(true);
      updateCallback(
        callback,
        props.callback[0],
        setChangesMade,
        props.togglePerformingSave,
        props,
        onCatch,
      );
    }
  };

  const onModalTransitionedOut = () => {
    setModalMessage('');
    toggleDisplayModal(false);
  };

  return (
    <>
      {
        displayModal || modalMessage
          ? (
            <ConfigMessageModal
              tag={tag || currentConfigUrl?.tag || ''}
              url={currentConfigUrl?.url || ''}
              version={currentConfigUrl?.version || defaultReportVersion}
              title={modalTitle}
              modalType={modalType}
              toggleDisplayModal={toggleDisplayModal}
              onModalTransitionedOut={onModalTransitionedOut}
              onModalCloseWithOK={onModalCloseWithOK}
              performingSave={props.performingSave}
              togglePerformingSave={props.togglePerformingSave}
            >
              { modalMessage }
            </ConfigMessageModal>
          )
          : null
      }

      <div className="configRow valuesOnly">
        { renderTrashIcon(isEmptyRow, toggleDisplayModal) }
        <SanitizedTextInput
          data-cy={props.isUat ? 'uat-callback-tag-input' : ''}
          className="codeStyleInput"
          onChange={onCallbackTagChanged}
          type="text"
          value={tag || currentConfigUrl?.tag || ''}
        />
        <SanitizedTextInput
          data-cy={props.isUat ? 'uat-callback-url-input' : ''}
          className="codeStyleInput _lr-hide"
          onChange={onCallbackUrlChanged}
          type="text"
          value={!checkNewFormat(currentConfigUrl) ? currentConfigUrl : currentConfigUrl?.url || ''}
        />

        {
          props.enabledFeatures?.allowSwitchReportVersion
            ? (
              <div className="codeStyleInput selectVersion">
                <select
                  style={{ width: '80px', textAlign: 'center' }}
                  key={currentConfigUrl?.version || defaultReportVersion}
                  defaultValue={currentConfigUrl?.version || defaultReportVersion}
                  onChange={(event) => handleSelectVersion(event, tag)}
                >
                  <option value="v2">v2</option>
                  <option value="v3">v3</option>
                </select>
              </div>
            )
            : ''
        }

        <div
          className={`${props.performingSave ? 'buttons-disabled' : ''} callbackBtns 
          ${(changesMade || isEmptyRow) ? 'visibleDisplay' : 'displayHidden'}`}
        >
          <button
            data-cy={props.isUat ? 'uat-callback-add-button' : ''}
            type="button"
            className={`edit btn btn-success p-1 ${isEmptyRow ? 'btn-add' : ''}`}
            onClick={onSaveButtonClicked}
          >
            { isEmptyRow ? 'Add' : 'Update' }
          </button>
          <button type="button" className="edit btn btn-cancel p-1" color="secondary" onClick={onCancelButtonClicked}>
            { isEmptyRow ? 'Clear' : 'Cancel' }
          </button>
        </div>

      </div>
    </>
  );
}
