import React from 'react';

function HelpMessage() {
  return (
    <div>
      <h3>Monthly Income Chart</h3>
      <p>
        Shows the monthly income sources for the last 12 months.
      </p>
      <h3>Self-reported Income</h3>
      <p>
        The total monthly income reported in the application.
      </p>
      <h3>Selected Income</h3>
      <p>
        The sum of selected income sources. This is broken down into high confidence income and all income (including high confidence income).
      </p>
      <h5>Line toggles</h5>
      <p>
        You can toggle the visibility of a line showing the sum of of selected incomes in the chart by clicking on the corresponding heading.
        This can be useful to determine if too many income sources have been selected.
      </p>
      <h5>Calculation Summary</h5>
      <p>
        You can click on the arrows next to each heading to see how the total is calculated.
      </p>
      <h3>Recent Income & Credit Sources</h3>
      <p>
        Includes income sources that have appeared in the last 3 months.
      </p>
      <h5>High Confidence Income</h5>
      <p>
        Includes incomes sources that are expected to continue at the same level into the future.
      </p>
      <h5>Other Income</h5>
      <p>
        Includes incomes sources that are not classified as high confidence income.
      </p>
      <h5>Other Credits</h5>
      <p>
        Includes one-off and other transactions that resemble income, but the system has not classified as income.
      </p>
      <h4>Fields</h4>
      <b>Description</b>
      <p>
        The description of the income source from the corresponding transactions.
      </p>
      <b>Monthly Amount</b>
      <p>
        The average monthly amount of the income source.
      </p>
      <b>Stability</b>
      <p>
        A metric indicating the reliability of the income source.
      </p>
      <b>Next payment</b>
      <p>
        The predicted date of the next payment from this income source.
      </p>
      <b>Start date</b>
      <p>
        The date of the first transaction for this income source.
      </p>
      <b>End date</b>
      <p>
        The date of the most recent transaction for this income source.
      </p>
      <b>Income Category</b>
      <p>
        The type of income source. Government subsidy, paycheck, etc. This can have multiple subcategories.
      </p>
      <b>Frequency</b>
      <p>
        The frequency of the income source. Weekly, bi-weekly, monthly, etc.
      </p>
      <b>Transactions</b>
      <p>
        You can click on the arrow on the right side of each income source card to see the transactions corresponding to that income source.
      </p>
    </div>
  );
}

export default HelpMessage;
