import React, { Component } from 'react';
import {
  Form,
} from 'reactstrap';
import LocationInformation from './LocationInformation';
import VehicleInformation from './VehicleInformation';
import LoanInformation from './LoanInformation';
import AssetInformation from './AssetInformation';
import TradeInInformation from './TradeInInformation';
import SCORING_USE_CASES from '../../config/scoringRequestUseCases';
import getUserLocationIdFromAttributesIfDefined from '../../utils/getUserLocationIdFromAttributesIfDefined';
import * as userService from '../../services/user-services';

import './LoanDetailsFormGroup.scss';
import { withUseModal } from '../../hooks/useModal';

const updateUserPreferredLocation = async (selectedAddress) => {
  try {
    await userService.updateUserAttributes({ preferredLocationId: selectedAddress.storeNumber });
  } catch (error) {
    console.error('Unable to update user attributes', error);
  }
};

class LoanDetailsFormGroup extends Component {
  validateFormBeforeProceeding = this.validateFormBeforeProceeding.bind(this);

  loanFormGroup = React.createRef();

  vehicleFormGroup = React.createRef();

  dealershipFormGroup = React.createRef();

  assetFormGroup = React.createRef();

  tradeInFormGroup = React.createRef();

  constructor(props) {
    super(props);
    this.state = { defaultLocationId: null };
  }

  componentDidMount() {
    if (this.props.sendToAms && !this.props.enabledFeatures.enableLocations) {
      console.error('Invalid AMS configuration. Locations must be enabled for AMS requests.');
    }
    this.loadDefaultLocation().catch(console.error);
  }

  loadDefaultLocation = async () => {
    const userAttributes = await userService.getUserAttributes();
    const defaultLocationId = getUserLocationIdFromAttributesIfDefined(userAttributes);
    if (!defaultLocationId) return;
    this.setState({ defaultLocationId });
  };

  showModal() {
    this.props.useModalProp.showModal({
      header: 'Problems with form.',
      body: 'We have detected incorrect input(s) from the form.\nPlease correct the form inputs and submit again.',
    });
  }

  // eslint-disable-next-line no-dupe-class-members
  validateFormBeforeProceeding() {
    let dealershipSectionIsInvalid;
    if (this.dealershipFormGroup.current) {
      dealershipSectionIsInvalid = this.dealershipFormGroup.current.runValidation();
    }
    const loanApplicationSectionIsInvalid = this.loanFormGroup.current.runValidation();

    let assetSectionIsInvalid = false;
    if (this.props.featureBlockAsset) {
      assetSectionIsInvalid = this.assetFormGroup.current.runValidation();
    }

    let vehicleSectionIsInvalid;
    if (this.props.requestUseCase === SCORING_USE_CASES.AUTO) {
      vehicleSectionIsInvalid = this.vehicleFormGroup.current.runValidation();
    }

    let tradeInSectionIsInvalid = false;
    if (this.props.enabledSections.tradeIn) {
      tradeInSectionIsInvalid = this.tradeInFormGroup.current.runValidation();
    }

    if (
      dealershipSectionIsInvalid
      || vehicleSectionIsInvalid
      || loanApplicationSectionIsInvalid
      || assetSectionIsInvalid
      || tradeInSectionIsInvalid
    ) {
      this.showModal();
      return true;
    }

    return false;
  }

  render() {
    const isPreapproval = this.props.requestUseCase.match(/-preapproval/ig)
      || this.props.enabledSections.preapproval;

    const autoLoanWorkflow = this.props.requestUseCase === SCORING_USE_CASES.AUTO
      || this.props.requestUseCase === SCORING_USE_CASES.AUTO_PREAPPROVAL;

    return (
      <Form className="auto-loan-container">
        {
            this.props.enabledFeatures.enableLocations
              ? (
                <LocationInformation
                  ref={this.dealershipFormGroup}
                  dataTreeKey="dealership"
                  sendToAms={this.props.sendToAms}
                  addresses={this.props.addresses}
                  required={this.props.enabledFeatures.requireLocationInForms}
                  highlightEssentialInputs={this.props.enabledFeatures.requireLocationInForms}
                  defaultLocationId={this.state.defaultLocationId}
                  updateUserPreferredLocation={updateUserPreferredLocation}
                  forceAllInputsOptional={isPreapproval}
                />
              ) : null
          }
        {
            autoLoanWorkflow
              ? (
                <VehicleInformation
                  ref={this.vehicleFormGroup}
                  dataTreeKey="vehicle"
                  forceAllInputsOptional={isPreapproval}
                />
              )
              : null
          }
        <LoanInformation
          useCase={this.props.requestUseCase}
          ref={this.loanFormGroup}
          dataTreeKey="loanInfo"
          requirePrincipalAmount={!this.props.allowEmptyPrincipalAmount}
          isPreapproval={isPreapproval}
          customLoanFields={this.props.customApplicationFormFields?.loanInfo}
          region={this.props.region}
          enabledFeatures={this.props.enabledFeatures}
        />
        {
            this.props.featureBlockAsset
              ? <AssetInformation ref={this.assetFormGroup} dataTreeKey="asset" />
              : null
          }
        {
            this.props.enabledSections.tradeIn
              ? (
                <TradeInInformation
                  ref={this.tradeInFormGroup}
                  dataTreeKey="tradeIn"
                  forceAllInputsOptional={isPreapproval}
                />
              )
              : null
          }
      </Form>
    );
  }
}

export default withUseModal(LoanDetailsFormGroup);
